import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';

import { AuthService } from './auth.service';
import { RefreshTokenDate } from '../../classes/refreshTokenDate.class';
import { IUser } from '../../models/IUser';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(navigator.onLine) {
      if (req.headers.has('Content-Type')) {
        if (req.headers.get('Content-Type') == 'multipart/form-data') {
          req = req.clone({
            headers: req.headers.delete('Content-Type', 'multipart/form-data')
          });
        }
      } else {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json')
        });
      }
  
      const loginDados = this.authService.getLoginDados();
      if (loginDados && loginDados.acesso) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${loginDados.acesso.token}`
          },
          headers: req.headers.set(
            'Content-Language',
            this.authService.getIdiomaUsuario()
          )
        });
      } else {
        const excecao = ['i18n', 'login', 'configuracao'];
        excecao.forEach(function (value) {
          if (req.url.indexOf(value) < 0) {
            return EMPTY;
          }
        });
      }
  
      if (loginDados != null) {
        if(RefreshTokenDate.checksIfNeedsRefresh()){
          var newDateToRefresh = new Date(RefreshTokenDate.dateToRefresh);
          newDateToRefresh.setHours(newDateToRefresh.getHours() + 1);
          RefreshTokenDate.dateToRefresh = newDateToRefresh;
  
          var dados = {
            email: loginDados.dados.email,
            userId: loginDados.dados.id,
            userName: loginDados.dados.login,
            isActiveDirectory: false,
            encryptedPassword : loginDados.acesso.encryptedPassword,
            token: loginDados.acesso.token,
          };
  
          var token: any;
          this.authService.refreshTokenJWT(dados)
          .subscribe((result) => {
            token = result;
            this.setToken(token, loginDados)
          });
        }
      }
  
      return next.handle(req);
    }
  }

  private setToken(token: string, loginDados: any){
    let user: IUser = loginDados
    user.acesso.token = token;

    this.authService.saveUserInfoOnLocalStorage(user);
  }
}
