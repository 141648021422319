<div class="modal" modal-name="modalLicenca">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <mat-form-field class="fieldLicencaLq" appearance="outline">
            <mat-label>{{ "paginas.licenca.modal.lq" | translate }}</mat-label>
            <input matInput formControlName="lq" type="number" />
          </mat-form-field>
          
          <mat-form-field class="fieldLicencaLd" appearance="outline">
            <mat-label>{{ "paginas.licenca.modal.ld" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickerDataHoraInicio"
              formControlName="ld"
              [min]="formValidParam.startDate"
              (dateChange)="changeDateDe($event)"
             
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataHoraInicio"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #pickerDataHoraInicio
              [startAt]="formValidParam.startDate"
            >
            </mat-datepicker>
          </mat-form-field>

          <mat-form-field class="fieldLicencaGuid" appearance="outline">
            <mat-label>{{
              "paginas.licenca.modal.guid" | translate
            }}</mat-label>
            <input matInput formControlName="guid" type="password" />
          </mat-form-field>
        </div>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary cancel"
              (click)="cancel()"
              [disabled]="formulario.invalid"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>

            <button
              type="submit"
              [disabled]="formulario.invalid"
              mat-button
              class="save"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <app-login 
*ngIf="closeForm"
>
</app-login> -->
