import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IUsuarioAtendente } from '../models/IUsuarioAtendente';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 19;

@Injectable({
  providedIn: 'root'
})
export class UsuarioAtendenteService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}UsuarioAtendente`;

  getGrid(): Observable<IUsuarioAtendente[]> {
    return this.http.get<IUsuarioAtendente[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IUsuarioAtendente) {
    return this.http.get<IUsuarioAtendente>(
      `${this.apiUrl}/Form/${dados.usuarioId}`
    );
  }

  insert(usuarioId: number) {
    return this.http.post(`${this.apiUrl}`, usuarioId);
  }

  update(dados: IUsuarioAtendente) {
    return this.http.put<IUsuarioAtendente>(`${this.apiUrl}`, dados);
  }

  delete(usuarioId: number) {
    return this.http.delete(`${this.apiUrl}/${usuarioId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
