<div class="modal">
    <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
        <div class="modal-container">

            <div class="modal-title" cdkDragHandle>
                <h2>{{ this.wordsTranslated.tituloGrid }} {{ atendimentoId }}</h2>
                <button mat-button (click)="close()" class="close">
                <i class="material-icons">close</i>
                </button>
            </div>

            <ws-grid
                *ngIf="!isLoading"
                [permissoes]="permissoesTela"
                [isLoading]="isLoading"
                [acoesGrid]="acoesGridMensagens"
                [colunasGrid]="colunasGridMensagens"
                [dadosGrid]="dadosGridMensagens">
            </ws-grid>
            
        </div>
    </div>
</div>

