<div mat-dialog-title>
  <h2>{{data.title}}</h2>
  <button mat-button class="close" (click)="dialogRef.close()">
    <i class="material-icons">close</i>
  </button>
</div>
<mat-dialog-content>
  <mat-card appearance="outlined">
    <ul>
      <li>Title:</li>
      <li>
        {{data.registro.Title ? data.registro.Title : '-'}}
      </li>
      <li (click)="showDetail()">
        <span class="material-icons">
          visibility
        </span>
      </li>
    </ul>
  </mat-card>
  <mat-card appearance="outlined" class="detail" *ngIf="detail">
    <ul *ngIf="data.registro.Detail">
      <li>Detail:</li>
      <li [innerHTML]="data.registro.Detail ? data.registro.Detail : '-'"></li>
    </ul>
    <hr *ngIf="data.registro.Detail">
    <ul>
      <li>Status:</li>
      <li>{{data.registro.StatusCode ? data.registro.StatusCode : '-'}}</li>
    </ul>
    <hr>
    <ul>
      <li>Url:</li>
      <li>{{data.registro.Instance ? data.registro.Instance : '-'}}</li>
    </ul>
    <hr>
    <ul>
      <li>Method:</li>
      <li>{{data.registro.Method ? data.registro.Method : '-'}}</li>
    </ul>
  </mat-card>
</mat-dialog-content>