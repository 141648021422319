import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IParametrosConfiguracaoSistema } from '../models/IParametrosConfiguracaoSistema';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ParametrosConfiguracaoSistemaService } from '../services/parametros-configuracao-sistema.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-parametros-configuracao-sistema-grid',
  templateUrl: './parametros-configuracao-sistema-grid.component.html'
})
export class ParametrosConfiguracaoSistemaGridComponent implements OnInit {
  @Input() permissoes: EventEmitter<IGridPermissoes>;

  public isLoading: boolean;
  public editForm = false;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IParametrosConfiguracaoSistema[];
  public dadosFormulario: IParametrosConfiguracaoSistema;

  wordsTranslated: any;

  constructor(
    private parametrosConfiguracaoSistemaService: ParametrosConfiguracaoSistemaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    public gridService: WsGridService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.configuracaoSistema.parametros.grid.';
    const words = {
      descricao: '',
      valor: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.editForm = this.permissoes['update'];
    this.configuraAcoesGrid();
    this.configuraColunasGrid();
    this.configuraDadosGrid();
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: false,
      editable: this.editForm ? 'parametroSistemaEditavel' : null,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: false,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'parametroSistemaDescricao',
        label: this.wordsTranslated.descricao,
        width: '50%',
        sort: true
      },
      {
        name: 'parametroSistemaValor',
        label: this.wordsTranslated.valor,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.parametrosConfiguracaoSistemaService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        parametroSistemaId: dados.parametroSistemaId
      });
    }

    this.dadosGrid = this.gridService.refreshGridData(
      this.dadosGrid,
      dados,
      indexPage,
      tipo
    );
  }

  openForm(dados: IParametrosConfiguracaoSistema = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeFormaPagamentoForm() {
    this.showForm = false;
  }
}
