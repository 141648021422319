import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  OnDestroy
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet, Portal } from '@angular/cdk/portal';
import { WindowSoftPhoneComponent } from './window/window-soft-phone.component';

@Component({
  selector: 'window',
  template: ``
})
export class WindowComponent implements OnInit, OnDestroy {
  portal: Portal<any>;
  private externalWindow = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.portal = new ComponentPortal(WindowSoftPhoneComponent);
    this.externalWindow = window.open(
      '',
      'windowOpenTab',
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=-1000,top=-1000'
    );

    const host = new DomPortalOutlet(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    host.attach(this.portal);
  }

  ngOnDestroy() {
    this.externalWindow.close();
  }
}
