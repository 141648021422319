import { IHistoricoAtendimentoDestinos } from './../models/IHistoricoAtendimentoDestinos';
import { HistoricoAtendimentoService } from './../services/historico-atendimento.service';
import { IHistoricoAtendimentoSaw } from './../models/IHistoricoAtendimentoSaw';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { DownloadHelper } from 'src/app/shared/helper/download.helper';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { finalize } from 'rxjs/operators';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';
import { forkJoin } from 'rxjs';
import { IHistoricoGravacoes } from '../models/IHistoricoGravacoes';
import { IGridAcoesCustom } from '@ws/grid/lib/interfaces/ws-grid-acoes-custom.interface';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
@Component({
  selector: 'app-historico-atendimento-grid',
  templateUrl: './historico-atendimento-grid.component.html',
  styleUrls: ['./historico-atendimento-grid.component.scss']
})
export class HistoricoAtendimentoGridComponent implements OnInit {
  @ViewChild('videoElement') private videoElement!: ElementRef<HTMLVideoElement>;
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public wordsTranslated: any;
  public wordsNoContent: any;
  titleNoContent: string;
  textNoContent: string;
  titleNoContentDestinos: string;
  textNoContentDestinos: string;
  showFilter: boolean = false;
  desabilitarExport: boolean = false;
  atendimentoId: any;
  showDestinoGrid: boolean = false;
  sawid: number;
  clickedAtendimento: boolean = false;
  showTabInicial= 0;
  showMessages: boolean = false;

  public acoesGridAtendimentos: IGridAcoes;
  public acoesGridDestinos: IGridAcoes;
  public acoesGridGravacoes: IGridAcoes;
  public colunasGridAtendimentos: IGridModelo[];
  public colunasGridDestinos: IGridModelo[];
  public colunasGridGravacoes: IGridModelo[];
  public dadosGridAtendimento: IHistoricoAtendimentoSaw[];
  public dadosGridDestino: IHistoricoAtendimentoDestinos[] = [];
  public dadosGridGravacoes: IHistoricoGravacoes[] = [];
  public permissoesTela: IGridPermissoes;
  public readForm = true;
  public filterForm: UntypedFormGroup;
  public focusFirstLineAtendimentoGrid: boolean = false;

  public itensComboSaw: ICombo[];
  public itemSelecionadoComboSaw: any;

  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  public videoPath: string;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private historicoAtendimentoService: HistoricoAtendimentoService,
    private sawService: SAWCombo,
    private downloadHelper: DownloadHelper,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private env: EnvironmentService,
  ) {
    
    this.loadWordsTranslate();
    this.loadWordsNoContent();
    this.videoPath = "";
   }

   loadWordsTranslate() {
    const keyRoot = 'paginas.historico-atendimento.grid.';
    const words = {
      atendimentoId: '',
      tipoAtendimentoNome: '',
      atendimentoPessoaNome: '',
      atendimentoVeiculoPlaca: '',
      atendimentoVeiculoModelo: '',
      atendimentoVeiculoCor: '',
      atendimentoData: '',
      atendimentoObs: '',
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      sequencia: '',
      destinoNome:'',
      latitude: '',
      longitude: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    }

  loadWordsNoContent() {
    const keyRoot = 'paginas.historico-atendimento.no-content.';
    const words = {
      titleNoFilter: '',
      textNoFilter: '',
      titleFilter: '',
      textFilter: '',
      titleNoContentDestinos: '',
      textNoContentDestinos: '',
    };
    this.wordsNoContent = this.wordsTranslate.get(words, keyRoot);
    this.titleNoContentDestinos = this.wordsNoContent.titleNoContentDestinos;
    this.textNoContentDestinos = this.wordsNoContent.textNoContentDestinos;
  }

  ngOnInit(): void {
    this.titleNoContent = this.wordsNoContent.titleNoFilter;
    this.textNoContent = this.wordsNoContent.textNoFilter;

    this.isLoading = true;
    this.historicoAtendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;

        this.dadosGridAtendimento = [];
        this.itensComboSaw = [];

        const observableComboSaw = this.sawService.listComboSelect();

        forkJoin([observableComboSaw])
          .subscribe((results) => {
          this.itensComboSaw = results[0];
          this.itemSelecionadoComboSaw = this.itensComboSaw;

        this.createFilterForm();
        this.setInitialDataFilters();

        });
      });
  }

  @HostListener('document:keyup', ['$event'])
  handleDocumentKeyUp(event: KeyboardEvent) {
    if (this.videoPath) {
      if (event.key === 'Escape') {
        this.videoPath = '';
      } else if (event.key === ' ') {
        const video: HTMLVideoElement = this.videoElement.nativeElement;
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      }
    }
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      dataInicio: [
        this.formValidParam.startDateDe,
        Validators.compose([Validators.required]),
      ],
      dataFim: [
        this.formValidParam.startDateAte,
        Validators.compose([Validators.required]),
      ],
      sawId: [null, Validators.compose([Validators.required])],
    });
    this.showFilter = true;
  }

  get f() {
    return this.filterForm.controls;
  }

  changeDateDe($event) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 60);
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataFim: maxDateAte,
    });
  }

  setInitialDataFilters() {
    let dataAtual = new Date(Date.now());
    let dataInicio = new Date(Date.now());
    dataInicio.setDate(dataAtual.getDate() - 60);

    this.formValidParam.startDateDe = dataInicio;
    this.formValidParam.minDateAte = dataInicio;
    this.formValidParam.startDateAte = dataAtual;

    this.filterForm.patchValue({
      dataInicio: dataInicio,
      dataFim: dataAtual,
    });
  }

  filter(): void {
    this.showTabInicial = 0;
    this.desabilitarExport = false;

    let {
      dataInicio,
      dataFim,
      sawId
    } = this.filterForm.value;

    this.loadingService.show();

    this.historicoAtendimentoService
      .get(dataInicio, dataFim, sawId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe((response) => {
        this.dadosGridAtendimento = response;
        this.dadosGridAtendimento.forEach(atendimento => {
          atendimento.atendimentos.forEach(dados => {
            dados.prefixo = atendimento.prefixo;
            dados.kmLocal = atendimento.kmLocal;
            dados.rodoviaSigla = atendimento.rodoviaSigla;
          });
        });
        this.setFocusLineTrue();

        this.configuraAcoesGrid();
        this.configuraColunasGridAtendimento();
        this.configuraColunasGridDestino();
        this.configuraColunasGridGravacao();
        if (response.length == 0) {
          this.titleNoContent = this.wordsNoContent.titleFilter;
          this.textNoContent = this.wordsNoContent.textFilter;
          this.desabilitarExport = true;
        }
      });
  }

  configuraColunasGridAtendimento(): void {
    this.colunasGridAtendimentos = [
      {
        name: 'atendimentoId',
        label: this.wordsTranslated.atendimentoId,
        width: '',
        sort: true,
      },
      {
        name: 'tipoAtendimentoNome',
        label: this.wordsTranslated.tipoAtendimentoNome,
        width: '',
        sort: true,
      },
      {
        name: 'atendimentoPessoaNome',
        label: this.wordsTranslated.atendimentoPessoaNome,
        width: '',
        sort: true,
      },
      {
        name: 'atendimentoVeiculoPlaca',
        label: this.wordsTranslated.atendimentoVeiculoPlaca,
        width: '',
        sort: true,
      },
      {
        name: 'atendimentoVeiculoCor',
        label: this.wordsTranslated.atendimentoVeiculoCor,
        width: '',
        sort: true,
      },
      {
        name: 'atendimentoData',
        label: this.wordsTranslated.atendimentoData,
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
      {
        name: 'atendimentoObs',
        label: this.wordsTranslated.atendimentoObs,
        width: '',
        sort: true,
      },
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        width: '',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '',
        sort: true,
      },
    ];
  }

  configuraAcoesGrid(gravacao: boolean = false): void {

    let actionCustom: IGridAcoesCustom[];

    if(gravacao) {
      actionCustom = [{
        icon: 'play_arrow',
        action: 'openVideoOrMessages',
        tooltip: 'Play Video',
      }];
    } else {
      actionCustom = [{
        icon: 'chat',
        action: 'openVideoOrMessages',
        tooltip: 'Chat Messages',
      }];
    }

    this.acoesGridDestinos = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,
      name: 'grid-destinos-atendimentos'
    };

    this.acoesGridGravacoes = {
      show: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,
      name: 'grid-gravacoes-atendimentos',
      custom: actionCustom,
    };

    this.acoesGridAtendimentos = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: true,
      export: false,
      disable: false,
      name: 'grid-atendimentos'
    };
  }

  configuraColunasGridDestino(): void {
    this.colunasGridDestinos = [
      {
        name: 'sequencia',
        label: this.wordsTranslated.sequencia,
        width: '150px',
        sort: true,
      },
      {
        name: 'destinoNome',
        label: this.wordsTranslated.destinoNome,
        width: '',
        sort: true,
      },
      {
        name: 'latitude',
        label: this.wordsTranslated.latitude,
        width: '',
        sort: true,
      },
      {
        name: 'longitude',
        label: this.wordsTranslated.longitude,
        width: '',
        sort: true,
      },
    ];
  }

  configuraColunasGridGravacao(): void {
    this.colunasGridGravacoes = [
      {
        name: 'tipoLigacao',
        label: "Tipo do Atendimento",
        width: '150px',
        sort: true,
        formatType: 'callType'
      },
      {
        name: 'dataHoraInicio',
        label: "Data Hora Inicio",
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm'
      },
      {
        name: 'dataHoraFim',
        label: "Data Hora Fim",
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm'
      },
      {
        name: 'atendente',
        label: "Atendente",
        width: '',
        sort: true,
      },
    ];
  }

  export(): void {
    var timeOut = 1500;
    let { dataInicio,
          dataFim,
          sawId
        } = this.filterForm.value;

    this.loadingService.show();

    this.historicoAtendimentoService
      .export( dataInicio, dataFim, sawId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        if (response) {
        this.downloadHelper.downloadMidia(response);
      } else {
        const keyRoot = 'alertas.nenhumDadoParaExportar.';
        const words = {
          mensagem: '',
          titulo: ''
        };
        let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

        this.alertaService.Warning(
          wordsTranslate.titulo,
          wordsTranslate.mensagem,
          timeOut
        );
       }
     });
  }

  refreshGridAtendimentos() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  refreshGridDestinos() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  refreshGridGravacoes() {
    if(this.filterForm.valid) {
      this.filter();
    }
  }

  changeComboSaw($event) {
    this.filterForm.patchValue({
      sawId: $event
    });
  }

  rowEvent($event) {
    this.dadosGridDestino = [];
    this.dadosGridGravacoes = [];
    this.atendimentoId = $event.atendimentoId;
    this.clickedAtendimento = true;

    this.dadosGridAtendimento.forEach(atendimento => {
      atendimento.destinos.forEach(destino => {
        if( destino.atendimentoId === this.atendimentoId){
          this.dadosGridDestino.push(destino)
        }
      });

      atendimento.gravacoes.forEach(grav => {
        if (grav.atendimentoId === this.atendimentoId) {
          this.configuraAcoesGrid(grav.gravacaoVideo);
          this.dadosGridGravacoes.push(grav);
        }
      });
    });

    this.showDestinoGrid = true;
  }

  onTabClick(){
    this.dadosGridDestino = [];
    this.clickedAtendimento = false;
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLineAtendimentoGrid = false
    }, 500);
  }

  setFocusLineTrue(){
    setTimeout(() => {
      this.focusFirstLineAtendimentoGrid = true
    }, 500);
  }

  openVideoOrMessages(event: any): void {
    if(event.row.gravacaoVideo){
      this.videoPath = this.env.apiUrl + "Uploads/Atendimento/" + event.row.caminhoGravacao;
      this.videoPath = this.env.apiUrl + "Uploads/Atendimento/" + event.row.caminhoGravacao;
    } else {
      this.showMessages = true;
    }
  }

  closeMessages() {
    this.showMessages = false;
  }

}
