import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAlertaParametros } from '../../models/IAlerta';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { finalize } from 'rxjs/operators';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaSistemaService } from '../../services/alerta.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';

@Component({
  selector: 'app-alerta-parametros-form',
  templateUrl: './alerta-parametros-form.component.html',
  styleUrls: ['./alerta-parametros-form.component.scss']
})
export class AlertaParametrosFormComponent implements OnInit {
  private readonly fileTranslate = 'AlertaParametrosForm';

  @Input() permissoes: IGridPermissoes;
  @Input() registro: IAlertaParametros;
  @Output() onClose = new EventEmitter();
  @Output() onChange = new EventEmitter();
  formulario: UntypedFormGroup;
  modalName = 'modalFormAlertaParametros';
  autoClose = true;

  constructor(
    private loadingService: LoadingService,
    private closeModalService: CloseModalService,
    private fb: UntypedFormBuilder,
    private alertaSistemaService: AlertaSistemaService,
    private alertaService: AlertaService
  ) {
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.onClose.emit();
      }
    });
  }

  ngOnInit(): void {
    this.criaFormulario();
  }

  criaFormulario() {
    this.formulario = this.fb.group({
      alertaId: [this.registro.alertaId],
      alertaParametroSeq: [this.registro.alertaParametroSeq],
      alertaParametroValor: [this.registro.alertaParametroValor, Validators.required],
      parametroDesc: [
        { value: this.registro.parametroDesc, disabled: true },
      ],
    });
  }

  submit() {
    const values = this.formulario.value;
    this.loadingService.show();
    this.alertaSistemaService.updateParametros(values).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    ).subscribe(() => {
      this.alertaService.sucesso();
      this.onChange.emit();
      this.checkAutoClose();
    });
  }

  checkAutoClose() {
    if (this.autoClose) {
      this.close();
    }
  }

  close() {
    this.onClose.emit();
  }

  cancel() {
    this.formulario.reset();
    this.criaFormulario();
  }
}
