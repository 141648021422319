import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  HostListener
} from '@angular/core';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';

import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IUsuario } from '../models/IUsuario';
import { UsuarioService } from '../services/usuario.service';

@Component({
  selector: 'app-usuario-upload',
  templateUrl: './usuario-upload.component.html'
})
export class UsuarioUploadComponent implements OnInit {
  titleDialog: string = 'Upload de Imagem';
  acceptExtensions: string = '*';
  files: File[] = [];
  isLoading: boolean;
  showUpload: boolean;
  listErrosUpload = [];

  wordsTranslated: any;

  @Input() editForm: EventEmitter<boolean>;
  @Input('dados') dados: IUsuario;

  @Output() closeEvent = new EventEmitter<string>();

  constructor(
    private usuarioService: UsuarioService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private closeModalService: CloseModalService,
    private imageZoomService: ImageZoomService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalUsuarioUpload') {
        this.close();
      }
    });
  }

  showPreview(image) {
    this.imageZoomService.show(image);
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.usuario.upload.';
    let words = {
      title: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadFiles();
    this.usuarioService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });

    this.titleDialog = this.wordsTranslated.title;
  }

  loadFiles() {
    this.isLoading = true;
    this.usuarioService.getMidia(this.dados).subscribe(
      (result) => {
        this.files = result;
        this.showUpload = true;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.alertaService.erro();
        this.close();
      }
    );
  }

  uploadFiles(uploadDados) {
    const req = this.usuarioService.uploadMidia(
      this.dados,
      uploadDados.formData
    );

    req.subscribe(
      (results: any) => {
        if (results && results[0]) {
          const errors = [];
          errors.push(results[0]);

          errors.forEach((element) => {
            this.listErrosUpload.push(element);
          });
        }

        this.alertaService.sucesso();
        this.loadFiles();
      },
      (error: any) => {
        this.alertaService.erro();
      }
    );
  }

  close(): void {
    this.closeEvent.next();
  }
}
