import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsEnum } from 'src/app/shared/enum/EventsEnum';
import { ISAW } from 'src/app/shared/models/ISaw';
import { INewCallViaWCallDataEvent } from 'src/app/shared/services/configuracao/INewCallViaWCallDataEvent';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IInsertedAtendimento } from '../../models/IInsertedAtendimento';
import { AtendimentoService } from '../../services/atendimento.service';
import { EventBusService } from './../../../../../shared/services/eventBus/event-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-atendimento-principal',
  templateUrl: './atendimento-principal.component.html',
  styleUrls: ['./atendimento-principal.component.scss']
})
export class AtendimentoPrincipalComponent implements OnInit, OnDestroy {
  selectedSaw: ISAW = null;
  selectedSawRegistro: IInsertedAtendimento | null = null;
  showGridMensagens: boolean = false;
  wordsTranslated: any;
  titleNoContentMensagens: string = '';
  textNoContentMensagens: string = '';
  showFormAtendimento: boolean = false;
  showFormMensagemAviso: boolean = false;

  private showFormAtendimentoSub!: Subscription;

  constructor(
    private wordsTranslate: WordsTranslateService,
    private atendimentoService: AtendimentoService,
    private eventBus: EventBusService
  ) {
    this.loadWordsTranslate();
  }

  ngOnDestroy(): void {
    this.showFormAtendimentoSub.unsubscribe();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.principal.';
    const words = {
      tituloHasNoMensagens: '',
      textHasNoMensagens: '',
      tituloNoContent: '',
      textNoContent: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    this.titleNoContentMensagens = this.wordsTranslated.tituloNoContent;
    this.textNoContentMensagens = this.wordsTranslated.textNoContent;
  }

  ngOnInit(): void {
    this.showFormAtendimentoSub = this.eventBus.on(EventsEnum.ShowFormAtendimento, (value: INewCallViaWCallDataEvent) => {
      console.log("HUB: EventsEnum ShowFormAtendimento: ", value.atendimentoId);
      this.atendimentoService.getAtendimentoBy(value.atendimentoId).subscribe((response) => {
        this.selectedSawRegistro = response;
        this.atendimentoService.showFormAtendimentoLigacao.emit(value.reference);
      });
    });
  }

  setSawId(selectedSaw: ISAW) {
    this.atendimentoService
      .getMensagens(selectedSaw.sawId)
      .subscribe((response) => {
        this.showGridMensagens = response.length > 0 ? true : false;
        this.titleNoContentMensagens = this.wordsTranslated.tituloHasNoMensagens;
        this.textNoContentMensagens = this.wordsTranslated.textHasNoMensagens;
      });

    this.selectedSaw = selectedSaw;
  }

  showAtendimentoForm() {
    this.showFormAtendimento = true;
  }

  closeAtendimentoForm() {
    this.showFormAtendimento = false;
  }

  showMensagemAvisoForm() {
    this.showFormMensagemAviso = true;
  }

  closeMensagemAvisoForm() {
    this.showFormMensagemAviso = false;
  }
}
