<div class="grids">
  <div class="grid-modulo">
    <div class="grid">
      <app-modulo-grid (moduloId)="setModuloId($event)"></app-modulo-grid>
    </div>
  </div>
  <div class="grid-modulos">
    <app-modulo-menu-grid
      *ngIf="showGridMenus"
      [moduloId]="moduloId"
    ></app-modulo-menu-grid>
    <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
      *ngIf="!showGridMenus"
    ></ws-no-content>
  </div>
</div>
