import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoadingService {

  showLoading = new EventEmitter<boolean>();

  public show() {
    this.showLoading.emit(true);
  }
  public hide() {
    this.showLoading.emit(false);
  }

}
