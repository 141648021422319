
<ws-grid
*ngIf="!isLoading"
[permissoes]="permissoes"
[isLoading]="isLoading"
[acoesGrid]="acoesGrid"
[colunasGrid]="colunasGrid"
[dadosGrid]="dadosGrid"
[focus]="focusFirstLine"
(refreshEvent)="refresh()"
(editEvent)="openForm($event)"
(addEvent)="openForm()"
(deleteEvent)="confirmDel($event)"
(fileEvent)="openUpload($event)">
</ws-grid>

<app-destino-upload-midia-grid
  *ngIf="showUpload"
  [destinoId]="destinoId"
  [editForm]="editForm"
  [dados]="dadosFormulario"
  (closeEvent)="closeUpload()">
</app-destino-upload-midia-grid>

<app-destino-form
*ngIf="showForm"
[permissoes]="permissoes"
[registro]="dadosFormulario"
(onChange)="refreshGridData($event.dados, $event.tipo)"
(onDelete)="confirmDel($event)"
(closeEvent)="closeForm()">
</app-destino-form>
