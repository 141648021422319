<div class="conteudo">
  <div class="acoes">
    <input
      (input)="onInput($event)"
      #search
      id="pac-input"
      matInput
      type="text"
      placeholder="Buscar destino"
      autocomplete="off"
    />
    <button
      type="button"
      class="save"
      [disabled]="!hasDestinoToSend"
      (click)="sendDestino()"
      mat-button
    >
      <i class="material-icons">send</i>
      <span class="text">Enviar</span>
    </button>
  </div>
  <div #mapRef id="map" class="mapContainer"></div>

  <div class="placesSearchs" *ngIf="placesSearchs && placesSearchs.length > 0">
    <div
      class="pac-item"
      *ngFor="let item of placesSearchs"
      (click)="getPlace(item)"
    >
      <span class="pac-icon pac-icon-marker"
        ><i class="fas fa-map-marker-alt"></i
      ></span>
      <span class="pac-item-query">
        {{ item.name }}
      </span>
      <span>{{ item.neighbourhood ? item.neighbourhood + ', ' : '' }} {{ item.city ? item.city + ' - ' : '' }} {{ item.state ? item.state + ', ': '' }} {{ item.country }}</span>
    </div>
  </div>
</div>
