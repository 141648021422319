<div class="modal" modal-name="modalDestinoForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="destinoId" />

          <ws-combo
            class="fieldGrupoDestino"
            [disabled]="!permissoes['update'] && registro"
            [focusMe]="disableComboTela() || !disableComboTela()!"
            [textoLabel]="'paginas.destino.form.grupoDestino' | translate"
            [multiple]="false"
            [selecionarTodos]="false"
            [itemCtrl]="f.grupoId"
            [items]="itensComboGrupoDestino"
            [itemSelecionado]="itemSelecionadoComboGrupoDestino"
            (changeEventCombo)="changeComboGrupoDestino($event)"
          >
          </ws-combo>

          <mat-form-field class="fieldTelefone" appearance="outline">
            <mat-label>
              {{ "paginas.destino.form.destinotelefone" | translate }}
            </mat-label>
            <input
              matInput
              placeholder="{{
                'paginas.destino.form.destinotelefone' | translate
              }}"
              autocomplete="off"
              formControlName="destinoTelefone"
            />
          </mat-form-field>

          <mat-form-field class="fieldNome" appearance="outline">
            <mat-label>{{
              "paginas.destino.form.destinoNome" | translate
            }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              autocomplete="off"
              formControlName="destinoNome"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
            >
            </textarea>
          </mat-form-field>

          <mat-form-field class="fieldEndereco" appearance="outline">
            <mat-label>{{
              "paginas.destino.form.destinoEndereco" | translate
            }}</mat-label>
 
            <input 
            type="text" 
            matInput
            [matAutocomplete]="auto"
            #search
              matInput
              cdkTextareaAutosize
              autocomplete="off"
              formControlName="destinoEndereco"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="1"
              [matAutocomplete]="auto"
              (input)="subject.next($event.target.value)"
              />
          <mat-autocomplete 
            #auto="matAutocomplete"
            (optionSelected)="locationOptionClicked($event.option.value)"

            >
              <mat-option *ngFor="let item of placesSearches" [value]="item">
                <div
                class="pac-item"
              >
                <span class="pac-icon pac-icon-marker"
                  ><i class="fas fa-map-marker-alt"></i
                ></span>
                <span class="pac-item-query">
                  {{ item.name + ','}}
                </span>
                <span>{{ item.neighbourhood ? item.neighbourhood + ' - ' : '' }}{{ item.city ? item.city + ' - ' : '' }}{{ item.country }}</span>
              </div>
              </mat-option>
            </mat-autocomplete>

         </mat-form-field>

          <mat-form-field
            class="fieldFuncionamentoBRA"
            [hintLabel]="hintLabelBRA"
            appearance="outline"
          >
            <mat-label>{{
              "paginas.destino.form.destinoFuncionamentoPortugues" | translate
            }}</mat-label>
            <textarea
              matInput
              maxlength="1000"
              cdkTextareaAutosize
              autocomplete="off"
              formControlName="destinoFuncionamentoPortugues"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="2"
            >
            </textarea>
          </mat-form-field>

          <mat-form-field
            class="fieldFuncionamentoESP"
            [hintLabel]="hintLabelESP"
            appearance="outline"
          >
            <mat-label>{{
              "paginas.destino.form.destinoFuncionamentoEspanhol" | translate
            }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              maxlength="1000"
              autocomplete="off"
              formControlName="destinoFuncionamentoEspanhol"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="2"
            >
            </textarea>
          </mat-form-field>

          <mat-form-field
            class="fieldFuncionamentoUSA"
            [hintLabel]="hintLabelUSA"
            appearance="outline"
          >
            <mat-label>{{
              "paginas.destino.form.destinoFuncionamentoIngles" | translate
            }}</mat-label>
            <textarea
              matInput
              maxlength="1000"
              cdkTextareaAutosize
              autocomplete="off"
              formControlName="destinoFuncionamentoIngles"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="2"
            >
            </textarea>
          </mat-form-field>

          <mat-form-field class="fieldLatitude" appearance="outline">
            <mat-label>
              {{ "paginas.destino.form.destinolatitude" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              placeholder="{{
                'paginas.destino.form.destinolatitude' | translate
              }}"
              autocomplete="off"
              formControlName="destinoLatitude"
              #latitudeInput
            />
          </mat-form-field>

          <mat-form-field class="fieldLongitude" appearance="outline">
            <mat-label>
              {{ "paginas.destino.form.destinolongitude" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              placeholder="{{
                'paginas.destino.form.destinolongitude' | translate
              }}"
              autocomplete="off"
              formControlName="destinoLongitude"
              #longitudeInput
            />
          </mat-form-field>

          <div class="fieldMapa mapa">
            <app-destino-mapa-component
              (eventDragMap)="onDragMap($event)"
              [hasCoordinates]="hasCoordenada"
              [icon]="iconeSrc"
              [latitude]="coordinates.lat"
              [longitude]="coordinates.lng"
            >
            </app-destino-mapa-component>
          </div>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary add"
              *ngIf="permissoes['create']"
              (click)="add()"
              [disabled]="!statusBotao.adicionar"
            >
              <i class="material-icons">add</i>
              <span class="text">{{ "botoes.adicionar" | translate }}</span>
            </button>

            <button
              mat-button
              type="button"
              class="primary delete"
              *ngIf="permissoes['delete']"
              (click)="del()"
              [disabled]="!statusBotao.deletar"
            >
              <i class="material-icons">delete</i>
              <span class="text">{{ "botoes.deletar" | translate }}</span>
            </button>

            <button
              mat-button
              type="button"
              class="primary cancel"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>

            <button
              type="submit"
              mat-button
              class="save"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
