<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form id="frm-filter" class="default" *ngIf="filterForm" [formGroup]="filterForm" (ngSubmit)="filter()">

      <div class="container-combo-saw">
        <ws-combo 
        [textoLabel]="'paginas.historico-mensagem.filtro.saw' | translate" 
        [multiple]="true" 
        [selecionarTodos]="true" 
        [itemCtrl]="f.sawId"
        [items]="itensComboEquipamentos"
        [itemSelecionado]="itemSelecionadoComboEquipamentos"
        (changeEventCombo)="changeComboSaw($event)">
        </ws-combo>
      </div>

      <div class="container-data-de">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.diagnostico-gravacoes.filtro.dataInicio" | translate
            }}</mat-label>
          <input matInput [matDatepicker]="pickerDe" formControlName="dataInicio" [max]="formValidParam.maxDateDe"
            (dateChange)="changeDataDe($event)" [focusMe]="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerDe"></mat-datepicker-toggle>
          <mat-datepicker #pickerDe [startAt]="formValidParam.startDateDe"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-data-ate">
        <mat-form-field appearance="outline">
          <mat-label>{{ "paginas.diagnostico-gravacoes.filtro.dataFim" | translate }}</mat-label>
          <input matInput [matDatepicker]="pickerAte" formControlName="dataAte" [min]="formValidParam.minDateAte"
            [max]="formValidParam.maxDateAte" />
          <mat-datepicker-toggle matSuffix [for]="pickerAte"></mat-datepicker-toggle>
          <mat-datepicker #pickerAte [startAt]="formValidParam.startDateAte"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="buttons-container">
        <button mat-button type="submit" class="save" [disabled]="filterForm.invalid">
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>
    </form>
  </div>

  <div class="content">
    <div class="grid">
      <ws-grid
        *ngIf="!isLoading" 
        [permissoes]="permissoesTela" 
        [isLoading]="isLoading"
        [acoesGrid]="acoesGrid" 
        [colunasGrid]="colunasGrid" 
        [dadosGrid]="dadosGrid"
        [registroPorPagina]="registroPorPagina" 
        [listaOpcaoPorPagina]="listaOpcaoPorPagina"
        [disableRefresh]="filterForm.invalid" 
        (rowEvent)="rowEvent($event)">
      </ws-grid>
    </div>

    <div *ngIf="midia" class="midia">
      <video controls class="bg" [src]="midia">
      </video>
    </div>

    <div *ngIf="!midia">
      <ws-no-content 
        [text]="'paginas.gravacoes-videos.grid.textoPersonalizado' | translate">
      </ws-no-content>
    </div>
  </div>
</div>