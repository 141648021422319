<div class="box" *ngIf="exibirLogin">
  <div class="imagem-default">
    <div class="imagem-personalizada"></div>
  </div>
  <div class="foco">
    <div class="formulario">
      <div class="box-login">
        <div class="logo">
          <img src="/assets/images/logo-topo-saw-black.svg" alt="">
        </div>
        <div class="divisor"></div>
        <div class="login">
          <form class="default" [formGroup]="loginForm" (ngSubmit)="enviarDados()">
            <h2>{{'paginas.login.informe_o_acesso' | translate}}</h2>
            <mat-form-field class="cmp-userName">
              <input type="text" matInput placeholder="{{'formulario.placeholder.usuario' | translate}}"
                formControlName="userName" value="">
            </mat-form-field>
            <mat-form-field class="cmp-password">
              <input type="password" matInput placeholder="{{'formulario.placeholder.sua_senha' | translate}}"
                formControlName="password" value="">
            </mat-form-field>
            <div class="cmp-login-ad">
              <mat-slide-toggle *ngIf="loginAd" (change)="changeLoginAd($event)">Login AD
              </mat-slide-toggle>
            </div>
            <button mat-flat-button class="primary" [disabled]="!loginForm.valid || logarLoading">
              <span>{{'botoes.entrar' | translate}}</span>
            </button>
            <mat-progress-bar mode="indeterminate" *ngIf="logarLoading"></mat-progress-bar>
          </form>
        </div>
        <div class="redes">
          <div class="titulo">
            <hr>
            <span> SIGA-NOS </span>
            <hr>
          </div>
          <div class="icones">
            <ul>
              <li>
                <a href="https://www.facebook.com/WriteSys" target="_blank">
                  <img src="../../../../assets/images/icon/ico-facebook.svg" alt=""> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/writesys?trk=biz-companies-cym" target="_blank">
                  <img src="../../../../assets/images/icon/ico-linkedin.svg" alt=""> Linkedin
                </a>
              </li>
              <li>
                <a href="https://twitter.com/WritesysTF" target="_blank">
                  <img src="../../../../assets/images/icon/ico-twitter.svg" alt=""> Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="suporte">
        <div class="titulo">
          <i class="material-icons">help_outline</i>
          <h2>{{'paginas.login.suporte' | translate}}</h2>
        </div>
        <ul>
          <li>
            <i class="material-icons">phone</i>
            <h3>
              <small>19</small> 3463-1961
            </h3>
          </li>
          <li>
            <i class="material-icons">email</i>
            <h3>
              suporte@writesys.com.br
            </h3>
          </li>
        </ul>

        <div class="logo-writesys">
          <img src="/assets/images/logo-writesys-login.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>