import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IParametrosLogConfiguracaoSistema } from '../models/IParametrosLogConfiguracaoSistema';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


@Injectable({ providedIn: 'root' })
export class ParametrosLogConfiguracaoSistemaService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}ConfiguracaoSistema/Log`;

  get(): Observable<IParametrosLogConfiguracaoSistema[]> {
    return this.http.get<IParametrosLogConfiguracaoSistema[]>(this.apiUrl);
  }
}
