<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form
      id="frm-relatorio-disponibilidade-conexao"
      *ngIf="filterForm && showFilter"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >
    
    <div class="container-combo-saw">
      <ws-combo
        [focusMe]="false"
        [textoLabel]="wordsTranslated.sawId"
        [multiple]="true"
        [selecionarTodos]="true"
        [itemCtrl]="f.sawId"
        [items]="itensComboSAW"
        [itemSelecionado]="itemSelecionadoComboSAW"
        (changeEventCombo)="onChangeComboSaw($event)"
      >
      </ws-combo>
    </div>

      <div class="container-data-inicio">
        <mat-form-field appearance="outline">
          <mat-label>{{ wordsTranslated.dataInicio }}</mat-label>
          <input
            [focusMe]="true"
            matInput
            [matDatepicker]="pickerDataInicio"
            formControlName="dataInicio"
            (dateChange)="changeDateDe($event)"
            [max]="formValidParam.maxDateDe"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataInicio"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataInicio
            [startAt]="formValidParam.startDateDe"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-data-fim">
        <mat-form-field appearance="outline">
          <mat-label>{{ wordsTranslated.dataFim }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDataFim"
            formControlName="dataFim"
            [min]="formValidParam.minDateAte"
            [max]="formValidParam.maxDateAte"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataFim"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataFim
            [startAt]="formValidParam.startDateAte"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="button"
          (click)="export()"
          class="primary exportar"
          [disabled]="filterForm.invalid || desabilitarExport"
        >
          <i class="far fa-file-excel"></i> {{ "botoes.exportar" | translate }}
        </button>
      </div>
    </form>

    <div class="box-direita">
      <button mat-button class="primary legenda-button" (click)="showLegenda()" matTooltip="{{ 'botoes.legenda' | translate }}">
        <i class="material-icons">list</i>
      </button>
    </div>
  </div>

  <ng-container
    *ngIf="dadosRelatorio && dadosRelatorio.length > 0; else tmpEmpty"
  >
    <div class="subTabs">
      <mat-tab-group
        [(selectedIndex)]="showTabInicial"
        class="mat-tab-group"
        (selectedIndexChange)="onTabClick()"
        animationDuration="0ms"
      >
        <mat-tab
          class="mat-tab"
          *ngFor="let equipamento of dadosRelatorio; let tabIndex = index"
          label="{{ equipamento.prefixo }} - {{
            equipamento.rodoviaSigla
          }} - Km: {{ equipamento.kmNominal }}"
        >
          <div class="graficos">
            <mat-accordion class="mat-accordion" *ngIf="showChangedTab">
              <mat-expansion-panel expanded class="mat-expansion-panel">
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                  <mat-panel-title>{{ firstMesDesc }}</mat-panel-title>
                </mat-expansion-panel-header>
                <apx-chart
                  [series]="seriesFirstMonth[equipamento.sawId]"
                  [chart]="chartOptionsFirstMonth[equipamento.sawId][0].chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [title]="titleFirstMonth[equipamento.sawId][0].title"                
                  [xaxis]="chartOptions.xaxis"                
                  [yaxis]="chartOptions.yaxis"
                  [legend]="chartOptions.legend"
                ></apx-chart>
              </mat-expansion-panel>
              <mat-expansion-panel
                class="mat-expansion-panel"
                *ngIf="equipamento.meses[1] != null"
              >
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                  <mat-panel-title>{{ secondMesDesc }}</mat-panel-title>
                </mat-expansion-panel-header>
                <apx-chart
                  [series]="seriesSecondMonth[equipamento.sawId]"
                  [chart]="chartOptionsSecondMonth[equipamento.sawId][0].chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [title]="titleSecondMonth[equipamento.sawId][0].title"
                  [xaxis]="chartOptions.xaxis"                 
                  [yaxis]="chartOptions.yaxis"
                  [legend]="chartOptions.legend"
                ></apx-chart>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>

  <ng-template #tmpEmpty>
    <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
    ></ws-no-content>
  </ng-template>
</div>
