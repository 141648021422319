<div class="modal" modal-name="modalMensagemAvisoEquipamentoForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>
          {{
            "paginas.atendimento.principal.mensagem-aviso.title-form"
              | translate
          }}
        </h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="dados-mensagem">
        <div class="grid-equipamentos">
          <div class="title">
            <p>{{ wordsTranslated.equipamentos }}</p>
          </div>
          <div class="grid">
            <ws-grid
              *ngIf="!isLoading"
              [acoesGrid]="acoesGrid"
              [colunasGrid]="colunasGrid"
              [dadosGrid]="dadosGrid"
              [registroPorPagina]="registroPorPagina"
              [listaOpcaoPorPagina]="listaOpcaoPorPagina"
              (toogleEvent)="toogleEvent($event)"
            >
            </ws-grid>
          </div>
        </div>
        <form
          class="default"
          *ngIf="formulario && showForm"
          [formGroup]="formulario"
          (ngSubmit)="save()"
          cdkTrapFocus
        >
          <div class="modal-content">
            <ws-combo
              class="fieldGrupoMensagem"
              [focusMe]="true"
              [textoLabel]="wordsTranslatedForm.grupoMensagemId"
              [multiple]="false"
              [selecionarTodos]="false"
              [itemCtrl]="f.grupoMensagemId"
              [items]="itensComboGrupoMensagem"
              [itemSelecionado]="itemSelecionadoComboGrupoMensagem"
              (changeEventCombo)="onChangeComboGrupoMensagem($event)"
            >
            </ws-combo>

            <mat-form-field class="fieldDataInicio" appearance="outline">
              <mat-label>{{ wordsTranslatedForm.dataHoraInicio }}</mat-label>
              <input
                matInput
                [matDatepicker]="pickerDataHoraInicio"
                formControlName="dataInicio"
                (dateChange)="changeDateDe($event)"
                [max]="formValidParam.maxDateDe"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerDataHoraInicio"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #pickerDataHoraInicio
                [startAt]="formValidParam.startDateDe"
              >
              </mat-datepicker>
            </mat-form-field>

            <mat-form-field class="fieldHoraInicio" appearance="outline">
              <mat-label>{{ wordsTranslatedForm.horaInicio }}</mat-label>
              <input
                matInput
                formControlName="horaInicio"
                type="time"
                placeholder="{{ wordsTranslatedForm.horaInicio }}"
              />
            </mat-form-field>

            <mat-form-field class="fieldDataFim" appearance="outline">
              <mat-label>{{ wordsTranslatedForm.dataHoraFim }}</mat-label>
              <input
                matInput
                [matDatepicker]="pickerDataHoraFim"
                formControlName="dataFim"
                [min]="formValidParam.minDateAte"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerDataHoraFim"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #pickerDataHoraFim
                [startAt]="formValidParam.startDateAte"
              >
              </mat-datepicker>
            </mat-form-field>

            <mat-form-field class="fieldHoraFim" appearance="outline">
              <mat-label>{{ wordsTranslatedForm.horaFim }}</mat-label>
              <input
                matInput
                formControlName="horaFim"
                type="time"
                placeholder="{{ wordsTranslatedForm.horaFim }}"
              />
            </mat-form-field>

            <mat-form-field class="fieldTextoPortugues" appearance="outline">
              <mat-label>
                {{ wordsTranslatedForm.textoPortugues }}
              </mat-label>
              <textarea
                matInput
                cdkTextareaAutosize
                autocomplete="off"
                formControlName="textoPortugues"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>

            <mat-form-field class="fieldTextoIngles" appearance="outline">
              <mat-label>
                {{ wordsTranslatedForm.textoIngles }}
              </mat-label>
              <textarea
                matInput
                cdkTextareaAutosize
                autocomplete="off"
                formControlName="textoIngles"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>

            <mat-form-field class="fieldTextoEspanhol" appearance="outline">
              <mat-label>
                {{ wordsTranslatedForm.textoEspanhol }}
              </mat-label>
              <textarea
                matInput
                cdkTextareaAutosize
                autocomplete="off"
                formControlName="textoEspanhol"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="3"
              ></textarea>
            </mat-form-field>
          </div>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="formLoading"
          ></mat-progress-bar>

          <div class="modal-actions">
            <div></div>
            <div class="buttons">
              <button
                mat-button
                type="button"
                class="primary cancel"
                *ngIf="permissoes['create'] || permissoes['update']"
                (click)="cancel()"
                [disabled]="!statusBotao.cancelar"
              >
                <i class="material-icons">block</i>
                <span class="text">{{ "botoes.cancelar" | translate }}</span>
              </button>

              <button
                type="submit"
                mat-button
                class="save"
                *ngIf="permissoes['create'] || permissoes['update']"
                [disabled]="formulario.invalid || !statusBotao.salvar"
              >
                <i class="material-icons">send</i>
                <span class="text">{{ "botoes.enviar" | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
