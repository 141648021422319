<div class="title-grid">
  <p>
    {{ wordsTranslated.title }}
    {{ moduloId.menuModulo }}
  </p>
</div>
<div class="menusSearchs">
  <button
    mat-button=""
    class="mat-focus-indicator primary mat-button mat-button-base ng-star-inserted"
    ng-reflect-disabled="false"
    (click)="openForm()"
  >
    <i _ngcontent-fhq-c146="" class="material-icons">add</i>
    {{ wordsTranslated.adicionar }}
  </button>
  <input
    type="text"
    class="menusSearch"
    placeholder="{{ wordsTranslated.searchMenu }}"
    [(ngModel)]="query"
    id="listSearch"
  />
</div>
<div *ngIf="showMenus">
  <div class="drag-drop-grid">
    <div class="bloqueio-header">
      <div class="header-drag-drop">
        <div class="acoesGridP">
          <p></p>
        </div>
        <div class="menuTelaP">
          <p>
            {{ wordsTranslated.menuTela }}
          </p>
        </div>
        <div class="aplicacaoTelaPaginaP">
          <p>
            {{ wordsTranslated.aplicacaoTelaPagina }}
          </p>
        </div>
        <div class="toggleLeituraP">
          <p>
            {{ wordsTranslated.leitura }}
          </p>
        </div>
        <div class="toggleInserirP">
          <p>
            {{ wordsTranslated.inserir }}
          </p>
        </div>
        <div class="toggleAtualizarP">
          <p>
            {{ wordsTranslated.atualizar }}
          </p>
        </div>
        <div class="toggleExcluirP">
          <p>
            {{ wordsTranslated.excluir }}
          </p>
        </div>
        <div class="toggleLiberadoP">
          <p>
            {{ wordsTranslated.liberado }}
          </p>
        </div>
      </div>
    </div>
    <div cdkDropList class="list-menu" (cdkDropListDropped)="drop($event)">
      <div class="bloqueioMenu">
        <div
          class="list-menu-dados"
          *ngFor="let dado of dadosGrid | searchFilter: query"
          cdkDragLockAxis="y"
          cdkDrag
          [cdkDragDisabled]="!this.permissoesTela.update"
        >
          <div class="drop-placeholder" *cdkDragPlaceholder></div>
          <div class="acoesGrid">
            <div class="insideAcoes">
              <button
                (click)="editForm(dado)"
                mat-stroked-button=""
                mattooltipposition="above"
                class="mat-focus-indicator mat-tooltip-trigger edit mat-stroked-button mat-button-base ng-star-inserted"
              >
                <span class="mat-button-wrapper">
                  <i class="material-icons">edit</i>
                </span>
              </button>
              <button
                (click)="deleteForm(dado)"
                mat-stroked-button=""
                mattooltipposition="above"
                class="mat-focus-indicator mat-tooltip-trigger edit mat-stroked-button mat-button-base ng-star-inserted"
              >
                <span class="mat-button-wrapper">
                  <i class="material-icons">delete</i>
                </span>
              </button>
            </div>
          </div>
          <div class="menuTela">
            <p>
              {{ dado.menuTela }}
            </p>
          </div>
          <div class="aplicacaoTelaPagina">
            <p>
              {{ dado.aplicacaoTelaPagina }}
            </p>
          </div>
          <div class="toggleLeitura">
            <p>
              <mat-slide-toggle
                [disabled]="!this.permissoesTela.update || dado.aplicacaoTelaId == 38"
                [checked]="dado.leitura"
                (toggleChange)="changeToogleLeitura(dado)"
              ></mat-slide-toggle>
            </p>
          </div>
          <div class="toggleInserir">
            <p>
              <mat-slide-toggle
                [disabled]="!this.permissoesTela.update || dado.aplicacaoTelaId == 38"
                [checked]="dado.inserir"
                (toggleChange)="changeToogleInserir(dado)"
              ></mat-slide-toggle>
            </p>
          </div>
          <div class="toggleAtualizar">
            <p>
              <mat-slide-toggle
                [disabled]="!this.permissoesTela.update || dado.aplicacaoTelaId == 38"
                [checked]="dado.atualizar"
                (toggleChange)="changeToogleAtualizar(dado)"
              ></mat-slide-toggle>
            </p>
          </div>
          <div class="toggleExcluir">
            <p>
              <mat-slide-toggle
                [disabled]="!this.permissoesTela.update || dado.aplicacaoTelaId == 38"
                [checked]="dado.excluir"
                (toggleChange)="changeToogleExcluir(dado)"
              ></mat-slide-toggle>
            </p>
          </div>
          <div class="toggleLiberado">
            <p>
              <mat-slide-toggle
                [disabled]="!this.permissoesTela.update || dado.aplicacaoTelaId == 38"
                [checked]="!dado.liberado"
                (toggleChange)="changeToogleLiberado(dado)"
              ></mat-slide-toggle>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ws-no-content
  [title]="titleNoContent"
  [text]="textNoContent"
  *ngIf="!showMenus"
></ws-no-content>
<app-modulo-menu-form
  *ngIf="showForm"
  [permissoes]="permissoesTela"
  [registro]="dadosFormulario"
  [moduloId]="moduloIdForm"
  (closeEvent)="closeForm()"
  (onChange)="changeEvent()"
></app-modulo-menu-form>
