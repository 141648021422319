import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'src/app/shared/services/auth/auth.service';

const telaId = 2;

@Injectable({ providedIn: 'root' })
export class TabsConfiguracaoSistemaService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
