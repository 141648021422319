import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import * as _ from 'underscore';

import { IGrupoUsuario } from '../models/IGrupoUsuario';
import { GrupoUsuarioService } from '../services/grupo-usuario.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-grupo-usuario',
  templateUrl: './grupo-usuario-grid.component.html'
})
export class GrupoUsuarioGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;

  public permissoesTela: IGridPermissoes;
  public editForm = true;

  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public showForm = false;
  public dadosGrid: IGrupoUsuario[];
  public dadosGrupoUsuario: IGrupoUsuario;

  wordsTranslated: any;

  constructor(
    private dialog: MatDialog,
    private grupoUsuarioService: GrupoUsuarioService,
    private loadingService: LoadingService,
    private gridService: WsGridService,
    private alertaService: AlertaService,
    private dialogService: DialogService,
    private wordsTranslate: WordsTranslateService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.grupo-usuario.grid.';
    const words = {
      grupoUsuario_Desc: '',
      mensagem_exclusao: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.grupoUsuarioService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'grupoUsuarioDesc',
        label: this.wordsTranslated.grupoUsuario_Desc,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.grupoUsuarioService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }

  refresh() {
    this.configuraDadosGrid();
  }

  confirmDel(dados: IGrupoUsuario) {
    const dialogConfirm = this.dialogService.confirmDelete();

    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.grupoUsuarioService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeGrupoUsuarioForm();
          });
      }
    });
  }

  openForm(dados: IGrupoUsuario = null) {
    this.dadosGrupoUsuario = dados;
    this.showForm = true;
  }

  closeGrupoUsuarioForm() {
    this.showForm = false;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        grupoUsuarioId: dados.grupoUsuarioId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.grupoUsuarioService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }
}
