<div *ngIf="dados" class="page-content page-content-row-2">
  <div class="box-filter">
    <form>
        <button mat-button type="button" (click)="clickSearch(search)">
          <i class="material-icons">{{ search.value ? 'close' : 'search'}}</i>
        </button>
        <input
          matInput
          class="search"
          type="search"
          placeholder="{{ 'formulario.placeholder.pesquisar' | translate }}"
          (input)="filter(search.value)"
          #search
        />
    </form>
  </div>
  <div class="content">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let usuario of dados.usuarios">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ usuario.usuarioNome }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="conteudo">
            <div *ngFor="let meioEnvio of usuario.meiosEnvio">
              <mat-slide-toggle [checked]="meioEnvio.selecionado" (change)="toggleEvent(alertaId, usuario.usuarioId, meioEnvio.meioEnvioId)">
                {{ meioEnvio.meioEnvioDesc }}
              </mat-slide-toggle>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
