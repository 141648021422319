import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IRodovia } from '../models/IRodovia';
import { ICoordenada } from '../models/ICoordenada';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 3;

@Injectable({ providedIn: 'root' })
export class RodoviaService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}Rodovia`;

  getGrid(): Observable<IRodovia[]> {
    return this.http.get<IRodovia[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IRodovia) {
    return this.http.get<IRodovia>(`${this.apiUrl}/Form/${dados.rodoviaId}`);
  }

  insert(dados: IRodovia) {
    return this.http.post<IRodovia>(`${this.apiUrl}`, dados);
  }

  update(dados: IRodovia) {
    return this.http.put<IRodovia>(`${this.apiUrl}`, dados);
  }

  delete(dados: IRodovia) {
    return this.http.delete<IRodovia>(`${this.apiUrl}/${dados.rodoviaId}`);
  }

  getCoordenadas(rodoviaId: number) {
    return this.http.get<ICoordenada[]>(`${this.apiUrl}/Coordenadas/${rodoviaId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
