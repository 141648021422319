<div class="page-content" [ngClass]="fullScreen ? 'full' : ''" *ngIf="readForm">
  <div class="content" [ngClass]="{ openInfo: showInfo }">
    <button
      mat-button
      class="primary legenda-button"
      (click)="showLegenda()"
      matTooltip="{{ 'botoes.legenda' | translate }}"
    >
      <i class="material-icons">list</i>
    </button>
    <div id="map" #mapRef class="boxMapa"></div>
    <div class="boxInfo">
      <div class="title">
        <!-- <h2>DETALHES SAU</h2> -->
        <h2>{{ titleInfo }}</h2> 
        <button mat-button (click)="closeInfo()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="boxContent">
        <app-mapa-operacional-informacoes-equipamento
          *ngIf="equipamentoInfo"
          [dados]="equipamentoInfo"
        ></app-mapa-operacional-informacoes-equipamento>
      </div>
    </div>
  </div>
</div>
