import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IRodovia } from '../models/IRodovia';
import { IRodoviaCoordenadas } from '../models/IRodoviaCoordenadas';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 3;

@Injectable({ providedIn: 'root' })
export class RodoviaCoordenadaService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';
  apiUrl = `${this.env.apiUrl}Rodovia/Coordenadas`;

  get(rodoviaId: number): Observable<IRodoviaCoordenadas[]> {
    return this.http.get<IRodoviaCoordenadas[]>(`${this.apiUrl}/${rodoviaId}`);
  }

  getGrid(dadosRodovia: IRodovia): Observable<IRodoviaCoordenadas[]> {
    return this.http.get<IRodoviaCoordenadas[]>(
      `${this.apiUrl}/${dadosRodovia.rodoviaId}`
    );
  }

  insert(dados: IRodoviaCoordenadas) {
    return this.http.post<IRodoviaCoordenadas>(`${this.apiUrl}`, dados);
  }

  uploadMidia(uploadDados, rodoviaId: number) {
    return this.http.post(`${this.apiUrl}/${rodoviaId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
