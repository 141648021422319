import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IModuloMenu } from '../../principal/models/IModuloMenu';
import { IModulo } from '../../modulo/models/IModulo';
import { ModuloMenuService } from '../../principal/services/modulo-menu.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';

@Component({
  selector: 'app-modulo-menu-grid',
  templateUrl: './modulo-menu-grid.component.html',
  styleUrls: ['./modulo-menu-grid.component.scss'],
})
export class ModuloMenuGridComponent implements OnInit, OnChanges {
  titleDialog: string;

  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public dadosGrid: IModuloMenu[];
  public dadosFormulario: IModuloMenu;
  public moduloIdForm: number;
  public searchFilter: any = '';
  public query: any;
  public statusBotao: IDialogButton;
  public showMenus: boolean = false;
  titleNoContent: string = '';
  textNoContent: string = '';

  public showForm = false;

  @Input() moduloId: IModulo = null;

  wordsTranslated: any;

  constructor(
    private moduloMenuService: ModuloMenuService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.seguranca.menu.grid.';
    const words = {
      title: '',
      menuTela: '',
      aplicacaoTelaPagina: '',
      leitura: '',
      inserir: '',
      atualizar: '',
      excluir: '',
      searchMenu: '',
      adicionar: '',
      liberado: '',
      tituloNoContent: '',
      textNoContent: '',
      tituloHasNoTelas: '',
      textHasNoTelas: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    this.textNoContent = this.wordsTranslated.textNoContent;
    this.titleNoContent = this.wordsTranslated.tituloNoContent;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.isLoading = true;
    this.moduloMenuService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  configuraDados(): void {
    this.loadingService.show();
    this.moduloIdForm = this.moduloId.aplicacaoModuloId;
    this.moduloMenuService
      .getGrid(this.moduloId.aplicacaoModuloId)
      .pipe(
        finalize(() => {
          this.dadosGrid.forEach((dados) => {
            dados.aplicacaoModuloId = this.moduloId.aplicacaoModuloId;
          });
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.dadosGrid.length > 0 ? this.showMenus = true : this.showMenus = false;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDados();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.query != '' && this.query != null) {
      const dialogConfirm = this.dialogService.confirmClearSearch();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.query = '';
        }
      });
    } else {
      if (this.permissoesTela.update) {
        moveItemInArray(
          this.dadosGrid,
          event.previousIndex,
          event.currentIndex
        );

        for (let index = 0; index < this.dadosGrid.length; index++) {
          if (this.dadosGrid[index].ordem > 0) {
            if (event.currentIndex != 0) {
              if (
                this.dadosGrid[event.previousIndex].ordem != 0 &&
                this.dadosGrid[event.currentIndex - 1].ordem != 0
              ) {
                this.dadosGrid[index].ordem = index + 1;
                var dado = this.dadosGrid[index];

                this.moduloMenuService.updateOrdem(dado).subscribe(() => {});
              }
            } else {
              if (this.dadosGrid[event.previousIndex].ordem != 0) {
                this.dadosGrid[index].ordem = index + 1;
                var dado = this.dadosGrid[index];

                this.moduloMenuService.updateOrdem(dado).subscribe(() => {});
              }
            }
          }
        }
      }
    }
  }

  changeToogleInserir(dado: IModuloMenu) {
    if (this.permissoesTela.update) {
      if (dado.inserir) {
        dado.inserir = false;
      } else {
        dado.inserir = true;
      }

      this.moduloMenuService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }

  changeToogleLeitura(dado: IModuloMenu) {
    if (this.permissoesTela.update) {
      if (dado.leitura) {
        dado.leitura = false;
      } else {
        dado.leitura = true;
      }

      this.moduloMenuService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }

  changeToogleAtualizar(dado: IModuloMenu) {
    if (this.permissoesTela.update) {
      if (dado.atualizar) {
        dado.atualizar = false;
      } else {
        dado.atualizar = true;
      }

      this.moduloMenuService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }

  changeToogleExcluir(dado: IModuloMenu) {
    if (this.permissoesTela.update) {
      if (dado.excluir) {
        dado.excluir = false;
      } else {
        dado.excluir = true;
      }

      this.moduloMenuService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }
  changeToogleLiberado(dado: IModuloMenu) {
    if (this.permissoesTela.update) {
      if (!dado.liberado) {
        this.moduloMenuService.insertLiberado(dado).subscribe(() => {
          dado.liberado = false;
          this.configuraDados();
          
        });
      } else {
        this.moduloMenuService.deleteLiberado(dado).subscribe(() => {
          dado.liberado = true;
          this.configuraDados();
        });
      }

      
    }
  }

  openForm(dados: IModuloMenu = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }
  editForm(dado) {
    this.dadosFormulario = dado;
    this.showForm = true;
  }
  closeForm() {
    this.showForm = false;
  }
  changeEvent() {
    this.configuraDados();
  }
  deleteForm(dado: IModuloMenu) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.moduloMenuService.delete(dado).subscribe(() => {
          this.configuraDados();
        });
      }
    });
    
  }

}
