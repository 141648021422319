import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IConfiguracao } from 'src/app/shared/models/IConfiguracao';
import { TranslateService } from '@ngx-translate/core';
import * as signalR from '@microsoft/signalr';
import { IConfiguracaoAbout } from '../../components/about/models/IConfiguracaoAbout';
import { Observable } from 'rxjs';
import { IConfiguracaoExtensao } from '../../models/IConfiguracaoExtensao';
import { IConfiguracaoAd } from '../../components/login/models/IConfiguracaoAd';
import { ISAW } from '../../models/ISaw';
import { EnvironmentService } from '../env/environment.service';
import { INewCallViaWCallDataEvent } from './INewCallViaWCallDataEvent';

const prefixo = environment.prefixo;

@Injectable({ providedIn: 'root' })
export class ConfiguracaoService {
  public processarEventoSignal = new EventEmitter<any>();
  public processarEventoNewCallViaWCall = new EventEmitter<INewCallViaWCallDataEvent>();

  private hubConnection!: signalR.HubConnection;
  wordsTranslated: any;
  private renderer: Renderer2;
  public dadosEquipamento: ISAW[];

  endCallToNumber = new EventEmitter<number>();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private env: EnvironmentService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  apiUrl = this.env.apiUrl + 'SistemaConfiguracao';
  hubUrl = this.env.hubUrl + 'streaminghub';
  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';

  public dadosConfiguracao: IConfiguracao = JSON.parse(
    localStorage.getItem(prefixo + 'config_default')
  );

  exposeConnection(){
    return this.hubConnection;
  }

  get(): Observable<boolean> {
    return new Observable((subscriber) => {
      const http$ = this.http.get<IConfiguracao>(this.apiUrl);
      http$.subscribe((res) => {
        this.dadosConfiguracao = res;
        localStorage.setItem(
          environment.prefixo + 'config_default',
          JSON.stringify(this.dadosConfiguracao)
        );
        this.setIdioma();
        this.setTema();
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  public getAbout(): Observable<IConfiguracaoAbout> {
    return this.http.get<IConfiguracaoAbout>(this.apiUrl + '/about');
  }

  public getAD(): Observable<IConfiguracaoAd> {
    return this.http.get<IConfiguracaoAd>(this.apiUrl + '/ad');
  }

  public getSip() {
    return {
      sipDomain: this.dadosConfiguracao.sipDomain,
      sipPortWs:  this.dadosConfiguracao.sipPortWs,
      sipPortUdp:  this.dadosConfiguracao.sipPortUdp,
      sipWss:  this.dadosConfiguracao.sipWss,
      sipEnable: this.dadosConfiguracao.sipEnable,
    }
  }

  public getKeyGoogleMaps() {
    return this.dadosConfiguracao.googleKey;
  }

  public setIdioma(idioma = null) {
    if (!idioma) {
      if (this.dadosConfiguracao && this.dadosConfiguracao.idioma) {
        idioma = this.dadosConfiguracao.idioma;
      } else {
        idioma = environment.idioma;
      }
    }

    this.translate.use(idioma);
    this.translate.setDefaultLang(idioma);
  }

  public setTema(tema = null) {
    if (!tema) {
      if (this.dadosConfiguracao && this.dadosConfiguracao.tema) {
        tema = this.dadosConfiguracao.tema;
      } else {
        tema = environment.tema;
      }
    }

    document.body.classList.value = '';
    this.renderer.addClass(document.body, tema);
  }

  public conectarHub() {
    if (this.dadosConfiguracao && this.dadosConfiguracao.hub) {
      this.setHub();
    } else {
      this.get();
    }
  }

  public disconnectHub(): void {
    if(this.hubConnection.state === "Connected") {
      this.hubConnection.stop();
    }
  }

  public setHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.initHubs();
      })
      .catch((err) => console.log('Não foi possível conectar ao Hub.'));
  }

  private initHubs() {
    this.Join();

    if(this.dadosConfiguracao.notificaVandalismo)
      this.vandalismoEquipamento();

    this.refreshConfig();
    this.endCall();
    this.newCallViaWCall();
    this.handleOnClose();
  }

  private Join(): void {
    var hostname = `saw`;
    this.hubConnection.invoke('JoinAsync', hostname);
    console.log(`HUB: Registrando hostname ${hostname}`);
  }

  private vandalismoEquipamento = () => {
    this.hubConnection.on('VandalismoEquipamento', (numeroSerie: string) => {
      let evento = {
        tipoEvento: 'vandalismo',
        numeroSerie,
      }

      this.processarEventoSignal.emit(evento);
      console.log(`HUB: VandalismoEquipamento `);
    });
  };

  private refreshConfig = () => {
    this.hubConnection.on('RefreshConfig', (data: any) => {
      console.log(`HUB: RefreshConfig`);

      let arg = {
        altoFalante: data && data.altoFalante ? data.altoFalante : '6',
        microfone: data && data.microfone ? data.microfone : '3',
        numeroSerie: data && data.numeroSerie ? data.numeroSerie : null,
      }

      //this.controleVolumeService.setVolumeEquipamento(arg.altoFalante, arg.microfone, arg.numeroSerie);
    });
  };

  private endCall = () => {
    this.hubConnection.on('EndCall', (data: any) => {
      console.log(`HUB: EndCall ${data}`);
      this.endCallToNumber.next(data)
    });
  };

  public getExtensions(telaId: number) {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  public newCallViaWCall (): void {
    this.hubConnection.on("NewCallViaWCall", (data: INewCallViaWCallDataEvent) => {
      console.log(`HUB: NewCallViaWCall => `, data);
      this.processarEventoNewCallViaWCall.emit(data);
    });
  }

  public handleOnClose(): void {
    this.hubConnection.onclose((error) => {
      console.log(`HUB: Conexão com o hub encerrada, error: ${error.name} - message: ${error.message} - stack: ${error.stack}`);
      console.log(`HUB: Tentando reconexão.`);
      this.setHub();
    })
  }
}
