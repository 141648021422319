import { Component, HostListener, OnInit, Type } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment, notificationIcon } from 'src/environments/environment';
import * as _ from 'underscore';

import { AtendimentoService } from './page/operacao/atendimento/services/atendimento.service';
import { MapaOperacionalService } from './page/operacao/mapa-operacional/services/mapa-operacional.service';
import { AboutComponent } from './shared/components/about/about.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { IShortCut } from './shared/components/shortcut/models/IShortCut';
import { ShortCutService } from './shared/components/shortcut/services/shortCut.service';
import { EventsEnum } from './shared/enum/EventsEnum';
import { TextHelper } from './shared/helper/text.helper';
import { IMenu } from './shared/models/IMenu';
import { IMenuItens } from './shared/models/IMenuItens';
import { ITabs } from './shared/models/ITabs';
import { IUser } from './shared/models/IUser';
import { IUsuarioRamal } from './shared/models/IUsuarioRamal';
import { IWallpaperConfiguracaoSistema } from './shared/models/IWallpaperConfiguracaoSistema';
import { AdItem, AdService } from './shared/services/ad-service';
import { AlertaService } from './shared/services/alerta/alerta.service';
import { AuthService } from './shared/services/auth/auth.service';
import { CloseModalService } from './shared/services/closeModal/close-modal.service';
import { ConfiguracaoService } from './shared/services/configuracao/configuracao.service';
import { DialogService } from './shared/services/dialog/dialog.service';
import { EventBusService } from './shared/services/eventBus/event-bus.service';
import { MenuService } from './shared/services/menu/menu.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { SoftPhoneService } from './shared/services/softPhone/soft-phone.service';
import { WallpaperConfiguracaoSistemaService } from './shared/services/wallpaper/wallpaper-configuracao-sistema.service';
import { LegendaService } from './shared/services/legenda/legenda.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CloseModalService]
})
export class AppComponent implements OnInit {
  public isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  public registroShortCut: IShortCut[];
  public menuAplicacao: Array<IMenu> = [];
  private ads: AdItem[];

  public imageUser: string;
  public dadosUsuario: IUser;
  public tabs: Array<ITabs> = [];
  public selected = new FormControl(0);
  public currentComponent;
  public dadosIniciaisCarregado = false;
  public tabsAbertas = [];
  public showLicencaModal: boolean = false;
  public showSegurancaModal: boolean = false;

  public formAutoCompleteControl = new FormControl();
  public filteredOptions: Observable<IMenu[]>;
  public exibirLogin = false;
  public wallPaperImage: string | boolean = false;
  public logoEmpresa: string | boolean = false;
  public shortCutLoaded = false;

  public showSoftPhone = false;
  public statusSoftPhone = false;
  public acessoSoftPhone: IUsuarioRamal;
  wordsTranslated: any;
  equipamento: any;

  constructor(
    private dialog: MatDialog,
    private adService: AdService,
    private menuService: MenuService,
    private textHelper: TextHelper,
    private authService: AuthService,
    private route: ActivatedRoute,
    private configService: ConfiguracaoService,
    private shortcutService: ShortCutService,
    private wallpaperService: WallpaperConfiguracaoSistemaService,
    private softPhoneService: SoftPhoneService,
    private mapaOperacional: MapaOperacionalService,
    private alertaService: AlertaService,
    private dialogService: DialogService,
    private notificacaoService: NotificationService,
    private atendimentoService: AtendimentoService,
    private eventBus: EventBusService,
    private legendaService: LegendaService
  ) {
    this.configService.setIdioma(environment.idioma);
    this.configService.setTema(environment.tema);
    this.checkLogin();
    this.listenFocusMap();
  }

  @HostListener('window:online', ['$event'])
  OnlineEvent(event: Event) {
    this.notificacaoStatusNetwork("SAW");
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.ads = this.adService.getAds();
    this.filteredOptions = this.formAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.wallpaperService.changeWallpaper.subscribe((dados) => {
      this.loadWallpaper();
    });

    this.shortcutService.changeShortCut.subscribe((dados) => {
      this.loadShortCut();
    });

    this.listenNotificao();

    this.route.queryParams.subscribe((params) => {
      if (params['modulo'] == 'suporte') {
        this.showSegurancaModal = true;
        this.confirmPasswordLicensa();
      }
    });
  }

  listenNotificao() {
    this.configService.processarEventoSignal.subscribe((evento) => {
      this.softPhoneService
        .getEquipamentoNumeroSerie(evento.numeroSerie.numeroSerie)
        .subscribe((result) => {
          this.equipamento = result;
            switch (evento.tipoEvento){
              case 'vandalismo':
                {
                  this.notificacaoVandalismo(this.equipamento.prefixo)
                  break;
                }
              case 'ligacao':
                {
                this.notificacaoLigacao(this.equipamento.prefixo)
                  break;
                }
            }
        })
    });

    this.configService.processarEventoNewCallViaWCall.subscribe((data) => {
      console.log("HUB: processarEventoNewCallViaCall => ", data);
      if (this.dadosUsuario.dados.nome.toLowerCase() === data.loggedAs.toLowerCase()) {
        this.addTab("Atendimento", "TAtendimento");
        setTimeout(() => {
          this.eventBus.emit(EventsEnum.ShowFormAtendimento, data);
        }, 1000);
      }
    })
  }

  notificacaoVandalismo(prefixo) {
    this.notificacaoService.createNotification(
      notificationIcon.vandalismo,
      `O equipamento foi vandalizado!`,
      `${prefixo} - Alerta de Vandalismo`,
      prefixo)
  }

  notificacaoLigacao(prefixo) {
    this.notificacaoService.createNotification(
      notificationIcon.ligacao,
      `Recebendo chamada de [${this.equipamento.ramal}]`,
      `${prefixo} - Ligação`,
      prefixo)
  }

  notificacaoStatusNetwork(prefixo: string): void {
    this.notificacaoService.createNotification(
      notificationIcon.recuperacaoOnline,
      `Evento de rede. Verificar sua sessão.`,
      `${prefixo} - Alerta de Rede`,
      prefixo)
  }

  listenFocusMap() {
    this.mapaOperacional.focusMap.subscribe(() => {
      let mapTabIndex;
      for (let index = 0; index < this.tabs.length; ++index) {
        if (this.tabs[index].component.name === 'MapaOperacionalComponent') {
          mapTabIndex = index;
          break;
        }
      }
      if (mapTabIndex !== undefined)
        this.selected.setValue(mapTabIndex)
    });
  }

  loadWallpaper() {
    this.wallpaperService
      .get()
      .subscribe((result: IWallpaperConfiguracaoSistema[]) => {
        if (result) {
          this.wallPaperImage = this.wallpaperService.loadImage(result);
        }
      });
  }

  checkLogin() {
    if (this.authService.checkLogin()) {
      if (!this.dadosIniciaisCarregado) {
        this.authService.validaToken().subscribe((result) => {
          this.dadosUsuario = this.authService.getLoginDados();
          if (this.dadosUsuario) {
            this.configService.get().subscribe(() => {
              this.authService.setIdiomaUsuario();
              this.configService.conectarHub();
              this.loadItensMenu();
              this.loadShortCut();
              this.loadImageUser();
              this.loadWallpaper();
              this.connectSoftPhone();
            });
          }

          this.dadosIniciaisCarregado = true;
          this.exibirLogin = false;
          return true;
        });
      }
    } else {
      this.exibirLogin = true;
      return false;
    }
  }

  loadImageUser() {
    if (this.dadosUsuario) {
      const imageString = JSON.stringify(
        `data:${this.dadosUsuario.dados.fotoContentType};base64, ${this.dadosUsuario.dados.foto}`
      ).replace(/\\n/g, '');
      this.imageUser = 'url(' + imageString + ')';
    }
  }

  showAbout() {
    this.dialog.open(AboutComponent, {
      disableClose: false,
      width: '40%'
    });
  }

  showChangePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '30%'
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      dialogRef.close();
    });
  }

  logout() {
    this.configService.disconnectHub();
    this.statusSoftPhone = false;
    this.acessoSoftPhone = null;
    this.authService.logout();
    this.checkLogin();
    this.dadosIniciaisCarregado = false;
    this.shortCutLoaded = false;
  }

  useTema(tema: string) {
    this.configService.setTema(tema);
  }

  useLanguage(language: string) {
    this.configService.setIdioma(language);
  }

  // ABAS
  addTab(label: string, path: string) {
    this.authService.validaToken().subscribe((result) => {
      if (path == 'TSeguranca') {
        this.confirmPassword(label, path);
      } else {
        this.confirmAddTab(label, path);
      }
    });
  }

  confirmAddTab(label: string, path: string) {
    const indexPage = _.findLastIndex(this.ads, {
      name: path
    });

    let component: Type<any>;

    if (indexPage >= 0) {
      component = this.ads[indexPage].component;
    } else {
      component = NotFoundComponent;
    }

    this.tabs.push({ label, path, component });
    this.selected.setValue(this.tabs.length - 1);
    this.closeLegenda();
  }

  removeTab(index: number, all: boolean = false) {
    if (all) {
      this.tabs = new Array<ITabs>();
    } else {
      this.tabs.splice(index, 1);
    }

    this.closeLegenda();
  }

  closeLegenda(){
    this.legendaService.hide();
  }

  onSelectedIndexChange($event){
    this.selected.setValue($event);
    this.closeLegenda();
  }

  // MENU NAVBAR
  loadItensMenu() {
    this.dadosUsuario = this.authService.getLoginDados();
    if (this.dadosUsuario) {
      this.menuAplicacao = new Array<IMenu>();
      this.menuService.getMenus(this.dadosUsuario).subscribe((data) => {
        for (const d of data as any) {
          const title: string = d.title;
          const icon: string = d.icon;
          const itens: Array<IMenuItens[]> = d.itens;
          this.menuAplicacao.push({ title, icon, itens });
        }
      });
    }
  }

  // MENU AUTO COMPLETE
  _filter(value: string) {
    if (typeof value !== 'undefined' && value != null && value !== '') {
      const filterValue = this.textHelper.removerAcentos(value.toLowerCase());
      const filteredArray = this.menuAplicacao
        .filter((categorias) =>
          categorias.itens.some((item) =>
            this.textHelper
              .removerAcentos(item['title'])
              .toLowerCase()
              .includes(filterValue)
          )
        )
        .map((categorias) => {
          const n = Object.assign({}, categorias, {
            itens: categorias.itens.filter((item) =>
              this.textHelper
                .removerAcentos(item['title'])
                .toLowerCase()
                .includes(filterValue)
            )
          });
          return n;
        });

      return filteredArray;
    }
  }

  // ShortCut
  loadShortCut() {
    this.dadosUsuario = this.authService.getLoginDados();
    this.shortcutService.get(this.dadosUsuario.dados.id).subscribe((res) => {
      localStorage.setItem(
        environment.prefixo + 'shortCut',
        JSON.stringify(res)
      );
      this.getAllShortCut();
    });
  }

  getAllShortCut() {
    this.registroShortCut = this.shortcutService.getAllShortCut();

    if (this.shortCutLoaded == false) {
      this.registroShortCut.map((item) => {
        if (item.abrirAuto) {
          if (
            this.tabs.length == 0 ||
            this.tabs.some((tab) => tab.path !== item.aplicacaoTelaPagina)
          ) {
            let abrir = {
              label: item.aplicacaoTelaTexto,
              path: item.aplicacaoTelaPagina
            };
            this.openShortCut(abrir);
          }
        }
      });
      this.shortCutLoaded = true;
    }
  }

  openShortCut($event) {
    this.tabsAbertas = [];

    if (this.tabs.length > 0){
      this.tabs.forEach((tab, index) => {
        this.tabsAbertas.push(tab.path)
        if(this.tabsAbertas[index] == $event.path){
          this.removeTab(index, false);
        }
      });
    }
    this.addTab($event.label, $event.path);
  }

  //SOFTPHONE
  connectSoftPhone() {
    let sipConfig = this.configService.getSip();
    this.acessoSoftPhone = null;
    this.statusSoftPhone = false;
    this.dadosUsuario = this.authService.getLoginDados();
    this.softPhoneService
      .getRamal(this.dadosUsuario.dados.id)
      .subscribe((result: IUsuarioRamal) => {
        if (result && sipConfig.sipEnable) {
          this.statusSoftPhone = true;
          this.acessoSoftPhone = result;
        }
      });

    this.softPhoneService.showSoftPhone.subscribe((dados) => {
      let tabAtendimento = this.tabs.map(result => {        
        return result.label == 'Atendimento'
      });
      
      const indiceAtendimentos: number[] = [];
      this.tabsAbertas = [];
      if (this.tabs.length > 0){
        this.tabs.forEach((tab, index) => {
          this.tabsAbertas.push(tab.path)
          if(this.tabsAbertas[index] == 'TAtendimento')
          indiceAtendimentos.push(index);
        });
      }

      this.atendimentoService.showFormAtendimentoLigacao.emit(true);
      
      if (dados) {
        this.showSoftPhone = true;

        if (tabAtendimento.length == 0){
          this.addTab('Atendimento', 'TAtendimento');
        } else {
          this.selected.setValue(indiceAtendimentos[0])
        } 
        } else {
          this.showSoftPhone = false;
        }
    });
  }

  //MODULE WITH ADMIN PASSWORD
  confirmPassword(label, path) {
    const dialogConfirm = this.dialogService.confirmPassword();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        if (result == environment.passwordAdminSystem) {
          this.dialog.closeAll();
          this.confirmAddTab(label, path);
        } else {
          this.alertaService.senhaIncorreta();
          this.confirmPassword(label, path);
        }
      }
    });
  }

  //MODULE WITH ADMIN PASSWORD
  confirmPasswordLicensa() {
    const dialogConfirm = this.dialogService.confirmPassword();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        if (result == environment.passwordAdminSystem) {
          this.dialog.closeAll();
          this.showLicencaModal = true;
        } else {
          this.alertaService.senhaIncorreta();
          this.confirmPasswordLicensa();
        }
      }
    });
  }
}
