import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 15;

@Injectable({
  providedIn: 'root'
})
export class ArquivoPadraoTotemService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService,) { }

  apiUrl = `${this.env.apiUrl}ArquivoPadraoTotem`;
  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';

  getMidia(tipoId: number) {
    return this.http.get<File[]>(`${this.apiUrl}/${tipoId}`);
  }

  uploadMidia(uploadDados, tipoId: number) {
    return this.http.put(`${this.apiUrl}/${tipoId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  loadImage(imagem) {
    if (!imagem[0] || imagem[0].content == '') {
      return false;
    }

    let img = imagem[0];

    const imageString = JSON.stringify(
      `data:${img.contentType};base64, ${img.content}`
    ).replace(/\\n/g, '');

    return imageString;
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
