import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaSistemaService } from '../../services/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { finalize } from 'rxjs/operators';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IAlertaParametros } from '../../models/IAlerta';

@Component({
  selector: 'app-alerta-parametros-grid',
  templateUrl: './alerta-parametros-grid.component.html',
  styleUrls: ['./alerta-parametros-grid.component.scss']
})
export class AlertaParametrosGridComponent implements OnInit, OnChanges {
  private readonly fileTranslate = 'AlertaParametrosGrid';
  @Input() permissoes: IGridPermissoes;
  acoesGrid: IGridAcoes;
  colunasGrid: IGridModelo[];
  dadosGrid: IAlertaParametros[];
  isLoading: boolean = true;

  @Input() alertaId: number;

  registro: IAlertaParametros;
  showForm: boolean = false;

  wordsTranslated = {
    parametroDesc: "",
    parametroValor: ""
  };

  constructor(
    private alertaSistemaService: AlertaSistemaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["alertaId"]) {
      this.getDados();
    }
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.wordsTranslated = this.wordsTranslate.get(this.wordsTranslated, 'paginas.alerta.parametros.grid.');
    this.getAcoes();
    this.getColunas();
  }

  getAcoes() {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false
    };
    this.isLoading = !(!!this.acoesGrid && !!this.colunasGrid && !!this.dadosGrid);
  }

  getColunas() {
    this.colunasGrid = [
      {
        name: 'parametroDesc',
        label: this.wordsTranslated.parametroDesc,
        width: '',
        sort: true
      },
      {
        name: 'alertaParametroValor',
        label: this.wordsTranslated.parametroValor,
        width: '100px',
        sort: false
      },
    ];
    this.isLoading = !(!!this.acoesGrid && !!this.colunasGrid && !!this.dadosGrid);
  }

  getDados() {
    this.loadingService.show();
    this.alertaSistemaService.getParametros(this.alertaId).pipe(
      finalize(() => {
        this.loadingService.hide();
        this.isLoading = !(!!this.acoesGrid && !!this.colunasGrid && !!this.dadosGrid);
      })
    ).subscribe((res) => {
      this.dadosGrid = res;
    });
  }

  openForm(row: IAlertaParametros) {
    this.registro = row;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }
}
