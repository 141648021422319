import { Renderer2 } from '@angular/core';
import { EventEmitter, Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';

@Injectable({ providedIn: 'root' })
export class CloseModalService {
  closeForm = new EventEmitter<any>();
  modal: any;
  modalName: any;
  retorno: any;
  globalListenFunc: Function;

  constructor(
    private dialogService: DialogService,
    private renderer: Renderer2
  ) {
    this.globalListenFunc = this.renderer.listen('document', 'keyup', (e) => {
      if (e.key == 'Escape') {
        this.modal = null;
        this.modalName = null;
        this.retorno = null;

        if (this.setThisModal()) {
          this.setStateModal(this.modalName);
        }
      }
    });
  }

  setStateModal(modalName: string) {
    if (this.modal != undefined) {
      if (this.modal.length > 0) {
        let formDirty = this.dirtyForm(this.modal);
        if (formDirty) {
          this.confirmClose(modalName);
        } else {
          this.emitClose(modalName);
        }
      }
    } else {
      this.setThisModal();

      if (this.dirtyForm(this.modal)) {
        this.confirmClose(modalName);
      } else {
        this.emitClose(modalName);
      }
    }
  }

  dirtyForm(modal: any) {
    let form = modal[modal.length - 1].getElementsByTagName('form');
    if (form.length > 0) return form[0].classList.contains('ng-dirty');
    return false;
  }

  confirmClose(modalName: any) {
    const dialogConfirm = this.dialogService.confirmCloseForm();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.emitClose(modalName);
      }
    });
  }

  emitClose(modalName) {
    this.retorno = {
      close: true,
      name: modalName
    };
    this.closeForm.emit(this.retorno);
  }

  setThisModal() {
    this.modal = document.getElementsByClassName('modal');
    if (this.modal.length > 0) {
      this.modalName = this.modal[this.modal.length - 1].getAttribute(
        'modal-name'
      );
      return true;
    }
    return false;
  }
}
