<ng-container
  *ngIf="!isLoading; else tmpEmpty"
>
  <ws-grid
    *ngIf="!isLoading"
    [permissoes]="permissoesTela"
    [isLoading]="isLoading"
    [acoesGrid]="acoesGrid"
    [colunasGrid]="colunasGrid"
    [dadosGrid]="dadosGrid"
    [registroPorPagina]="registroPorPagina"
    [listaOpcaoPorPagina]="listaOpcaoPorPagina"
    (toogleEvent)="toogleEvent($event)"
  ></ws-grid>
</ng-container>

<ng-template #tmpEmpty>
  <ws-no-content
    [title]="titleNoContent"
    [text]="textNoContent"
  ></ws-no-content>
</ng-template>
