import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize, map } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IModulo } from '../models/IModulo';
import { ModuloService } from '../services/modulo.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';

@Component({
  selector: 'app-modulo-grid',
  templateUrl: './modulo-grid.component.html',
  styleUrls: ['./modulo-grid.component.scss'],
})
export class ModuloGridComponent implements OnInit, OnChanges {
  titleDialog: string;

  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public dadosGrid: IModulo[];
  public dadosFormulario: IModulo;
  public searchFilter: any = '';
  public query: any;
  public statusBotao: IDialogButton;

  public showForm = false;

  @Output() moduloId = new EventEmitter<any>();

  wordsTranslated: any;

  constructor(
    private moduloService: ModuloService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.seguranca.modulo.grid.';
    const words = {
      title: '',
      menuModulo: '',
      icone: '',
      searchModulo: '',
      adicionar: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.moduloService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  ngOnChanges() {
    this.isLoading = true;
    this.moduloService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  configuraDados(): void {
    this.loadingService.show();

    this.moduloService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDados();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.query != '' && this.query != null) {
      const dialogConfirm = this.dialogService.confirmClearSearch();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.query = '';
        }
      });
    } else {
      if (this.permissoesTela.update) {
        moveItemInArray(
          this.dadosGrid,
          event.previousIndex,
          event.currentIndex
        );

        for (let index = 0; index < this.dadosGrid.length; index++) {
          if (this.dadosGrid[index].ordem > 0) {
            if (event.currentIndex != 0) {
              if (
                this.dadosGrid[event.previousIndex].ordem != 0 &&
                this.dadosGrid[event.currentIndex - 1].ordem != 0
              ) {
                this.dadosGrid[index].ordem = index + 1;
                var dado = this.dadosGrid[index];

                this.moduloService.updateOrdem(dado).subscribe(() => {});
              }
            } else {
              if (this.dadosGrid[event.previousIndex].ordem != 0) {
                this.dadosGrid[index].ordem = index + 1;
                var dado = this.dadosGrid[index];

                this.moduloService.updateOrdem(dado).subscribe(() => {});
              }
            }
          }
        }
      }
    }
  }
  sendModuloId(moduloId){
    this.moduloId.emit(moduloId);
  }
  
  openForm(dados: IModulo = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  editForm(dado){
    this.dadosFormulario = dado;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  changeEvent(){
    this.configuraDados();
  }
  
}
