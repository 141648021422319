import { MockupTotemService } from './../services/mockup-totem.service';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IMockupTotem } from '../models/IMockupTotem';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';

@Component({
  selector: 'app-mockup-totem-form',
  templateUrl: './mockup-totem-form.component.html',
  styleUrls: ['./mockup-totem-form.component.scss']
})
export class MockupTotemFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  @Input() registro: IMockupTotem[] = [];
  @Input() habilitaRevista: boolean;

  public statusBotao: IDialogButton;
  public formLoading = false;
  public nameLabelColorPicker: string = 'Cor';
  public corValue: string = '#000000';

  cardAplicacao : boolean = false;
  cardAtendimento: boolean =false;
  cardTitulo : boolean = false;
  cardCondicoesRodovia : boolean = false;
  cardProcuraDestino : boolean = false;
  cardPerguntasFrequentes : boolean = false;
  cardTelefonesUteis : boolean = false;
  cardLivroRegistro : boolean = false;
  cardLeitorRevista: boolean = false;
  cardBarras : boolean = false;
  cardTelefone : boolean = false;
  cardVideoRemoto : boolean = false;
  cardVideoLocal : boolean = false;

  public dadosAplicacao: IMockupTotem;
  public dadosAtendimento: IMockupTotem;
  public dadosTitulo: IMockupTotem;
  public dadosCondicoesRodovia: IMockupTotem;
  public dadosProcurarDestino: IMockupTotem;
  public dadosPerguntasFrequentes: IMockupTotem;
  public dadosTelefonesUteis: IMockupTotem;
  public dadosTelefone: IMockupTotem;
  public dadosLivroRegistro: IMockupTotem;
  public dadosLeitorRevista: IMockupTotem;
  public dadosBarras: IMockupTotem;
  public dadosVideoRemoto: IMockupTotem;
  public dadosVideoLocal: IMockupTotem;
  public dadosUpdate: IMockupTotem[] = [];
  wordsTranslated: any;

  constructor(
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private mockupTotemService: MockupTotemService,
    private wordsTranslate: WordsTranslateService
  ) {
    this.loadWordsTranslate();
    this.listemChangesForm();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.mockup-totem.alerta.';
    const words = {
      corEmpty: '',
      selectColor:''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  listemChangesForm(){
    this.mockupTotemService.changeCard.subscribe((cardEdit) => {
      this.cardAplicacao = false;
      this.cardAtendimento = false;
      this.cardTitulo = false;
      this.cardCondicoesRodovia = false;
      this.cardLivroRegistro = false;
      this.cardLeitorRevista = false;
      this.cardPerguntasFrequentes = false;
      this.cardProcuraDestino = false;
      this.cardBarras = false;
      this.cardTelefonesUteis = false;
      this.cardTelefone = false;
      this.cardVideoLocal = false;
      this.cardVideoRemoto = false;

      switch (cardEdit) {
        case cardEdit = "BG_APLICACAO":
          this.cardAplicacao = true;
          break;
        case cardEdit = "BG_ATENDIMENTO":
          this.cardAtendimento = true;
          break;
        case cardEdit = "COR_TITULO":
          this.cardTitulo = true;
          break;
       case cardEdit = "BG_CONDICOES_RODOVIA":
        this.cardCondicoesRodovia = true;
          break;
      case cardEdit = "BG_PROCURAR_DESTINO":
        this.cardProcuraDestino = true;
          break;
      case cardEdit = "BG_PERGUNTAS_FREQUENTES":
        this.cardPerguntasFrequentes = true;
          break;
      case cardEdit = "BG_TELEFONES_UTEIS":
          this.cardTelefonesUteis = true;
          break;
      case cardEdit = "BG_LIVRO_REGISTRO":
        this.cardLivroRegistro = true;
          break;
      case cardEdit = "BG_LEITOR_REVISTA":
        this.cardLeitorRevista = true;
          break;
      case cardEdit = "BG_BARRAS":
        this.cardBarras = true;
          break;
      case cardEdit = "BG_TELEFONE":
        this.cardTelefone = true;
          break;
      case cardEdit = "BG_VIDEO_LOCAL":
        this.cardVideoLocal = true;
          break;
      case cardEdit = "BG_VIDEO_REMOTO":
        this.cardVideoRemoto = true;
          break;
        default:
          break;
      }
    });
  }

  getParametro(parametro){
    let retorno = this.registro.find(x => x.personalizarTotemParametro == parametro)
    return retorno
  }

  ngOnInit() {
    this.loadingService.show();
    if (this.registro != null) {
      this.setValoresForm();
      this.loadingService.hide();
    }
  }

  setValoresForm(){
    this.dadosVideoRemoto = this.getParametro("BG_VIDEO_REMOTO");
    this.dadosVideoLocal = this.getParametro("BG_VIDEO_LOCAL");
    this.dadosTelefone = this.getParametro("BG_TELEFONE");
    this.dadosBarras = this.getParametro("BG_BARRAS");
    this.dadosLeitorRevista = this.getParametro("BG_LEITOR_REVISTA");
    this.dadosLivroRegistro = this.getParametro("BG_LIVRO_REGISTRO");
    this.dadosTelefonesUteis = this.getParametro("BG_TELEFONES_UTEIS");
    this.dadosPerguntasFrequentes = this.getParametro("BG_PERGUNTAS_FREQUENTES");
    this.dadosProcurarDestino = this.getParametro("BG_PROCURAR_DESTINO");
    this.dadosCondicoesRodovia = this.getParametro("BG_CONDICOES_RODOVIA");
    this.dadosTitulo = this.getParametro("COR_TITULO");
    this.dadosAplicacao = this.getParametro("BG_APLICACAO");
    this.dadosAtendimento = this.getParametro("BG_ATENDIMENTO");
    this.dadosLeitorRevista = this.getParametro("BG_LEITOR_REVISTA");
  }
}
