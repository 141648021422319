import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGrupoUsuario } from '../models/IGrupoUsuario';
import { GrupoUsuarioService } from '../services/grupo-usuario.service';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';

@Component({
  selector: 'app-grupo-usuario-form',
  templateUrl: './grupo-usuario-form.component.html',
  styleUrls: ['./grupo-usuario-form.component.scss']
})
export class GrupoUsuarioFormComponent implements OnInit {
  public formValidParam = {
    descricao: {
      max: 255
    }
  };
  wordsTranslated: any;
  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IGrupoUsuario = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IGrupoUsuario>();

  private registroClean: IGrupoUsuario;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private grupoUsuarioService: GrupoUsuarioService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalGrupoUsuarioForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.grupo-usuario.form.';
    const words = {
      editar_registro: '',
      adicionar_registro: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();

    if (this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }

    this.loadingService.hide();
  }

  loadEditData() {
    this.grupoUsuarioService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.grupoUsuarioService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      const valueToInsert = { ...valuesForm, grupoUsuarioId: null };

      this.grupoUsuarioService
        .insert(valueToInsert)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno: IGrupoUsuario, tipo: string) {
    const change = {
      dados: retorno,
      tipo
    };

    this.onChange.emit(change);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.registro
      ? this.wordsTranslated.editar_registro
      : this.wordsTranslated.adicionar_registro;
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      grupoUsuarioId: [
        this.registro != null && this.registro.grupoUsuarioId !== null
          ? this.registro.grupoUsuarioId
          : ''
      ],
      grupoUsuarioDesc: [
        this.registro != null && this.registro.grupoUsuarioDesc
          ? this.registro.grupoUsuarioDesc
          : '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.formValidParam.descricao.max)
        ])
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    }
  }
}
