import { Component, OnInit, Input } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IAtendimento } from '../../models/IAtendimento';
import { AtendimentoService } from '../../services/atendimento.service';

@Component({
  selector: 'app-atendimento-atendimentos-grid',
  templateUrl: './atendimento-atendimentos-grid.component.html',
  styleUrls: ['./atendimento-atendimentos-grid.component.scss']
})
export class AtendimentoAtendimentosGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IAtendimento[];

  wordsTranslated: any;

  constructor(
    private atendimentoService: AtendimentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
    this.refreshAtendimento();
  }

  refreshAtendimento() {
    this.atendimentoService.refreshAtendimentos.subscribe((result) => {
      this.configuraDadosGrid();
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.atendimentos-grid.';
    const words = {
      atendimentos: '',
      prefixo: '',
      tipoAtendimentoNome: '',
      atendimentoData: '',
      atendente: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.atendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,
      enable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true,
      },
      {
        name: 'tipoAtendimentoNome',
        label: this.wordsTranslated.tipoAtendimentoNome,
        width: '',
        sort: true,
      },
      {
        name: 'atendimentoData',
        label: this.wordsTranslated.atendimentoData,
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm'
      },
      {
        name: 'atendente',
        label: this.wordsTranslated.atendente,
        width: '',
        sort: true,
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.atendimentoService
      .getAtendimentos()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }
}
