import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IModulo } from './../models/IModulo';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ModuloService } from '../services/modulo.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-modulo-form',
  templateUrl: './modulo-form.component.html',
  styleUrls: ['./modulo-form.component.scss']
})
export class ModuloFormComponent implements OnInit {

  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IModulo = null;
  @Input() dado: IModulo = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IModulo>();

  private registroClean: IModulo;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  wordsTranslated: any;
  public modalName: string = 'modalModuloForm'

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private moduloService: ModuloService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }
  loadWordsTranslate() {
    const keyRoot = 'paginas.seguranca.modulo.form.';
    const words = {
      editar_registro: '',
      adicionar_registro:'' 
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadingService.show();
    if (this.registro != null) {
      this.loadEditaData();
    } else {
      this.criarFormulario();
    }
    this.loadingService.hide();
  }

  loadEditaData() {
    this.moduloService
      .getForm(this.registro)
      .subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }


  criarFormulario() {
    this.formulario = this.fb.group({
      aplicacaoModuloId:[
        this.registro != null && this.registro.aplicacaoModuloId !== null
          ? this.registro.aplicacaoModuloId
          : null,
      ],
      menuModuloPortugues: [
        this.registro != null && this.registro.menuModuloPortugues
          ? this.registro.menuModuloPortugues
          : '',
        Validators.compose([Validators.required])
      ],
      menuModuloIngles: [
        this.registro != null && this.registro.menuModuloIngles
          ? this.registro.menuModuloIngles
          : ''
      ],
      menuModuloEspanhol: [
        this.registro != null && this.registro.menuModuloEspanhol
          ? this.registro.menuModuloEspanhol
          : ''
      ],
      icone: [
        this.registro != null && this.registro.icone
          ? this.registro.icone
          : ''
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.moduloService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
     } else {
      this.moduloService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
      }
  }

  finalitySave(retorno: IModulo, tipo: string) {
    const change = {
      dados: retorno,
      tipo
    };

    this.onChange.emit(change);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  get f() {
    return this.formulario.controls;
  }

  configuraTitleDialog() {
    if (this.registro){
      this.titleDialog = this.wordsTranslated.editar_registro;
    }else{
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

}
