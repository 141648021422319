import { IUltimoStatusSaw } from './../models/IUltimoStatusSaw';
import { AuthService } from './../../../../shared/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUltimoStatusDados } from '../models/IUltimoStatusDados';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


const telaId = 28;

@Injectable({
  providedIn: 'root'
})
export class UltimoStatusService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }

  apiUrl = `${this.env.apiUrl}UltimoStatus`;

  getGridPrincipal(): Observable<IUltimoStatusSaw[]> {
    return this.http.get<IUltimoStatusSaw[]>(`${this.apiUrl}/GridSawPrincipal`);
  }

  getGridSecundario(sawId): Observable<IUltimoStatusDados[]> {
    return this.http.get<IUltimoStatusDados[]>(
      `${this.apiUrl}/GridStatus/${sawId}`
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
