import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ITipoLivroRegistro } from '../models/ITipoLivroRegistro';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { TipoLivroRegistroService } from '../services/tipo-livro-registro.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tipo-livro-registro-form',
  templateUrl: './tipo-livro-registro-form.component.html',
  styleUrls: ['./tipo-livro-registro-form.component.scss']
})
export class TipoLivroRegistroFormComponent implements OnInit {

  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: ITipoLivroRegistro = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<ITipoLivroRegistro>();

  private registroClean: ITipoLivroRegistro;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  wordsTranslated: any;
  public modalName: string = 'modalTipoLivroRegistroForm'

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private tipoLivroRegistroService: TipoLivroRegistroService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.tipo-livro-registro.form.'
    const words = {
      tipoLivroRegistoId: '',
      tipoLivroRegistoDes: '',
      registro:'',
      editar_registro: '',
      adicionar_registro:''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  listenKeyPress(){
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  ngOnInit() {
    this.loadingService.show();

    if(this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }
    this.loadingService.hide();
  }

  loadEditData() {
    this.tipoLivroRegistroService
      .getForm(this.registro)
      .subscribe((result) => {
        this.registro = result;
        this.criarFormulario();
      });
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      livroRegistroTipoId: [
        this.registro != null && this.registro.livroRegistroTipoId !== null
        ? this.registro.livroRegistroTipoId
        : null
      ],
      livroRegistroTipoDesc: [
        this.registro != null && this.registro.livroRegistroTipoDesc !== null
        ? this.registro.livroRegistroTipoDesc
        : '',
        Validators.compose([Validators.required])
      ]
    });
    

    this.onValueChanges();
    this.configuraAcoesBotoes();
    this.configuraTitleDialog();
  }

  configuraAcoesBotoes() {
    this.statusBotao = {
      adicionar: this.registro ? true: false,
      deletar: this.registro ? true: false,
      cancelar: false,
      salvar: false
    }
  }

  onValueChanges() {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  save() {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if(this.registro) {
      this.tipoLivroRegistroService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.tipoLivroRegistroService
        .insert(valuesForm)
        .pipe(
          finalize (() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno: ITipoLivroRegistro, tipo: string) {
    const change = {
      dados: retorno,
      tipo
    };

    this.onChange.emit(change);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuraAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel() {
    this.formulario.reset();
    this.criarFormulario();
  }

  del() {
    this.onDelete.emit(this.formulario.value);
  }

  close() {
    if(this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose() {
    if(this.autoClose) {
      this.confirmClose();
    }
  }

  get f() {
    return this.formulario.controls;
  }

}
