<div class="modal" modal-name="modalEmpresaForm">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
  >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form
        class="default"
        *ngIf="formulario && focusRazaoSocial"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="empresaId" />

          <mat-form-field class="fieldRazaoSocial" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.razaoSocial" | translate }}
            </mat-label>
            <input
              [focusMe]="focusRazaoSocial"
              matInput
              placeholder="{{ 'paginas.empresa.form.razaoSocial' | translate }}"
              autocomplete="off"
              formControlName="empresaRazaoSocial"
            />
          </mat-form-field>

          <mat-form-field class="fieldNomeFantansia" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.nomeFantasia" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{
                'paginas.empresa.form.nomeFantasia' | translate
              }}"
              autocomplete="off"
              formControlName="empresaNomeFantasia"
            />
          </mat-form-field>

          <mat-form-field class="fieldCNPJ" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.CNPJ" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{ 'paginas.empresa.form.CNPJ' | translate }}"
              autocomplete="off"
              formControlName="empresaCNPJ"
            />
          </mat-form-field>

          <mat-form-field class="fieldCEP" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.CEP" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{ 'paginas.empresa.form.CEP' | translate }}"
              autocomplete="off"
              formControlName="empresaCEP"
            />
          </mat-form-field>

          <mat-form-field class="fieldEndereco" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.endereco" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{ 'paginas.empresa.form.endereco' | translate }}"
              autocomplete="off"
              formControlName="empresaEndereco"
            />
          </mat-form-field>

          <mat-form-field class="fieldBairro" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.bairro" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{ 'paginas.empresa.form.bairro' | translate }}"
              autocomplete="off"
              formControlName="empresaBairro"
            />
          </mat-form-field>

          <mat-form-field class="fieldComplemento" appearance="outline">
            <mat-label>
              {{ "paginas.empresa.form.complemento" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{ 'paginas.empresa.form.complemento' | translate }}"
              autocomplete="off"
              formControlName="empresaComplemento"
            />
          </mat-form-field>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              *ngIf="editForm"
              class="primary cancel"
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>
            <button
              type="submit"
              *ngIf="editForm"
              mat-button
              class="save"
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
