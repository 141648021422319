import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISAW } from '../../models/ISaw';

@Component({
  selector: 'app-controle-volume',
  templateUrl: './controle-volume.component.html',
  styleUrls: ['./controle-volume.component.scss'],
})
export class ControleVolumeComponent implements OnInit {
  @Input() public vertical: boolean = false;
  @Input() public iconeOn: string =  'mic';
  @Input() public iconeOff: string = 'mic_off';
  @Input() public volumeNumber: number = 6;
  @Input() public disabled: boolean = false;
  @Input() public equipamento: ISAW;

  @Output() public volume = new EventEmitter<number>();

  public step: number = 1;
  public max: number = 10;
  public min: number = 0;
  public thumbLabel: boolean = false;
  public showTicks: boolean = true;
  public volumeNumberAux: number = 0;
  public mudo: boolean = false;

  public ngOnInit(): void {
    this.checkMudo();
  }

  public onChangeSlider($event: any): void {
    this.volumeNumber = $event.value;
    this.checkMudo();
  }

  public setMudo(): void {
    if (!this.mudo) {
      this.volumeNumberAux = this.volumeNumber;
      this.volumeNumber = 0;
    } else {
      this.volumeNumber = this.volumeNumberAux;
    }
    this.checkMudo();
  }

  public checkMudo(): void {
    this.mudo = this.volumeNumber == 0 ? true : false;
    this.volume.emit(this.volumeNumber / 10);
  }
}
