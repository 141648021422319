import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { MdePopoverModule } from '@material-extended/mde';
import { ngfModule, ngf } from 'angular-file';

@NgModule({
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    MaterialModule,
    MdePopoverModule,
    ngfModule
  ]
})
export class ComumModule {}
