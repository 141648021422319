<div mat-dialog-title>
  <h2>{{data.title}}</h2>
  <button mat-button class="close" (click)="dialogRef.close()">
    <i class="material-icons">close</i>
  </button>
</div>
<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-button class="primary false" (click)="onClick(false)">{{data.textButtonFalse}}</button>
    <button mat-button class="primary true" (click)="onClick(true)">{{data.textButtonTrue}}</button>
  </div>
</mat-dialog-actions>
