import { Component, OnInit } from '@angular/core';
import { ISAW } from 'src/app/shared/models/ISaw';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DestinosEquipamentoService } from '../../services/destinos-equipamento.service';

@Component({
  selector: 'app-saw-destino-principal-grid',
  templateUrl: './saw-destino-principal-grid.component.html',
  styleUrls: ['./saw-destino-principal-grid.component.scss'],
})
export class SawDestinoPrincipalGridComponent implements OnInit {
  selectedSaw: ISAW = null;
  showGridTelefones: boolean = false;
  wordsTranslated: any;
  titleNoContent: string = '';
  textNoContent: string = '';

  constructor(
    private wordsTranslate: WordsTranslateService,
    private destinoEquipamentoService: DestinosEquipamentoService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.destinos-equipamento.principal.';
    const words = {
      tituloNoContent: '',
      textNoContent: '',
      tituloHasNoDestinos: '',
      textHasNoDestinos: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    this.textNoContent = this.wordsTranslated.textNoContent;
    this.titleNoContent = this.wordsTranslated.tituloNoContent;
  }
  ngOnInit(): void {}

  setSawId(selectedSaw: ISAW) {
    this.destinoEquipamentoService
      .getDestinosGrid(selectedSaw.sawId)
      .subscribe((response) => {
        this.showGridTelefones = response.length > 0 ? true : false;
        this.textNoContent = this.wordsTranslated.textHasNoDestinos;
        this.titleNoContent = this.wordsTranslated.tituloHasNoDestinos;
      });

    this.selectedSaw = selectedSaw;
  }
}
