<div class="modal" modal-name="modalImportacaoConfiguracaoForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag style="width: 30%;">
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
        <div class="modal-content">
          <input type="hidden" formControlName="usuarioADParametroId" />

            <mat-form-field class="fieldNomeUsuario" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.nomeUsuario' | translate}}
              </mat-label>
              <input [focusMe]="focusRazaoSocial" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.nomeUsuario' | translate}}" autocomplete="off"
                formControlName="usuarioADParametroNome" />
            </mat-form-field>

            <mat-form-field class="fieldLinkedServer" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.linkedServer' | translate}}
              </mat-label>
              <input [focusMe]="false" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.linkedServer' | translate}}"
                autocomplete="off" formControlName="usuarioADParametroLinkedServer" />
            </mat-form-field>

            <mat-form-field class="fieldServer" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.server' | translate}}
              </mat-label>
              <input [focusMe]="false" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.server' | translate}}" autocomplete="off"
                formControlName="usuarioADParametroServer" />
            </mat-form-field>

            <mat-form-field class="fieldOu" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.ou' | translate}}
              </mat-label>
              <input [focusMe]="false" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.ou' | translate}}" autocomplete="off"
                formControlName="usuarioADParametroOU" />
            </mat-form-field>

            <mat-form-field class="fieldProtocol" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.protocol' | translate}}
              </mat-label>
              <input [focusMe]="false" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.protocol' | translate}}" autocomplete="off"
                formControlName="usuarioADParametroProtocol" />
            </mat-form-field>

            <mat-form-field class="fieldDomain" appearance="outline">
              <mat-label>
                {{'paginas.importacao-ad.configuracao.form.domain' | translate}}
              </mat-label>
              <input [focusMe]="false" matInput
                placeholder="{{'paginas.importacao-ad.configuracao.form.domain' | translate}}" autocomplete="off"
                formControlName="usuarioADParametroDomain" />
            </mat-form-field>

        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button mat-button type="button" *ngIf="editForm" class="primary cancel" (click)="cancel()"
              [disabled]="!statusBotao.cancelar">
              <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
            </button>
            <button type="submit" *ngIf="editForm" mat-button class="save"
              [disabled]="formulario.invalid || !statusBotao.salvar">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
