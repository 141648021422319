<ws-grid
  *ngIf="dadosGrid"
  [permissoes]="permissoes"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  (rowEvent)="rowEvent($event)"
></ws-grid>

<div class="aside">
  <app-alerta-usuarios-meio-envio-grid
    [permissoes]="permissoes"
    [alertaId]="alertaId"
    *ngIf="alertaId; else empty"
  ></app-alerta-usuarios-meio-envio-grid>
  <ng-template #empty>
    <ws-no-content [title]="wordsTranslated.no_content"></ws-no-content>
  </ng-template>
</div>
<app-alerta-parametros-grid
  [permissoes]="permissoes"
  [alertaId]="alertaId"
  *ngIf="alertaId; else empty"
></app-alerta-parametros-grid>
