import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IHistoricoMensagem } from './../models/IHistorico-mensagem';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 27;

@Injectable({
  providedIn: 'root'
})
export class HistoricoMensagemService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}HistoricoMensagem`;

  getGrid(dataInicio,
          dataFim,
          sawId: any[],
          grupoMensagemId: any[],
  ): Observable<IHistoricoMensagem[]> {
    var datePipe = new DatePipe('en-US');
    dataInicio = datePipe.transform(dataInicio, 'yyyy-MM-dd');
    dataFim = datePipe.transform(dataFim, 'yyyy-MM-dd');

    let params = new HttpParams()
      .set('dataInicio', dataInicio)
      .set('dataFim', dataFim);

      sawId.forEach(sawId => {
        params = params.append('sawId', sawId)
      })

      grupoMensagemId.forEach(grupoMensagemId =>{
        params = params.append('grupoMensagemId',grupoMensagemId)
      })

    return this.http.get<IHistoricoMensagem[]>(`${this.apiUrl}/Grid`, { params });
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
