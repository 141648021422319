<div class="modal" modal-name="modalRodoviaForm" cdkTrapFocus>
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <mat-tab-group
        (selectedTabChange)="onTabClick($event)"
        animationDuration="0ms"
        style="width: 100%"
      >
        <!-- TAB FORM E MAPA -->
        <mat-tab
          label="{{ 'paginas.rodovia.tab.cadastroRodovia' | translate }}"
        >
          <form *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()">
            <div class="modal-content">
              <input type="hidden" formControlName="rodoviaId" />

              <mat-form-field class="fieldNome" appearance="outline">
                <mat-label>
                  {{ "paginas.rodovia.cadastro.form.nome" | translate }}
                </mat-label>
                <input
                  [focusMe]="true"
                  matInput
                  placeholder="{{
                    'paginas.rodovia.cadastro.form.nome' | translate
                  }}"
                  autocomplete="off"
                  formControlName="rodoviaNome"
                />
              </mat-form-field>

              <mat-form-field class="fieldSigla" appearance="outline">
                <mat-label>
                  {{ "paginas.rodovia.cadastro.form.sigla" | translate }}
                </mat-label>
                <input
                  matInput
                  placeholder="{{
                    'paginas.rodovia.cadastro.form.sigla' | translate
                  }}"
                  autocomplete="off"
                  formControlName="rodoviaSigla"
                />
              </mat-form-field>

              <ws-color-picker
                [nameLabel]="nameLabelColorPicker"
                [fieldClass]="nameField"
                [corValue]="corValue"
                [disabled]="false"
                [touchUi]="false"
                (colorPicked)="getColor($event)"
              >
              </ws-color-picker>
              
              <mat-form-field class="fieldKmInicial" appearance="outline">
                <mat-label>
                  {{ "paginas.rodovia.cadastro.form.kmInicial" | translate }}
                </mat-label>
                <input
                  matInput
                  type="number"
                  numeric
                  decimals="3"
                  placeholder="{{
                    'paginas.rodovia.cadastro.form.kmInicial' | translate
                  }}"
                  autocomplete="off"
                  formControlName="rodoviaKmInicio"
                />
              </mat-form-field>

              <mat-form-field class="fieldKmFinal" appearance="outline">
                <mat-label>
                  {{ "paginas.rodovia.cadastro.form.kmFinal" | translate }}
                </mat-label>
                <input
                  matInput
                  type="number"
                  numeric
                  decimals="3"
                  maxlength="7"
                  placeholder="{{
                    'paginas.rodovia.cadastro.form.kmFinal' | translate
                  }}"
                  autocomplete="off"
                  formControlName="rodoviaKmFim"
                />
              </mat-form-field>

              <ws-combo
                class="fieldPrimeiroSentido"
                [textoLabel]="
                  'paginas.rodovia.cadastro.form.primeiroSentido' | translate
                "
                [multiple]="false"
                [selecionarTodos]="false"
                [itemCtrl]="f.sentidoId1"
                [items]="itensComboSentido1"
                [itemSelecionado]="itemSelecionadoComboSentido1"
                (changeEventCombo)="onChangeComboSentido1($event)"
              >
              </ws-combo>

              <ws-combo
                class="fieldSegundoSentido"
                [textoLabel]="
                  'paginas.rodovia.cadastro.form.segundoSentido' | translate
                "
                [multiple]="false"
                [selecionarTodos]="false"
                [itemCtrl]="f.sentidoId2"
                [items]="itensComboSentido2"
                [itemSelecionado]="itemSelecionadoComboSentido2"
                (changeEventCombo)="onChangeComboSentido2($event)"
              >
              </ws-combo>

              <div class="fieldMapa">
                <app-rodovia-mapa
                [rodoviaId]="registro != null ? registro.rodoviaId : null"
                [corHTML]="registro != null ? registro.corHTML : null"
                ></app-rodovia-mapa>
              </div>
            </div>

            <mat-progress-bar
              mode="indeterminate"
              *ngIf="formLoading"
            ></mat-progress-bar>

            <div class="modal-actions">
              <div></div>
              <div class="buttons">
                <button
                  mat-button
                  type="button"
                  class="primary add"
                  *ngIf="permissoes['create']"
                  (click)="add()"
                  [disabled]="!statusBotao.adicionar"
                >
                  <i class="material-icons">add</i>
                  <span class="text">{{ "botoes.adicionar" | translate }}</span>
                </button>
                <button
                  mat-button
                  type="button"
                  class="primary delete"
                  *ngIf="permissoes['delete']"
                  (click)="del()"
                  [disabled]="!statusBotao.deletar"
                >
                  <i class="material-icons">delete</i>
                  <span class="text">{{ "botoes.deletar" | translate }}</span>
                </button>
                <button
                  mat-button
                  type="button"
                  class="primary cancel"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  (click)="cancel()"
                  [disabled]="!statusBotao.cancelar"
                >
                  <i class="material-icons">block</i>
                  <span class="text">{{ "botoes.cancelar" | translate }}</span>
                </button>
                <button
                  type="submit"
                  mat-button
                  class="save"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  [disabled]="formulario.invalid || !statusBotao.salvar"
                >
                  <i class="material-icons">check</i>
                  <span class="text">{{ "botoes.salvar" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </mat-tab>

        <!-- TAB IMPORTACAO -->
        <mat-tab
          [disabled]="!registro"
          label="{{ 'paginas.rodovia.tab.importarKML' | translate }}"
        >
          <app-rodovia-importar-kml-grid
            *ngIf="showImportacao"
            [dadosRodovia]="registro"
          >
          </app-rodovia-importar-kml-grid>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
