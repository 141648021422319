import { IVersaoTotem } from './../../models/IVersaoTotem';
import { VersaoTotemService } from '../../services/versao-totem.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUploadTraducoes } from '@ws/upload/lib/interfaces/ws-upload-traducoes.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { GuidHelper } from 'src/app/shared/helper/guid.helper';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

@Component({
  selector: 'app-versao-totem-upload',
  templateUrl: './versao-totem-upload.component.html'
})
export class VersaoTotemUploadComponent implements OnInit {
  titleDialog: string;
  acceptExtensions: string = '*';
  files: File[] = [];
  isLoading: boolean;
  showUpload: boolean;
  listErrosUpload = [];
  midiaLoad: boolean = false;
  editForm: boolean = false;
  wordsTranslated: any;
  fileToSave: boolean = false;

  palavrasUpload: IUploadTraducoes = {
    limite_de_arquivos: '',
  };

  @Input() dados: IVersaoTotem;
  @Output() closeEvent = new EventEmitter<string>();
  @Output() fileUpload = new EventEmitter<any>();

  constructor(
    private versaoTotemService: VersaoTotemService,
    private alertaService: AlertaService,
    private guidHelper: GuidHelper,
    public loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.listenKeyPress();
  }

  listenKeyPress(): void {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalVersaoTotemUpload') {
        this.close();
      }
    });
  }

  ngOnInit() {
    this.loadingService.show();
    this.showUpload = false;
    this.versaoTotemService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });
    this.loadTitleDialog();
    this.loadFiles();
    this.showUpload = true;
    this.loadingService.hide();
  }

  loadTitleDialog(): void {
    const keyRoot = 'paginas.versaoTotem.upload.';
    const words = {
      arquivo: '',
      tituloErro: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    this.titleDialog = this.wordsTranslated.arquivo;
  }

  uploadFiles(uploadDados): void {
    if (uploadDados.files.length > 0) {
      const file = this.formatFiles(uploadDados);
      this.versaoTotemService.uploadArquivoAtualizacao(file, this.dados.id).subscribe(
        (response: any) => {

          if (response !== null) {
            this.alertaService.erro(this.wordsTranslated.tituloErro, response.message, 3000);
            this.files = [];
            this.editForm = true;
          } else {
            const fileVersao: IVersaoTotem = {
              id: this.dados.id,
              firmware: this.dados.firmware,
              nomeArquivo: uploadDados.files[0].name,
              ativo: this.dados.ativo
            };

            this.fileUpload.emit(fileVersao);
            this.versaoTotemService.saveFile.emit(true);

            this.editForm = false;
            this.alertaService.sucesso();
          }
          this.fileToSave = false;
        },
        (err: any) => {
          this.alertaService.erro(this.wordsTranslated.tituloErro, err.message, 3000);
          this.editForm = true;
        }
      );
    }
  }

  loadFiles(): void {
    this.isLoading = true;
    this.versaoTotemService.getArquivoAtualizacao(this.dados.id).subscribe(
      (result) => {
        this.files = result;
        this.isLoading = false;

        if (this.dados.nomeArquivo.length > 0)
          this.editForm = false;
        else
          this.editForm = true;

      },
      (err) => {
        this.isLoading = false;
        this.alertaService.erro();
        this.close();
      }
    );
  }

  formatFiles(uploadDados): FormData {
    let conta = 0;
    let sendableFormData = new FormData();

    if (uploadDados.files.length > 0) {
      uploadDados.files.forEach((element, index) => {
        let up;
        up = new File([element], element.name, {
          type: element.type,
        });

        sendableFormData.append(`Uploads[${conta}].File`, up);
        sendableFormData.append(
          `Uploads[${conta}].Id`,
          this.guidHelper.newGuid().toString()
        );

        conta++;
      });
    }
    return sendableFormData;
  }

  close(): void {
    if (this.fileToSave) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.closeEvent.next();
        }
      });
    } else {
      this.closeEvent.next();
    }
  }
}
