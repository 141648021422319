import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 22;

@Injectable({
  providedIn: 'root',
})
export class SAWMensagemService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}SAWMensagem`;

  insertMensagemSaw(dados: any){
    return this.http.post(`${this.apiUrl}`, dados);
  }

  deleteMensagemSaw(dados: any) {

    var params = new HttpParams()
    .set('sawId', dados.sawId)
    .set('mensagemId', dados.mensagemId)
    .set('usuarioId', dados.usuarioId);

    return this.http.delete(`${this.apiUrl}`, {params});
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}