import { ITipoAtendimento } from './../models/ITipoAtendimento';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 25;


@Injectable({
  providedIn: 'root'
})
export class TipoAtendimentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }

  apiUrl = `${this.env.apiUrl}TipoAtendimento`;

  getGrid(): Observable<ITipoAtendimento[]> {
    return this.http.get<ITipoAtendimento[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: ITipoAtendimento) {
    return this.http.get<ITipoAtendimento>(`${this.apiUrl}/Form/${dados.tipoAtendimentoId}`);
  }

  insert(dados: ITipoAtendimento) {
    return this.http.post<ITipoAtendimento>(`${this.apiUrl}`, dados);
  }

  update(dados: ITipoAtendimento) {
    return this.http.put<ITipoAtendimento>(`${this.apiUrl}`, dados);
  }

  delete(dados: ITipoAtendimento) {
    return this.http.delete<ITipoAtendimento>(`${this.apiUrl}/${dados.tipoAtendimentoId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
