<div class="title-grid">
  <p>
    {{ wordsTranslated.title }}
    {{ selectedSaw.prefixo }}
  </p>
</div>
<div class="telefonesSearchs">
  <input type="text" class="telefonesSearch" placeholder="{{ wordsTranslated.buscaTelefone }}" [(ngModel)]="query" id="listSearch">
</div>
<div class="drag-drop-grid">
  <div class="bloqueio-header">
    <div class="header-drag-drop">
      <div class="selecionadoToggleP">
        <p>
          {{ wordsTranslated.selecionado }}
        </p>
      </div>
      <div class="nomePortuguesP">
        <p>
          {{ wordsTranslated.nomePortugues }}
        </p>
      </div>
      <div class="ordenacaoTotemP">
        <p>
          {{ wordsTranslated.ordenacaoTotem }}
        </p>
      </div>
      <div class="selecionadoAtivoP">
        <p>
          {{ wordsTranslated.ativo }}
        </p>
      </div>
    </div>
  </div>
  <div cdkDropList class="list-telefones" (cdkDropListDropped)="drop($event)">
    
    <div class="espacoHeader">
      <div
        class="list-telefones-dados"
        *ngFor="let dado of dadosGrid | searchFilter:query"
        cdkDragLockAxis="y"
        cdkDrag
        [cdkDragDisabled]="!dado.selecionado || !this.permissoesTela.update"
      >
        <div class="drop-placeholder" *cdkDragPlaceholder></div>
        <div class="selecionadoToggle">
          <p>
            <mat-slide-toggle
              [disabled]="!this.permissoesTela.update"
              [checked]="dado.selecionado"
              (toggleChange)="changeToogle(dado)"
            ></mat-slide-toggle>
          </p>
        </div>
        <div class="nomePortugues">
          <p>
            {{ dado.nomePortugues }}
          </p>
        </div>
        <div class="ordenacaoTotem">
          <p>{{ dado.ordenacaoTotem }}</p>
        </div>
        <div class="selecionadoAtivo">
          <p>
            <mat-slide-toggle
              [disabled]="!dado.selecionado || !this.permissoesTela.update"
              [checked]="dado.ativo"
              (toggleChange)="changeToogleAtivo(dado)"
            ></mat-slide-toggle>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
