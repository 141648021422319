<div class="page-content page-content-row-2">
  <div class="box-filter" *ngIf="showFilter">
    <form
      id="frm-filter"
      class="default"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >

    <div class="container-combo-saw">
      <ws-combo
        [textoLabel]="'paginas.historico-mensagem.filtro.saw' | translate"
        [multiple]="true"
        [selecionarTodos]="true"
        [itemCtrl]="f.sawId"
        [items]="itensComboSaw"
        [itemSelecionado]="itemSelecionadoComboSaw"
        (changeEventCombo)="changeComboSaw($event)"
      >
      </ws-combo>
    </div>
    
      <div class="container-dataDe">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.historico-mensagem.filtro.dataInicio" | translate
          }}</mat-label>
          <input
            matInput
            [focusMe]="true"
            [matDatepicker]="pickerDe"
            formControlName="dataInicio"
            [max]="formValidParam.maxDateDe"
            (dateChange)="changeDateDe($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDe"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDe
            [startAt]="formValidParam.startDateDe"
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-dataAte">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.historico-mensagem.filtro.dataFim" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerAte"
            formControlName="dataFim"
            [min]="formValidParam.minDateAte"
            [max]="formValidParam.maxDateAte"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerAte"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerAte
            [startAt]="formValidParam.startDateAte"
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-combo-grupo-mensagem">
        <ws-combo
          [focusMe]="false"
          [textoLabel]="
            'paginas.historico-mensagem.filtro.grupoMensagem' | translate
          "
          [multiple]="true"
          [selecionarTodos]="true"
          [itemCtrl]="f.grupoMensagemId"
          [items]="itensComboGrupoMensagem"
          [itemSelecionado]="itemSelecionadoComboGrupoMensagem"
          (changeEventCombo)="changeComboGrupoMensagem($event)"
        >
        </ws-combo>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>
    </form>
  </div>

  <ws-grid
    *ngIf="!isLoading"
    [permissoes]="permissoes"
    [isLoading]="isLoading"
    [acoesGrid]="acoesGrid"
    [focus]="focusFirstLine"
    [colunasGrid]="colunasGrid"
    [dadosGrid]="dadosGrid"
    [disableRefresh]="filterForm.invalid"
    (refreshEvent)="refresh()"
  >
  </ws-grid>
</div>
