import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';

import { IDialogButton } from 'src/app/shared/models/IDialogButton';

import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IImportacaoAd } from '../../models/IImportacaoAd';
import { ImportacaoAdService } from '../../services/importacao-ad.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { GrupoUsuarioCombo } from 'src/app/shared/services/combo/grupo-usuario.combo';
import { forkJoin } from 'rxjs';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';

@Component({
  selector: 'app-importacao-ad-usuarios-form',
  templateUrl: './importacao-ad-usuarios-form.component.html'
})
export class ImportacaoAdUsuariosFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IImportacaoAd = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() saved = new EventEmitter<any>(true);

  statusBotao: IDialogButton;
  registroClean: IImportacaoAd;

  focusRazaoSocial = false;
  exibirContatoGrid = false;
  valueCombo: Array<any>;
  exibirFormulario: false;
  autoClose = true;
  formLoading = false;
  tipoAcao = null;
  modalName = 'modalImportacaoAdUsuarioForm';
  itensComboGrupoUsuario: ICombo[];
  itemSelecionadoComboGrupoUsuario: any;

  itensComboColors: ICombo[];
  itemSelecionadoComboColors: any;

  wordsTranslated: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private importacaoAdService: ImportacaoAdService,
    private dialogService: DialogService,
    private grupoUsuarioCombo: GrupoUsuarioCombo,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.importacao-ad.usuarios.form.';
    let words = {
      selecione_o_grupo: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    const observableComboGrupoUsuario = this.grupoUsuarioCombo.listComboSelect();

    forkJoin([observableComboGrupoUsuario]).subscribe((results) => {
      this.itensComboGrupoUsuario = results[0];

      this.criarFormulario();
    });
  }

  save(): void {
    this.formLoading = true;
    const { usuarios, grupoUsuarioId } = this.formulario.value;

    this.importacaoAdService
      .insertUsuariosAd(usuarios, grupoUsuarioId)
      .pipe(
        finalize(() => {
          this.formLoading = false;
          this.saved.next();
        })
      )
      .subscribe((response) => {
        this.alertaService.sucesso();
        this.checkAutoClose();
      });
  }

  close(): void {
    this.closeModalService.setStateModal(this.modalName)
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.wordsTranslated.selecione_o_grupo;
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      usuarios: [this.registro, Validators.compose([Validators.required])],
      grupoUsuarioId: [null, Validators.compose([Validators.required])]
    });

    this.configuraTitleDialog();
    this.loadingService.hide();

    if (!this.editForm) {
      this.formulario.disable();
    } else {
      this.focusRazaoSocial = true;
    }
  }

  changeComboGrupoUsuario(event) {
    this.formulario.patchValue({
      grupoUsuarioId: event
    });
  }
}
