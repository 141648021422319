import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IMapaOperacionalEquipamentoInfo } from '../models/IMapaOperacionalEquipamentoInfo';
import { IMapaOperacionalEquipamentoMarker } from '../models/IMapaOperacionalEquipamentoMarker';
import { IMapaOperacionalRodovia } from '../models/IMapaOperacionalRodovia';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 21;

@Injectable({
  providedIn: 'root',
})
export class MapaOperacionalService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}MapaOperacional`;

  public focusMap = new EventEmitter<any>(false);
  
  getRodovias(): Observable<IMapaOperacionalRodovia[]> {
    return this.http.get<IMapaOperacionalRodovia[]>(`${this.apiUrl}/Rodovias`, );
  } 
   
  getEquipamentos(): Observable<IMapaOperacionalEquipamentoMarker[]> {
    return this.http.get<IMapaOperacionalEquipamentoMarker[]>(`${this.apiUrl}/Equipamentos`);
  }  
  
  getEquipamentoInfo(equipamentoId: number): Observable<IMapaOperacionalEquipamentoInfo> {
    return this.http.get<IMapaOperacionalEquipamentoInfo>(`${this.apiUrl}/EquipamentoInfo/${equipamentoId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

}
