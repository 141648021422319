<ws-grid
*ngIf="!isLoading"
[permissoes]="permissoes"
[isLoading]="isLoading"
[acoesGrid]="acoesGrid"
[colunasGrid]="colunasGrid"
[dadosGrid]="dadosGrid"
(refreshEvent)="refresh()"
(editEvent)="openForm($event)"
(addEvent)="openForm()"
(deleteEvent)="confirmDel($event)">
</ws-grid>


<app-grupo-mensagem-form
  *ngIf="showForm"
  [permissoes]="permissoes"
  [registro]="dadosFormulario"
  (onChange)="refreshGridData($event.dados, $event.tipo)"
  (onDelete)="confirmDel($event)"
  (closeEvent)="closeForm()">
</app-grupo-mensagem-form>
