import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IComboSelect } from 'src/app/shared/components/combo-select/combo-select.interface';
import { DownloadHelper } from 'src/app/shared/helper/download.helper';
import { IUser } from 'src/app/shared/models/IUser';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
import { ILivroRegistro } from '../../models/ILivroRegistro';
import { ILivroRegistroFilterFormDTO } from '../../models/ILivroRegistroFilterFormDTO';
import { LivroRegistroService } from '../../services/livro-registro.service';
import { SawComboService } from './../../services/sat-combo.service';

type ILivroRegistroFilterForm = {
  [K in keyof ILivroRegistroFilterFormDTO]: FormControl<ILivroRegistroFilterFormDTO[K]>;
}

@Component({
  selector: 'app-livro-registro-grid',
  templateUrl: './livro-registro-grid.component.html',
  styleUrls: ['./livro-registro-grid.component.scss'],
})
export class LivroRegistroGridComponent implements OnInit {
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ILivroRegistro[] = [];
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public dadosLivroRegistro: ILivroRegistro;
  public focusFirstLine: boolean = false;
  public editForm = true;
  public showForm = false;
  public showFormEnvios = false;
  public showUpload = false;

  public isLoading: boolean;

  public permissoesTela: IGridPermissoes;
  public readForm = true;
  public filterForm: FormGroup<ILivroRegistroFilterForm>;

  public itensComboSaw: IComboSelect[];
  public itemSelecionadoComboSaw: any;

  public dadosUsuario: IUser;
  public desabilitarExport: boolean = true;

  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  constructor(
    private loadingService: LoadingService,
    private livroRegistroService: LivroRegistroService,
    private sawComboService: SawComboService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public gridService: WsGridService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private downloadHelper: DownloadHelper,
  ) {}

  ngOnInit() {
    this.createFilterForm();

    this.isLoading = true;
    this.livroRegistroService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.isLoading = true;
        const observableComboSat = this.sawComboService.listComboSelect();

        forkJoin([observableComboSat]).subscribe((results) => {
          this.itensComboSaw = results[0];
          this.itemSelecionadoComboSaw = this.itensComboSaw;
        });
      });
  }

  filter(): void {
    const { sawId, dataDe, dataAte } = this.filterForm.value;
    this.loadingService.show();

    this.livroRegistroService
      .getGrid(sawId, dataDe, dataAte)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe((response) => {
        response.length === 0 ? this.desabilitarExport = true : this.desabilitarExport = false;

        this.dadosGrid = response;
        this.setFocusLineTrue();
      });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      sawId: [null, Validators.compose([Validators.required])],
      dataDe: [
        this.formValidParam.maxDateAte,
        Validators.compose([Validators.required]),
      ],
      dataAte: [
        this.formValidParam.maxDateAte,
        Validators.compose([Validators.required]),
      ],
    });
  }

  changeDateDe($event: any) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 90);
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataAte: maxDateAte,
    });
  }

  changeDateAte($event: any) {
    this.formValidParam.startDateAte = new Date($event.value);
    this.filterForm.patchValue({
      dataAte: new Date($event.value),
    });
  }

  changeComboSat(event) {
    this.filterForm.patchValue({
      sawId: event,
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  refresh() {
    if (this.filterForm.valid) {
      this.configuraDadosGrid();
    }

    this.closeForm();
  }

  refreshEnvios() {
    if (this.filterForm.valid) {
      this.configuraDadosGrid();
    }

    this.closeForm();
  }

  refreshGridData(dados, tipo) {
    if (tipo === undefined) tipo = 'edit';

    let indexPage = -1;
    if (tipo !== 'add') {
      if (dados.ocorrenciaDados) {
        indexPage = _.findLastIndex(this.dadosGrid, {
          ocorrenciaId: dados.ocorrenciaDados.ocorrenciaId,
        });
      } else if (typeof dados === 'number') {
        indexPage = _.findLastIndex(this.dadosGrid, {
          ocorrenciaId: dados,
        });
      } else {
        indexPage = _.findLastIndex(this.dadosGrid, {
          ocorrenciaId: dados.ocorrenciaId,
        });
      }
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.livroRegistroService.getByIdGrid(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  configuraColunasGrid(): void {
    const keyRoot = 'paginas.livroRegistro.grid.';
    const words = {
      livro_id: '',
      livro_tipo_desc: '',
      livro_data_hora: '',
      prefixo: '',
      rodovia_sigla: '',
      km_local: '',
      livro_nome: '',
      livro_email: '',
      livro_endereco: '',
      livro_complemento: '',
      livro_bairro: '',
      livro_cidade: '',
      livro_estado: '',
      livro_telefone: '',
      livro_observacao: '',
      veiculo_modelo: '',
      veiculo_cor: '',
      veiculo_marca: '',
      veiculo_placa: '',
      veiculo_ano: '',
      motivo_viagem: '',
      descricao_manifestacao: '',
      data_hora_ocorrencia: '',
      km_ocorrencia: '',
      cidade_ocorrencia: '',
      uf_origem: '',
      cidade_origem: '',
      uf_destino: '',
      cidade_destino: '',
      data_hora_gravacao: '',
      fila_envio_id:''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    this.colunasGrid = [
      {
        name: 'livroId',
        label: wordsTranslate.livro_id,
        width: '100px',
        sort: true,
      },
      {
        name: 'livroRegistroTipoDesc',
        label: wordsTranslate.livro_tipo_desc,
        width: '150px',
        sort: true,
      },
      {
        name: 'dataHoraCriacao',
        label: wordsTranslate.livro_data_hora,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
      {
        name: 'prefixo',
        label: wordsTranslate.prefixo,
        width: '200px',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: wordsTranslate.rodovia_sigla,
        width: '200px',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: wordsTranslate.km_local,
        width: '100px',
        sort: true,
      },
      {
        name: 'livroNome',
        label: wordsTranslate.livro_nome,
        width: '300px',
        sort: true,
      },
      {
        name: 'livroEmail',
        label: wordsTranslate.livro_email,
        width: '',
        sort: true,
      },
      {
        name: 'livroEndereco',
        label: wordsTranslate.livro_endereco,
        width: '',
        sort: true,
      },
      {
        name: 'livroCidade',
        label: wordsTranslate.livro_cidade,
        width: '',
        sort: true,
      },
      {
        name: 'livroEstado',
        label: wordsTranslate.livro_estado,
        width: '',
        sort: true,
      },
      {
        name: 'livroTelefone',
        label: wordsTranslate.livro_telefone,
        width: '',
        sort: true,
      },
      {
        name: 'veiculoModelo',
        label: wordsTranslate.veiculo_modelo,
        width: '',
        sort: true,
      },
      {
        name: 'veiculoMarca',
        label: wordsTranslate.veiculo_marca,
        width: '',
        sort: true,
      },
      {
        name: 'veiculoPlaca',
        label: wordsTranslate.veiculo_placa,
        width: '',
        sort: true,
      },
      {
        name: 'veiculoCor',
        label: wordsTranslate.veiculo_cor,
        width: '',
        sort: true,
      },
      {
        name: 'veiculoAno',
        label: wordsTranslate.veiculo_ano,
        width: '',
        sort: true,
      },
      {
        name: 'motivoViagem',
        label: wordsTranslate.motivo_viagem,
        width: '',
        sort: true,
      },
      {
        name: 'descricaoManifestacao',
        label: wordsTranslate.descricao_manifestacao,
        width: '',
        sort: true,
      },
      {
        name: 'dataHoraOcorrencia',
        label: wordsTranslate.data_hora_ocorrencia,
        width: '',
        sort: true,
      },
      {
        name: 'kmOcorrencia',
        label: wordsTranslate.km_ocorrencia,
        width: '',
        sort: true,
      },
      {
        name: 'cidadeOcorrencia',
        label: wordsTranslate.cidade_ocorrencia,
        width: '',
        sort: true,
      },
      {
        name: 'ufOrigem',
        label: wordsTranslate.uf_origem,
        width: '',
        sort: true,
      },
      {
        name: 'cidadeOrigem',
        label: wordsTranslate.cidade_origem,
        width: '',
        sort: true,
      },
      {
        name: 'ufDestino',
        label: wordsTranslate.uf_destino,
        width: '',
        sort: true,
      },
      {
        name: 'cidadeDestino',
        label: wordsTranslate.cidade_destino,
        width: '',
        sort: true,
      },
      {
        name: 'dataHoraGravacao',
        label: wordsTranslate.data_hora_gravacao,
        width: '',
        sort: true,
      },
      {
        name: 'filaEnvioId',
        label: wordsTranslate.fila_envio_id,
        width: '100px',
        sort: true,
      },


    ];
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      custom: [
        {
          icon: 'visibility',
          action: 'visualizar',
          tooltip: 'Visualizar',
        },
        {
          icon: 'search',
          action: 'enviados',
          tooltip: 'Visualizar Fila',
          compare: true,
          propertyToCompare: 'filaEnvioId',
          propertyToCompareDifferent: 'filaEnvioId',
          valueToCompareDifferent: null
        },
      ],
    };
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    const { sawId, dataDe, dataAte } = this.filterForm.value;
    this.livroRegistroService
      .getGrid(sawId, dataDe, dataAte)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.setFocusLineTrue();
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  openForm(dados: ILivroRegistro = null, editForm = true) {
    this.dadosLivroRegistro = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  openFormEnvios(dados: ILivroRegistro = null, editForm = false) {
    this.showFormEnvios = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeFormEnvios() {
    this.showFormEnvios = false;
  }

  customEventGrid($event) {
    this.dadosLivroRegistro = $event.row;
    if ($event.action === 'visualizar') {
      this.openForm(this.dadosLivroRegistro);
    } else {
      if ($event.action === 'enviados') {
        this.openFormEnvios(this.dadosLivroRegistro);
      }
    }
  }

  confirmDisable(dados) {
    this.livroRegistroService
      .delete(dados)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.refreshGridData(dados, 'disable');
        this.alertaService.sucesso();
        this.dialog.closeAll();
      });
  }

  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 200);
  }

  setFocusLineTrue() {
    setTimeout(() => {
      this.focusFirstLine = true;
    }, 200);
  }

  public export(): void {
    let timeOut = 1500;
    let valuesFilter = this.filterForm.value as ILivroRegistroFilterFormDTO;

    this.loadingService.show();

    this.livroRegistroService
      .export(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        if (response) {
          this.downloadHelper.downloadMidia(response);
        } else {
          const keyRoot = 'alertas.nenhumDadoParaExportar.';
          const words = {
            mensagem: '',
            titulo: '',
          };
          let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

          this.alertaService.Warning(
            wordsTranslate.titulo,
            wordsTranslate.mensagem,
            timeOut
          );
        }
      });
  }
}
