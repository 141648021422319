import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { IVersaoTotem } from '../../models/IVersaoTotem';
import { VersaoTotemService } from '../../services/versao-totem.service';
import { IEquipamentosVersaoTotem } from '../../models/IEquipamentosVersaoTotem';

@Component({
  selector: 'app-equipamentos-versao-totem-grid',
  templateUrl: './equipamentos-versao-totem-grid.component.html',
  styleUrls: ['./equipamentos-versao-totem-grid.component.scss'],
})
export class EquipamentosVersaoTotemGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = true;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IEquipamentosVersaoTotem[];
  public dadosVersaoTotem: IVersaoTotem;
  @Input() paramsVersaoTotem: EventEmitter<any>;
  @Input() hasArquivoInVersion: EventEmitter<boolean>;

  public versaoSelecionada;
  public editForm = true;
  public showForm = false;
  public showGrid = false;
  public showUpload = false;
  public toggleAtivo = true;

  public titleNoContent = 'Nenhum equipamento encontrado.';
  public textNoContent = 'Selecione a Versão na listagem ao lado.';

  @Output() equipamentosSelecionados = new EventEmitter<any>();

  auxEquipamentosSelecionados: number[] = [];

  wordsTranslated: any;

  constructor(
    public versaoTotemService: VersaoTotemService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog
  ) {
    this.loadWordsTranslate();
    this.changesUpdate();
    this.onChangeFormVersao();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.equipamentosVersaoTotem.grid.';
    let words = {
      prefixo: '',
      firmwareAtual: '',
      firmwareDataAtual: '',
      firmwareDataSolicitacao: '',
      tituloSemArquivo: '',
      mensagemSemArquivo: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.paramsVersaoTotem.currentValue) {
      this.dadosVersaoTotem = changes.paramsVersaoTotem.currentValue;
      this.versaoSelecionada = this.dadosVersaoTotem.id;
      this.loadData();
    }
  }

  ngOnInit() {
  }

  loadData() {
    this.auxEquipamentosSelecionados = [];
    this.equipamentosSelecionados.emit(this.auxEquipamentosSelecionados);

    if (this.dadosVersaoTotem) {
      this.versaoTotemService
        .getPermissoes()
        .pipe(
          finalize(() => {
            this.loadingService.hide();
            this.isLoading = false;
          })
        )
        .subscribe((response) => {
          this.permissoesTela = response[0];
          this.editForm = this.permissoesTela.update;
          this.toggleAtivo = this.dadosVersaoTotem.ativo;
          this.configuraGrid();
        });
    }
  }

  configuraGrid() {
    this.showGrid = false;
    this.configuraAcoesGrid();
    this.configuraColunasGrid();
    this.configuraDadosGrid();
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      search: true,
      refresh: true
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [];

    if (this.toggleAtivo) {
      this.colunasGrid.push({
        name: "toggleState",
        label: "",
        width: "50px",
        sort: false,
        type: "toogle",
        toogleAction: "selecionarEquipamento",
        toogleHide: true
      });
    }

    this.colunasGrid.push({
      name: 'prefixo',
      label: this.wordsTranslated.prefixo,
      width: '100px',
      sort: true,
    },
      {
        name: 'firmwareAtual',
        label: this.wordsTranslated.firmwareAtual,
        width: '150px',
        sort: true,
      },
      {
        name: 'firmwareDataAtual',
        label: this.wordsTranslated.firmwareDataAtual,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
        width: '200px',
        sort: true,
      },
      {
        name: 'firmwareDataSolicitacao',
        label: this.wordsTranslated.firmwareDataSolicitacao,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
        width: '200px',
        sort: true,
      });
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.versaoTotemService
      .getEquipamentosGrid(this.versaoSelecionada)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          response.forEach(element => {
            element.toggleState = element.atualizado;
          });
          this.dadosGrid = response;
          this.showGrid = true;
          this.isLoading = false;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IVersaoTotem = null, editForm = true) {
    this.dadosVersaoTotem = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  changesUpdate() {
    this.versaoTotemService.saveUpdate.subscribe(() => {
      this.toggleAtivo = true;
      this.auxEquipamentosSelecionados = [];
      this.refresh();
    })
  }

  onChangeFormVersao() {
    this.versaoTotemService.onChangeFormVersao.subscribe(change => {
      if (change.tipo === 'edit')
        this.toggleAtivo = false;
      else
        this.toggleAtivo = true;

      this.versaoSelecionada = change.dados.id;

      this.configuraGrid();
    })
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosVersaoTotem = $event;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        id: dados.id,
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.versaoTotemService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  checkEvent($event) {
    this.equipamentosSelecionados.emit($event);
  }

  toogleEvent($event) {
    if ($event.action === 'selecionarEquipamento') {
      if ($event.status) {
        this.auxEquipamentosSelecionados.push($event.row.sawId);
      }
      if (!$event.status) {
        var index = this.auxEquipamentosSelecionados.indexOf($event.row.sawId);
        if (index !== -1) {
          this.auxEquipamentosSelecionados.splice(index, 1);
        }
      }
      this.equipamentosSelecionados.emit(this.auxEquipamentosSelecionados);
    }

    if (!this.hasArquivoInVersion)
      this.alertaService.Warning(this.wordsTranslated.tituloSemArquivo, this.wordsTranslated.mensagemSemArquivo, 3000);
  }

}


