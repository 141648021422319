import { IHistoricoMensagem } from './../models/IHistorico-mensagem';
import { Component, OnInit } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { environment } from 'src/environments/environment';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { HistoricoMensagemService } from '../services/historico-mensagem.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { WsGridService } from '@ws/grid';
import { MatDialog } from '@angular/material/dialog';
import { GrupoMensagemCombo } from 'src/app/shared/services/combo/grupo-mensagem.combo';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';

@Component({
  selector: 'app-historico-mensagem-grid',
  templateUrl: './historico-mensagem-grid.component.html',
  styleUrls: ['./historico-mensagem-grid.component.scss'],
})
export class HistoricoMensagemGridComponent implements OnInit {
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IHistoricoMensagem[] = [];
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public dadosHistorico: IHistoricoMensagem;
  public focusFirstLine: boolean = false;
  public hasReadPermission = true;
  public showFilter = false;
  public isLoading: boolean;
  public filterForm: UntypedFormGroup;
  public permissoes: IGridPermissoes;

  public itensComboSaw: ICombo[];
  public itemSelecionadoComboSaw: any;

  public itensComboGrupoMensagem: ICombo[];
  public itemSelecionadoComboGrupoMensagem: any;

  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  constructor(
    private loadingService: LoadingService,
    private historicoMensagemService: HistoricoMensagemService,
    private sawService: SAWCombo,
    private grupoMensagemService: GrupoMensagemCombo,
    private wordsTranslate: WordsTranslateService,
    public gridService: WsGridService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.historicoMensagemService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.hasReadPermission = this.permissoes.read;

        this.dadosGrid = [];
        this.itensComboSaw = [];
        this.itensComboGrupoMensagem = [];

        const observableComboSaw = this.sawService.listComboSelect();
        const observableComboGrupoMensagem =
          this.grupoMensagemService.listComboSelect();

        forkJoin([observableComboSaw, observableComboGrupoMensagem]).subscribe(
          (results) => {
            this.itensComboSaw = results[0];
            this.itemSelecionadoComboSaw = this.itensComboSaw;

            this.itensComboGrupoMensagem = results[1];
            this.itemSelecionadoComboGrupoMensagem = this.itensComboGrupoMensagem;

            this.createFilterForm();
            this.configuraAcoesGrid();
            this.configuraColunasGrid();
            this.isLoading = false;
          }
        );
      });
  }

  filter(): void {
    let { dataInicio, dataFim, sawId, grupoMensagemId } = this.filterForm.value;

    this.dadosGrid = [];

    this.loadingService.show();

    this.historicoMensagemService
      .getGrid(dataInicio, dataFim, sawId, grupoMensagemId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
          this.setFocusLineFalse();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
        this.checkExclusion();
        this.setFocusLineTrue();
      });
  }

  checkExclusion(){
    this.dadosGrid.forEach(mensagem => {
      mensagem.usuarioNome != null ? mensagem.excluido = "SIM" : mensagem.excluido = "NÃO" 
    });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      dataInicio: [
        this.formValidParam.maxDateDe,
        Validators.compose([Validators.required]),
      ],
      dataFim: [
        this.formValidParam.maxDateAte,
        Validators.compose([Validators.required]),
      ],
      sawId: [null, Validators.compose([Validators.required])],
      grupoMensagemId: [null, Validators.compose([Validators.required])],
    });
    this.showFilter = true;
  }

  changeDateDe($event: any) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 90);
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataFim: maxDateAte,
    });
  }

  changeComboSaw($event) {
    this.filterForm.patchValue({
      sawId: $event,
    });
  }

  changeComboGrupoMensagem($event) {
    this.filterForm.patchValue({
      grupoMensagemId: $event,
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  refresh() {
    if (!this.filterForm.invalid) {
      this.filter();
    }
  }

  configuraColunasGrid(): void {
    const keyRoot = 'paginas.historico-mensagem.grid.';
    const words = {
      mensagemId: '',
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      textoPortugues: '',
      dataHoraInicio: '',
      dataHoraFim: '',
      excluido: '',
      autorExclusao: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    this.colunasGrid = [
      {
        name: 'mensagemId',
        label: wordsTranslate.mensagemId,
        width: '60px',
        sort: true,
      },
      {
        name: 'prefixo',
        label: wordsTranslate.prefixo,
        width: '60px',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: wordsTranslate.rodoviaSigla,
        width: '100px',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: wordsTranslate.kmLocal,
        width: '100px',
        sort: true,
      },
      {
        name: 'dataHoraInicio',
        label: wordsTranslate.dataHoraInicio,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm:ss',
      },
      {
        name: 'dataHoraFim',
        label: wordsTranslate.dataHoraFim,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm:ss',
      },
      {
        name: 'excluido',
        label: wordsTranslate.excluido,
        width: '80px',
        sort: false
      },
      {
        name: 'usuarioNome',
        label: wordsTranslate.autorExclusao,
        width: '170px',
        sort: false
      },
      {
        name: 'textoPortugues',
        label: wordsTranslate.textoPortugues,
        width: '',
        sort: true,
      },
    ];
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
    };
  }

  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 200);
  }

  setFocusLineTrue() {
    setTimeout(() => {
      this.focusFirstLine = true;
    }, 200);
  }
}
