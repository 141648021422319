import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { IMidia } from 'src/app/shared/models/IMidia';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IGravacoesVideos } from '../models/IGravacoesVideos';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 20;

@Injectable({
  providedIn: 'root'
})
export class GravacoesVideosService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';
  apiUrl = `${this.env.apiUrl}GravacoesVideos`;

  getGrid(dataDe, dataAte,sawId: any[]): Observable<IGravacoesVideos[]> {
    var datePipe = new DatePipe('en-US');
    dataDe = datePipe.transform(dataDe, 'yyyy-MM-dd HH:mm:ss');
    dataAte = datePipe.transform(dataAte, 'yyyy-MM-dd HH:mm:ss');

    let params = new HttpParams()
    .set('dataDe', dataDe)
    .set('dataAte', dataAte);

    sawId.forEach(sawId => {
      params = params.append('sawId', sawId)
    })

    return this.http.get<IGravacoesVideos[]>(`${this.apiUrl}/Grid`, { params });
  }
  
  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

}
