<div class="subTabs">
  <mat-tab-group animationDuration="0ms" style="width: 100%;" (selectedTabChange)="onTabClick($event)">
    <mat-tab label="{{ 'paginas.permissao.tab.titulo.usuario' | translate }}">

      <div class="grid" *ngIf="showUsuario">
        <div class="opcoes">
          <app-permissao-usuario-grid (dadosUsuario)="onChangeDadosUsuario($event)">
          </app-permissao-usuario-grid>
        </div>
        <div class="permissoes">
          <app-permissao-usuario-modulos [permissoes]="permissoesTela" [dadosUsuario]="dadosUsuario">
          </app-permissao-usuario-modulos>
        </div>
      </div>

    </mat-tab>

    <mat-tab label="{{ 'paginas.permissao.tab.titulo.grupo' | translate }}">

      <div class="grid" *ngIf="showGrupo">
        <div class="opcoes">
          <app-permissao-grupo-grid (dadosGrupo)="onChangeDadosGrupo($event)">
          </app-permissao-grupo-grid>
        </div>
        <div class="permissoes">
          <app-permissao-grupo-modulos [permissoes]="permissoesTela" [dadosGrupo]="dadosGrupo">
          </app-permissao-grupo-modulos>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>