<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [justOneLine]="hasAnEmail"
  (addEvent)="openForm()"
  (refreshEvent)="refresh()"
  (editEvent)="openForm($event)"
  (deleteEvent)="deleteConta($event)"
></ws-grid>

<app-conta-email-form
  *ngIf="showForm"
  [permissoes]="permissoesTela"
  [registro]="dadosFormulario"
  (onChange)="refreshGridData($event.dados, $event.tipo)"
  (closeEvent)="closeContaEmailForm()"
>
</app-conta-email-form>
