import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { AtendimentoService } from '../../../services/atendimento.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { NominatimService } from '../../../../../../shared/services/nomitatim/nominatim.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet-routing-machine';

@Component({
  selector: 'app-atendimento-mapa',
  templateUrl: './atendimento-mapa.component.html',
  styleUrls: ['./atendimento-mapa.component.scss'],
})
export class AtendimentoMapaComponent implements OnInit {
  @ViewChild('mapRef', { static: true }) mapElement: ElementRef;
  @ViewChild('search', { static: false })
  searchElementRef!: ElementRef;
  @ViewChild('latitudeInput', { static: true }) latitudeInput: ElementRef;
  @ViewChild('longitudeInput', { static: true }) longitudeInput: ElementRef;

  @Input() coordenadasDestino: any;
  @Input() coordenadasEquipamento: any;
  @Input() atendimentoId: number;

  @Output() mapReady = new EventEmitter<boolean>(true);
  @Output() eventDragMap = new EventEmitter<any>();

  private markers = [];
  private mapObj;
  private searchSubject = new Subject<string>();
  wordsTranslated: any;
  placesSearchs: any[] = [];
  place: any;
  alertaTitulo: string;
  alertaMensagem: string;
  hasDestinoToSend: boolean = false;
  osmServerUrl: string = "";

  constructor(
    private atendimentoService: AtendimentoService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService,
    private env: EnvironmentService,
    private nominatimService: NominatimService
  ) {
    this.osmServerUrl = env.osmServerUrl;
    this.listenChangesDestino();
    this.listenLoadMap();
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.principal.destinos.grid.';
    const words = {
      alertaTitulo: '',
      alertaMensagem: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    this.alertaTitulo = this.wordsTranslated.alertaTitulo;
    this.alertaMensagem = this.wordsTranslated.alertaMensagem;
  }

  ngOnInit() {
    this.createMap();
    this.subscribeToSearch();
  }
  
  subscribeToSearch() {
    this.searchSubject.pipe(debounceTime(400)).subscribe(() => {
      this.changeSearch();
    });
  }

  onInput(event: any) {
    this.searchSubject.next(event.target.value);
  }

  listenLoadMap() {
    this.atendimentoService.loadMap.subscribe((retorno) => {
      if (retorno.latitude && retorno.longitude) {
        this.coordenadasEquipamento = retorno;
        this.createMap();
      }
    });
  }

  listenChangesDestino() {
    this.atendimentoService.loadDestino.subscribe((state) => {
      if (state) {
        this.createMap();
      }
    });
  }

  createMap() {
    if (this.mapObj) {
      this.mapObj.remove(); // Remove the existing map
    }
    if (
      this.coordenadasEquipamento.latitude &&
      this.coordenadasEquipamento.longitude
    ) {
      this.mapObj = L.map(this.mapElement.nativeElement).setView(
        [
          this.coordenadasEquipamento.latitude,
          this.coordenadasEquipamento.longitude,
        ],
        10
      );

      L.tileLayer(this.osmServerUrl, {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.mapObj);

      this.addMarkerToMap();
      this.calculateAndDisplayRoute();
    }
  }

  addMarker = (location: L.LatLngExpression, isEquip: boolean) => {
    const iconUrl = 'assets/images/logo_mapa.png'
    var marker: any;
    var circle: any;

    const icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [45, 57],
      iconAnchor: [22, 57],
      popupAnchor: [0, -57],
    });
    
    if (isEquip) {
      marker = L.marker(location, {
        icon: icon,
        draggable: false,
      });
      marker.addTo(this.mapObj);
      this.markers.push(marker);

    } else {
      circle = L.circleMarker(location,{
        radius: 5,
        color: 'blue',
        fillColor: 'blue',
        fillOpacity: 1,
      });
      circle.addTo(this.mapObj);
      this.markers.push(circle);
      circle.bringToFront();
    }
  };

  searchDestination(input: string) {
    this.nominatimService.search(input).subscribe(
      (data) => {
        this.placesSearchs = data;
      }
    );
  }

  getPlace(item: any) {
    this.coordenadasDestino.latitude = null;
    this.coordenadasDestino.longitude = null;
    this.place = item;

    this.coordenadasDestino.latitude = item.lat;
    this.coordenadasDestino.longitude = item.lon;
    this.placesSearchs = [];
      this.createMap();
      this.hasDestinoToSend = true;
  }

  changeSearch() {
    this.searchDestination(this.searchElementRef.nativeElement.value);
  }

  addMarkerToMap() {
    if (
      this.coordenadasEquipamento.latitude &&
      this.coordenadasEquipamento.longitude
    ) {
      const latLngEquipamento = [
        this.coordenadasEquipamento.latitude,
        this.coordenadasEquipamento.longitude,
      ] as L.LatLngTuple;

      this.addMarker(latLngEquipamento, true);
    }

    if (
      this.coordenadasDestino.latitude &&
      this.coordenadasDestino.longitude
    ) {
      const latLngDestino = [
        this.coordenadasDestino.latitude,
        this.coordenadasDestino.longitude,
      ] as L.LatLngTuple;

      this.addMarker(latLngDestino, false);
    }
  }

  calculateAndDisplayRoute() {
    if (
      this.coordenadasEquipamento.latitude &&
      this.coordenadasEquipamento.longitude &&
      this.coordenadasDestino.latitude &&
      this.coordenadasDestino.longitude
    ) {
      const map = this.mapObj;
  
      this.setCenterMap();
      L.Routing.control({
        waypoints: [
          L.latLng(this.coordenadasEquipamento.latitude, this.coordenadasEquipamento.longitude),
          L.latLng(this.coordenadasDestino.latitude, this.coordenadasDestino.longitude),
        ],
        createMarker: function () {
          return null;
        },
        serviceUrl: 'https://routing.openstreetmap.de/routed-car/route/v1/',
      }).addTo(map);
    }
  }

  setCenterMap(){
    var bounds = L.latLngBounds([]);
    this.markers.forEach((marker) => {
        bounds.extend(marker.getLatLng());
    });
    this.mapObj.fitBounds(bounds, { padding: [10, 10], maxZoom: 10 });
  }

  sendDestino() {
    if (this.atendimentoId) {
      const dados = {
        atendimentoId: this.atendimentoId,
        destinoNome: this.place.name,
        latitude: this.coordenadasDestino.latitude,
        longitude: this.coordenadasDestino.longitude,
      };
      const dialogConfirm = this.dialogService.confirmAction();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.atendimentoService
            .insertAtendimentoDestino(dados)
            .subscribe(() => {
              this.alertaService.sucesso();
            });
        }
      });
    } else {
      this.alertaService.atendimentoWarning(
        this.alertaTitulo,
        this.alertaMensagem
      );
    }
  }
}
