import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';


import { IPermissaoGrupo } from '../models/IPermissaoGrupo';
import { IPermissaoGrupoModulos } from '../models/IPermissaoGrupoModulos';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const apiTela = `PermissaoGrupoUsuario`;

@Injectable({ providedIn: 'root' })
export class PermissaoGrupoService {
  httpEmitter: Subscription;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  get(): Observable<IPermissaoGrupo[]> {
    return this.http.get<IPermissaoGrupo[]>(`${this.apiUrl}`);
  }

  getById(dados: IPermissaoGrupo) {
    return this.http.get<IPermissaoGrupo>(`${this.apiUrl}/${dados.grupoUsuarioId}`);
  }

  getModulos(grupoUsuarioId: number): Observable<IPermissaoGrupoModulos[]> {
    return this.http.get<IPermissaoGrupoModulos[]>(
      `${this.apiUrl}/Telas/${grupoUsuarioId}`
    );
  }

  changePermissaoTela($grupoUsuarioId: number, $event): Observable<any> {
    let body = {
      grupoUsuarioId: $grupoUsuarioId,
      aplicacaoTelaId: $event.telaId,
      telaPermissaoId: $event.permissaoId,
      possuiPermissao: $event.permissaoStatus
    };

    return this.http.post<any>(`${this.apiUrl}/Detalhe`, body);
  }

  changePermissaoTotal($grupoUsuarioId: number, $event): Observable<any> {
    let body = {
      grupoUsuarioId: $grupoUsuarioId,
      aplicacaoTelaId: $event.telaId,
      possuiPermissao: $event.permissaoStatus
    };
    return this.http.post<any>(`${this.apiUrl}/Tela`, body);
  }
  
  grantPermissionAllModule(grupoUsuarioId, aplicacaoModuloId, possuiPermissao){
    let body = {
      grupoUsuarioId: grupoUsuarioId,
      aplicacaoModuloId: aplicacaoModuloId,
      possuiPermissao: possuiPermissao
    };
    return this.http.post<any>(`${this.apiUrl}/TotalModulos`, body);
  }
}
