import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IMeusAtalhos } from '../models/IMeusAtalhos';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 5;


@Injectable({ providedIn: 'root' })
export class MeusAtalhosService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}AplicacaoAtalho`;
  apiUrlCombo = `${this.env.apiUrl}AplicacaoTelaCombo`;

  get(usuarioId: number): Observable<IMeusAtalhos[]> {
    const options = {
      params: new HttpParams().set('usuarioId', usuarioId.toString())
    };

    return this.http.get<IMeusAtalhos[]>(this.apiUrl, options);
  }

  getById(dados: IMeusAtalhos) {
    if (dados) {
      const { aplicacaoTelaId, usuarioId } = dados;

      const params = new HttpParams().set('usuarioId', usuarioId.toString());

      return this.http.get<IMeusAtalhos>(`${this.apiUrl}/${aplicacaoTelaId}`, {
        params
      });
    }
  }

  listComboSelect(usuarioId: number): Observable<ICombo[]> {
    return this.http.get<ICombo[]>(`${this.apiUrlCombo}/${usuarioId}`);
  }

  insert(dados: IMeusAtalhos) {
    return this.http.post<IMeusAtalhos>(`${this.apiUrl}`, dados);
  }

  update(dados: IMeusAtalhos) {
    return this.http.put<IMeusAtalhos>(
      `${this.apiUrl}/${dados.aplicacaoTelaId}`,
      dados
    );
  }

  delete(dados: IMeusAtalhos) {
    const { aplicacaoTelaId, usuarioId } = dados;
    const body = {
      aplicacaoTelaId,
      usuarioId
    };

    return this.http.request<IMeusAtalhos>(
      'DELETE',
      `${this.apiUrl}/${aplicacaoTelaId}`,
      {
        body
      }
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
