<div class="title-grid">
  <p>
    {{ wordsTranslated.title }}
    {{ selectedSaw.prefixo }}
  </p>
</div>
<div class="perguntasSearchs">
  <input
    type="text"
    class="perguntasSearch"
    placeholder="{{ wordsTranslated.buscaPergunta }}"
    [(ngModel)]="query"
    id="listSearch"
  />
</div>
<div class="drag-drop-grid">      
<div class="bloqueio-header">
  <div class="header-drag-drop">
    <div class="selecionadoToggleP">
      <p>
        {{ wordsTranslated.selecionado }}
      </p>
    </div>
    <div class="perguntaPortuguesP">
      <p>
        {{ wordsTranslated.perguntaPortugues }}
      </p>
    </div>
    <div class="ordenacaoTotemP">
      <p>
        {{ wordsTranslated.ordenacaoTotem }}
      </p>
    </div>
    <div class="selecionadoAtivoP">
      <p>
        {{ wordsTranslated.ativo }}
      </p>
    </div>
  </div>
</div>


  <div cdkDropList class="list-pergunta" (cdkDropListDropped)="drop($event)">
    <div class="espacoHeader">
      <div
        class="list-pergunta-dados"
        *ngFor="let dado of dadosGrid | searchFilter: query"
        cdkDragLockAxis="y"
        cdkDrag
        [cdkDragDisabled]="!dado.selecionado || !this.permissoesTela.update"
      >
        <div class="drop-placeholder" *cdkDragPlaceholder></div>
        <div class="selecionadoToggle">
          <p>
            <mat-slide-toggle
              [disabled]="!this.permissoesTela.update"
              [checked]="dado.selecionado"
              (toggleChange)="changeToogle(dado)"
            ></mat-slide-toggle>
          </p>
        </div>
        <div class="perguntaPortugues">
          <p>
            {{ dado.perguntaPortugues }}
          </p>
        </div>
        <div class="ordenacaoTotem">
          <p>{{ dado.ordenacaoTotem }}</p>
        </div>
        <div class="selecionadoAtivo">
          <p>
            <mat-slide-toggle
              [disabled]="!dado.selecionado || !this.permissoesTela.update"
              [checked]="dado.ativo"
              (toggleChange)="changeToogleAtivo(dado)"
            ></mat-slide-toggle>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
