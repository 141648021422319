import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[hidePassword]'
})
export class HidePasswordDirective {
  private _shown = false;
  constructor(private el: ElementRef) {
    this.setup();
  }
  toggle(span: HTMLElement) {
    this._shown = !this._shown;

    if (this._shown) {
      this.el.nativeElement
        .getElementsByTagName('input')[0]
        .setAttribute('type', 'text');
      span.innerHTML = 'visibility_off';
    } else {
      this.el.nativeElement
        .getElementsByTagName('input')[0]
        .setAttribute('type', 'password');
      span.innerHTML = 'visibility';
    }
  }
  setup() {
    const parent = this.el.nativeElement;
    const span = document.createElement('span');
    span.setAttribute('class', 'material-icons icone-show-hide-password');
    span.innerHTML = `visibility`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.append(span);
  }
}
