import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { EventsEnum } from '../../enum/EventsEnum';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private readonly subject$: Subject<EmitEvent>;

  constructor() {
    this.subject$ = new Subject<EmitEvent>();
  }

  public on(event: EventsEnum, action: (...agrs: any[]) => any) {
    return this.subject$.pipe(
      filter((e) => e.name === event),
      map((e) => e.value))
      .subscribe(action);
  }

  public emit(name: EventsEnum, value?: any) {
    this.subject$.next(new EmitEvent(name, value));
  }
}

class EmitEvent {
  constructor(public name: EventsEnum, public value?: any) {}
}
