import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

export interface ISenha {
  senha: string;
}

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirmPassword.component.html',
  styleUrls: ['./confirmPassword.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {
  @Output() returnEventConfirm = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<any>(true);
  @Input() registro: ISenha = null;

  formulario: UntypedFormGroup;
  public formLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConfirmPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.criarFormulario();
  }

  cancel() {
    this.dialogRef.close();
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      senha: [
        this.registro != null && this.registro.senha
          ? this.registro.senha
          : null,
        Validators.compose([Validators.required])
      ]
    });
  }

  save(): void {
    if(this.formulario.value.senha){
      this.dialogRef.close(this.formulario.value.senha);
    }
  }
}
