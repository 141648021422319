<div class="modal" modal-name="modalTelefonesUteisForm">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
  >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="telefoneUtilId" />

          <mat-form-field class="fieldNomeBRA" appearance="outline">
            <mat-label>
              {{ "paginas.telefonesUteis.form.nome_portugues" | translate }}
            </mat-label>
            <input
              [focusMe]="true"
              matInput
              placeholder="{{
                'paginas.telefonesUteis.form.nome_portugues' | translate
              }}"
              autocomplete="off"
              formControlName="nomePortugues"
            />
          </mat-form-field>

          <mat-form-field class="fieldNomeESP" appearance="outline">
            <mat-label>
              {{ "paginas.telefonesUteis.form.nome_espanhol" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{
                'paginas.telefonesUteis.form.nome_espanhol' | translate
              }}"
              autocomplete="off"
              formControlName="nomeEspanhol"
            />
          </mat-form-field>

          <mat-form-field class="fieldNomeEUA" appearance="outline">
            <mat-label>
              {{ "paginas.telefonesUteis.form.nome_ingles" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{
                'paginas.telefonesUteis.form.nome_ingles' | translate
              }}"
              autocomplete="off"
              formControlName="nomeIngles"
            />
          </mat-form-field>

          <mat-form-field class="fieldTelefone" appearance="outline">
            <mat-label>
              {{ "paginas.telefonesUteis.form.telefone" | translate }}
            </mat-label>
            <input
              [focusMe]="false"
              matInput
              placeholder="{{
                'paginas.telefonesUteis.form.telefone' | translate
              }}"
              autocomplete="off"
              formControlName="telefone"
            />
          </mat-form-field>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              *ngIf="editForm"
              class="primary cancel"
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>
            <button
              type="submit"
              *ngIf="editForm"
              mat-button
              class="save"
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
