import { Component, OnInit, Input, HostListener } from '@angular/core';
import { IUsuarioRamal } from 'src/app/shared/models/IUsuarioRamal';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { SoftPhoneService } from 'src/app/shared/services/softPhone/soft-phone.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';

@Component({
  selector: 'app-status-soft-phone',
  templateUrl: './status-soft-phone.component.html',
  styleUrls: ['./status-soft-phone.component.scss']
})
export class StatusSoftPhoneComponent implements OnInit {
  @Input('acesso') acesso: IUsuarioRamal = null;

  online = false;
  status = '';
  numero = '';
  bloquearAcao = false;
  showPhone = false;
  showChamando = false;
  ramal: string = '';
  senha: string = '';
  permissaoCameraMic = false;
  iconeToolTip: string;
  noMediaPermission: string;
  wordsTranslated: any;

  // MONITORA AS TECLA PRECIONADAS
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.altKey) {
      let key = event.code.replace('Digit', '');
      key = key.replace('Key', '');

      if(key == 'A'){
        this.aceitar();
      }
      if(key == 'R'){
        this.rejeitar();
      }
    }
  }

  constructor(private softPhoneService: SoftPhoneService, private wordsTranslate: WordsTranslateService, private configuracaoService: ConfiguracaoService) {
    this.checkMediaPermission();
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.status-soft-phone.';
    const words = {
      noMediaPermission: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    this.noMediaPermission = this.wordsTranslated.noMediaPermission;
  }

  ngOnDestroy(){
    this.disconnectSIP();
  }

  ngOnInit() {
    this.checkMediaPermission();

    this.configuracaoService.endCallToNumber.subscribe((data: { totem: string; atendente: string; }) => {

      if(data.totem == this.numero && (data.atendente != this.ramal || data.atendente === ''))
        this.rejeitar()
    })
  }

  ngOnChanges(changes: any) {
    if (
      changes.acesso.currentValue &&
      changes.acesso.currentValue.ramal &&
      changes.acesso.currentValue.senha
    ) {
      this.ramal = changes.acesso.currentValue.ramal;
      this.senha = changes.acesso.currentValue.senha;
    }
  }

  connectSIP() {
    this.softPhoneService.sipRegister();


    this.softPhoneService.statusSoftPhone.subscribe((state) => {
      if(this.permissaoCameraMic == true){
        this.online = state;
      }
      else{
        this.online = false;
      }

    });

    this.softPhoneService.newCallSoftPhone.subscribe((newCall) => {
      this.ligar(newCall)
    });

    this.softPhoneService.showCallingSoftPhone.subscribe((state) => {
      if(state){
        this.numero = state.numero;
        this.showChamando = true;
        this.status = state.equipamento ? state.equipamento.prefixo : '';
      }else{
        this.showPhone = false;
        this.showChamando = false;
        this.status = '';
      }
    });

  }

  disconnectSIP() {
    this.softPhoneService.sipUnRegister();
  }

  ligar(ramal) {
    this.softPhoneService.showSoftPhone.emit(true);
    this.showPhone = true;
    // this.showChamando = true;
    this.status = 'Chamando';
    this.numero = ramal;

    setTimeout(() => {
      this.softPhoneService.ramalToCall.emit(ramal)
    }, 1000);
  }

  aceitar() {
    this.showPhone = true;
    this.showChamando = false;
    this.status = 'Em andamento';
    this.softPhoneService.showSoftPhone.emit(true)

    this.softPhoneService.setStartCall(this.numero, this.ramal);

  }

  rejeitar() {
    this.softPhoneService.sipHangUp();
    this.showPhone = false;
    this.showChamando = false;
    this.status = '';
  }

  checkMediaPermission(){
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((resp) => {
      this.permissaoCameraMic = true;
      this.connectSIP();
    }).catch(err => {
      this.permissaoCameraMic = false;
      this.iconeToolTip = this.noMediaPermission;
      // this.confirmMediaPermission();
    }),function(stream){
      stream.forEach(track => {
        track.stop();
      });
    }
  }
}
