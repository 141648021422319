import { Injectable } from '@angular/core';
import { AlertaService } from '../services/alerta/alerta.service';
import { LoadingService } from '../services/loading/loading.service';
import { WordsTranslateService } from '../services/translate/words.translate';

@Injectable({
  providedIn: 'root'
})
export class DownloadHelper {
  [x: string]: any;
  constructor(
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    ) {}
  downloadMidia(event, name = null) {
    let generateFile: boolean = false;

    if(typeof event === 'object' && event != null){
      if(event['content']){
        generateFile = true
      }
    }

    if(generateFile){
      var byteCharacters = atob(event['content']);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: event['contentType'].toString()
      });

      // Create an invisible A element
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(blob);

      // Use download attribute to set set desired file name
      a.setAttribute('download', name ? name : event['name']);

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

    } else {
      this.loadingService.hide();
      const keyRoot = 'alertas.nenhumDadoParaExportar.';
      const words = {
        mensagem: '',
        titulo: ''
      };
      let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
      this.alertaService.Warning(wordsTranslate.titulo,wordsTranslate.mensagem);
    }
    this.loadingService.hide();
  }
}
