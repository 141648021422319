<div class="legenda">
  <mat-sidenav #legendaNav mode="over" position="end" class="sidenav" fixedInViewport>
    <div class="space"></div>
    <div class="title">
      <h2>{{'legenda.title' | translate}}</h2>
      <button mat-button (click)="close()" class="close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <mat-list>
      <div class="item" *ngFor="let item of dados">
        <span *ngIf="item.color" [style.backgroundColor]="item.color"></span>
        <img *ngIf="item.icone" src="{{item.icone}}" alt="">
        <span *ngIf="item.badge" class="badge" [style.backgroundColor]="item.badgeColor"></span>
        <div class="texto">
          <h2>{{item.title}}</h2>
          <small *ngIf="item.description">{{item.description }}</small>
        </div>
      </div>
    </mat-list>
  </mat-sidenav>
</div>