import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DeviceAudioVideo {
    constructor(){}

    public hasVideo:boolean = false;
    public hasAudio:boolean = false;

    getMediaDevices(): Promise<any>{

           
            return navigator.mediaDevices.enumerateDevices().then(function (devices) {
                
                let retorno = {
                    video: false,
                    audio: false
                };
                devices.forEach(function (device){
                    if (device.kind == 'videoinput'){
                        retorno.video = true;
                    }
                    if (device.kind == 'audioinput'){
                        retorno.audio = true;
                    }
                })
                return retorno
            })

       
        
    }

}