<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [focus]="focusFirstLine"
  (refreshEvent)="refreshGridData()"
  (addEvent)="openForm()"
  (editEvent)="openForm($event)"
  (readEvent)="openForm($event)"
  (deleteEvent)="confirmDel($event)"
></ws-grid>

<app-usuario-atendente-form
  *ngIf="showForm"
  [permissoes]="permissoesTela"
  [registro]="dadosUsuarioAtendente"
  (onChange)="refreshGridData()"
  (closeEvent)="closeForm()"
>
</app-usuario-atendente-form>
