<div class="telefone">
  <audio #audio_remote id="audio_remote" muted autoplay="autoplay"></audio>
  <audio
    #ringtone
    id="ringtone"
    loop
    src="./../assets/sounds/ringtone.wav"
  ></audio>
  <audio
    #ringbacktone
    id="ringbacktone"
    loop
    src="./../assets/sounds/ringbacktone.wav"
  ></audio>
  <audio #dtmfTone id="dtmfTone" src="./../assets/sounds/dtmf.wav"></audio>

  <div class="centro">
    <div class="atendimento">
      <div class="remoto">
        <div class="identificacao">{{ "soft-phone.usuario" | translate }}</div>
        <button class="fullScreen" (click)="remoteFullScreen()">
          <i class="fas fa-expand"></i>
        </button>
        <video
          #video_remote
          id="video_remote"
          autoplay="autoplay"
          *ngIf="showVideoRemote"
        ></video>
      </div>

      <div class="informacoes-equipamento">
        <ul *ngIf="equipamento">
          <li class="boxTitulo">
            {{ "soft-phone.informacoes-do-equipamento" | translate }}
          </li>
          <li class="boxControle">
            <div class="autofalante">
              <app-controle-volume
                (volume)="setVolumeAudioRemoto($event)"
                [iconeOn]="'volume_up'"
                [iconeOff]="'volume_off'"
                [volumeNumber]="volumeAudioRemoto"
              ></app-controle-volume>
            </div>
            <div class="microfone">
              <app-controle-volume
                (volume)="setVolumeMicRemoto($event)"
                [iconeOn]="'mic_none'"
                [iconeOff]="'mic_off'"
                [volumeNumber]="volumeMicRemoto"
              ></app-controle-volume>
            </div>
          </li>

          <li class="boxGrupo">
            <div class="boxPrefixo">
              <span>{{ "soft-phone.prefixo" | translate }}</span>
              <br />
              {{ equipamento.prefixo }}
            </div>
            <div class="boxRodovia">
              <span>{{ "soft-phone.rodovia" | translate }}</span>
              <br />
              {{ equipamento.rodoviaSigla }}
            </div>
            <div class="boxSentido">
              <span>{{ "soft-phone.sentido" | translate }}</span>
              <br />
              {{ equipamento.sentidoDesc }}
            </div>
            <div class="boxKm">
              <span>{{ "soft-phone.km" | translate }}</span>
              <br />
              {{ equipamento.kmNominal }}
            </div>
          </li>

          <li class="boxEndereco">
            <span>{{ "soft-phone.endereco" | translate }}</span>
            <br />
            {{ equipamento.endereco }}
          </li>
        </ul>
        <div class="boxHelp">
          <div class="titulo">
            <h2>Teclas de Atalho</h2>
            <!-- <button class="closeBoxHelp">
              <i class="far fa-times-circle"></i>
            </button> -->
          </div>
          <ul>
            <li>
              <div>{{ "soft-phone.atender-chamada" | translate }}</div>
              <div>ALT + A</div>
            </li>
            <li>
              <div>{{ "soft-phone.rejeitar-chamada" | translate }}</div>
              <div>ALT + R</div>
            </li>
            <li>
              <div>{{ "soft-phone.encerrar-chamada" | translate }}</div>
              <div>ALT + X</div>
            </li>
            <!-- <li>
              <div>{{ "soft-phone.transferir-chamada" | translate }}</div>
              <div>ALT + T</div>
            </li> -->
            <li>
              <div>{{ "soft-phone.desligar-mic-cam-chamada" | translate }}</div>
              <div>ALT + M</div>
            </li>
            <!-- <li>
              <div>{{ "soft-phone.colocar-em-espera" | translate }}</div>
              <div>ALT + P</div>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="local" *ngIf="showVideoLocal">
        <div class="boxTime">
          <span *ngIf="timeCall != 0">{{ timeCall }}</span>
        </div>
        <div class="boxAcoes">
          <ul>
            <!-- <li class="botaoHold">
              <button
                (click)="sipHold()"
                [title]="'soft-phone.espera' | translate"
              >
                <i *ngIf="!onHold" class="fas fa-pause"></i>
                <i *ngIf="onHold" class="fas fa-play"></i>
              </button>
            </li> -->
            <li class="botaoEncerrar">
              <button
                (click)="sipHangUp()"
                [title]="'soft-phone.encerrar' | translate"
              >
                <i class="fas fa-phone-slash"></i>
              </button>
            </li>
            <!-- <li class="botaoTransferir">
              <div class="boxRamal" *ngIf="showBoxTransfer">
                <mat-form-field class="fieldTelefone" appearance="outline">
                  <mat-label>{{
                    "soft-phone.informe-o-ramal" | translate
                  }}</mat-label>
                  <input
                    #numberRamalTransferElement
                    (keydown.enter)="confirmTranfer()"
                    [(ngModel)]="numberRamalTransfer"
                    matInput
                    [placeholder]="'soft-phone.informe-o-ramal' | translate"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>

              <button
                (click)="transfer()"
                [title]="'soft-phone.transferir' | translate">
                <i class="fas fa-random"></i>
              </button>
            </li> -->

            <li class="botaoOffMic">
              <button
                (click)="offMic()"
                [title]="'soft-phone.controle-microfone' | translate"
              >
                <span *ngIf="statusMic" class="material-icons">mic_none</span>
                <span *ngIf="!statusMic" class="material-icons">mic_off</span>
              </button>
            </li>
            <li class="botaoOffCam">
              <button
                (click)="offCam()"
                [title]="'soft-phone.controle-webcam' | translate"
              >
                <span *ngIf="statusCam" class="material-icons">videocam</span>
                <span *ngIf="!statusCam" class="material-icons"
                  >videocam_off</span
                >
              </button>
            </li>
            <li
              class="botaoControles"
              [title]="'soft-phone.volume-usuario' | translate"
            >
              <app-controle-volume
                [vertical]="true"
                (volume)="setVolumeAudioLocal($event)"
                [iconeOn]="'volume_up'"
                [iconeOff]="'volume_off'"
              ></app-controle-volume>
            </li>
          </ul>
        </div>
        <div class="boxVideo">
          <div class="identificacao">{{ "soft-phone.voce" | translate }}</div>
          <video
            #video_local
            id="video_local"
            muted
            autoplay="autoplay"
          ></video>

          <!-- <button class="exibirAtalho" (click)="showHelp()">
            <i class="far fa-question-circle"></i>
          </button> -->
        </div>
      </div>
    </div>

    <div class="acao">
      <svg
        class="voicecall"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="microphone"
        data-container-transform="translate(3)"
        viewBox="0 0 16 20"
        x="0px"
        y="0px"
      >
        <path
          d="M4.5 0c-1.4 0-2.5 1.1-2.5 2.5v5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-5c0-1.4-1.1-2.5-2.5-2.5zm-4.125 6.188a.5.5 0 0 0-.375.5v.813c0 2.302 1.763 4.184 4 4.438v3.063h-2c-.6 0-1 .4-1 1h7c0-.6-.4-1-1-1h-2v-3.063c2.237-.254 4-2.136 4-4.438v-.813a.5.5 0 1 0-1 0v.813c0 1.927-1.573 3.5-3.5 3.5s-3.5-1.573-3.5-3.5v-.813a.5.5 0 0 0-.563-.5.5.5 0 0 0-.063 0z"
          transform="translate(3)"
        />
      </svg>
      <!-- <input type="button" class="btn btn-success" id="btnRegister" value="Conectar" (click)='sipRegister();' /> -->
      <input
        type="button"
        class="btn btn-success"
        id="sipCall2"
        value="Ligar"
        (click)="sipCall()"
      />
      <input
        type="button"
        class="btn btn-success"
        id="btnCall"
        value="Atender"
        (click)="sipCall()"
      />
      <!-- <input type="button" class="btn btn-success" id="sipHangUp" value="Rejeitar" (click)="sipHangUp()" />
            <input type="button" class="btn btn-success" id="btnHangup" value="Desligar" (click)="sipHangUp()" /> -->
      <!-- <input type="button" class="btn btn-danger" id="btnUnRegister" value="Desconectar"
                (click)='sipUnRegister();' /> -->
      <!-- <input type="button" class="btn btn-danger" id="btnTransfer" value="Transferir" (click)='sipTransfer();' /> -->
    </div>
  </div>
</div>
