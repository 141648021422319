import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';

import { IDialogButton } from 'src/app/shared/models/IDialogButton';

import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { Globals } from 'src/app/app.globals';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { IVersaoTotem } from '../models/IVersaoTotem';
import { VersaoTotemService } from '../services/versao-totem.service';

@Component({
  selector: 'app-versao-totem-form',
  templateUrl: './versao-totem-form.component.html',
  styleUrls: ['./versao-totem-form.component.scss'],
})

// tslint:disable-next-line: component-class-suffix
export class VersaoTotemFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;

  public permissoesTela: IGridPermissoes;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IVersaoTotem = null;

  @Output() closeEvent = new EventEmitter<string>();

  statusBotao: IDialogButton;
  registroClean: IVersaoTotem;

  focusVersaoTotem = false;
  exibirContatoGrid = false;
  valueCombo: Array<any>;
  exibirFormulario: false;
  autoClose = true;
  formLoading = false;
  tipoAcao = null;

  itensComboMunicipio: ICombo[];
  itemSelecionadoComboMunicipio: any;

  wordsTranslated: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    public wordsTranslate: WordsTranslateService,
    public versaoTotemService: VersaoTotemService,
    private dialogService: DialogService,
    public globals: Globals,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalVersaoTotemForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.versaoTotem.form.';
    let words = {
      registro: '',
      editar_registro: '',
      adicionar_registro: '',
      versaoInativa:'',
      nomeArquivo: '',
      firmware: '',
      ativo: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    if (this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }
  }

  loadEditData() {
    this.versaoTotemService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;

    let valuesForm = this.formulario.value;

    if (this.registro) {
      valuesForm = {
        id: this.formulario.value.id,
        nomeArquivo: this.registro.nomeArquivo,
        ativo:this.formulario.value.ativo
      };
      this.versaoTotemService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.versaoTotemService
        .insertAtualizacao(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.versaoTotemService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo,
      };
      this.versaoTotemService.onChangeFormVersao.emit(change);

      this.formLoading = false;
      this.checkAutoClose();

      this.criarFormulario();
      this.configuracaAcoesBotoes();
    });
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    if (this.registro) {
      if (this.registro.ativo) {
        this.titleDialog = this.wordsTranslated.editar_registro;
      } else {
        this.titleDialog = this.wordsTranslated.versaoInativa;
      }
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      id: [
        this.registro !== null && this.registro.id !== null
          ? this.registro.id
          : null,
      ],
      nomeArquivo: [
        this.registro !== null && this.registro.nomeArquivo !== null
          ? this.registro.nomeArquivo
          : null
      ],
      firmware: [
        this.registro !== null && this.registro.firmware !== null
          ? this.registro.firmware
          : null,
        Validators.compose([Validators.required]),
      ],
      ativo: [this.registro == null ? true : this.registro.ativo],
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();

    if (this.registro !== null && this.registro.nomeArquivo !== null)
      this.formulario.controls['nomeArquivo'].disable();

    if (this.registro !== null && this.registro.firmware != null)
      this.formulario.controls['firmware'].disable();

    if (!this.editForm && this.editForm !== null) {
      this.formulario.disable();
    } else {
      this.focusVersaoTotem = true;
    }
  }

  changeToogleAtivo($event) {
    this.formulario.patchValue({
      ativo: $event,
    });
  }
}
