import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IRodovia } from '../models/IRodovia';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { RodoviaService } from '../services/rodovia.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';

@Component({
  selector: 'app-rodovia-grid-component',
  templateUrl: './rodovia-grid.component.html'
})
export class RodoviaGridComponent implements OnInit {
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IRodovia[];
  public dadosFormulario: IRodovia;
  public focusFirstLine: boolean = false;
  wordsTranslated: any;

  constructor(
    private rodoviaService: RodoviaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.rodovia.grid.';
    const words = {
      nome: '',
      sigla: '',
      kmInicial: '',
      kmFinal: '',
      primeiroSentido: '',
      segundoSentido: '',
      cor: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.rodoviaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.editForm = this.permissoes.update;

        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'rodoviaNome',
        label: this.wordsTranslated.nome,
        width: '50%',
        sort: true
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.sigla,
        width: '',
        sort: true
      },
      {
        name: 'rodoviaKmInicio',
        label: this.wordsTranslated.kmInicial,
        width: '',
        sort: true
      },
      {
        name: 'rodoviaKmFim',
        label: this.wordsTranslated.kmFinal,
        width: '',
        sort: true
      },
      {
        name: 'sentido1',
        label: this.wordsTranslated.primeiroSentido,
        width: '',
        sort: true
      },
      {
        name: 'sentido2',
        label: this.wordsTranslated.segundoSentido,
        width: '',
        sort: true
      },
      {
        name: 'bgColor',
        label: this.wordsTranslated.cor,
        width: '',
        sort: false
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.rodoviaService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  confirmDel(dados: IRodovia) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.rodoviaService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        rodoviaId: dados.rodoviaId
      });
    }

    this.dadosGrid = this.gridService.refreshGridData(
      this.dadosGrid,
      dados,
      indexPage,
      tipo
    );
  }

  openForm(dados: IRodovia = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 500);
  }
}
