import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ILicenca } from './models/ILicenca';
import { LicencaService } from './services/licensa.service';

@Component({
  selector: 'app-licenca',
  templateUrl: './licenca.component.html',
  styleUrls: ['./licenca.component.scss'],
})
export class LicencaComponent implements OnInit {
  @Input() registro: ILicenca = null;

  titleDialog: string;
  modalName: string = 'modalLicenca';
  wordsTranslated: any;
  formulario: UntypedFormGroup;
  formLoading = false;

  public formValidParam = {
    startDate: new Date(Date.now())
  };

  constructor(
    private wordsTranslate: WordsTranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private licencaService: LicencaService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.licenca.modal.';
    const words = {
      lq: '',
      ld: '',
      guide: '',
    };

    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  close(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['modulo'] == 'suporte') {

        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    });
  }

  get f() {
    return this.formulario.controls;
  }

  ngOnInit(): void {
    this.titleDialog = 'Renovar Licença';
    this.criarFormulario();
    this.setInitialFilterData();
  }

  private setInitialFilterData() {
    this.formulario.patchValue({
      ld: this.formValidParam.startDate,
    });
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      lq: [
        this.registro != null && this.registro.lq !== null
          ? this.registro.lq
          : null,
        Validators.required,
      ],
      ld: [
        this.registro != null && this.registro.ld !== null
          ? this.registro.ld
          : null,
        Validators.required,
      ],
      guid: [
        this.registro != null && this.registro.guid !== null
          ? this.registro.guid
          : null,
        Validators.required,
      ],
    });
  }

  save(): void {
    this.formLoading = true;
    const valuesForm: ILicenca = this.formulario.value;

    this.licencaService
      .update(valuesForm)
      .pipe(
        finalize(() => {
          this.formLoading = false;
        })
      )
      .subscribe((_) => {
        this.alertaService.sucesso();
      });
  }

  changeDateDe($event) {
   let date = new Date($event.value);

    this.formulario.patchValue({
      ld: date,
    });
  }


  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }
}
