import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { forkJoin } from 'rxjs';

import { IMeusAtalhos } from '../models/IMeusAtalhos';
import { IUserDados } from 'src/app/shared/models/IUserDados';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { MeusAtalhosService } from '../services/meus-atalhos.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';

import { environment } from 'src/environments/environment';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { IconeService } from 'src/app/shared/services/icone/icone.service';

@Component({
  selector: 'app-meus-atalhos-form',
  templateUrl: './meus-atalhos-form.component.html',
  styleUrls: ['./meus-atalhos-form.component.scss']
})
export class MeusAtalhosFormComponent implements OnInit {
  public prefixoAtalho = environment.prefixoShortCut;

  public formValidParam = {
    atalho: {
      max: 20
    }
  };

  formulario: UntypedFormGroup;
  titleDialog: string;
  labelAbrirAutomaticamente: string;
  disabledToggle: boolean;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IMeusAtalhos = null;
  @Input() userInfo: IUserDados;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IMeusAtalhos>();

  private registroClean: IMeusAtalhos;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboTela: ICombo[];
  public itemSelecionadoComboTela: any;

  public itensComboIcone: ICombo[];
  public itemSelecionadoComboIcone: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private meusAtalhosService: MeusAtalhosService,
    private iconeService: IconeService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.listenKeyPress();
  }
  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalMeusAtalhosForm') {
        this.confirmClose();
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.show();

    const observableComboIcone = this.iconeService.listComboSelect();
    const observableComboTela = this.meusAtalhosService.listComboSelect(
      this.userInfo.id
    );

    if (this.registro) {
      this.itemSelecionadoComboTela = this.registro.aplicacaoTelaId
        ? { value: this.registro.aplicacaoTelaId }
        : null;

      this.itemSelecionadoComboIcone = this.registro.iconeId
        ? { value: this.registro.iconeId }
        : null;
    }

    forkJoin([observableComboTela, observableComboIcone]).subscribe(
      (results) => {
        this.itensComboTela = results[0];
        this.itensComboIcone = results[1].map((x) => {
          x.image = `assets/images/icon/atalhos/${x.image}`;
          return x;
        });

        if (this.registro) {
          // Adicionado o item ativo
          this.itensComboTela.push({
            value: this.registro.aplicacaoTelaId,
            text: this.registro.aplicacaoTelaTexto
          });
        }

        const keyRoot = 'paginas.meus-atalhos.form.labels.';
        const words = {
          nao_abrir_auto: ''
        };
        let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
        this.labelAbrirAutomaticamente = wordsTranslate.nao_abrir_auto;

        if (this.registro != null) {
          this.loadEditaData();
        } else {
          this.criarFormulario();
        }
        this.loadingService.hide();
      },
      (error) => {
        console.error(error);
        this.loadingService.hide();
      }
    );
  }

  loadEditaData() {
    this.meusAtalhosService.getById(this.registro).subscribe((result) => {
      result.teclaAtalho = this.formatShortcut(result.teclaAtalho);
      this.registro = result;
      this.criarFormulario();
      this.setToggleLabel(this.registro.abrirAuto);
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;
    valuesForm.teclaAtalho = `${this.prefixoAtalho}+${valuesForm.teclaAtalho}`;

    if (this.registro) {
      this.meusAtalhosService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.meusAtalhosService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno: IMeusAtalhos, tipo: string) {
    const change = {
      dados: retorno,
      tipo
    };

    this.onChange.emit(change);
    retorno.teclaAtalho = this.formatShortcut(retorno.teclaAtalho);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;
    this.itemSelecionadoComboTela = null;
    this.itemSelecionadoComboIcone = null;

    this.formulario.patchValue({
      abrirAuto: false
    });

    const observableComboIcone = this.iconeService.listComboSelect();
    const observableComboTela = this.meusAtalhosService.listComboSelect(
      this.userInfo.id
    );

    forkJoin([observableComboTela, observableComboIcone]).subscribe(
      (results) => {
        this.itensComboTela = results[0];
        this.itensComboIcone = results[1].map((x) => {
          x.image = `assets/images/icon/atalhos/${x.image}`;
          return x;
        });

        this.formulario.reset();
        this.criarFormulario();
        this.setToggleLabel(this.formulario.value.abrirAuto);
      }
    );
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
    this.setToggleLabel(this.formulario.value.ativo);
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    const keyRoot = 'paginas.meus-atalhos.form.';
    const words = {
      editar_registro: '',
      adicionar_registro: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    this.titleDialog = this.registro
      ? wordsTranslate.editar_registro
      : wordsTranslate.adicionar_registro;
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      usuarioId: [
        this.registro == null ? this.userInfo.id : this.registro.usuarioId
      ],
      aplicacaoTelaId: [
        this.registro != null && this.registro.aplicacaoTelaId !== null
          ? this.registro.aplicacaoTelaId
          : null,
        Validators.compose([Validators.required])
      ],
      ordemExibicao: [
        this.registro != null ? this.registro.ordemExibicao : null,
        Validators.compose([Validators.required])
      ],
      iconeId: [
        this.registro != null && this.registro.iconeId !== null
          ? this.registro.iconeId
          : null,
        Validators.compose([Validators.required])
      ],
      teclaAtalho: [
        this.registro != null && this.registro.teclaAtalho
          ? this.registro.teclaAtalho
          : '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.formValidParam.atalho.max)
        ])
      ],
      abrirAuto: [
        this.registro == null ? false : this.registro.abrirAuto,
        Validators.compose([Validators.required])
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    }
  }

  onChangeVeiculoAtivo(event: MatSlideToggleChange) {
    this.setToggleLabel(event.checked);

    this.formulario.patchValue({
      abrirAuto: event.checked
    });
  }

  changeComboTela(event) {
    this.formulario.patchValue({
      aplicacaoTelaId: event
    });
  }

  changeComboIcone(event) {
    this.formulario.patchValue({
      iconeId: event
    });
  }

  private setToggleLabel(ativo: boolean) {
    const keyRoot = 'paginas.meus-atalhos.form.labels.';
    const words = {
      abrir_auto: '',
      nao_abrir_auto: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (ativo) {
      this.labelAbrirAutomaticamente = wordsTranslate.abrir_auto;
    } else {
      this.labelAbrirAutomaticamente = wordsTranslate.nao_abrir_auto;
    }
  }

  onKeydown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    let keys = '';
    const specialKeys = {
      Tab: () => {
        return false;
      },
      Enter: () => {
        return false;
      },
      Control: () => {
        return false;
      },
      Alt: () => {
        return false;
      },
      Shift: () => {
        return false;
      }
    };

    if (event.keyCode >= 112 && event.keyCode <= 123) {
      return false;
    }

    const specialKey = specialKeys[event.key];
    if (specialKey) {
      specialKey();
    } else {
      keys = this.keyValidation(event);
    }

    this.formulario.patchValue({
      teclaAtalho: keys
    });
  }

  private keyValidation(event: KeyboardEvent): string {
    let retorno = '';

    if (
      (event.keyCode >= 48 && event.keyCode <= 90) ||
      (event.keyCode >= 112 && event.keyCode <= 123)
    ) {
      retorno = event.code.replace('Digit', '');
      retorno = retorno.replace('Key', '');
      retorno = retorno.toUpperCase();
    }

    return retorno;
  }

  disableComboTela() {
    if (this.registro) {
      return true;
    } else {
      return false;
    }
  }

  formatShortcut(shortcut: string): string {
    shortcut = shortcut.replace('ALT+', '');
    return shortcut;
  }
}
