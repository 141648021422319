export class RefreshTokenDate {
  public static dateToRefresh: Date = new Date(Date.now());

  public static checksIfNeedsRefresh(): boolean {
    let dateNow = new Date(Date.now());

    if (dateNow >= this.dateToRefresh) return true;

    return false;
  }
}
