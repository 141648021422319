import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { ErrorDetailComponent } from '../../components/error-detail/error-detail.component';
import { IDialogMessage } from '../../models/IDialogMessage';
import { ConfirmPasswordComponent } from '../../components/confirmPassword/confirmPassword.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  confirmDelete(messageConfig?: IDialogMessage) {
    let config: IDialogMessage = {
      title: 'Confirme',
      message: 'Deseja realmente excluir o registro?',
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    if (messageConfig) {
      Object.assign(config, messageConfig);
    }

    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: config
    });
  }

  confirmAction(messageConfig?: IDialogMessage) {
    let config: IDialogMessage = {
      title: 'Confirme',
      message: 'Confirma a ação?',
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    if (messageConfig) {
      Object.assign(config, messageConfig);
    }

    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: config
    });
  }

  confirmCloseForm(messageConfig?: IDialogMessage) {
    let config: IDialogMessage = {
      title: 'Confirme',
      message:
        'Existem dados não salvos no formulário, deseja realmente fechar sem salvar?',
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    if (messageConfig) {
      Object.assign(config, messageConfig);
    }

    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: config
    });
  }

  confirmDisable() {
    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: 'Confirme',
        message: 'Deseja realmente desativar o registro?',
        textButtonFalse: 'Não',
        textButtonTrue: 'Sim'
      }
    });
  }

  confirmEnable() {
    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: 'Confirme',
        message: 'Deseja realmente ativar o registro?',
        textButtonFalse: 'Não',
        textButtonTrue: 'Sim'
      }
    });
  }

  confirmResetPassword() {
    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: 'Confirme',
        message: 'Deseja realmente resetar a senha do usuário?',
        textButtonFalse: 'Não',
        textButtonTrue: 'Sim'
      }
    });
  }

  errorDetail() {
    return this.dialog.open(ErrorDetailComponent, {
      width: '400px',
      data: {
        title: 'Confirme',
        message: 'Deseja realmente desativar o registro?',
        textButtonFalse: 'Não',
        textButtonTrue: 'Sim'
      }
    });
  }

  confirmPermissionAllWindows(usuarioNome: string) {
    let config: IDialogMessage = {
      title: `Confirmar Permissão Total?`,
      message: `Conceder permissão total ao usuário: ${usuarioNome} `,
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    return this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: config
    });
  }

  confirmPermissionGroupAllWindows(grupoDesc: string) {
    let config: IDialogMessage = {
      title: `Confirmar Permissão Total?`,
      message: `Conceder permissão total ao grupo: ${grupoDesc} `,
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    return this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: config
    });
  }

  removePermissionAllWindows(usuarioNome: string) {
    let config: IDialogMessage = {
      title: `Remover Permissão Total?`,
      message: `Retirar permissão total do usuário: ${usuarioNome} `,
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    return this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: config
    });
  }

  removePermissionGroupAllWindows(grupoDesc: string) {
    let config: IDialogMessage = {
      title: `Remover Permissão Total?`,
      message: `Retirar permissão total do grupo: ${grupoDesc} `,
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    return this.dialog.open(ConfirmComponent, {
      width: '600px',
      data: config
    });
  }

  confirmClearSearch(messageConfig?: IDialogMessage) {
    let config: IDialogMessage = {
      title: 'Confirme',
      message:
        'Para utilizar a função de arrasto a busca deve estar em branco, deseja limpar a busca?',
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

    if (messageConfig) {
      Object.assign(config, messageConfig);
    }

    return this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: config
    });
  }

  confirmPassword() {
    let config: IDialogMessage = {
      title: 'Confirme',
      message:
        'Informe a senha de administrador:',
      textButtonFalse: 'Cancelar',
      textButtonTrue: 'Acessar'
    };

    return this.dialog.open(ConfirmPasswordComponent, {
      width: '400px',
      data: config,
      disableClose: true
    });
  }
}
