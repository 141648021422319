import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { AlertaSistemaService } from '../services/alerta.service';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IAlerta } from '../models/IAlerta';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';

@Component({
  selector: 'app-alerta-grid',
  templateUrl: './alerta-grid.component.html',
  styleUrls: ['./alerta-grid.component.scss']
})
export class AlertaGridComponent implements OnInit {
  private readonly fileTranslate = 'AlertaGrid';

  permissoes: IGridPermissoes;
  colunasGrid: IGridModelo[];
  dadosGrid: IAlerta[];
  acoesGrid: IGridAcoes;

  alertaId: number;

  wordsTranslated = {
    alertaDesc: "",
    no_content: ""
  };

  constructor(
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaSistemaService: AlertaSistemaService
  ) { }

  ngOnInit(): void {
    this.getPermissoes();
  }

  getPermissoes() {
    this.alertaSistemaService.getPermissoes().pipe(
      finalize(() => {
        this.loadWordsTranslated();
        this.getAcoes();
        this.getColunas();
        this.getDados();
      })
    ).subscribe((res) => {
      this.permissoes = res[0];
    });
  }

  loadWordsTranslated() {
    this.wordsTranslated = this.wordsTranslate.get(this.wordsTranslated, 'paginas.alerta.grid.');
  }

  getAcoes() {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false
    };
  }

  getColunas() {
    this.colunasGrid = [
      {
        name: 'alertaDesc',
        label: this.wordsTranslated.alertaDesc,
        width: '',
        sort: true
      },
    ];
  }

  getDados() {
    this.loadingService.show();
    this.alertaSistemaService.get().pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    ).subscribe((res) => {
      this.dadosGrid = res;
    });
  }

  rowEvent(row: IAlerta) {
    this.alertaId = row.alertaId;
  }

}
