import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TabsConfiguracaoSistemaService } from './services/tabsConfiguracaoSistema.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { finalize } from 'rxjs/operators';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';

@Component({
  selector: 'app-tabs-configuracao-sistema',
  templateUrl: './tabs-configuracao-sistema-tab.component.html'
})
export class TabsConfiguracaoSistemaTabComponent implements OnInit {
  showParametros = true;
  showLog = false;
  showWallpaper = false;

  public isLoading: boolean;
  public permissoesTela: IGridPermissoes;
  public editForm = true;

  constructor(
    private loadingService: LoadingService,
    private tabsConfiguracaoSistemaService: TabsConfiguracaoSistemaService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.tabsConfiguracaoSistemaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
      });
  }

  onTabClick($event: MatTabChangeEvent) {
    this.showParametros = false;
    this.showLog = false;
    this.showWallpaper = false;

    const events = {
      // Parametros
      0: () => {
        this.showParametros = true;
      },
      // Log
      1: () => {
        this.showLog = true;
      },
      // Wallpaper
      2: () => {
        this.showWallpaper = true;
      }
    };

    events[$event.index]();
  }
}
