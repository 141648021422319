<div class="boxGripUpload">
  <div class="grid">
    <ws-grid
      class="importarKml"
      *ngIf="!isLoading && dadosGrid"
      [permissoes]="permissoes"
      [isLoading]="isLoading"
      [acoesGrid]="acoesGrid"
      [colunasGrid]="colunasGrid"
      [dadosGrid]="dadosGrid"
      (refreshEvent)="refresh()">
    </ws-grid>
  </div>
  <div class="upload">
    <ws-upload
      class="importarKml"
      [edit]="editForm"
      [listErrosUpload]="listErrosUpload"
      [limit]="1"
      [accept]="acceptExtensions"
      [statusUpload]="statusUpload"
      [listFiles]="listFiles"
      (eventPreview)="showPreview($event)"
      (uploadEvent)="uploadFiles($event)"
    ></ws-upload>
  </div>
</div>
