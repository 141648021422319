import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';

import { IDialogButton } from 'src/app/shared/models/IDialogButton';

import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IEmpresa } from '../models/IEmpresa';
import { EmpresaService } from '../services/empresa.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { Globals } from 'src/app/app.globals';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';

@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.scss']
})

// tslint:disable-next-line: component-class-suffix
export class EmpresaFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IEmpresa = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);

  statusBotao: IDialogButton;
  registroClean: IEmpresa;

  focusRazaoSocial = false;
  exibirContatoGrid = false;
  valueCombo: Array<any>;
  exibirFormulario: false;
  autoClose = true;
  formLoading = false;
  tipoAcao = null;

  itensComboMunicipio: ICombo[];
  itemSelecionadoComboMunicipio: any;

  wordsTranslated: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    public wordsTranslate: WordsTranslateService,
    public empresaService: EmpresaService,
    private dialogService: DialogService,
    public globals: Globals,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalEmpresaForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.empresa.form.';
    let words = {
      registro: '',
      editar_registro: '',
      adicionar_registro: '',
      razaoSocial: '',
      nomeFantasia: '',
      CNPJ: '',
      CEP: '',
      endereco: '',
      bairro: '',
      complemento: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    if (this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }
  }

  loadEditData() {
    this.empresaService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.empresaService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.empresaService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.empresaService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo
      };
      this.onChange.emit(change);

      this.formLoading = false;
      this.checkAutoClose();

      this.criarFormulario();
      this.configuracaAcoesBotoes();
    });
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      empresaId: [
        this.registro != null && this.registro.empresaId !== null
          ? this.registro.empresaId
          : null
      ],
      empresaRazaoSocial: [
        this.registro != null && this.registro.empresaRazaoSocial !== null
          ? this.registro.empresaRazaoSocial
          : null,
        Validators.compose([Validators.required])
      ],
      empresaNomeFantasia: [
        this.registro != null && this.registro.empresaNomeFantasia !== null
          ? this.registro.empresaNomeFantasia
          : null,
        Validators.compose([Validators.required])
      ],
      empresaCNPJ: [
        this.registro != null && this.registro.empresaCNPJ !== null
          ? this.registro.empresaCNPJ
          : null,
        Validators.compose([Validators.required])
      ],
      empresaEndereco: [
        this.registro != null && this.registro.empresaEndereco !== null
          ? this.registro.empresaEndereco
          : null
      ],
      empresaBairro: [
        this.registro != null && this.registro.empresaBairro !== null
          ? this.registro.empresaBairro
          : null
      ],
      empresaComplemento: [
        this.registro != null && this.registro.empresaComplemento !== null
          ? this.registro.empresaComplemento
          : null
      ],
      empresaCEP: [
        this.registro != null && this.registro.empresaCEP !== null
          ? this.registro.empresaCEP
          : null
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();

    if (!this.editForm) {
      this.formulario.disable();
    } else {
      this.focusRazaoSocial = true;
    }
  }
}
