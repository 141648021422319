<div class="gridMock" *ngIf="showGrid">
  <div class="opcoes" [style.background-color]="dadosAplicacao.personalizarTotemValorCor">
    <div class="faixaTop" [style.background-color]="dadosBarras.personalizarTotemValorCor" (click)="showPreview(dadosBarras.personalizarTotemParametro)">
      <div class="faixaMovimento">
        <div class="textoNaFaixa">
         <h1> {{ "paginas.mockup-totem.opcoes_atendimento.informacaoFaixaTop" | translate }}</h1>
        </div>
      </div>
      <span class="pulsating-circle"></span>
    </div>

    <div class="chamada" (click)="showPreview(dadosTitulo.personalizarTotemParametro)">
      <h1 class ="titleChamada" [style.color]="dadosTitulo.personalizarTotemValorCor">
        {{ dadosTitulo.personalizarTotemValorTituloPortugues}}
      </h1>
    </div>
    
    <ul>
      <li class="atendimento" [ngClass]="{'revistaAtiva': habilitaRevista}">
        <mat-card appearance="outlined" (click)="showPreview(dadosAtendimento.personalizarTotemParametro)">
          <div class="imagem"></div>
          <div class="descricao">
          <span class="pulsating-circle"></span>
                <h2> {{dadosAtendimento.personalizarTotemValorTituloPortugues}} </h2>
                <p> {{dadosAtendimento.personalizarTotemValorDescPortugues}} </p>
          </div>
        </mat-card>
      </li>

      <li class="incall" *ngIf="showCardLigacao" cdkDragBoundary=".opcoes" cdkDrag>
        <mat-card appearance="outlined">
          <div class="telefoneRemoto" [style.background-color]="dadosVideoRemoto.personalizarTotemValorCor" (click)="showPreview(dadosVideoRemoto.personalizarTotemParametro)">
            <span class="pulsating-circle"></span>
            <p>{{ "paginas.mockup-totem.opcoes_atendimento.atendente" | translate }}</p>
          </div>
          <div class="imagemSaw"></div>
          <div class="descricao">
                <div class="telefoneLocal" [style.background-color]="dadosVideoLocal.personalizarTotemValorCor" (click)="showPreview(dadosVideoLocal.personalizarTotemParametro)"><span class="pulsating-circle"></span></div>
                <div class="telefone" [style.background-color]="dadosTelefone.personalizarTotemValorCor" (click)="showPreview(dadosTelefone.personalizarTotemParametro)"><div class="chamando">{{ "paginas.mockup-totem.opcoes_atendimento.chamando" | translate }}</div><span class="pulsating-circle"></span></div>
          </div>
        </mat-card>
      </li>

      <li class="condicoes">
        <mat-card appearance="outlined" (click)="showPreview(dadosCondicoesRodovia.personalizarTotemParametro)">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosCondicoesRodovia.personalizarTotemValorCor">
              {{ dadosCondicoesRodovia.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
            <p> {{dadosCondicoesRodovia.personalizarTotemValorDescPortugues}} </p>
          </div>
        </mat-card>
      </li>

      <li class="destino" (click)="showPreview(dadosProcurarDestino.personalizarTotemParametro)">
        <mat-card appearance="outlined">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosProcurarDestino.personalizarTotemValorCor">
              {{dadosProcurarDestino.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
            <p> {{dadosProcurarDestino.personalizarTotemValorDescPortugues}} </p>
          </div>
        </mat-card>
      </li>

      <li class="telefones" (click)="showPreview(dadosTelefonesUteis.personalizarTotemParametro)">
        <mat-card appearance="outlined">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosTelefonesUteis.personalizarTotemValorCor">
              {{dadosTelefonesUteis.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
            <p> {{dadosTelefonesUteis.personalizarTotemValorDescPortugues}} </p>
          </div>
        </mat-card>
      </li>

      <li class="perguntas">
        <mat-card appearance="outlined" (click)="showPreview(dadosPerguntasFrequentes.personalizarTotemParametro)">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosPerguntasFrequentes.personalizarTotemValorCor">
              {{dadosPerguntasFrequentes.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
            <p>{{dadosPerguntasFrequentes.personalizarTotemValorDescPortugues}}</p>
          </div>
        </mat-card>
      </li>

      <li class="revista" [ngClass]="{'revistaAtiva': habilitaRevista}">
        <mat-card appearance="outlined" (click)="showPreview(dadosLeitorRevista.personalizarTotemParametro)">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosLeitorRevista.personalizarTotemValorCor">
              {{dadosLeitorRevista.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
          </div>
        </mat-card>
      </li>

      <li class="livro">
        <mat-card appearance="outlined" (click)="showPreview(dadosLivroRegistro.personalizarTotemParametro)">
          <div class="imagem"></div>
          <div class="descricao">
            <h2 [style.background-color]="dadosLivroRegistro.personalizarTotemValorCor">
              {{dadosLivroRegistro.personalizarTotemValorTituloPortugues}}
              <span class="pulsating-circle"></span>
            </h2>
          </div>
        </mat-card>
      </li>
    </ul>

    <div class="background-color-app" (click)="showPreview(dadosAplicacao.personalizarTotemParametro)">
      <span class="pulsating-circle"></span>
    </div>

    <div class="faixaFooter" [style.background-color]="dadosBarras.personalizarTotemValorCor" (click)="showPreview(dadosBarras.personalizarTotemParametro)">
      <div class="sawAddress">
        <i class="fas fa-map-pin"></i>
        <span>SAU 0 | BSO 0</span>
      </div>
        <span class="pulsating-circle"></span>
      <div class="info">
        <div class="dateTime">
          <i class="far fa-clock"></i> 00/00/0000 00:00
        </div>
      </div>
    </div>
  </div>

  <div class="mockupForm">
    <app-mockup-totem-form
      *ngIf="showForm"
      [registro]="dados"
      [habilitaRevista]="habilitaRevista"
      (cardAtendimentoOpen)="showCardLigacao = $event"
      (colorPickedCard)="setColorScreen($event)">
    </app-mockup-totem-form>
  </div>
</div>
