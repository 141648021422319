import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { IRevista } from '../models/IRevista';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';

const telaId = 43;
const apiTela = `Revista`;

@Injectable({
  providedIn: 'root'
})
export class RevistaService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;
  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';

  getGrid(): Observable<IRevista[]> {
    return this.http.get<IRevista[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IRevista) {
    return this.http.get<IRevista>(`${this.apiUrl}/Form/${dados.revistaId}`);
  }

  insert(dados: IRevista) {
    return this.http.post<IRevista>(`${this.apiUrl}`, dados);
  }

  update(dados: IRevista) {
    return this.http.put<IRevista>(`${this.apiUrl}`, dados);
  }

  delete(dados: IRevista) {
    return this.http.delete<IRevista>(`${this.apiUrl}/${dados.revistaId}`);
  }

  getMidia(revistaId: number, tipoMidia) {
    return this.http.get<File[]>(`${this.apiUrl}/revistaMidia/${revistaId}/${tipoMidia}`);
  }

  uploadMidia(revistaId: number, tipoMidia, uploadDados) {
    return this.http.post(`${this.apiUrl}/revistaMidia/${revistaId}/${tipoMidia}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data'}
    });
  }

  getExtensions(){
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`);
  }
  
  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

}
