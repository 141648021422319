import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';


import { IUsuario } from '../models/IUsuario';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 7;
const apiTela = `Usuario`;

@Injectable({ providedIn: 'root' })
export class UsuarioService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<IUsuario[]> {
    return this.http.get<IUsuario[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IUsuario) {
    return this.http.get<IUsuario>(`${this.apiUrl}/Form/${dados.usuarioId}`);
  }

  getById(dados: IUsuario) {
    return this.http.get<IUsuario>(`${this.apiUrl}/Form/${dados.usuarioId}`);
  }

  getExtensions() {
    return this.configuracaoService.getExtensions(telaId);
  }

  getMidia(dados: IUsuario) {
    return this.http.get<File[]>(`${this.apiUrl}/Midia/${dados.usuarioId}`);
  }

  uploadMidia(dados: IUsuario, uploadDados) {
    return this.http.put(`${this.apiUrl}/Midia/${dados.usuarioId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  insert(dados: IUsuario) {
    return this.http.post<IUsuario>(this.apiUrl, dados);
  }

  update(dados: IUsuario) {
    return this.http.put<IUsuario>(`${this.apiUrl}`, dados);
  }

  delete(dados: IUsuario) {
    return this.http.delete<IUsuario>(`${this.apiUrl}/${dados.usuarioId}`);
  }

  enable(dados: any) {
    return this.http.delete<IUsuario>(
      `${this.apiUrl}/Desbloquear/${dados.usuarioId}`
    );
  }

  disable(dados: any) {
    return this.http.post<IUsuario>(
      `${this.apiUrl}/Bloquear/${dados.usuarioId}`,
      dados
    );
  }

  resetPassword(dados: IUsuario) {
    return this.http.request<IUsuario>(
      'put',
      `${this.apiUrl}/ResetarSenha/${dados.usuarioId}`
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
