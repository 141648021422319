import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize, map } from 'rxjs/operators';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ISAWMensagem } from '../../models/ISAWMensagem';
import { AtendimentoService } from '../../services/atendimento.service';
import * as _ from 'underscore';
import { SAWMensagemService } from '../../services/saw-mensagem.service';

@Component({
  selector: 'app-atendimento-mensagens-grid',
  templateUrl: './atendimento-mensagens-grid.component.html',
  styleUrls: ['./atendimento-mensagens-grid.component.scss'],
})
export class AtendimentoMensagensGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ISAWMensagem[];

  @Input() selectedSaw: ISAW;
  wordsTranslated: any;

  constructor(
    private sawMensagemService: SAWMensagemService,
    private atendimentoService: AtendimentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService,
    private dialog: MatDialog,
  ) {
    this.loadWordsTranslate();
    this.refreshMensagensNovoEquip();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.mensagens-grid.';
    const words = {
      mensagens: '',
      iconeSrc: '',
      grupoMensagem: '',
      textoPortugues: '',
      dataHoraInicio: '',
      dataHoraFim: '',
      usuarioResponsavel: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  refreshMensagensNovoEquip() {
    this.atendimentoService.refreshMensagens.subscribe((result) => {
      this.configuraDadosGrid();
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.atendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: false,
      create: false,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: false,
      export: false,
      disable: false,
      enable: false,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'iconeSrc',
        label: this.wordsTranslated.iconeSrc,
        width: '20px',
        sort: false,
        type: 'image',
      },
      {
        name: 'grupoMensagem',
        label: this.wordsTranslated.grupoMensagem,
        width: '200px',
        sort: true,
      },
      {
        name: 'textoPortugues',
        label: this.wordsTranslated.textoPortugues,
        width: '',
        sort: true,
      },
      {
        name: 'dataHoraInicio',
        label: this.wordsTranslated.dataHoraInicio,
        width: '130px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
      {
        name: 'dataHoraFim',
        label: this.wordsTranslated.dataHoraFim,
        width: '130px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
      {
        name: 'usuarioResponsavel',
        label: this.wordsTranslated.usuarioResponsavel,
        width: '250px',
        sort: true,
      },
    ];
  }

  configuraDadosGrid(): void {
    this.atendimentoService
      .getMensagens(this.selectedSaw.sawId)
      .pipe(
        map((x) => {
          x.forEach(
            (i) => (i.iconeSrc = `assets/images/icon/atalhos/${i.iconeSrc}`)
          );
          return x;
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  confirmDel(dados: ISAWMensagem) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        let param = {
          sawId: this.selectedSaw.sawId,
          usuarioId: dados.usuarioId,
          mensagemId: dados.mensagemId
        }
        this.sawMensagemService
          .deleteMensagemSaw(param)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refresh()
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
        }
    });
  }

}
