<div class="controles" [ngClass]="vertical ? 'vertical' : ''">
  <div class="sliders-controles">
    <div class="volume">
      <div class="header-slider">
        <a (click)="setMudo()" class="button_volume">
          <span *ngIf="!mudo" class="material-icons">{{ iconeOn }}</span>
          <span *ngIf="mudo" class="material-icons">{{ iconeOff }}</span>
        </a>
        <p>Volume: {{ volumeNumber }}</p>
      </div>
      <div class="slider-component">
        <mat-slider
          class="slider-volume"
          [disabled]="disabled"
          [max]="max"
          [min]="min"
          [step]="step"
          [discrete]="thumbLabel"
          [showTickMarks]="showTicks"
          #ngSlider
        >
          <input
            matSliderThumb
            #ngSliderThumb="matSliderThumb"
            (change)="onChangeSlider(
              {
                source: ngSliderThumb,
                parent: ngSlider,
                value: ngSliderThumb.value
              }
            )"
            [(ngModel)]="volumeNumber"
          />
        </mat-slider>
      </div>
    </div>
  </div>
</div>
