import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IHistoricoAtendimentoMensagens } from '../models/IHistoricoAtendimentoMensagens';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { HistoricoAtendimentoService } from '../services/historico-atendimento.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';

@Component({
  selector: 'app-mensagens',
  templateUrl: './mensagens.component.html',
  styleUrls: ['./mensagens.component.scss']
})
export class MensagensComponent implements OnInit{

  public permissoesTela: IGridPermissoes;
  public acoesGridMensagens: IGridAcoes;
  public colunasGridMensagens: IGridModelo[];
  public dadosGridMensagens: IHistoricoAtendimentoMensagens[] = [];
  public isLoading: boolean;
  public wordsTranslated: any;

  @Input() atendimentoId: number;
  @Output() closeEvent = new EventEmitter<any>();

  constructor(
    private historicoAtendimentoService: HistoricoAtendimentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate(){
    const keyRoot = 'paginas.historico-atendimento.messages.';
    const words = {
      origem: '',
      nomeAtendente: '',
      dataHora: '',
      mensagem: '',
      tituloGrid: '',
      atendente: '',
      usuario: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    this.isLoading = true;

    this.historicoAtendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0]

        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
    })
  }

  configuraAcoesGrid(){
    this.acoesGridMensagens = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false
    }
  }

  configuraColunasGrid(){
    this.colunasGridMensagens =[
      {
        name: 'origem',
        label: this.wordsTranslated.origem,
        width: '70px',
        sort: true,
      },
      {
        name: 'atendente',
        label: this.wordsTranslated.nomeAtendente,
        width: '130px',
        sort: false,
      },
      {
        name: 'dataHora',
        label: this.wordsTranslated.dataHora,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
      {
        name: 'mensagem',
        label: this.wordsTranslated.mensagem,
        width: '',
        sort: false,
      }
    ]
  }

  configuraDadosGrid(){
    this.loadingService.show();
    this.historicoAtendimentoService
      .getGridMensagens(this.atendimentoId)
      .pipe(
        finalize(() =>
        this.loadingService.hide())
      )
      .subscribe(
        (response) => {
          this.dadosGridMensagens = response;

          this.dadosGridMensagens.forEach(mensagem => {
            mensagem.origem = mensagem.origem == "1" ? this.wordsTranslated.atendente : this.wordsTranslated.usuario
          });
        })
  }

  close() {
    this.closeEvent.emit();
  }

}
