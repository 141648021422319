import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


@Injectable({
  providedIn: 'root'
})
export class ComboProtocoloEmailService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}ComboProtocoloEmail`;

  getProtocolos(): Observable<ICombo[]> {
    return this.http.get<ICombo[]>(`${this.apiUrl}`);
  }
}
