import * as _ from 'underscore';
import { finalize, map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, Pipe } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IDestinosAtendimento } from '../../../../models/IDestinosAtendimento';
import { AtendimentoService } from '../../../../services/atendimento.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';

@Component({
  selector: 'app-atendimento-destino-grid',
  templateUrl: './atendimento-destinos-grid.component.html'
})
export class AtendimentoDestinosGridComponent implements OnInit {
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public showUpload = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IDestinosAtendimento[];
  public destinoId: number;

  @Input() sawId: number;
  @Input() atendimentoId: number;
  @Output() sendCordinates = new EventEmitter<IDestinosAtendimento>();

  wordsTranslated: any;
  alertaTitulo : string;
  alertaMensagem : string;


  constructor(
    private atendimentoService: AtendimentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.principal.destinos.grid.';
    const words = {
      alertaTitulo: '',
      alertaMensagem: '',
      enviarLocalizacao: '',
      visualizarMapa: '',
      destinoNome: '',
      destinoLatitude: '',
      destinoLongitude: '',
      grupoPortugues: '',
      iconeSrc: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
    this.alertaTitulo = this.wordsTranslated.alertaTitulo;
    this.alertaMensagem = this.wordsTranslated.alertaMensagem;
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.isLoading = true;
    this.atendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.editForm = this.permissoes.update;

        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      custom: [
        {
          icon: 'send',
          action: 'enviarLocalizacao',
          tooltip: this.wordsTranslated.enviarLocalizacao,
        },
        {
          icon: 'search',
          action: 'visualizarMapa',
          tooltip: this.wordsTranslated.visualizarMapa,
        },
      ],
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'iconeSrc',
        label: this.wordsTranslated.iconeSrc,
        width: '20px',
        sort: true,
        type: 'image',
      },
      {
        name: 'destinoNome',
        label: this.wordsTranslated.destinoNome,
        width: '',
        sort: true,
      },
      {
        name: 'grupoPortugues',
        label: this.wordsTranslated.grupoPortugues,
        width: '',
        sort: true,
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.atendimentoService
      .getDestinos(this.sawId)
      .pipe(
        map((x) => {
          x.forEach(
            (i) => (i.iconeSrc = `assets/images/icon/atalhos/${i.iconeSrc}`)
          );
          return x;
        }),
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }

  customEvent($event) {
    if ($event.action === 'enviarLocalizacao') {
      if(this.atendimentoId != null){
        this.insertDestinoAtendimento($event.row);
      } else {
        this.alertaService.atendimentoWarning(this.alertaTitulo, this.alertaMensagem);
      }
    }
    if ($event.action === 'visualizarMapa') {
      this.sendCondinatesToMap($event.row)
    }
  }

  insertDestinoAtendimento(destino: IDestinosAtendimento) {
    var dados = {
      atendimentoId: this.atendimentoId,
      destinoNome: destino.destinoNome,
      latitude: destino.destinoLatitude,
      longitude: destino.destinoLongitude,
    };

    const dialogConfirm = this.dialogService.confirmAction();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.atendimentoService
          .insertAtendimentoDestino(dados)
          .subscribe(() => {
            this.alertaService.sucesso();
          })
        }
      });
  }

  sendCondinatesToMap(destino: IDestinosAtendimento){
    this.sendCordinates.emit(destino);
  }
}
