import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { Globals } from 'src/app/app.globals';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { ILivroRegistro } from '../../models/ILivroRegistro';
import { LivroRegistroService } from '../../services/livro-registro.service';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-livro-registro-form',
  templateUrl: './livro-registro-form.component.html',
  styleUrls: ['./livro-registro-form.component.scss'],
})

export class LivroRegistroFormComponent implements OnInit {
  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: ILivroRegistro = null;

  @Output() closeEvent = new EventEmitter<string>();

  formulario: UntypedFormGroup;
  titleDialog: string;
  valueCombo: Array<any>;
  exibirFormulario: false;
  autoClose = true;
  formLoading = false;
  modalName: string = 'modalLivroRegistroForm';
  wordsTranslated: any;

  constructor(
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    public wordsTranslate: WordsTranslateService,
    public livroRegistroService: LivroRegistroService,
    public globals: Globals,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.livroRegistro.form.';
    let words = {
      registro: '',
      visualizar_registro: '',
      livro_id: '',
      livro_data_hora: '',
      prefixo: '',
      rodovia_sigla: '',
      km_local: '',
      livro_nome: '',
      livro_idade: '',
      livroemail: '',
      livroendereco: '',
      livro_complemento: '',
      livro_bairro: '',
      livro_cidade: '',
      livro_estado: '',
      livro_telefone: '',
      livro_observacao: '',
      veiculo_modelo: '',
      veiculo_cor: '',
      veiculo_marca: '',
      veiculo_placa: '',
      veiculo_ano: '',
      motivo_viagem: '',
      descricao_manifestacao: '',
      data_hora_ocorrencia: '',
      km_ocorrencia: '',
      cidade_ocorrencia: '',
      uf_origem: '',
      cidade_origem: '',
      uf_destino: '',
      cidade_destino: '',
      data_hora_gravacao: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    if (this.registro != null) {
      this.loadEditData();
    }
  }

  loadEditData() {
    this.livroRegistroService.getForm(this.registro).pipe(
      map((registro) => {
        const dateFields = ['dataHoraCriacao', 'dataHoraGravacao', 'dataHoraOcorrencia'];
        const format = 'dd/MM/yyyy HH:mm';
        dateFields.forEach(prop => {
          registro[prop] = this.datePipe.transform(registro[prop], format);
        });
        return registro;
      })
    ).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  close(): void {
    this.closeModalService.setStateModal(this.modalName);
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = `${this.wordsTranslated.visualizar_registro} ${this.registro.livroId}`;
    }
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      livroId: [
        this.registro != null && this.registro.livroId !== null
          ? this.registro.livroId
          : null,
      ],
      dataHoraCriacao: [
        this.registro != null && this.registro.dataHoraCriacao !== null
          ? this.registro.dataHoraCriacao
          : null,
      ],
      dataHoraOcorrencia: [
        this.registro != null && this.registro.dataHoraOcorrencia !== null
          ? this.registro.dataHoraOcorrencia
          : null,
      ],
      dataHoraGravacao: [
        this.registro != null && this.registro.dataHoraGravacao !== null
          ? this.registro.dataHoraGravacao
          : null,
      ],
      prefixo: [
        this.registro != null && this.registro.prefixo !== null
          ? this.registro.prefixo
          : null,
      ],
      veiculoModelo: [
        this.registro != null && this.registro.veiculoModelo !== null
          ? this.registro.veiculoModelo
          : null,
      ],
      veiculoMarca: [
        this.registro != null && this.registro.veiculoMarca !== null
          ? this.registro.veiculoMarca
          : null,
      ],
      veiculoPlaca: [
        this.registro != null && this.registro.veiculoPlaca !== null
          ? this.registro.veiculoPlaca
          : null,
      ],
      veiculoAno: [
        this.registro != null && this.registro.veiculoAno !== null
          ? this.registro.veiculoAno
          : null,
      ],
      veiculoCor: [
        this.registro != null && this.registro.veiculoCor !== null
          ? this.registro.veiculoCor
          : null,
      ],
      rodoviaSigla: [
        this.registro != null && this.registro.rodoviaSigla !== null
          ? this.registro.rodoviaSigla
          : null,
      ],
      motivoViagem: [
        this.registro != null && this.registro.motivoViagem !== null
          ? this.registro.motivoViagem
          : null,
      ],
      descricaoManifestacao: [
        this.registro != null && this.registro.descricaoManifestacao !== null
          ? this.registro.descricaoManifestacao
          : null,
      ],
      kmLocal: [
        this.registro != null && this.registro.kmLocal !== null
          ? this.registro.kmLocal
          : null,
      ],
      kmOcorrencia: [
        this.registro != null && this.registro.kmOcorrencia !== null
          ? this.registro.kmOcorrencia
          : null,
      ],
      livroNome: [
        this.registro != null && this.registro.livroNome !== null
          ? this.registro.livroNome
          : null,
      ],
      livroEmail: [
        this.registro != null && this.registro.livroEmail !== null
          ? this.registro.livroEmail
          : null,
      ],
      livroEndereco: [
        this.registro != null && this.registro.livroEndereco !== null
          ? this.registro.livroEndereco
          : null,
      ],
      livroBairro: [
        this.registro != null && this.registro.livroBairro !== null
          ? this.registro.livroBairro
          : null,
      ],
      livroCidade: [
        this.registro != null && this.registro.livroCidade !== null
          ? this.registro.livroCidade
          : null,
      ],
      cidadeOrigem: [
        this.registro != null && this.registro.cidadeOrigem !== null
          ? this.registro.cidadeOrigem
          : null,
      ],
      cidadeDestino: [
        this.registro != null && this.registro.cidadeDestino !== null
          ? this.registro.cidadeDestino
          : null,
      ],
      cidadeOcorrencia: [
        this.registro != null && this.registro.cidadeOcorrencia !== null
          ? this.registro.cidadeOcorrencia
          : null,
      ],
      ufOrigem: [
        this.registro != null && this.registro.ufOrigem !== null
          ? this.registro.ufOrigem
          : null,
      ],
      ufDestino: [
        this.registro != null && this.registro.ufDestino !== null
          ? this.registro.ufDestino
          : null,
      ],
      livroEstado: [
        this.registro != null && this.registro.livroEstado !== null
          ? this.registro.livroEstado
          : null,
      ],
      livroTelefone: [
        this.registro != null && this.registro.livroTelefone !== null
          ? this.registro.livroTelefone
          : null,
      ],
      sentidoOcorrencia: [
        this.registro != null && this.registro.sentidoOcorrencia !== null
          ? this.registro.sentidoOcorrencia
          : null,
      ]
    });

    this.configuraTitleDialog();
    this.loadingService.hide();
  }
}
