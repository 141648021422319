import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ISAW } from '../../models/ISaw';
import { EnvironmentService } from '../env/environment.service';

const telaId = 18;

@Injectable({
  providedIn: 'root'
})
export class SawEquipamentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}SAWEquipamento`;

  getSawGrid(): Observable<ISAW[]> {
    return this.http.get<ISAW[]>(`${this.apiUrl}/Grid`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
