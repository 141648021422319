import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';


import { IPermissaoUsuario } from '../models/IPermissaoUsuario';
import { IPermissaoUsuarioModulos } from '../models/IPermissaoUsuarioModulos';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const apiTela = `PermissaoUsuario`;

@Injectable({ providedIn: 'root' })
export class PermissaoUsuarioService {
  httpEmitter: Subscription;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  get(): Observable<IPermissaoUsuario[]> {
    return this.http.get<IPermissaoUsuario[]>(`${this.apiUrl}`);
  }

  getById(dados: IPermissaoUsuario) {
    return this.http.get<IPermissaoUsuario>(`${this.apiUrl}/${dados.usuarioId}`);
  }

  getModulos(usuarioId: number): Observable<IPermissaoUsuarioModulos[]> {
    return this.http.get<IPermissaoUsuarioModulos[]>(
      `${this.apiUrl}/Modulos/${usuarioId}`
    );
  }

  changePermissaoTela($usuarioId: number, $event): Observable<any> {
    let body = {
      usuarioId: $usuarioId,
      aplicacaoTelaId: $event.telaId,
      telaPermissaoId: $event.permissaoId,
      possuiPermissao: $event.permissaoStatus
    };

    return this.http.post<any>(`${this.apiUrl}/detalhe`, body);
  }

  changePermissaoTotal($usuarioId: number, $event): Observable<any> {
    let body = {
      usuarioId: $usuarioId,
      aplicacaoTelaId: $event.telaId,
      telaPermissaoId: $event.permissaoId,
      possuiPermissao: $event.permissaoStatus
    };
    return this.http.post<any>(`${this.apiUrl}/modulo`, body);
  }
  
  grantPermissionAllModule(usuarioId, aplicacaoModuloId, possuiPermissao){
    let body = {
      usuarioId: usuarioId,
      aplicacaoModuloId: aplicacaoModuloId,
      possuiPermissao: possuiPermissao
    };
    return this.http.post<any>(`${this.apiUrl}/TotalModulos`, body);
  }
}
