<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form
      id="frm-relatorio-telemetria"
      *ngIf="filterForm && showFilter"
      class="default"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >
    <div class="container-combo-saw">
      <ws-combo
        [focusMe]="false"
        [textoLabel]="wordsTranslated.sawId"
        [multiple]="true"
        [selecionarTodos]="true"
        [itemCtrl]="f.sawId"
        [items]="itensComboSAW"
        [itemSelecionado]="itemSelecionadoComboSAW"
        (changeEventCombo)="onChangeComboSaw($event)"
      >
      </ws-combo>
    </div>
      <div class="container-data-inicio">
        <mat-form-field appearance="outline">
          <mat-label>{{ wordsTranslated.dataInicio }}</mat-label>
          <input
            [focusMe]="true"
            matInput
            [matDatepicker]="pickerDataInicio"
            formControlName="dataInicio"
            (dateChange)="changeDateDe($event)"
            [max]="formValidParam.maxDateDe"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataInicio"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataInicio
            [startAt]="formValidParam.startDateDe"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-data-fim">
        <mat-form-field appearance="outline">
          <mat-label>{{ wordsTranslated.dataFim }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDataFim"
            formControlName="dataFim"
            [min]="formValidParam.minDateAte"
            [max]="formValidParam.maxDateAte"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataFim"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataFim
            [startAt]="formValidParam.startDateAte"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="button"
          (click)="export()"
          class="primary exportar"
          [disabled]="filterForm.invalid || desabilitarExport"
        >
          <i class="far fa-file-excel"></i> {{ "botoes.exportar" | translate }}
        </button>
      </div>
    </form>
  </div>

  <ng-container
    *ngIf="dadosRelatorio && dadosRelatorio.length > 0; else tmpEmpty"
  >
    <div class="subTabs">
      <mat-tab-group
      [(selectedIndex)]="showTabInicial"
      (selectedIndexChange)="onTabClick()"
        animationDuration="0ms"
        style="
          width: 100%;
          border-bottom: 1px solid var(--bg-tertiary-color);
          overflow: hidden;
        "
      >
        <mat-tab
          style="overflow: hidden"
          *ngFor="let equipamento of dadosRelatorio; let tabIndex = index"
          label="{{ equipamento.prefixo }} - {{
            equipamento.rodoviaSigla
          }} - Km: {{ equipamento.kmLocal }}"
        >
          <div class="informacoes">
            <div class="grid-detalhes">
              <div class="title">
                <p>{{ wordsTranslated.detalhes }}</p>
              </div>
              <div class="grid">
                <ws-grid
                  *ngIf="!isLoading"
                  [permissoes]="permissoesTela"
                  [isLoading]="isLoading"
                  [acoesGrid]="acoesGridDetalhe"
                  [focus]="focusFirstLine"
                  [disableRefresh]="filterForm.invalid"
                  [colunasGrid]="colunasGridDetalhes"
                  [registroPorPagina]="registroPorPagina"
                  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
                  [dadosGrid]="dadosRelatorio[tabIndex].perguntas"
                  [disableRefresh]="filterForm.invalid"
                  (refreshEvent)="refreshGridDetalhes()"
                ></ws-grid>
              </div>
            </div>
            <div class="dados">
              <div class="grid-resumo">
                <div class="title">
                  <p>{{ wordsTranslated.resumos }}</p>
                </div>
                <div class="grid">
                  <ws-grid
                    *ngIf="!isLoading"
                    [permissoes]="permissoesTela"
                    [isLoading]="isLoading"
                    [acoesGrid]="acoesGridResumo"
                    [colunasGrid]="colunasGridResumos"
                    [dadosGrid]="dadosRelatorio[tabIndex].resumos"
                    [registroPorPagina]="registroPorPagina"
                    [listaOpcaoPorPagina]="listaOpcaoPorPagina"
                    (refreshEvent)="refreshGridResumos()"
                  ></ws-grid>
                </div>
              </div>
              <div class="grafico-resumo">
                <div class="grafico">
                  <apx-chart
                    [title]="titlesCharts[equipamento.sawId][0].title"
                    [series]="resumoSeries[equipamento.sawId]"
                    [chart]="chartInfos[equipamento.sawId][0].chart"
                    [dataLabels]="chartOptions.dataLabels"
                    [plotOptions]="chartOptions.plotOptions"
                    [stroke]="chartOptions.stroke"
                    [legend]="chartOptions.legend"
                    [yaxis]="chartOptions.yaxis"
                    [xaxis]="xaxisDados[equipamento.sawId][0].xaxis"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>

  <ng-template #tmpEmpty>
    <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
    ></ws-no-content>
  </ng-template>
</div>
