import { MockupTotemGridComponent } from './../../page/saw/mockup-totem/grid/mockup-totem-grid.component';
import { Injectable } from '@angular/core';
import { Type } from '@angular/core';

import { TabsConfiguracaoSistemaTabComponent } from 'src/app/page/cadastro/configuracao-sistema/tabs/tabs-configuracao-sistema-tab.component';
import { ImportacaoAdTabComponent } from 'src/app/page/usuario/importacao-ad/tabs/importacao-ad-tab.component';
import { ContaEmailGridComponent } from 'src/app/page/cadastro/conta-email/grid/conta-email-grid.component';
import { EmpresaGridComponent } from 'src/app/page/cadastro/empresa/grid/empresa-grid.component';
import { MeusAtalhosGridComponent } from 'src/app/page/cadastro/meus-atalhos/grid/meus-atalhos-grid.component';
import { GrupoUsuarioGridComponent } from 'src/app/page/usuario/grupo-usuario/grid/grupo-usuario-grid.component';
import { PermissaoTabComponent } from 'src/app/page/usuario/permissao/tabs/permissao-tab.component';
import { UsuarioGridComponent } from 'src/app/page/usuario/usuarios/grid/usuario-grid.component';
import { RodoviaGridComponent } from 'src/app/page/cadastro/rodovia/grid/rodovia-grid.component';
import { UsuarioAtendenteGridComponent } from 'src/app/page/usuario/usuario-atendente/grid/usuario-atendente-grid.component';
import { SawTelefonesPrincipalGridComponent } from 'src/app/page/saw/telefones-uteis-equipamento/principal/grid/saw-telefones-principal-grid.component';
import { GrupoDestinoGridComponent } from 'src/app/page/cadastro/grupo-destino/grid/grupo-destino-grid.component';
import { SawDestinoPrincipalGridComponent } from 'src/app/page/saw/destino-equipamento/principal/grid/saw-destino-principal-grid.component';
import { SawPerguntaPrincipalGridComponent } from 'src/app/page/saw/perguntas-equipamento/principal/grid/saw-pergunta-principal-grid.component';
import { ArquivoPadraoTotemGridComponent } from 'src/app/page/cadastro/arquivo-padrao-totem/grid/arquivo-padrao-totem-grid.component';
import { EquipamentoGridComponent } from 'src/app/page/saw/equipamento/grid/equipamento-grid.component';
import { DestinoGridComponent } from 'src/app/page/cadastro/destino/grid/destino-grid.component';
import { GrupoMensagemGridComponent } from 'src/app/page/operacao/grupo-mensagem/grid/grupo-mensagem-grid.component';
import { LivroRegistroGridComponent } from 'src/app/page/cadastro/livro-registro/grid/livro-registro-grid/livro-registro-grid.component';
import { TelefonesUteisGridComponent } from 'src/app/page/cadastro/telefones-uteis/grid/telefones-uteis-grid.component';
import { PerguntasFrequentesGridComponent } from 'src/app/page/cadastro/perguntas-frequentes/grid/perguntas-frequentes-grid.component';
import { HistoricoMensagemGridComponent } from 'src/app/page/operacao/historico-mensagem/grid/historico-mensagem-grid.component';
import { ClickPerguntasGridComponent } from 'src/app/page/relatorio/click-perguntas/grid/click-perguntas-grid.component';
import { UltimoStatusGridSawComponent } from 'src/app/page/saw/ultimo-status/grid-saw-principal/ultimo-status-grid-saw.component';
import { ClickGrupoDestinoGridComponent } from 'src/app/page/relatorio/click-grupo-destino/grid/click-grupo-destino-grid.component';
import { TipoAtendimentoGridComponent } from 'src/app/page/operacao/tipo-atendimento/grid/tipo-atendimento-grid.component';
import { ClickClassificacaoAtendimentoGridComponent } from 'src/app/page/relatorio/click-classificacao-atendimento/grid/click-classificacao-atendimento-grid.component';
import { ClickAtendimentoIdiomaGridComponent } from 'src/app/page/relatorio/click-atendimento-idioma/grid/click-atendimento-idioma-grid.component';
import { AtendimentoPrincipalComponent } from 'src/app/page/operacao/atendimento/principal/grid/atendimento-principal.component';
import { GravacoesVideosGridComponent } from 'src/app/page/saw/gravacoes-videos/grid/gravacoes-videos-grid.component';
import { DisponibilidadeConexaoGridComponent } from 'src/app/page/relatorio/disponibilidade-conexao/grid/disponibilidade-conexao-grid.component';
import { HistoricoAtendimentoGridComponent } from 'src/app/page/operacao/historico-atendimento/grid/historico-atendimento-grid.component';
import { DiagnosticoGravacoesGridComponent } from 'src/app/page/operacao/diagnostico-gravacoes/grid/diagnostico-gravacoes-grid.component';
import { MapaOperacionalComponent } from 'src/app/page/operacao/mapa-operacional/mapa-operacional.component';
import { ModuloMenuPrincipalGridComponent } from 'src/app/page/configuracoes/seguranca/principal/grid/modulo-menu-principal-grid.component';
import { VandalismoSolicAtendimentoGridComponent } from 'src/app/page/relatorio/vandalismo-solic-atendimento/grid/vandalismo-solic-atendimento-grid.component';
import { DisponibilidadeSIPGridComponent } from 'src/app/page/relatorio/disponibilidade-SIP/grid/disponibilidade-SIP-grid.component';
import { VersaoTotemPrincipalComponent } from 'src/app/page/cadastro/versao-totem/principal/versao-totem-principal.component';
import { AlertaGridComponent } from 'src/app/page/configuracoes/alerta/grid/alerta-grid.component';
import { RevistaGridComponent } from 'src/app/page/cadastro/revista/grid/revista-grid.component';
import { TipoLivroRegistroGridComponent } from 'src/app/page/cadastro/tipo-livro-registro/grid/tipo-livro-registro-grid.component';

//TODO
@Injectable()
export class AdService {
  getAds() {
    return [
      new AdItem('TConfiguracaoSistema', TabsConfiguracaoSistemaTabComponent),
      new AdItem('TUsuario', UsuarioGridComponent),
      new AdItem('TMeusAtalhos', MeusAtalhosGridComponent),
      new AdItem('TPermissao', PermissaoTabComponent),
      new AdItem('TUsuario', UsuarioGridComponent),
      new AdItem('TGrupoUsuario', GrupoUsuarioGridComponent),
      new AdItem('TContaEmail', ContaEmailGridComponent),
      new AdItem('TEmpresa', EmpresaGridComponent),
      new AdItem('TRodovia', RodoviaGridComponent),
      new AdItem('TImportacaoAD', ImportacaoAdTabComponent),
      new AdItem('TUsuarioxAtendente', UsuarioAtendenteGridComponent),
      new AdItem('TEquipamentoxTelefonesUteis', SawTelefonesPrincipalGridComponent),
      new AdItem('TEquipamentoxDestino', SawDestinoPrincipalGridComponent),
      new AdItem('TEquipamentoxPerguntaFrequente', SawPerguntaPrincipalGridComponent),
      new AdItem('TGrupoDestino', GrupoDestinoGridComponent),
      new AdItem('TArquivoPadraoTotem', ArquivoPadraoTotemGridComponent),
      new AdItem('TSAW', EquipamentoGridComponent),
      new AdItem('TDestino', DestinoGridComponent),
      new AdItem('TGrupoMensagem', GrupoMensagemGridComponent),
      new AdItem('TTelefonesUteis', TelefonesUteisGridComponent),
      new AdItem('TPerguntaFrequente', PerguntasFrequentesGridComponent),
      new AdItem('TLivrodeRegistro', LivroRegistroGridComponent),
      new AdItem('TTipoLivrodeRegistro', TipoLivroRegistroGridComponent),
      new AdItem('THistoricoMensagem', HistoricoMensagemGridComponent),
      new AdItem('TAtendimento', AtendimentoPrincipalComponent),
      new AdItem('TClickPerguntaFrequente', ClickPerguntasGridComponent),
      new AdItem('TClickGrupoDestino', ClickGrupoDestinoGridComponent),
      new AdItem('TClickClassificacaoAtendimento', ClickClassificacaoAtendimentoGridComponent),
      new AdItem('TClickAtendimentoIdioma', ClickAtendimentoIdiomaGridComponent),
      new AdItem('TUltimoStatus', UltimoStatusGridSawComponent),
      new AdItem('TTipoAtendimento', TipoAtendimentoGridComponent),
      new AdItem('TGravacoesVideo', GravacoesVideosGridComponent),
      new AdItem('TDisponibilidadeConexao', DisponibilidadeConexaoGridComponent),
      new AdItem('TGravacoesVideo', GravacoesVideosGridComponent),
      new AdItem('THistoricoAtendimento', HistoricoAtendimentoGridComponent),
      new AdItem('TDiagnosticoGravacoes', DiagnosticoGravacoesGridComponent),
      new AdItem('TPersonalizar', MockupTotemGridComponent),
      new AdItem('TMapa', MapaOperacionalComponent),
      new AdItem('TSeguranca', ModuloMenuPrincipalGridComponent),
      new AdItem('RVandalismoSolicitacaoAtendimento', VandalismoSolicAtendimentoGridComponent),
      new AdItem('RDisponibilidadeSIP', DisponibilidadeSIPGridComponent),
      new AdItem('TPersonalizar', MockupTotemGridComponent),
      new AdItem('TVersaoTotem', VersaoTotemPrincipalComponent),
      new AdItem('TAlerta', AlertaGridComponent),
      new AdItem('TRevistas', RevistaGridComponent),
    ];
  }
}
export class AdItem {
  constructor(public name: string, public component: Type<any>) {}
}
