import { EventEmitter, SimpleChanges } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { SawEquipamentoService } from 'src/app/shared/services/saw-grid/saw-equipamento.service';
import { SoftPhoneService } from 'src/app/shared/services/softPhone/soft-phone.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { AtendimentoService } from '../../services/atendimento.service';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';

@Component({
  selector: 'app-saw-atendimento-grid',
  templateUrl: './saw-atendimento-grid.component.html',
})
export class SawAtendimentoGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ISAW[];

  @Output() clickRow = new EventEmitter<any>();
  @Output() showFormAtendimento = new EventEmitter<boolean>();
  @Output() showFormMensagemAviso = new EventEmitter<boolean>();

  wordsTranslated: any;

  constructor(
    private configService : ConfiguracaoService,
    private sawEquipamentoService: SawEquipamentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private atendimentoService: AtendimentoService,
    private softPhoneService: SoftPhoneService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.shared.grid-saw.';
    const words = {
      equipamentos: '',
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      registroAtendimento: '',
      enviarMensagemAviso: '',
      ligar: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.sawEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];

        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });

      this.atendimentoService.showFormAtendimentoLigacao.subscribe((r) => {
        let saw = this.dadosGrid.filter((result) => result.numeroSerie == r);

        if (saw[0]) {
          this.clickRow.emit(saw[0]);
          this.showFormAtendimento.next();
        }

        this.checkEmLigacao();
      });
  }

  configuraAcoesGrid(): void {
    let custom = [
      {
        icon: 'group_add',
        action: 'registroAtendimento',
        tooltip: this.wordsTranslated.registroAtendimento,
      },
      {
        icon: 'message',
        action: 'enviarMensagemAviso',
        tooltip: this.wordsTranslated.enviarMensagemAviso,
      },
    ];

    let sipConfig = this.configService.getSip();
    let softPhone = localStorage.getItem(environment.prefixo + 'softPhone');
    if (softPhone === 'true' && sipConfig.sipEnable) {
      custom.push({
        icon: 'call',
        action: 'ligar',
        tooltip: this.wordsTranslated.ligar,
      });
    }

    this.acoesGrid = {
      show: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,
      enable: false,
      keyboardNavigation: false,
      custom,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        width: '',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '',
        sort: true,
      },
    ];
  }

  checkEmLigacao() {
    let numero = this.softPhoneService.getNumero();
    let saw = this.dadosGrid.filter((result) => {
      return result.ramal == numero;
    });
    if (saw[0]) {
      this.clickRow.emit(saw[0]);
      this.showFormAtendimento.next();
    }
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.sawEquipamentoService
      .getSawGrid()
      .pipe(
        finalize(() => {
          this.checkEmLigacao();
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  sendSawId($event) {
    this.clickRow.emit($event);
    this.atendimentoService.refreshMensagens.next();
  }

  customEvent($event) {
    if ($event.action === 'registroAtendimento') {
      this.sawEquipamentoService.getSawGrid().subscribe((response) => {
        let saw;
        response.filter((value) => {
           if(value.numeroSerie == $event.row.numeroSerie) saw = value;
        });
        this.clickRow.emit(saw);
        this.showFormAtendimento.next();
      });
    }
    if ($event.action === 'enviarMensagemAviso') {
      this.showFormMensagemAviso.next();
    }
    if ($event.action === 'ligar') {
      this.softPhoneService.newCallSoftPhone.emit($event.row.ramal);
      this.softPhoneService.setChamadaEquipamento($event.row.numeroSerie);
    }
  }
}
