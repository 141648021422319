import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IMapaOperacionalEquipamentoInfo } from '../models/IMapaOperacionalEquipamentoInfo';

@Component({
  selector: 'app-mapa-operacional-informacoes-equipamento',
  templateUrl: './mapa-operacional-informacoes-equipamento.component.html',
  styleUrls: ['./mapa-operacional-informacoes-equipamento.component.scss']
})
export class MapaOperacionalInformacoesEquipamentoComponent implements OnInit {
  @Input() dados: IMapaOperacionalEquipamentoInfo & {
    statusOnline: boolean,
    statusFalha: boolean
  } 

  public wordsTranslated: any

  constructor(
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.cdr.detectChanges();
  }
}
