import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { IShortCut } from '../models/IShortCut';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const prefixoShortCut = `${environment.prefixoShortCut}`;

@Injectable({ providedIn: 'root' })
export class ShortCutService {
  changeShortCut = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}AplicacaoAtalho`;

  get(usuarioId: number) {
    const options = {
      params: new HttpParams().set('usuarioId', usuarioId.toString())
    };

    return this.http.get<IShortCut>(this.apiUrl, options);
  }

  change() {
    this.changeShortCut.emit(true);
  }

  getAllShortCut(): IShortCut[] {
    let shortCuts = localStorage.getItem(environment.prefixo + 'shortCut');
    return JSON.parse(shortCuts);
  }

  getShortCut(key: string) {
    let shortCuts = localStorage.getItem(environment.prefixo + 'shortCut');
    let shortCut = JSON.parse(shortCuts);

    return shortCut
      .filter(function(obj) {
        let getKey = `${prefixoShortCut}+${key}`.trim().toLowerCase();
        let objKey = obj.teclaAtalho.trim().toLowerCase();

        if (getKey === objKey) {
          return obj;
        }
      })
      .map(function(obj) {
        return obj;
      });
  }
}
