<div class="grids">
  <div class="grid-saw">
    <div class="title">
      <p>Equipamentos</p>
    </div>
    <div class="grid">      
      <app-saw-equipamento-grid
        (clickRow)="setSawId($event)"
      ></app-saw-equipamento-grid>
    </div>
  </div>
  <div class="grid-destinos">
    <app-destinos-equipamento-grid
      *ngIf="showGridTelefones"
      [selectedSaw]="selectedSaw"
    ></app-destinos-equipamento-grid>
    <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
      *ngIf="!showGridTelefones"
    ></ws-no-content>
  </div>
</div>
