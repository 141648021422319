import { Component, OnInit } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { IUltimoStatusSaw } from '../models/IUltimoStatusSaw';
import { UltimoStatusService } from '../services/ultimo-status.service';

@Component({
  selector: 'app-ultimo-status-grid-saw',
  templateUrl: './ultimo-status-grid-saw.component.html',
  styleUrls: ['./ultimo-status-grid-saw.component.scss']
})
export class UltimoStatusGridSawComponent implements OnInit {
  public isLoading: boolean;
  public permissoesTela: IGridPermissoes;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IUltimoStatusSaw[];
  public focusFirstLine: boolean = false;
  public showStatusGrid = false;
  public sawId: number;
  public dadosStatus: IUltimoStatusSaw;

  wordsTranslated: any;

  constructor(
    private wordsTranslate: WordsTranslateService,
    private ultimoStatusService: UltimoStatusService,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.ultimo-status.gridSaw.';
    let words = {
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      titleNoContent: '',
      textNoContent: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.ultimoStatusService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
       })
     )
     .subscribe((response) => {
       this.permissoesTela = response[0];
       this.configuraAcoesGrid();
       this.configuraColunasGrid();
       this.configuraDadosGrid();
     })
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      read: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      name: 'grid-equipamento-ultimo-status'
    }
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [

      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        sort: true,
        width: ''
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.ultimoStatusService
      .getGridPrincipal()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  rowEvent($event) {
    this.dadosStatus = $event;
    this.sawId = this.dadosStatus.sawId;
    this.showStatusGrid = true;
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 500);
  }
}
