// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // URL of production API
  prefixo: 'ws_saw_',
  tema: 'black',
  idioma: 'pt-BR',
  // apiUrl: 'https://dev-sawng-api.writesys.com.br/',
  // hubUrl: 'https://dev-sawng-hub.writesys.com.br/',
  registroPorPagina: 20,
  listaOpcaoPorPagina: [20, 50, 100],
  prefixoShortCut: 'ALT',
  portaAPIEquipamento: 81,
  portaConfigEquipamento: 82,
  refreshpMap: 900000,
  mapIconAnimationDuration: 60000,
  passwordAdminSystem: '123',
  uploadAtendimento: 'Uploads/Atendimento/',
  uploadDiagnostico: 'Uploads/Diagnostico/',
  uploadRevista:'Uploads/Revistas',
  uploadRevistaImagem: 'Uploads/Revistas/IMAGEM/',
  uploadRevistaPdf: 'Uploads/Revistas/PDF'
};

export const notificationIcon ={
  falha: 'assets/images/icon/notificacao/warning.png',
  recuperacaoFalha: 'assets/images/icon/notificacao/success.png',
  recuperacaoOnline: 'assets/images/icon/notificacao/online.png',
  offline: 'assets/images/icon/notificacao/offiline.png',
  vandalismo: 'assets/images/icon/notificacao/warning.png',
  sawIcon: 'assets/images/logo-topo-saw.svg',
  ligacao: 'assets/images/icon/notificacao/ligacao.png'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

