<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form
      id="frm-filter"
      *ngIf="filterForm && showFilter"
      class="default"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >
      <div class="container-combo-saw">
        <ws-combo
          [textoLabel]="'paginas.historico-atendimento.grid.saw' | translate"
          [multiple]="true"
          [selecionarTodos]="true"
          [itemCtrl]="f.sawId"
          [items]="itensComboSaw"
          [itemSelecionado]="itemSelecionadoComboSaw"
          (changeEventCombo)="changeComboSaw($event)"
        >
        </ws-combo>
      </div>

      <div class="container-data-inicio">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.historico-atendimento.grid.dataInicio" | translate
          }}</mat-label>
          <input
            [focusMe]="true"
            matInput
            [matDatepicker]="pickerDataInicio"
            formControlName="dataInicio"
            (dateChange)="changeDateDe($event)"
            [max]="formValidParam.maxDateDe"
          />
          <mat-datepicker-toggle matSuffix [for]="pickerDataInicio">
          </mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataInicio
            [startAt]="formValidParam.startDateDe"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-data-fim">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.historico-atendimento.grid.dataFim" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDataFim"
            formControlName="dataFim"
            [min]="formValidParam.minDateAte"
            [max]="formValidParam.maxDateAte"
          />
          <mat-datepicker-toggle matSuffix [for]="pickerDataFim">
          </mat-datepicker-toggle>
          <mat-datepicker
            #pickerDataFim
            [startAt]="formValidParam.startDateAte"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="button"
          (click)="export()"
          class="primary exportar"
          [disabled]="filterForm.invalid || desabilitarExport"
        >
          <i class="far fa-file-excel"></i> {{ "botoes.exportar" | translate }}
        </button>
      </div>
    </form>
  </div>

  <div class="content" *ngIf=" dadosGridAtendimento && dadosGridAtendimento.length > 0; else tmpEmpty">
    <div class="subTabs">
      <mat-tab-group
        [(selectedIndex)]="showTabInicial"
        (selectedTabChange)="onTabClick()"
        animationDuration="0ms"
        style="
          width: 100%;
          border-bottom: 1px solid var(--bg-tertiary-color);
          overflow: hidden;
        "
      >
        <mat-tab
          style="overflow: hidden"
          *ngFor="let equipamento of dadosGridAtendimento; let tabIndex = index"
          label="{{ equipamento.prefixo }} - {{
            equipamento.rodoviaSigla
          }} - Km: {{ equipamento.kmLocal }}"
        >
          <div class="informacoes">
            <div class="grid-atendimentos">
              <div class="title-grids">
                {{
                  "paginas.historico-atendimento.grid.atendimentos" | translate
                }}
              </div>
              <div class="grid-atendimento">
                <ws-grid
                  *ngIf="!isLoading"
                  [permissoes]="permissoesTela"
                  [isLoading]="isLoading"
                  [focus]="focusFirstLineAtendimentoGrid"
                  [acoesGrid]="acoesGridAtendimentos"
                  [colunasGrid]="colunasGridAtendimentos"
                  [registroPorPagina]="registroPorPagina"
                  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
                  [disableRefresh]="filterForm.invalid"
                  [dadosGrid]="dadosGridAtendimento[tabIndex].atendimentos"
                  (refreshEvent)="refreshGridAtendimentos()"
                  (rowEvent)="rowEvent($event)"
                >
                </ws-grid>
              </div>
            </div>
            <div
              class="grid-destinos"
              *ngIf="clickedAtendimento; else tmpEmptyDestinos"
            >
              <div class="title-grids">
                {{
                  "paginas.historico-atendimento.grid.destinos" | translate
                }}
                - Atendimento: {{ atendimentoId }}
              </div>
              <div class="grid-destino">
                <ws-grid
                  [permissoes]="permissoesTela"
                  [isLoading]="isLoading"
                  [acoesGrid]="acoesGridDestinos"
                  [colunasGrid]="colunasGridDestinos"
                  [dadosGrid]="dadosGridDestino"
                  [registroPorPagina]="registroPorPagina"
                  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
                  (refreshEvent)="refreshGridDestinos()"
                >
                </ws-grid>
              </div>
              <div class="title-grids">
                {{
                  "Vídeos e Chats"
                }}
                - Atendimento: {{ atendimentoId }}
              </div>
              <div class="grid-gravacao">
                <ws-grid
                  [permissoes]="permissoesTela"
                  [isLoading]="isLoading"
                  [acoesGrid]="acoesGridGravacoes"
                  [colunasGrid]="colunasGridGravacoes"
                  [dadosGrid]="dadosGridGravacoes"
                  (refreshEvent)="refreshGridGravacoes()"
                  (customEvent)="openVideoOrMessages($event)"
                >
                </ws-grid>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <app-mensagens 
    *ngIf="showMessages"
    [atendimentoId]="atendimentoId"
    (closeEvent)="closeMessages()">
  </app-mensagens>

  <div class="container-video" *ngIf="videoPath">
    <button mat-icon-button (click)="videoPath = ''">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path fill="white" d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
      </svg>
    </button>
    <video #videoElement [src]="videoPath" autoplay controls cdkDrag></video>
  </div>

  <ng-template #tmpEmpty>
    <div class="grid-noContent">
      <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
    ></ws-no-content>
    </div>
  </ng-template>

  <ng-template #tmpEmptyDestinos>
    <div class="grid-noContent">
      <ws-no-content
      [title]="titleNoContentDestinos"
      [text]="textNoContentDestinos"
    ></ws-no-content>
    </div>
  </ng-template>
</div>
