import { SimpleChanges } from '@angular/core';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IDestino } from '../../models/IDestino';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-destino-mapa-component',
  templateUrl: './destino-mapa.component.html'
})
export class DestinoMapaComponent implements OnInit {
  @ViewChild('mapRef', { static: true }) mapElement: ElementRef;
  @ViewChild('latitudeInput', { static: true }) latitudeInput: ElementRef;
  @ViewChild('longitudeInput', { static: true }) longitudeInput: ElementRef;

  @Input() destinoId: number;
  @Input() icon: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() hasCoordinates: boolean;

  @Output() mapReady = new EventEmitter<boolean>(true);
  @Output() eventDragMap = new EventEmitter<any>();

  osmServerUrl: string = "";

  public formulario: UntypedFormGroup;
  public statusBotao: IDialogButton;
  public formLoading = false;

  private marker;
  private mapObj;

  constructor(
    private env: EnvironmentService
  ) {
    this.osmServerUrl = env.osmServerUrl
  }

  ngOnInit() {
    this.createMap();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['latitude'] || changes['longitude']){
      this.createMap();
    }
  }

  createMap() {
    if (this.mapObj) {
      this.mapObj.remove();
    }
    const options = {
      zoom: 14,
      center: [this.latitude, this.longitude],
      layers: [
        L.tileLayer(this.osmServerUrl, {
          attribution: '© OpenStreetMap contributors'
        })
      ],
    };

    this.mapObj = L.map(this.mapElement.nativeElement, options);

    this.addMarkerToMap();
  }

  addMarkerToMap() {
    var cordinate = {
      lat: this.latitude,
      lng: this.longitude
    };
    if (this.icon != null) {
      const icon = L.icon({
        iconUrl: `assets/images/icon/atalhos/${this.icon}`,
        iconSize: [40, 40],
        iconAnchor:[20, 40],
        popupAnchor: [0, -40]
      });
      
      this.marker = L.marker(cordinate, {
        title: 'Título do marcador',
        draggable: false,
        zIndexOffset: 1,
        icon: icon
      }).addTo(this.mapObj);
    } else {
      const icon = new L.Icon.Default();
      icon.options.shadowSize = [0,0];
      this.marker = L.marker(cordinate, {
        title: 'Título do marcador',
        draggable: false,
        zIndexOffset: 1,
        icon: icon
      }).addTo(this.mapObj);
    }
    this.mapObj.setView(cordinate, this.mapObj.getZoom());
  }
}
