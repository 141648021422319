import { Component, Input, OnInit } from '@angular/core';

import * as _ from 'underscore';

import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IRodoviaCoordenadas } from '../../../models/IRodoviaCoordenadas';
import { RodoviaCoordenadaService } from '../../../services/rodovia-coordenada.service';
import { IRodovia } from '../../../models/IRodovia';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';

@Component({
  selector: 'app-rodovia-importar-kml-grid',
  templateUrl: './rodovia-importar-kml-grid.component.html',
  styleUrls: ['./rodovia-importar-kml-grid.component.scss']
})
export class RodoviaImportarKmlGridComponent implements OnInit {
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IRodoviaCoordenadas[] = [];

  @Input() dadosRodovia: IRodovia;

  wordsTranslated: any;

  acceptExtensions: string = '*';
  showUpload = false;
  statusUpload = false;
  listErrosUpload = [];

  listFiles = [];

  constructor(
    private rodoviaCoordenadaService: RodoviaCoordenadaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private imageZoomService: ImageZoomService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.rodovia.cadastro.importarKml.grid.';
    const words = {
      latitude: '',
      longitude: '',
      kmNominal: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.rodoviaCoordenadaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.editForm = this.permissoes.update;

        this.loadExtensions();
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  loadExtensions() {
    this.rodoviaCoordenadaService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true
    };
  }

  showPreview(image) {
    this.imageZoomService.show(image);
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'rodoviaCoordenadaKmNominal',
        label: this.wordsTranslated.kmNominal,
        width: '100px',
        sort: true
      },
      {
        name: 'coordenadaLatitude',
        label: this.wordsTranslated.latitude,
        width: '',
        sort: true
      },
      {
        name: 'coordenadaLongitude',
        label: this.wordsTranslated.longitude,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.rodoviaCoordenadaService
      .getGrid(this.dadosRodovia)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        rodoviaId: dados.rodoviaId
      });
    }

    this.dadosGrid = this.gridService.refreshGridData(
      this.dadosGrid,
      dados,
      indexPage,
      tipo
    );
  }

  uploadFiles(uploadDados) {
    this.listErrosUpload = [];
    this.statusUpload = true;
    const req = this.rodoviaCoordenadaService.uploadMidia(
      uploadDados.formData,
      this.dadosRodovia.rodoviaId
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUpload.push(element.detail);
          });
        }
        this.listFiles = [];
        this.alertaService.sucesso();
        this.statusUpload = false;
        this.refresh();
        //this.rodoviaCoordenadaService.changeWallpaper.emit(true);
      },
      (error: any) => {
        this.statusUpload = false;
        this.alertaService.erro();
      }
    );
  }
}
