import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../../../environments/environment';
import { IGrupoDestino } from './../models/IGrupoDestino';
import { AuthService } from './../../../../shared/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 12;

@Injectable({ providedIn: 'root'})
export class GrupoDestinoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}GrupoDestino`;

  getGrid(): Observable<IGrupoDestino[]> {
    return this.http.get<IGrupoDestino[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IGrupoDestino) {
    return this.http.get<IGrupoDestino>(`${this.apiUrl}/Form/${dados.grupoId}`);
  }

  insert(dados: IGrupoDestino) {
    return this.http.post<IGrupoDestino>(`${this.apiUrl}`, dados);
  }

  update(dados: IGrupoDestino) {
    return this.http.put<IGrupoDestino>(`${this.apiUrl}`, dados);
  }

  delete(dados: IGrupoDestino) {
    return this.http.delete<IGrupoDestino>(`${this.apiUrl}/${dados.grupoId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
