import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { IComboSelect } from 'src/app/shared/components/combo-select/combo-select.interface';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


@Injectable({ providedIn: 'root' })
export class SawComboService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = this.env.apiUrl + 'SawCombo';

  listComboSelect(): Observable<IComboSelect[]> {
    return this.http.get<IComboSelect[]>(`${this.apiUrl}`);
  }
}
