import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, Pipe } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { LivroRegistroService } from '../../../services/livro-registro.service';
import { ILivroRegistroEnvios } from '../../../models/ILivroRegistroEnvios';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { ILivroRegistro } from '../../../models/ILivroRegistro';

@Component({
  selector: 'app-livro-registro-envios-grid',
  templateUrl: './livro-registro-envios-grid.component.html',
  styleUrls: ['./livro-registro-envios-grid.component.scss']

})
export class LivroRegistroEnviosGridComponent implements OnInit {
  public isLoading: boolean;

  public permissoes: IGridPermissoes;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ILivroRegistroEnvios[];

  titleDialog: string;

  @Input() registro: ILivroRegistro;
  wordsTranslated: any;

  @Output() closeEvent = new EventEmitter<string>();
  modalName: string = 'modalLivroRegistroFormEnvios';

  constructor(
    private livroEnvioService: LivroRegistroService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private closeModalService: CloseModalService,
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  confirmClose() {
    this.closeEvent.next();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.livroRegistro.gridenvios.';
    const words = {
      visualizar_registro_envios: ' ',
      filaEnvioId: ' ',
      nroMaximoTentativa: ' ',
      meioEnvioId: ' ',
      statusId: ' ',
      dataHoraSolicitacao: ' ',
      meioEnvioDesc: ' ',
      status: ' ',
      emailDe: ' ',
      emailPara: ' ',
      emailCC: ' ',
      emailBCC: ' ',
      emailAssunto: ' ',
      emailHTML: ' ',
      configSMTP: ' ',
      configPorta: ' ',
      configUsuario: ' ',
      configSenha: ' ',
      configProtocolo: ' ',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.getPermissoes();
  }

  getPermissoes() {
    this.loadingService.show();
    this.isLoading = true;
    this.livroEnvioService.getPermissoes().pipe(
      finalize(() => {
        this.configuraTitleDialog();
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      })
    ).subscribe((response) => {
      this.permissoes = response[0];
    });
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = `${this.wordsTranslated.visualizar_registro_envios} ${this.registro.livroId}`;
    }
  }

  close(): void {
    this.closeModalService.setStateModal(this.modalName);
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'filaEnvioId',
        label: this.wordsTranslated.filaEnvioId,
        width: '75px',
        sort: true,
      },
      {
        name: 'dataHoraSolicitacao',
        label: this.wordsTranslated.dataHoraSolicitacao,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm'
      },
      {
        name: 'status',
        label: this.wordsTranslated.status,
        width: '150px',
        sort: true,
      },
      {
        name: 'meioEnvioDesc',
        label: this.wordsTranslated.meioEnvioDesc,
        width: '150px',
        sort: true,
      },
      {
        name: 'nroTentativa',
        label: this.wordsTranslated.nroMaximoTentativa,
        width: '100px',
        sort: true,
      },
      {
        name: 'emailDe',
        label: this.wordsTranslated.emailDe,
        width: '200px',
        sort: true,
      },
      {
        name: 'emailPara',
        label: this.wordsTranslated.emailPara,
        width: '200px',
        sort: true,
      },
      {
        name: 'emailAssunto',
        label: this.wordsTranslated.emailAssunto,
        width: '',
        sort: true,
      },
      {
        name: 'emailCC',
        label: this.wordsTranslated.emailCC,
        width: '',
        sort: true,
      },
      {
        name: 'emailBCC',
        label: this.wordsTranslated.emailBCC,
        width: '',
        sort: true,
      },
    ];
  }

  configuraDadosGrid(): void {
    this.isLoading = true;
    this.loadingService.show();
    this.livroEnvioService
      .getGridEnv(this.registro.filaEnvioId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }
}
