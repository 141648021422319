<div class="modal" modal-name="modalGrupoMensagemForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag style="width: 40%;">
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
    <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
     <div class="modal-content">
      <ws-combo class="fieldIconeSrc"
          [disabled]="!permissoes['update'] && registro"
          [focusMe]="disableComboTela()|| !disableComboTela() "
          [textoLabel]="'paginas.grupo-mensagem.form.iconeSrc' | translate"
          [multiple]="false"
          [selecionarTodos]="false"
          [itemCtrl]="f.iconeId"
          [items]="itensComboIcone"
          [itemSelecionado]="itemSelecionadoComboIcone"
          (changeEventCombo)="changeComboIcone($event)">
      </ws-combo>

      <mat-form-field class="fieldGrupoMensagem" appearance="outline">
          <mat-label>{{ 'paginas.grupo-mensagem.form.grupoMensagem' | translate }}</mat-label>
          <input matInput
          placeholder="{{'paginas.grupo-mensagem.form.grupoMensagem' | translate}}"
          autocomplete="off"
          formControlName="grupoMensagem" />
      </mat-form-field>
     </div>

     <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

      <div class="modal-actions">
        <div></div>
        <div class="buttons">
          <button mat-button type="button" class="primary add" *ngIf="permissoes['create']" (click)="add()"
            [disabled]="!statusBotao.adicionar">
            <i class="material-icons">add</i> <span class="text">{{ 'botoes.adicionar' | translate }}</span>
          </button>

          <button mat-button type="button" class="primary delete" *ngIf="permissoes['delete']" (click)="del()"
            [disabled]="!statusBotao.deletar">
            <i class="material-icons">delete</i> <span class="text">{{ 'botoes.deletar' | translate }}</span>
          </button>

          <button mat-button type="button" class="primary cancel"
            *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)" (click)="cancel()"
            [disabled]="!statusBotao.cancelar">
            <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
          </button>

          <button type="submit" mat-button class="save"
            *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)"
            [disabled]="formulario.invalid || !statusBotao.salvar">
            <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
         </button>
        </div>
      </div>
    </form>
    </div>
  </div>
</div>
