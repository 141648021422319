<div class="modal" modal-name="modalContaEmailForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
        <div class="modal-content">
          <input type="hidden" formControlName="contaEmailId" />

          <mat-form-field class="fieldSmtp" appearance="outline">
            <mat-label>
              {{'paginas.contaEmail.form.smtp' | translate}}
            </mat-label>
            <input [focusMe]='focusSmtp' matInput placeholder="{{'paginas.contaEmail.form.smtp' | translate}}"
              formControlName="contaEmailSMTP" />
          </mat-form-field>

          <mat-form-field class="fieldPorta" appearance="outline">
            <mat-label>
              {{'paginas.contaEmail.form.porta' | translate}}
            </mat-label>
            <input matInput placeholder="{{'paginas.contaEmail.form.porta' | translate}}"
              formControlName="contaEmailPorta" />
          </mat-form-field>

          <mat-form-field class="fieldUsuario" appearance="outline">
            <mat-label>
              {{'paginas.contaEmail.form.usuario' | translate}}
            </mat-label>
            <input matInput placeholder="{{'paginas.contaEmail.form.usuario' | translate}}"
              formControlName="contaEmailUsuario" />
          </mat-form-field>

          <mat-form-field class="fieldEmail" appearance="outline">
            <mat-label>
              {{'paginas.contaEmail.form.email' | translate}}
            </mat-label>
            <input matInput placeholder="{{'paginas.contaEmail.form.email' | translate}}"
            autocomplete="new-email"  formControlName="contaEmailEmail" />
          </mat-form-field>

          <mat-form-field class="fieldSenha" hidePassword appearance="outline">
            <mat-label>
              {{'paginas.contaEmail.form.senha' | translate}}
            </mat-label>
            <input type="password" matInput placeholder="{{'paginas.contaEmail.form.senha' | translate}}"
            autocomplete="new-password" formControlName="contaEmailSenha" />
          </mat-form-field>

          <ws-combo class="fieldProtocolo" [textoLabel]="'paginas.contaEmail.form.protocolo' | translate" [multiple]="false"
            [selecionarTodos]="false" [itemCtrl]="f.protocoloId" [items]="itensComboProtocolo"
            [itemSelecionado]="itemSelecionadoProtocolo" (changeEventCombo)="onChangeComboCulture($event)">
          </ws-combo>

        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button mat-button type="button" class="primary cancel"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)" (click)="cancel()"
              [disabled]="!statusBotao.cancelar">
              <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
            </button>
            <button type="submit" mat-button class="save"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)"
              [disabled]="formulario.invalid || !statusBotao.salvar">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
