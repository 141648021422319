<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoes"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [focus]="focusFirstLine"
  (refreshEvent)="refresh()"
  (editEvent)="openForm($event)"
  (addEvent)="openForm()"
  (deleteEvent)="confirmDel($event)"
></ws-grid>

<app-rodovia-form-component
  *ngIf="showForm"
  [permissoes]="permissoes"
  [registro]="dadosFormulario"
  (onChange)="refresh()"
  (onDelete)="confirmDel($event)"
  (closeEvent)="closeForm()"
>
</app-rodovia-form-component>
