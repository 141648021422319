import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';


import { IImportacaoAd } from '../models/IImportacaoAd';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IImportacaoAdUsuarios } from '../models/IImportacaoAdUsuarios';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 9;
const apiTela = `ImportacaoAd`;

@Injectable({ providedIn: 'root' })
export class ImportacaoAdService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<IImportacaoAd[]> {
    return this.http.get<IImportacaoAd[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IImportacaoAd) {
    return this.http.get<IImportacaoAd>(
      `${this.apiUrl}/Form/${dados.usuarioADParametroId}`
    );
  }

  insert(dados: IImportacaoAd) {
    return this.http.post<IImportacaoAd>(this.apiUrl, dados);
  }

  update(dados: IImportacaoAd) {
    return this.http.put<IImportacaoAd>(`${this.apiUrl}`, dados);
  }

  delete(dados: IImportacaoAd) {
    return this.http.delete<IImportacaoAd>(
      `${this.apiUrl}/${dados.usuarioADParametroId}`
    );
  }

  getUsuariosGrid(
    configuracaoAdId: number
  ): Observable<IImportacaoAdUsuarios[]> {
    return this.http.get<IImportacaoAdUsuarios[]>(
      `${this.apiUrl}/LinkedServer/${configuracaoAdId}`
    );
  }
  insertUsuariosAd(usuarios: IImportacaoAdUsuarios[], grupoUsuarioId: number) {
    let novoUsuario: any;
    let dadosUsuarios = [];
    usuarios.forEach((item) => {
      novoUsuario = {
        nomeCompleto: item.nomeCompleto,
        login: item.login,
        email: item.email,
        loginAD: item.loginAD,
        grupoUsuarioId: grupoUsuarioId
      };
      dadosUsuarios.push(novoUsuario);
    });

    return this.http.post<IImportacaoAdUsuarios>(
      `${this.apiUrl}/Importacao`,
      dadosUsuarios
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
