import { Component, OnInit } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ArquivoPadraoTotemService } from '../services/arquivo-padrao-totem.service';
import { error } from 'console';

@Component({
  selector: 'app-arquivo-padrao-totem-grid',
  templateUrl: './arquivo-padrao-totem-grid.component.html',
  styleUrls: ['./arquivo-padrao-totem-grid.component.scss'],
})
export class ArquivoPadraoTotemGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  acceptExtensions: string = '*';
  acceptExtensionsAudio: string = '.mp3,.wav';
  showUploadDescanso = false;
  showUploadAtendimento = false;
  showUploadLocalizacao = false;
  showUploadAudioEspera = false;
  statusUploadDescanso = false;
  statusUploadAtendimento = false;
  statusUploadLocalizacao = false;
  statusUploadAudioEspera = false;
  listErrosUploadDescanso = [];
  listErrosUploadAtendimento = [];
  listErrosUploadLocalizacao = [];
  listErrosUploadAudioEspera = [];
  editForm: boolean = false;
  filesDescanso: File[] = [];
  filesAtendimento: File[] = [];
  filesLocalizacao: File[] = [];
  filesAudioEspera: File[] = [];

  wordsTranslated: any;

  constructor(
    private arquivoPadraoTotemService: ArquivoPadraoTotemService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private imageZoomService: ImageZoomService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.arquivo-padrao-totem.';
    const words = {
      descansoTela: '',
      atendimentoIndisponivel: '',
      mapaOffline: '',
      audioEspera: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadFilesDesacanso();
    this.loadFilesAtendimento();
    this.loadFilesLocalizacao();
    this.loadFilesAudioEspera();
    this.loadExtensions();

    this.arquivoPadraoTotemService.getPermissoes().subscribe((result) => {
      this.permissoesTela = result[0];
      this.editForm = this.permissoesTela.update;
    });
  }

  loadFilesDesacanso() {
    const midiaDescanso = 1;
    this.arquivoPadraoTotemService.getMidia(midiaDescanso).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesDescanso = response;
            this.showUploadDescanso = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadFilesAtendimento() {
    const midiaAtendimento = 2;
    this.arquivoPadraoTotemService.getMidia(midiaAtendimento).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesAtendimento = response;
            this.showUploadAtendimento = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadFilesLocalizacao() {
    const midiaLocalizacao = 3;
    this.arquivoPadraoTotemService.getMidia(midiaLocalizacao).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesLocalizacao = response;
            this.showUploadLocalizacao = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadFilesAudioEspera() {
    const midiaAudioEspera = 4;
    this.arquivoPadraoTotemService.getMidia(midiaAudioEspera).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesAudioEspera = response;
            this.showUploadAudioEspera = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    )
  }

  loadExtensions() {
    this.arquivoPadraoTotemService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });
  }

  uploadFilesDescanso(uploadDados) {
    const midiaDescanso = 1;
    this.listErrosUploadDescanso = [];
    this.statusUploadDescanso = true;
    const req = this.arquivoPadraoTotemService.uploadMidia(
      uploadDados.formData,
      midiaDescanso
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadDescanso.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadDescanso = false;
        this.loadFilesDesacanso();
      },
      (error: any) => {
        this.statusUploadDescanso = false;
        this.alertaService.erro();
      }
    );
  }

  uploadFilesAtendimento(uploadDados) {
    const midiaAtendimento = 2;
    this.listErrosUploadAtendimento = [];
    this.statusUploadAtendimento = true;
    const req = this.arquivoPadraoTotemService.uploadMidia(
      uploadDados.formData,
      midiaAtendimento
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadAtendimento.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadAtendimento = false;
        this.loadFilesAtendimento();
      },
      (error: any) => {
        this.statusUploadAtendimento = false;
        this.alertaService.erro();
      }
    );
  }

  uploadFilesLocalizacao(uploadDados) {
    const midiaLocalizacao = 3;
    this.listErrosUploadLocalizacao = [];
    this.statusUploadLocalizacao = true;
    const req = this.arquivoPadraoTotemService.uploadMidia(
      uploadDados.formData,
      midiaLocalizacao
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadLocalizacao.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadLocalizacao = false;
        this.loadFilesLocalizacao();
      },
      (error: any) => {
        this.statusUploadLocalizacao = false;
        this.alertaService.erro();
      }
    );
  }

  uploadFilesAudioEspera(uploadDados) {
    const midiaAudioEspera = 4;
    this.listErrosUploadAudioEspera = [];
    this.statusUploadAudioEspera = true;
    const req = this.arquivoPadraoTotemService.uploadMidia(
      uploadDados.formData,
      midiaAudioEspera
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadAudioEspera.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadAudioEspera = false;
        this.loadFilesAudioEspera();
      },
      (error: any) => {
        this.statusUploadAudioEspera = false;
        this.alertaService.erro();
      }
    )
  }

  showPreview(image) {
    this.imageZoomService.show(image);
  }
}
