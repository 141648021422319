<div class="grid">
<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [registroPorPagina]="registroPorPagina"
  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
  (refreshEvent)="refresh()"
  (addEvent)="openForm()"
  (editEvent)="openForm($event)"
  (rowEvent)="rowEvent($event)"
  (fileEvent)="openUpload($event)"
></ws-grid>
</div>
