import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ISaw } from 'src/app/page/saw/equipamento/models/ISaw';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { GrupoMensagemCombo } from 'src/app/shared/services/combo/grupo-mensagem.combo';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { SawEquipamentoService } from 'src/app/shared/services/saw-grid/saw-equipamento.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { AtendimentoService } from '../../services/atendimento.service';
import { SAWMensagemService } from '../../services/saw-mensagem.service';

@Component({
  selector: 'app-mensagem-aviso-form',
  templateUrl: './mensagem-aviso-form.component.html',
  styleUrls: ['./mensagem-aviso-form.component.scss'],
})
export class MensagemAvisoFormComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoes: IGridPermissoes;
  public isLoading: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public statusBotao: IDialogButton;
  public dadosGrid: ISAW[];
  public itensComboGrupoMensagem: ICombo[];
  public itemSelecionadoComboGrupoMensagem: any;
  public formLoading = false;
  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  @Input() equipamento: ISaw;
  @Output() closeEvent = new EventEmitter<string>();

  formulario: UntypedFormGroup;
  showForm: boolean = false;
  modalName: string = 'modalMensagemAvisoEquipamentoForm';
  wordsTranslated: any;
  wordsTranslatedForm: any;
  equipamentosSelecionados: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private sawEquipamentoService: SawEquipamentoService,
    private sawMensagemService: SAWMensagemService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private closeModalService: CloseModalService,
    private atendimentoService: AtendimentoService,
    private grupoMensagemCombo: GrupoMensagemCombo
  ) {
    this.loadWordsTranslateGrid();
    this.loadWordsTranslateFormulario();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslateGrid() {
    const keyRoot = 'paginas.shared.grid-saw.';
    const words = {
      equipamentos: '',
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  loadWordsTranslateFormulario() {
    const keyRoot = 'paginas.atendimento.principal.mensagem-aviso.form.';
    const words = {
      grupoMensagemId: '',
      textoPortugues: '',
      textoEspanhol: '',
      textoIngles: '',
      dataHoraInicio: '',
      horaInicio: '',
      dataHoraFim: '',
      horaFim: '',
      alertaTitulo: '',
      alertaMensagem: '',
    };
    this.wordsTranslatedForm = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    this.isLoading = true;
    this.sawEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
        this.carregaComboGrupoMensagem();
        this.criarFormulario();
      });
  }

  carregaComboGrupoMensagem() {
    const listComboGrupoMensagem = this.grupoMensagemCombo.listComboSelect();

    forkJoin([listComboGrupoMensagem]).subscribe((results) => {
      this.itensComboGrupoMensagem = results[0];
    });
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      grupoMensagemId: [null, Validators.compose([Validators.required])],
      textoPortugues: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(3000)]),
      ],
      textoEspanhol: ['', Validators.compose([Validators.maxLength(3000)])],
      textoIngles: ['', Validators.compose([Validators.maxLength(3000)])],
      dataInicio: [
        this.formValidParam.startDateDe,
        Validators.compose([Validators.required]),
      ],
      horaInicio: ['', Validators.compose([Validators.required])],
      dataFim: [
        this.formValidParam.startDateAte,
        Validators.compose([Validators.required]),
      ],
      horaFim: ['', Validators.compose([Validators.required])],
      sawId: [
        this.equipamentosSelecionados,
        Validators.compose([Validators.required]),
      ],
    });

    this.configuracaAcoesBotoes();
    this.dirtyFormButtonsState();
    this.showForm = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: false,
      deletar: false,
      cancelar: false,
      salvar: false,
    };
  }

  save(): void {
    this.formLoading = true;
    let valuesForm = this.formulario.value;

    let valid = this.checkHourAndDate();

    if (valid) {
      var datePipe = new DatePipe('en-US');
      var dataInicio = datePipe.transform(valuesForm.dataInicio, 'yyyy-MM-dd');
      var dataFim = datePipe.transform(valuesForm.dataFim, 'yyyy-MM-dd');

      valuesForm.dataHoraInicio = `${dataInicio}T${valuesForm.horaInicio}`;
      valuesForm.dataHoraFim = `${dataFim}T${valuesForm.horaFim}`;

      this.sawMensagemService
        .insertMensagemSaw(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.confirmClose();
          this.atendimentoService.refreshMensagens.next();
        });
    } else {
      var timeOut = 5000;
      this.alertaService.Warning(
        this.wordsTranslatedForm.alertaTitulo,
        this.wordsTranslatedForm.alertaMensagem,
        timeOut
      );
      this.formLoading = false;
    }
  }

  close() {
    this.closeModalService.setStateModal(this.modalName);
  }

  checkHourAndDate(): boolean {
    let dataInicio = new Date(this.f.dataInicio.value);
    let dataFim = new Date(this.f.dataFim.value);

    if (dataInicio.getTime() === dataFim.getTime()) {
      let horaMinutoInicio = this.f.horaInicio.value;
      let horaMinutoFim = this.f.horaFim.value;

      let partsHoraInicio = horaMinutoInicio.split(':');
      let partsHoraFim = horaMinutoFim.split(':');

      let horaInicio = +partsHoraInicio[0];
      let minutosInicio = +partsHoraInicio[1];

      let horaFim = +partsHoraFim[0];
      let minutosFim = +partsHoraFim[1];

      if (
        horaFim < horaInicio ||
        (horaFim == horaInicio && minutosFim < minutosInicio)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  get f() {
    return this.formulario.controls;
  }

  onChangeComboGrupoMensagem($event) {
    this.itemSelecionadoComboGrupoMensagem = $event;

    this.formulario.patchValue({
      grupoMensagemId: this.itemSelecionadoComboGrupoMensagem,
    });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,
      enable: false,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'toggleSelect',
        label: '',
        width: '50px',
        sort: true,
        type: 'toogle',
        toogleAction: 'selecionarEquipamento',
        toogleHide: false,
      },
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        width: '',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '',
        sort: true,
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.sawEquipamentoService
      .getSawGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.dadosGrid.forEach((equipamento) => {
            if (equipamento.sawId == this.equipamento.sawId) {
              equipamento.toggleSelect = true;
              this.equipamentosSelecionados.push(equipamento.sawId);
              this.formulario.patchValue({
                sawId: this.equipamentosSelecionados,
              });
            }
          });
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  toogleEvent($event) {
    if ($event.action === 'selecionarEquipamento') {
      if ($event.status) {
        this.equipamentosSelecionados.push($event.row.sawId);
      }
      if (!$event.status) {
        var index = this.equipamentosSelecionados.indexOf($event.row.sawId);
        if (index !== -1) {
          this.equipamentosSelecionados.splice(index, 1);
        }
      }
      this.formulario.patchValue({
        sawId: this.equipamentosSelecionados,
      });
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  changeDateDe($event) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 60);
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.formulario.patchValue({
      dataHoraFim: maxDateAte,
    });
  }

  setInitialDataFilters() {
    let dataAtual = new Date(Date.now());
    let dataInicio = new Date(Date.now());
    dataInicio.setDate(dataAtual.getDate() - 60);

    this.formValidParam.startDateDe = dataInicio;
    this.formValidParam.minDateAte = dataInicio;
    this.formValidParam.startDateAte = dataAtual;

    this.formulario.patchValue({
      dataHoraInicio: dataInicio,
      dataHoraFim: dataAtual,
    });
  }
}