import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { IEmpresa } from '../models/IEmpresa';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 1;
const apiTela = `Empresa`;

@Injectable({ providedIn: 'root' })
export class EmpresaService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env : EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<IEmpresa[]> {
    return this.http.get<IEmpresa[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IEmpresa) {
    return this.http.get<IEmpresa>(`${this.apiUrl}/Form/${dados.empresaId}`);
  }

  getMidia(dados: IEmpresa) {
    return this.http.get<File[]>(`${this.apiUrl}/Midia/${dados.empresaId}`);
  }

  getExtensions() {
    return this.configuracaoService.getExtensions(telaId);
  }


  uploadMidia(dados: IEmpresa, uploadDados) {
    return this.http.put(`${this.apiUrl}/Midia/${dados.empresaId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  insert(dados: IEmpresa) {
    return this.http.post<IEmpresa>(this.apiUrl, dados);
  }

  update(dados: IEmpresa) {
    return this.http.put<IEmpresa>(`${this.apiUrl}`, dados);
  }

  delete(dados: IEmpresa) {
    return this.http.delete<IEmpresa>(`${this.apiUrl}/${dados.empresaId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
