import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IParametrosLogConfiguracaoSistema } from '../models/IParametrosLogConfiguracaoSistema';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ParametrosLogConfiguracaoSistemaService } from '../services/parametros-log-configuracao-sistema.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-parametros-log-configuracao-sistema-grid',
  templateUrl: './parametros-log-configuracao-sistema-grid.component.html'
})
export class ParametrosLogConfiguracaoSistemaGridComponent implements OnInit {
  public isLoading: boolean;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public focusFirstLine: boolean = false;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IParametrosLogConfiguracaoSistema[];
  public dadosFormulario: IParametrosLogConfiguracaoSistema;

  wordsTranslated: any;

  constructor(
    private parametrosLogConfiguracaoSistemaService: ParametrosLogConfiguracaoSistemaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    public gridService: WsGridService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.configuracaoSistema.parametrosLog.grid.';
    const words = {
      data: '',
      usuario: '',
      descricaoParametro: '',
      valorAntigo: '',
      valorNovo: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.configuraAcoesGrid();
    this.configuraColunasGrid();
    this.configuraDadosGrid();
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: false,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'dataHora',
        label: this.wordsTranslated.data,
        width: '150px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm:ss'
      },
      {
        name: 'usuarioNome',
        label: this.wordsTranslated.usuario,
        width: '280px',
        sort: true
      },
      {
        name: 'parametroSistemaDescricao',
        label: this.wordsTranslated.descricaoParametro,
        width: '350px',
        sort: true
      },
      {
        name: 'valorAntigo',
        label: this.wordsTranslated.valorAntigo,
        width: '200px',
        sort: true
      },
      {
        name: 'valorNovo',
        label: this.wordsTranslated.valorNovo,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.parametrosLogConfiguracaoSistemaService
      .get()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 500);
  }
}
