import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IMenu } from '../../../shared/models/IMenu';
import { IUser } from '../../models/IUser';

import { AuthService } from '../auth/auth.service';
import { EnvironmentService } from '../env/environment.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }

  apiUrl = this.env.apiUrl + 'menu';

  public getMenus(dadosUsuario: IUser) {
    const http$ = this.http.get<IMenu>(`${this.apiUrl}/${dadosUsuario.dados.id}`);
    return http$;
  }
}
