<div class="grids">
  <div class="grid-versoes">
    <div class="title">
      <p>Versões</p>
    </div>
    <div class="grid">
      <app-versoes-totem-grid
        (onActionForm)="onActionForm($event)"
        (onActionRow)="onActionRow($event)"
        (onActionUpload)="onActionUpload($event)"
      >
      </app-versoes-totem-grid>
    </div>
  </div>
  <div class="grid-equipamentos">
    <div class="title">
      <p>Equipamentos</p>
    </div>
    <div class="grid">
      <app-equipamentos-versao-totem-grid
        [paramsVersaoTotem]="dadosVersaoTotem"
        [hasArquivoInVersion]="hasArquivoInVersion"
        (equipamentosSelecionados)="onChangeEquipamentos($event)"
      ></app-equipamentos-versao-totem-grid>
    </div>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="formLoading"
    ></mat-progress-bar>
    <div class="actions">
      <button
        type="button"
        mat-button
        class="save"
        (click)="save(equipamentosVersaoTotem)"
        [disabled]="equipamentosVersaoTotem.length <= 0 || !hasArquivoInVersion"
      >
        <i class="material-icons">check</i>
        <span class="text">{{
          "paginas.equipamentosVersaoTotem.atualizarEquipamentos" | translate
        }}</span>
      </button>
    </div>
  </div>
</div>

<app-versao-totem-form
  *ngIf="showForm"
  [editForm]="editForm"
  [permissoes]="permissoesTela"
  [registro]="dadosVersaoTotem"
  (closeEvent)="closeForm()"
>
</app-versao-totem-form>

<app-versao-totem-upload
  *ngIf="showUpload"
  [editForm]="editForm"
  [dados]="dadosVersaoTotem"
  (closeEvent)="closeUpload()"
  (fileUpload)="fileVersaoUpload($event)"
>
</app-versao-totem-upload>
