import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { SoftPhoneService } from 'src/app/shared/services/softPhone/soft-phone.service';

import { ISoftPhoneEquipammento } from 'src/app/shared/models/ISoftPhoneEquipammento';
import { Subscription, interval } from 'rxjs';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ControleVolumeService } from 'src/app/shared/services/controle-volume/controle-volume.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

interface VideoElement extends HTMLVideoElement {
  requestPictureInPicture(): any;
}

@Component({
  selector: 'app-window-soft-phone',
  templateUrl: './window-soft-phone.component.html',
  styleUrls: ['./window-soft-phone.component.scss'],
})
export class WindowSoftPhoneComponent implements OnInit, OnDestroy {
  wordsTranslated: any;

  @ViewChild('numberRamalTransferElement')
  numberRamalTransferElement: ElementRef;
  showBoxTransfer = false;
  numberRamalTransfer: number = null;

  timeCall: any = 0;
  num;
  subscription!: Subscription;

  volumeInicial = 0.6;
  microfoneInicial = 0.3;
  equipamento: ISoftPhoneEquipammento;
  showPhone = true;
  statusChamada = '';
  showVideoLocal = true;
  showVideoRemote = true;
  onHold = false;

  statusMic = true;
  statusCam = true;

  videoRemote: any;
  videoLocal: any;
  audioRemote: any;

  // showBoxHelp = false;
  ramalToCall;

  volumeAudioRemoto: number;
  volumeMicRemoto: number;

  tokenView: any;

  audio_remote!: HTMLAudioElement;
  @ViewChild('audio_remote') set audio_remoteRef(
    ref: ElementRef<HTMLAudioElement>
  ) {
    this.audio_remote = ref.nativeElement;
  }

  video_remote!: HTMLAudioElement;
  @ViewChild('video_remote') set video_remoteRef(
    ref: ElementRef<HTMLAudioElement>
  ) {
    this.video_remote = ref.nativeElement;
  }

  video_local!: HTMLAudioElement;
  @ViewChild('video_local') set video_localRef(
    ref: ElementRef<HTMLAudioElement>
  ) {
    this.video_local = ref.nativeElement;
  }

  // MONITORA AS TECLA PRECIONADAS
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.altKey) {
      let key = event.code.replace('Digit', '');
      key = key.replace('Key', '');

      if (key == 'M') {
        this.mute();
      }
      if (key == 'X') {
        this.sipHangUp();
      }
      if (key == 'T') {
        this.transfer();
      }
      if (key == 'P') {
        this.sipHold();
      }
      // if (key == 'H') {
      //   this.showHelp();
      // }
    }
  }

  constructor(
    private softPhoneService: SoftPhoneService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private controleVolumeService: ControleVolumeService,
    private authService: AuthService
  ) {
    const keyRoot = 'paginas.soft-phone.';
    const words = {
      equipamentoNaoIdentificado: '',
      atendimentoLimitado: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }
  ngOnDestroy(){
    if (this.tokenView) {
      clearInterval(this.tokenView);
    }
  }

  ngOnInit() {
    this.sipCall();
    this.softPhoneService.ramalToCall.subscribe((ramal) => {
      this.sipCall(ramal);
    });
    this.tokenView = setInterval(() => {
      this.verificaToken()
    }, 10000);
  }

  verificaToken(){
    this.authService.validaToken().subscribe((result) => {});
  }

  sipCall = (callToRamal = null) => {
    let ramal = callToRamal;
    if (ramal == null) ramal = this.softPhoneService.getNumero();
    if (ramal && ramal != null && ramal != '') {
      this.softPhoneService
        .getEquipamento(ramal)
        .subscribe((result: ISoftPhoneEquipammento) => {
          if (result) {
            this.equipamento = result;
          } else {
            this.alertaService.atendimentoWarning(
              "Equipamento não identificado",
              "O atendimento vai ser limitado devido a falta de identificação."
            );
          }

          this.softPhoneService.sipCall(this.video_remote, this.video_local, callToRamal);
          this.video_local.volume = 0;
          this.initCountTimeCall();
          this.initVolume();
        });
    }
  };

  transfer() {
    this.showBoxTransfer = !this.showBoxTransfer;
    setTimeout(() => {
      this.numberRamalTransferElement.nativeElement.focus();
    }, 300);
  }
  confirmTranfer() {
    this.softPhoneService.sipTransfer(this.numberRamalTransfer);
  }

  sipHold() {
    this.onHold = !this.onHold;
    this.softPhoneService.sipToggleHoldResume();

    this.softPhoneService.setHold(8).toPromise()
  }

  sipHangUp() {
    this.softPhoneService.sipHangUp();
  }

  mute() {
    this.statusMic = !this.statusMic;
    this.statusCam = !this.statusCam;
    this.softPhoneService.mute(this.statusMic);
  }

  offMic() {
    this.statusMic = !this.statusMic;
    this.softPhoneService.offMic(this.statusMic);
  }

  offCam() {
    this.statusCam = !this.statusCam;
    this.softPhoneService.offCam(this.statusCam);
  }

  setVolumeRemoto() {
    this.controleVolumeService.setVolumeEquipamento(
      this.volumeAudioRemoto.toString(),
      this.volumeMicRemoto.toString(),
      this.equipamento.numeroSerie,
    );
  }

  setVolumeMicRemoto($event) {
    let set = parseFloat($event) * 10;
    if(set != this.volumeMicRemoto){
      this.volumeMicRemoto = set;
      this.setVolumeRemoto();
    }
  }
  setVolumeAudioRemoto($event) {
    let set = parseFloat($event) * 10;
    if(set != this.volumeAudioRemoto){
      this.volumeAudioRemoto = set;
      this.setVolumeRemoto();
    }
  }

  setVolumeAudioLocal($event) {
    if(this.video_remote){
      this.video_remote.volume = $event;
    }
  }

  remoteFullScreen() {
    this.video_remote.requestFullscreen();
  }

  // showHelp() {
  //   this.showBoxHelp = !this.showBoxHelp;
  // }

  initVolume(){
    this.volumeAudioRemoto = this.volumeInicial * 10;
    this.volumeMicRemoto = this.microfoneInicial * 10;
    this.setVolumeRemoto();
  }

  initCountTimeCall() {
    this.timeCall = 0;
    this.num = interval(1000);
    this.subscription = this.num.subscribe((contador) => {
      var dateObj = new Date(contador * 1000);
      var hours = dateObj.getUTCHours();
      var minutes = dateObj.getUTCMinutes();
      var seconds = dateObj.getSeconds();
      var timeString =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0');
      this.timeCall = timeString;

      if (!this.showPhone) {
        this.timeCall = 0;
        this.subscription.unsubscribe();
      }
    });
  }
}
