import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IHistoricoAtendimentoSaw } from '../models/IHistoricoAtendimentoSaw';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { IHistoricoAtendimentoMensagens } from '../models/IHistoricoAtendimentoMensagens';

const telaId = 23;

@Injectable({
  providedIn: 'root'
})
export class HistoricoAtendimentoService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}RHistoricoAtendimento`;

  getGridMensagens(atendimentoId: number){
    return this.http.get<IHistoricoAtendimentoMensagens[]>(`${this.apiUrl}/Mensagens/${atendimentoId}`);
  }

  get(dataInicio,
      dataFim,
      sawId: any[]
  ): Observable<IHistoricoAtendimentoSaw[]> {
    var datePipe = new DatePipe("en-US");
    dataInicio = datePipe.transform(dataInicio, 'yyyy-MM-dd');
    dataFim = datePipe.transform(dataFim, 'yyyy-MM-dd');

    let params = new HttpParams()
    .set('dataInicio', dataInicio)
    .set('dataFim', dataFim);

    sawId.forEach(sawId => {
      params = params.append('sawId', sawId)
    })

    return this.http.get<IHistoricoAtendimentoSaw[]>(this.apiUrl, { params }).pipe(
      map((response) => {
        response.forEach((equipamento) => {
          equipamento.gravacoes.forEach((gravacao) => {
            if (gravacao.tipoLigacao === "received") {
              gravacao.tipoLigacao = "Recebido";
            } else if (gravacao.tipoLigacao === "outgoing") {
              gravacao.tipoLigacao = "Feito";
            }
          })
        })

        return response;
      })
    );
  }

  export(dataInicio,
        dataFim,
        sawId: any[]
  ): Observable<IHistoricoAtendimentoSaw[]> {
  var datePipe = new DatePipe("en-US");
  dataInicio = datePipe.transform(dataInicio, "yyyy-MM-dd");
 dataFim = datePipe.transform(dataFim, "yyyy-MM-dd");

  let params = new HttpParams()
  .set('dataInicio', dataInicio)
  .set('dataFim', dataFim);

  sawId.forEach(sawId => {
    params = params.append('sawId', sawId)
  })

  return this.http.get<IHistoricoAtendimentoSaw[]>(`${this.apiUrl}/export`, { params });
}

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
