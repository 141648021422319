<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form
      id="frm-filter-livro-registro"
      class="default"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >

    <div class="container-combo-sat">
      <ws-combo
        [focusMe]="false"
        [textoLabel]="'paginas.livroRegistro.filtro.saw' | translate"
        [multiple]="true"
        [selecionarTodos]="true"
        [itemCtrl]="f.sawId"
        [items]="itensComboSaw"
        [itemSelecionado]="itemSelecionadoComboSaw"
        (changeEventCombo)="changeComboSat($event)"
      >
      </ws-combo>
    </div>
    
      <div class="container-dataDe">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.livroRegistro.filtro.data_inicio" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDe"
            formControlName="dataDe"
            [max]="formValidParam.maxDateDe"
            (dateChange)="changeDateDe($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDe"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerDe
            [startAt]="formValidParam.startDateDe"
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="container-dataAte">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "paginas.livroRegistro.filtro.data_fim" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerAte"
            formControlName="dataAte"
            [min]="formValidParam.minDateAte"
            (dateChange)="changeDateAte($event)"
            [max]="formValidParam.maxDateAte"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerAte"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #pickerAte
            [startAt]="formValidParam.startDateAte"
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          {{ "botoes.visualizar" | translate }}
        </button>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="button"
          (click)="export()"
          class="primary exportar"
          [disabled]="filterForm.invalid || desabilitarExport"
        >
          <i class="far fa-file-excel"></i> {{ "botoes.exportar" | translate }}
        </button>
      </div>
    </form>
  </div>
  <ws-grid 
    *ngIf="!isLoading"
    [permissoes]="permissoesTela"
    [isLoading]="isLoading"
    [acoesGrid]="acoesGrid"
    [disableRefresh]="filterForm.invalid"
    [colunasGrid]="colunasGrid"
    [focus]="focusFirstLine"
    [dadosGrid]="dadosGrid"
    [registroPorPagina]="registroPorPagina"
    [listaOpcaoPorPagina]="listaOpcaoPorPagina"
    (refreshEvent)="refresh()"
    (addEvent)="openForm()"
    (editEvent)="openForm($event)"
    (readEvent)="openForm($event, false)"
    (customEvent)="customEventGrid($event)"
  />

  <app-livro-registro-envios-grid
    *ngIf="showFormEnvios"
    [registro]="dadosLivroRegistro"
    [permissoes]="permissoesTela"
    (onChange)="refreshEnvios()"
    (closeEvent)="closeFormEnvios()"
  />

  <app-livro-registro-form
    *ngIf="showForm"
    [editForm]="editForm"
    [permissoes]="permissoesTela"
    [registro]="dadosLivroRegistro"
    (onChange)="refresh()"
    (closeEvent)="closeForm()"
  />

</div>
