import { Component, OnInit, ViewChild } from '@angular/core';
import { ILegenda } from './models/ILegenda';
import { LegendaService } from '../../services/legenda/legenda.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-legenda',
  templateUrl: './legenda.component.html',
  styleUrls: ['./legenda.component.scss']
})
export class LegendaComponent implements OnInit {
  public legenda = false;
  public dados: ILegenda[];
  @ViewChild('legendaNav') public legendaNav: MatDrawer;
  constructor(private legendaService: LegendaService) {}
  ngOnInit() {
    this.legendaService.dadosLegenda.subscribe((dados) => {
      this.dados = dados;
      this.legendaNav.toggle();
    });

    this.legendaService.showLegenda.subscribe((abrir) => {
      if(abrir == false)
        this.legendaNav.close();
    });
  }

  close() {
    this.dados = null;
    this.legendaNav.toggle();
  }
}
