import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ISaw } from '../models/ISaw';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 10;

@Injectable({
  providedIn: 'root'
})
export class EquipamentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}
  
  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';
  apiUrl = `${this.env.apiUrl}Equipamento`;

  editedForm = new EventEmitter<boolean>(true);
  
  editForm(){
    this.editedForm.emit(true);
  }
  
  getGrid(): Observable<ISaw[]> {
    return this.http.get<ISaw[]>(`${this.apiUrl}/Grid`);
  }

  getForm(sawId: number): Observable<ISaw> {
    return this.http.get<ISaw>(`${this.apiUrl}/Form/${sawId}`);
  }

  insert(dados: any) {
    return this.http.post(`${this.apiUrl}`, dados);
  }

  update(dados: any) {
    return this.http.put(`${this.apiUrl}`, dados);
  }

  delete(sawId) {
    var params = new HttpParams()
    .set('sawId', sawId);

    return this.http.delete(`${this.apiUrl}`, { params });
  }

  getMidia(tipoId, sawId) {
    var params = new HttpParams()
    .set('tipo', tipoId)
    .set('sawId', sawId);

    return this.http.get<File[]>(`${this.apiUrl}/Midia`, { params });
  }

  uploadMidia(uploadDados, tipoId, sawId) {
    var params = new HttpParams()
    .set('tipo', tipoId)
    .set('sawId', sawId);

    return this.http.put(`${this.apiUrl}/Midia`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      params: params
    });
  }

  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  loadImage(imagem) {
    if (!imagem[0] || imagem[0].content == '') {
      return false;
    }

    let img = imagem[0];

    const imageString = JSON.stringify(
      `data:${img.contentType};base64, ${img.content}`
    ).replace(/\\n/g, '');

    return imageString;
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
