import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'underscore';

import { IEmpresa } from '../models/IEmpresa';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { EmpresaService } from '../services/empresa.service';
import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa-grid.component.html'
})
export class EmpresaGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IEmpresa[];
  public dadosEmpresa: IEmpresa;
  public editForm = true;
  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  constructor(
    public empresaService: EmpresaService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private dialogService: DialogService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.empresa.grid.';
    let words = {
      mensagem_exclusao: '',
      razaoSocial: '',
      nomeFantasia: '',
      CNPJ: '',
      CEP: '',
      endereco: '',
      bairro: '',
      complemento: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.empresaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: true,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      keyboardNavigation: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'empresaCNPJ',
        label: this.wordsTranslated.CNPJ,
        width: '150px',
        sort: false
      },
      {
        name: 'empresaRazaoSocial',
        label: this.wordsTranslated.razaoSocial,
        width: '300px',
        sort: false
      },
      {
        name: 'empresaNomeFantasia',
        label: this.wordsTranslated.nomeFantasia,
        width: '300px',
        sort: false
      },
      {
        name: 'empresaCEP',
        label: this.wordsTranslated.CEP,
        width: '100px',
        sort: false
      },
      {
        name: 'empresaEndereco',
        label: this.wordsTranslated.endereco,
        width: '',
        sort: false
      },
      {
        name: 'empresaBairro',
        label: this.wordsTranslated.bairro,
        width: '250px',
        sort: false
      },
      {
        name: 'empresaComplemento',
        label: this.wordsTranslated.complemento,
        width: '150px',
        sort: false
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.empresaService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IEmpresa = null, editForm = true) {
    this.dadosEmpresa = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosEmpresa = $event;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        empresaId: dados.empresaId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.empresaService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  confirmDel(dados: IEmpresa) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.empresaService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }
}
