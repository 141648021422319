import { Component, OnInit } from '@angular/core';
import { IModuloMenu } from '../models/IModuloMenu';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ModuloMenuService } from '../services/modulo-menu.service';

@Component({
  selector: 'app-menu-principal-grid',
  templateUrl: './modulo-menu-principal-grid.component.html',
  styleUrls: ['./modulo-menu-principal-grid.component.scss'],
})
export class ModuloMenuPrincipalGridComponent implements OnInit {
  moduloId: IModuloMenu = null;
  showGridMenus: boolean = false;
  wordsTranslated: any;
  titleNoContent: string = '';
  textNoContent: string = '';

  constructor(
    private wordsTranslate: WordsTranslateService,
    private moduloMenuService: ModuloMenuService  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.seguranca.principal.';
    const words = {
      tituloNoContent: '',
      textNoContent: '',
      tituloHasNoTelas: '',
      textHasNoTelas: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    this.textNoContent = this.wordsTranslated.textNoContent;
    this.titleNoContent = this.wordsTranslated.tituloNoContent;
  }
  ngOnInit(): void {}

  setModuloId(moduloId: IModuloMenu) {
    this.moduloMenuService.getGrid(moduloId.aplicacaoModuloId)
    .subscribe((response) => {
      this.showGridMenus =true;// response.length > 0 ? true : false;
      this.textNoContent = this.wordsTranslated.textHasNoTelas;
      this.titleNoContent = this.wordsTranslated.tituloHasNoTelas;
    });
    this.moduloId = moduloId;
  }
}
