import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { ITipoLivroRegistro } from '../models/ITipoLivroRegistro';
import { TipoLivroRegistroService } from '../services/tipo-livro-registro.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  selector: 'app-tipo-livro-registro-grid',
  templateUrl: './tipo-livro-registro-grid.component.html',
  styleUrls: []
})
export class TipoLivroRegistroGridComponent {

  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ITipoLivroRegistro[];
  public dadosFormulario: ITipoLivroRegistro;

  wordsTranslated: any;

  constructor(
    private tipoLivroRegistroService: TipoLivroRegistroService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.tipo-livro-registro.grid.';
    const words = {
      tipoLivroRegistroId: '',
      tipoLivroRegistroDesc: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.isLoading = true;
    this.tipoLivroRegistroService.getPermissoes()
    .pipe(
      finalize(() => {
        this.loadingService.hide();
        this.isLoading = false;
      })
    )
    .subscribe((response) => {
      this.permissoes = response[0];
      this.editForm = this.permissoes.update;

      this.configuraAcoesGrid();
      this.configuraColunasGrid();
      this.configuraDadosGrid();
    });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'livroRegistroTipoId',
        label: this.wordsTranslated.tipoLivroRegistroId,
        width: '20px',
        sort: true
      },
      {
        name: 'livroRegistroTipoDesc',
        label: this.wordsTranslated.tipoLivroRegistroDesc,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.tipoLivroRegistroService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }

  refreshGridData(dados: ITipoLivroRegistro, tipo) {
    let indexPage = -1;
    if(tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        livroRegistroTipoId: dados.livroRegistroTipoId
      });
    }

    if( tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.tipoLivroRegistroService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  confirmDel(dados: ITipoLivroRegistro) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if(result) {
        this.loadingService.show();

        this.tipoLivroRegistroService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          })
      }
    });
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: ITipoLivroRegistro = null, editForm = true) {
    this.dadosFormulario = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

}
