import { Component, Input, OnInit } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { EquipamentoService } from '../../../services/equipamento.service';

@Component({
  selector: 'app-equipamento-arquivo-grid',
  templateUrl: './equipamento-arquivo-grid.component.html',
  styleUrls: ['./equipamento-arquivo-grid.component.scss'],
})
export class EquipamentoArquivoGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  acceptExtensions: string = '*';
  showUploadDescanso = false;
  showUploadAtendimento = false;
  showUploadLocalizacao = false;
  statusUploadDescanso = false;
  statusUploadAtendimento = false;
  statusUploadLocalizacao = false;
  listErrosUploadDescanso = [];
  listErrosUploadAtendimento = [];
  listErrosUploadLocalizacao = [];
  editForm: boolean = false;
  filesDescanso: File[] = [];
  filesAtendimento: File[] = [];
  filesLocalizacao: File[] = [];

  @Input() sawId: number;

  wordsTranslated: any;

  constructor(
    private equipamentomService: EquipamentoService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private imageZoomService: ImageZoomService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.equipamento.arquivos.';
    const words = {
      descansoTela: '',
      atendimentoIndisponivel: '',
      mapaOffline: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadFilesDesacanso();
    this.loadFilesAtendimento();
    this.loadFilesLocalizacao();
    this.loadExtensions();

    this.equipamentomService.getPermissoes().subscribe((result) => {
      this.permissoesTela = result[0];
      this.editForm = this.permissoesTela.update;
    });
  }

  loadFilesDesacanso() {
    const midiaDescanso = 1;
    this.equipamentomService.getMidia(midiaDescanso, this.sawId).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesDescanso = response;
            this.showUploadDescanso = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadFilesAtendimento() {
    const midiaAtendimento = 2;
    this.equipamentomService.getMidia(midiaAtendimento, this.sawId).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesAtendimento = response;
            this.showUploadAtendimento = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadFilesLocalizacao() {
    const midiaLocalizacao = 3;
    this.equipamentomService.getMidia(midiaLocalizacao, this.sawId).subscribe(
      (response) => {
        if (response.length > 0) {
          if (response[0]['content']) {
            this.filesLocalizacao = response;
            this.showUploadLocalizacao = true;
          }
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadExtensions() {
    this.equipamentomService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });
  }

  uploadFilesDescanso(uploadDados) {
    const midiaDescanso = 1;
    this.listErrosUploadDescanso = [];
    this.statusUploadDescanso = true;
    const req = this.equipamentomService.uploadMidia(
      uploadDados.formData,
      midiaDescanso,
      this.sawId
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadDescanso.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadDescanso = false;
        this.loadFilesDesacanso();
      },
      (error: any) => {
        this.statusUploadDescanso = false;
        this.alertaService.erro();
      }
    );
  }

  uploadFilesAtendimento(uploadDados) {
    const midiaAtendimento = 2;
    this.listErrosUploadAtendimento = [];
    this.statusUploadAtendimento = true;
    const req = this.equipamentomService.uploadMidia(
      uploadDados.formData,
      midiaAtendimento,
      this.sawId
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadAtendimento.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadAtendimento = false;
        this.loadFilesAtendimento();
      },
      (error: any) => {
        this.statusUploadAtendimento = false;
        this.alertaService.erro();
      }
    );
  }

  uploadFilesLocalizacao(uploadDados) {
    const midiaLocalizacao = 3;
    this.listErrosUploadLocalizacao = [];
    this.statusUploadLocalizacao = true;
    const req = this.equipamentomService.uploadMidia(
      uploadDados.formData,
      midiaLocalizacao,
      this.sawId
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUploadLocalizacao.push(element.detail);
          });
        }

        this.alertaService.sucesso();
        this.statusUploadLocalizacao = false;
        this.loadFilesLocalizacao();
      },
      (error: any) => {
        this.statusUploadLocalizacao = false;
        this.alertaService.erro();
      }
    );
  }

  showPreview(image) {
    this.imageZoomService.show(image);
  }
}