import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { IMidia } from 'src/app/shared/models/IMidia';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { IDiagnosticoGravacoes } from '../models/IDiagnosticoGravacoes';
import { DiagnosticoGravacoesService } from '../services/diagnostico-gravacoes.service';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';
import { forkJoin } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

@Component({
  selector: 'app-diagnostico-gravacoes-grid',
  templateUrl: './diagnostico-gravacoes-grid.component.html',
  styleUrls: ['./diagnostico-gravacoes-grid.component.scss']
})
export class DiagnosticoGravacoesGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public filterForm: UntypedFormGroup;
  public formReclassificacao: UntypedFormGroup;
  public formulario: UntypedFormGroup;
  public readForm: boolean = true;
  public isLoading: boolean;
  public id: number;
  public midia: any = null;
  public textoPersonalizado: string;
  public wordsTranslated: any;
  public dadosGrid: IDiagnosticoGravacoes[] = [];
  public dadosVideo: IDiagnosticoGravacoes;
  public permissoesTela: IGridPermissoes;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public showVideoGrid: boolean = false;
  public videoType: string;

  itensComboEquipamentos: ICombo[];
  itemSelecionadoComboEquipamentos: any;

  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now())
  };


  constructor(
    private loadingService: LoadingService,
    private sawService: SAWCombo,
    private diagnosticoGravacoesService: DiagnosticoGravacoesService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private fb: UntypedFormBuilder,
    private env: EnvironmentService,
    public domSanatizationService: DomSanitizer
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.diagnostico-gravacoes.grid.';
    const words = {
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      midiaNome: '',
      dataHora: '',
      textoPersonalizado: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.textoPersonalizado = this.wordsTranslated.textoPersonalizado;

    this.diagnosticoGravacoesService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;

        this.dadosGrid = [];

        const observableComboSaw = this.sawService.listComboSelect();

        forkJoin([observableComboSaw]).subscribe(
          (results) => {
            this.itensComboEquipamentos = results[0]
            this.itemSelecionadoComboEquipamentos = this.itensComboEquipamentos

            this.createFilterForm();

            this.configuraAcoesGrid();
            this.configuraColunasGrid();
            this.setInitialFilterData();
            this.isLoading = false;
          });
      });
  }

  get f() {
    return this.filterForm.controls;
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '25px',
        sort: true
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        width: '50px',
        sort: true
      },
      {
        name: 'dataHora',
        label: this.wordsTranslated.dataHora,
        width: '40px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm:ss'
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '40px',
        sort: true
      },
      {
        name: 'caminhoGravacao',
        label: this.wordsTranslated.midiaNome,
        width: '40px',
        sort: true,
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    const { dataDe, dataAte, sawId } = this.filterForm.value;

    this.diagnosticoGravacoesService
      .getGrid(dataDe, dataAte, sawId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  changeDataDe($event: any) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 90);

    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataAte: maxDateAte
    });
  }

  changeComboSaw($event) {
    this.filterForm.patchValue({
      sawId: $event,
    });
  }

  filter(): void {
    this.resetVideo();
    let { dataDe, dataAte, sawId } = this.filterForm.value;

    this.loadingService.show();

    this.diagnosticoGravacoesService
      .getGrid(dataDe, dataAte, sawId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }

  private setInitialFilterData() {
    let dataAtual = new Date(Date.now());
    let dataDe = new Date(Date.now());

    dataDe.setDate(dataAtual.getDate() - 90);

    this.formValidParam.startDateDe = dataDe;
    this.formValidParam.minDateAte = dataDe;
    this.formValidParam.startDateAte = dataAtual;

    this.filterForm.patchValue({
      dataDe: dataDe,
      dataAte: dataAtual
    });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      dataDe: [
        this.formValidParam.maxDateAte,
        Validators.compose([Validators.required])
      ],
      dataAte: [
        this.formValidParam.maxDateAte,
        Validators.compose([Validators.required])
      ],
      sawId: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  resetVideo(){
    this.midia = null
  }

  rowEvent(event) {
    this.resetVideo();
    this.midia = `${this.env.apiUrl}${environment.uploadDiagnostico}${event.caminhoGravacao}`
    // this.midia = "https://dev-sawng-api.writesys.com.br/Uploads/Gravacoes/1280.ogv"
  }
}
