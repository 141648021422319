import { IParametrosAtualizaTotem } from './../models/IParametrosAtualizaTotem';
import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { IVersaoTotem } from '../models/IVersaoTotem';
import { VersaoTotemService } from '../services/versao-totem.service';
import { IEquipamentosVersaoTotem } from '../models/IEquipamentosVersaoTotem';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IDialogMessage } from 'src/app/shared/models/IDialogMessage';

@Component({
  selector: 'app-versao-totem-principal',
  templateUrl: './versao-totem-principal.component.html',
  styleUrls: ['./versao-totem-principal.component.scss'],
})
export class VersaoTotemPrincipalComponent implements OnInit {


  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;

  public formLoading = false;
  public editForm = true;
  public showForm = false;
  public showUpload = false;
  public hasArquivoInVersion = false;
  public dadosVersaoTotem: IVersaoTotem;
  public equipamentosVersaoTotem: IEquipamentosVersaoTotem[] = [];

  wordsTranslated: any;

  constructor(
    public versaoTotemService: VersaoTotemService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    public dialog: MatDialog,
    public dialogService : DialogService,
    private alertaService: AlertaService,
  ) {
    this.onChangeSaveFormVersao();
  }


  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.versaoTotemService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        if(this.dadosVersaoTotem && this.dadosVersaoTotem.nomeArquivo !== null && this.dadosVersaoTotem.nomeArquivo !==' ')
          this.hasArquivoInVersion = true;

      });
  }

  onActionForm(dados: IVersaoTotem) {
    this.dadosVersaoTotem = null;
    if (dados) {
      this.dadosVersaoTotem = dados;
      this.editForm = true;
      if (this.dadosVersaoTotem.nomeArquivo.length > 0)
        this.hasArquivoInVersion = true
      else
        this.hasArquivoInVersion = false;
    }
    this.showForm = true;
  }

  onActionRow(dados: IVersaoTotem) {
    this.dadosVersaoTotem = null;
    if (dados) {
      this.dadosVersaoTotem = dados;

      if (this.dadosVersaoTotem.nomeArquivo.length > 0)
        this.hasArquivoInVersion = true;
      else
        this.hasArquivoInVersion = false;
    }
  }

  onChangeSaveFormVersao() {
    this.versaoTotemService.onChangeFormVersao.subscribe(change => {
      this.dadosVersaoTotem = change.dados;

      if (this.dadosVersaoTotem.nomeArquivo.length > 0)
        this.hasArquivoInVersion = true;
      else
        this.hasArquivoInVersion = false;

    })
  }

  onChangeEquipamentos(dados: any) {
    this.equipamentosVersaoTotem = dados;
  }

  save(equipamentos: any) {
    let config: IDialogMessage = {
      title: 'Confirme',
      message: `Deseja realmente atualizar os equipamentos selecionados para a versão ${this.dadosVersaoTotem.nomeArquivo}?`,
      textButtonFalse: 'Não',
      textButtonTrue: 'Sim'
    };

      this.dialogService.confirmAction(config).afterClosed().subscribe((result) => {
        if (result) {
          const dados: IParametrosAtualizaTotem = {
            sawId: equipamentos,
            versaoId: this.dadosVersaoTotem.id,
            nomeArquivo: this.dadosVersaoTotem.nomeArquivo,
            versaoFirmware: this.dadosVersaoTotem.firmware
          };

          this.formLoading = true;
          this.versaoTotemService
            .insertAtualizacaoEquipamento(dados)
            .pipe(
              finalize(() => {
                this.formLoading = false;
              })
            )
            .subscribe(
              (response) => {
                this.equipamentosVersaoTotem = [];
                this.versaoTotemService.saveUpdate.emit(true);
                this.alertaService.sucesso();
              },
              (err) => {
                this.alertaService.erro();
              });
        }
      });
  }

  onActionUpload(dados: IVersaoTotem) {
    this.dadosVersaoTotem = null;
    if (dados) {
      this.dadosVersaoTotem = dados;
      this.editForm = true;
    }
    this.showUpload = true;
  }

  closeForm() {
    this.dadosVersaoTotem = null;
    this.editForm = null;
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  fileVersaoUpload($event){
    this.dadosVersaoTotem = $event;
    this.hasArquivoInVersion = true;
  }

}
