import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAlerta, IAlertaParametros, IAlertaUsuariosMeioEnvio } from '../models/IAlerta';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 42;

@Injectable({
  providedIn: 'root'
})
export class AlertaSistemaService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }

  apiUrl = `${this.env.apiUrl}Alerta`;

  public getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

  public get() {
    return this.http.get<IAlerta[]>(this.apiUrl);
  }

  public getUsuariosMeioEnvio(alertaId: number) {
    return this.http.get<IAlertaUsuariosMeioEnvio>(`${this.apiUrl}/UsuariosMeioEnvio/${alertaId}`)
  }

  public getParametros(alertaId: number) {
    return this.http.get<IAlertaParametros[]>(`${this.apiUrl}/Parametros/${alertaId}`);
  }

  public updateUsuarioMeioEnvio(dados: { usuarioId: number, alertaId: number, meioEnvioId: number }) {
    return this.http.put(`${this.apiUrl}/UsuariosMeioEnvio/`, dados);
  }

  public updateParametros(dados: { alertaId: number, alertaParametroSeq: 0, alertaParametroValor: string }) {
    return this.http.put(`${this.apiUrl}/Parametros/`, dados);
  }
}
