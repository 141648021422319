<div class="modal" modal-name="modalLivroRegistroForm">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
  >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="livroId" />
          <mat-card appearance="outlined" class="mat-card-dados-pessoais">
            <mat-card-header>
              <mat-card-title>{{ "paginas.livroRegistro.form.dados_registro" | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="card card-registro">
              <mat-form-field class="fieldDataHora" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_data_hora" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.dataHoraCriacao' | translate
                  }}"
                  autocomplete="off"
                  formControlName="dataHoraCriacao" />
              </mat-form-field>
              <mat-form-field class="fieldPrefixo" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.prefixo" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.prefixo' | translate
                  }}"
                  autocomplete="off"
                  formControlName="prefixo" />
              </mat-form-field>
              <mat-form-field class="fieldRodoviaSigla" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.rodovia_sigla" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.rodovia_sigla' | translate
                  }}"
                  autocomplete="off"
                  formControlName="rodoviaSigla" />
              </mat-form-field>
              <mat-form-field class="fieldKmLocal" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.km_local" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  type="number"
                  numeric
                  decimals="3"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.km_local' | translate
                  }}"
                  autocomplete="off"
                  formControlName="kmLocal" />
              </mat-form-field>
              <mat-form-field class="fieldDataGrava" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.data_hora_gravacao" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.data_hora_gravacao' | translate
                  }}"
                  autocomplete="off"
                  formControlName="dataHoraGravacao" />
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>{{ "paginas.livroRegistro.form.dados_pessoais" | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="card card-pessoal">
              <mat-form-field class="fieldNome" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_nome" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_nome' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroNome" />
              </mat-form-field>
              <mat-form-field class="fieldTelefone" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_telefone" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_telefone' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroTelefone" />
              </mat-form-field>
              <mat-form-field class="fieldEmail" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_email" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_email' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroEmail" />
              </mat-form-field>
              <mat-form-field class="fieldEndereco" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_endereco" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_endereco' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroEndereco" />
              </mat-form-field>
              <mat-form-field class="fieldEstado no-padding" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_estado" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_estado' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroEstado" />
              </mat-form-field>
              <mat-form-field class="fieldCidade no-padding" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.livro_cidade" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.livro_cidade' | translate
                  }}"
                  autocomplete="off"
                  formControlName="livroCidade" />
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>{{ "paginas.livroRegistro.form.dados_veiculo" | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="card card-viagem">
              <mat-form-field class="fieldVeiculoMarca" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.veiculo_marca" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.veiculo_marca' | translate
                  }}"
                  autocomplete="off"
                  formControlName="veiculoMarca" />
              </mat-form-field>
              <mat-form-field class="fieldVeiculoModelo" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.veiculo_modelo" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.veiculo_modelo' | translate
                  }}"
                  autocomplete="off"
                  formControlName="veiculoModelo" />
              </mat-form-field>
              <mat-form-field class="fieldVeiculoPlaca" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.veiculo_placa" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.veiculo_placa' | translate
                  }}"
                  autocomplete="off"
                  formControlName="veiculoPlaca" />
              </mat-form-field>
              <mat-form-field class="fieldVeiculoAno" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.veiculo_ano" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.veiculo_ano' | translate
                  }}"
                  autocomplete="off"
                  formControlName="veiculoAno" />
              </mat-form-field>
              <mat-form-field class="fieldVeiculoCor" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.veiculo_cor" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.veiculo_cor' | translate
                  }}"
                  autocomplete="off"
                  formControlName="veiculoCor" />
              </mat-form-field>
              <mat-form-field class="fieldMotivo" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.motivo_viagem" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.motivo_viagem' | translate
                  }}"
                  autocomplete="off"
                  formControlName="motivoViagem" />
              </mat-form-field>
              <mat-form-field class="fieldEstadoOrig" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.uf_origem" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.uf_origem' | translate
                  }}"
                  autocomplete="off"
                  formControlName="ufOrigem" />
              </mat-form-field>
              <mat-form-field class="fieldCidadeOrig" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.cidade_origem" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.cidade_origem' | translate
                  }}"
                  autocomplete="off"
                  formControlName="cidadeOrigem" />
              </mat-form-field>
              <mat-form-field class="fieldEstadoDest no-padding" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.uf_destino" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.uf_destino' | translate
                  }}"
                  autocomplete="off"
                  formControlName="ufDestino" />
              </mat-form-field>
              <mat-form-field class="fieldCidadeDest no-padding" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.cidade_destino" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.cidade_destino' | translate
                  }}"
                  autocomplete="off"
                  formControlName="cidadeDestino" />
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>{{ "paginas.livroRegistro.form.dados_ocorrencia" | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="card card-ocorrencia">
              <mat-form-field class="fieldDataOcorr" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.data_hora_ocorrencia" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.dataHoraOcorrencia' | translate
                  }}"
                  autocomplete="off"
                  formControlName="dataHoraOcorrencia" />
              </mat-form-field>
              <mat-form-field class="fieldKmocorrencia" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.km_ocorrencia" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  type="number"
                  numeric
                  decimals="3"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.km_ocorrencia' | translate
                  }}"
                  autocomplete="off"
                  formControlName="kmOcorrencia" />
              </mat-form-field>
              <mat-form-field class="fieldSentido" appearance="outline">
                <mat-label>{{ "paginas.livroRegistro.form.ocorrencia_sentido" | translate }}</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.ocorrencia_sentido' | translate
                  }}"
                  autocomplete="off"
                  formControlName="sentidoOcorrencia"
                  type="text" />
              </mat-form-field>
              <mat-form-field class="fieldCidadeOcorr" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.cidade_ocorrencia" | translate }}
                </mat-label>
                <input
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.cidade_ocorrencia' | translate
                  }}"
                  autocomplete="off"
                  formControlName="cidadeOcorrencia" />
              </mat-form-field>
              <mat-form-field class="fieldDescricao" appearance="outline">
                <mat-label>
                  {{ "paginas.livroRegistro.form.descricao_manifestacao" | translate }}
                </mat-label>
                <textarea
                  [readonly]="true"
                  [focusMe]="false"
                  matInput
                  placeholder="{{
                    'paginas.livroRegistro.form.descricao_manifestacao' | translate
                  }}"
                  autocomplete="off"
                  formControlName="descricaoManifestacao"
                  rows="9"
                ></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>
      </form>
    </div>
  </div>
</div>
