import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { ILivroRegistro } from '../models/ILivroRegistro';
import { ILivroRegistroEnvios } from '../models/ILivroRegistroEnvios';
import { ILivroRegistroFilterFormDTO } from '../models/ILivroRegistroFilterFormDTO';

const telaId = 24;
const apiTela = `LivroRegistro`;

@Injectable({ providedIn: 'root' })
export class LivroRegistroService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(sat: any, dataDe: any, dataAte: any): Observable<ILivroRegistro[]> {
    var datePipe = new DatePipe('en-US');
    dataDe = datePipe.transform(dataDe, 'yyyy-MM-dd');
    dataAte = datePipe.transform(dataAte, 'yyyy-MM-dd');

    let params = new HttpParams().set('dataDe', dataDe).set('dataAte', dataAte);

    sat.forEach((x) => {
      params = params.append('sawId', x);
    });
    
    return this.http.get<ILivroRegistro[]>(`${this.apiUrl}/Grid`, { params });
  }

  getByIdGrid(dados: ILivroRegistro) {
    if (dados) {
      return this.http.get<ILivroRegistro>(
        `${this.apiUrl}/${dados.livroId}/byIdGrid`
      );
    } else if (typeof dados === 'number') {
      return this.http.get<ILivroRegistro>(`${this.apiUrl}/${dados}/byIdGrid`);
    } else {
      return this.http.get<ILivroRegistro>(
        `${this.apiUrl}/${dados.livroId}/byIdGrid`
      );
    }
  }

  getForm(dados: ILivroRegistro) {
   return this.http.get<ILivroRegistro>(`${this.apiUrl}/Form/${dados.livroId}`);
    
  }

  getGridEnv(filaEnvioId: number): Observable<ILivroRegistroEnvios[]>{
    return this.http.get<ILivroRegistroEnvios[]>(`${this.apiUrl}/StatusEnvio/${filaEnvioId}`);
  }

  insert(dados: ILivroRegistro) {
    return this.http.post<ILivroRegistro>(this.apiUrl, dados);
  }

  update(dados: ILivroRegistro) {
    return this.http.put<ILivroRegistro>(`${this.apiUrl}`, dados);
  }

  delete(dados: ILivroRegistro) {
    return this.http.delete<ILivroRegistro>(`${this.apiUrl}/${dados.livroId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

  public export(filterFormDTO: ILivroRegistroFilterFormDTO): any {
    const params = this.getParams(filterFormDTO);

    return this.http.get(`${this.apiUrl}/Export`, {
      params,
    });
  }

  private getParams(filterFormDTO: ILivroRegistroFilterFormDTO): HttpParams {
    const datePipe = new DatePipe("en-US");
    filterFormDTO.dataDe = datePipe.transform(filterFormDTO.dataDe, "yyyy-MM-dd");
    filterFormDTO.dataAte = datePipe.transform(filterFormDTO.dataAte, "yyyy-MM-dd");

    let params = new HttpParams()
      .set('dataDe', filterFormDTO.dataDe)
      .set('dataAte', filterFormDTO.dataAte);

      filterFormDTO.sawId.forEach((sawid) => {
        params = params.append('sawId', sawid);
      }); 

    return params
  }
}
