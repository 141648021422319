import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IMockupTotem } from '../../models/IMockupTotem';
import { MockupTotemService } from '../../services/mockup-totem.service';
import { finalize } from 'rxjs/operators';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { IConfiguracao } from 'src/app/shared/models/IConfiguracao';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campos-mockup-form',
  templateUrl: './campos-mockup-form.component.html',
  styleUrls: ['./campos-mockup-form.component.scss']
})
export class CamposMockUpComponent implements OnInit {

  @Input() dados: IMockupTotem;
  @Input() possuiTitulo: boolean;
  @Input() possuiDescricao: boolean;
  @Input() possuiCor: boolean;
  @Input() abrirCardForm: boolean;

  prefixo = environment.prefixo;
  dadosConfiguracao: IConfiguracao;
  habilitaRevista: boolean;
  formulario: UntypedFormGroup;
  wordsTranslated: any;
  permissoes: IGridPermissoes = {
    update: false,
    read: false,
    create: false,
    delete: false,
  };

  public nameLabelColorPicker: string = 'Cor';
  public statusBotao: IDialogButton = {
    salvar: false,
    cancelar: false,
  };
  public formLoading = false;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private mockupTotemService: MockupTotemService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.mockup-totem.alerta.';
    const words = {
      corEmpty: '',
      selectColor: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.dadosConfiguracao = JSON.parse(
      localStorage.getItem(this.prefixo + 'config_default')
    );
    this.habilitaRevista = this.dadosConfiguracao.magazineEnable;

    this.loadingService.show();
    this.mockupTotemService
    .getPermissoes()
    .pipe(
      finalize(() => {
        this.loadingService.hide();
        this.criarFormulario();
      })
    )
    .subscribe((response) => {
      this.permissoes = response[0];
    });

    if(this.dados != null) {
      this.criarFormulario();
    }
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      personalizarTotemId: [
        this.dados != null && this.dados.personalizarTotemId
        ? this.dados.personalizarTotemId
        : null,
      ],
      personalizarTotemValorCor: [
        this.dados != null && this.dados.personalizarTotemValorCor
          ? this.dados.personalizarTotemValorCor
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorTituloPortugues: [
        this.dados != null && this.dados.personalizarTotemValorTituloPortugues
          ? this.dados.personalizarTotemValorTituloPortugues
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorTituloIngles: [
        this.dados != null && this.dados.personalizarTotemValorTituloIngles
          ? this.dados.personalizarTotemValorTituloIngles
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorTituloEspanhol: [
        this.dados != null && this.dados.personalizarTotemValorTituloEspanhol
          ? this.dados.personalizarTotemValorTituloEspanhol
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorDescPortugues: [
        this.dados != null && this.dados.personalizarTotemValorDescPortugues
          ? this.dados.personalizarTotemValorDescPortugues
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorDescIngles: [
        this.dados != null && this.dados.personalizarTotemValorDescIngles
          ? this.dados.personalizarTotemValorDescIngles
          : '',
        Validators.compose([Validators.required])
      ],
      personalizarTotemValorDescEspanhol: [
        this.dados != null && this.dados.personalizarTotemValorDescEspanhol
          ? this.dados.personalizarTotemValorDescEspanhol
          : '',
        Validators.compose([Validators.required])
      ],
    });

    this.onValueChanges();

    if (!this.permissoes['update'] && this.dados)
    this.formulario.disable();
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.dados) {
        for (const prop in val) {
          if (prop in this.dados) {
            if (this.dados[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm: IMockupTotem = this.formulario.value;

    if (this.dados) {
      this.mockupTotemService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
            this.initialFormButtonsState();
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave();
        });
    }
  }

  finalitySave() {
    this.formLoading = false;
    this.updateFormData();
    this.mockupTotemService.eventoAtualizaGrid.emit();
  }

  cancel() {
    this.formulario.reset();
    this.mockupTotemService.eventoAtualizaGrid.emit();
    this.updateFormData();
  }

  updateFormData(){
    this.mockupTotemService.getGrid().subscribe((result) => {
      this.dados = result.find(x => x.personalizarTotemId == this.dados.personalizarTotemId)
      this.criarFormulario();
    });
  }

  getColor(color: any, cardEdit: string) {

    if(color === undefined || color === null){
      this.alertaService.Warning(this.wordsTranslated.corEmpty,this.wordsTranslated.selectColor,3000);
      this.initialFormButtonsState();
      return;
    }

    var colorHex = `#${color.hex}`;
      this.formulario.patchValue({
        personalizarTotemValorCor: colorHex,
      });

    const changeColor = {
      cor: colorHex,
      tipo: cardEdit
    };

    this.mockupTotemService.eventoAtualizaCor.emit(changeColor);

  }
}
