import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IPermissaoUsuario } from '../usuario/models/IPermissaoUsuario';
import { IPermissaoGrupo } from '../grupo/models/IPermissaoGrupo';
import { TabPermissaoService } from '../services/tabPermissao.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';

@Component({
  selector: 'app-permissao-tabs',
  templateUrl: './permissao-tab.component.html',
  styleUrls: ['./permissao-tab.component.scss']
})
export class PermissaoTabComponent implements OnInit {
  showUsuario = true;
  showGrupo = false;

  public isLoading: boolean;

  public permissoesTela: IGridPermissoes;
  public editForm = true;

  dadosUsuario: IPermissaoUsuario;
  dadosGrupo: IPermissaoGrupo;

  constructor(
    private tabPermissaoService: TabPermissaoService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.tabPermissaoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
      });
  }

  onTabClick($event: MatTabChangeEvent) {
    this.showUsuario = false;
    this.showGrupo = false;

    const events = {
      // Usuarios
      0: () => {
        this.showUsuario = true;
      },
      // Grupo
      1: () => {
        this.showGrupo = true;
      }
    };

    events[$event.index]();
  }

  onChangeDadosUsuario(dadosUsuario) {
    this.dadosUsuario = dadosUsuario;
  }

  onChangeDadosGrupo(dadosGrupo) {
    this.dadosGrupo = dadosGrupo;
  }
}
