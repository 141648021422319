<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [focus]="false" 
  [registroPorPagina]="registroPorPagina"
  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
  (refreshEvent)="refresh()"
  (addEvent)="openForm()"
  (editEvent)="openForm($event)"
  (deleteEvent)="confirmDel($event)"
  (fileEvent)="openUpload($event)"
></ws-grid>

<app-empresa-form
  *ngIf="showForm"
  [editForm]="editForm"
  [permissoes]="permissoesTela"
  [registro]="dadosEmpresa"
  (onChange)="refreshGridData($event.dados, $event.tipo)"
  (closeEvent)="closeForm()"
>
</app-empresa-form>

<app-empresa-upload
  *ngIf="showUpload"
  [editForm]="editForm"
  [dados]="dadosEmpresa"
  (closeEvent)="closeUpload()"
>
</app-empresa-upload>
