import { Injectable, ResolvedReflectiveFactory } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertaService } from '../alerta/alerta.service';
import { LoadingService } from '../loading/loading.service';
import { SoftPhoneService } from '../softPhone/soft-phone.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alertaService: AlertaService,
    private loadingService: LoadingService,
    private softPhoneService: SoftPhoneService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse, source) => {
        //CONDICAO ACIONADA PARA ESCAPAR DO ERRO QUE SETA O VOLUME DO EQUIPAMENTO

        if (err.url.search('VolumeSpeaker') == -1)  {
          switch (err.status) {
            case 401: {
              this.loadingService.hide();
              this.authService.logout();
              this.alertaService.sessaoExpirou();
              this.softPhoneService.sipHangUp();
              this.softPhoneService.sipUnRegister();
              break;
            }
            case 419: {
              this.authService.logout();
              this.alertaService.sessaoExpirou();
              this.softPhoneService.sipUnRegister();
              break;
            }
            case 400: {
              if (err.error.statusCode === 403) {
                this.alertaService.permissao();
              } else {
                this.alertaService.erro(err.error.title, err.error.detail);
              }
              break;
            }
            case 500: {
              if (err.error.IsExpected) {
                this.alertaService.erro(
                  err.error.Title,
                  err.error.Detail,
                  15000
                );
              } else {
                this.alertaService.erro500('Error', err);
              }
              break;
            }
            default: {
              this.alertaService.erroDesconhecido(err);
              break;
            }
          }
        }
        return throwError(err);
      })
    );
  }
}
