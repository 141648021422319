import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { IVersaoTotem } from '../../models/IVersaoTotem';
import { VersaoTotemService } from '../../services/versao-totem.service';

@Component({
  selector: 'app-versoes-totem-grid',
  templateUrl: './versoes-totem-grid.component.html',
  styleUrls: ['./versoes-totem-grid.component.scss']
})
export class VersoesTotemGridComponent implements OnInit {

  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IVersaoTotem[];
  public dadosVersaoTotem: IVersaoTotem;


  public editForm = true;
  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  @Output() onActionUpload = new EventEmitter<any>();
  @Output() onActionForm = new EventEmitter<any>();
  @Output() onActionRow = new EventEmitter<any>();

  constructor(
    public versaoTotemService: VersaoTotemService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog
  ) {
    this.loadWordsTranslate();
    this.refreshSaveForm();
    this.refreshGridUpdateFile();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.versaoTotem.grid.';
    let words = {
      nomeArquivo: '',
      firmware: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.versaoTotemService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: false,
      upload: true,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'nomeArquivo',
        label: this.wordsTranslated.nomeArquivo,
        width: '250px',
        sort: true
      },
      {
        name: 'firmware',
        label: this.wordsTranslated.firmware,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.versaoTotemService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IVersaoTotem = null) {
    if(dados){
      this.onActionForm.emit(dados);
    }else{
      this.onActionForm.emit(null);
    }
  }

  openUpload(dados: IVersaoTotem = null) {
    if(dados){
      this.onActionUpload.emit(dados);
    }else{
      this.onActionUpload.emit(null);
    }
  }

  rowEvent(dados: IVersaoTotem) {
    if(dados){
      this.onActionRow.emit(dados);
    }
  }

  refreshSaveForm(){
    this.versaoTotemService.onChangeFormVersao.subscribe(change =>{
      this.refreshGridData(change.dados,change.tipo);
    })
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.onActionUpload.emit(false);
  }

  refreshGridUpdateFile(){
    this.versaoTotemService.saveFile.subscribe(()=>{
    this.refresh();
    })
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        id: dados.id
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.versaoTotemService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

}
