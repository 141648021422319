import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { EnvironmentService } from '../env/environment.service';


@Injectable({ providedIn: 'root' })
export class SentidoCombo {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = this.env.apiUrl + 'ComboSentidoRodovia';


  listComboSelect(): Observable<ICombo[]> {
    return this.http.get<ICombo[]>(this.apiUrl);
  }
}
