import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { IModuloMenu } from '../../principal/models/IModuloMenu';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ModuloMenuService } from '../../principal/services/modulo-menu.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { finalize } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { ModuloCombo } from 'src/app/shared/services/combo/modulo.combo';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-modulo-menu-form',
  templateUrl: './modulo-menu-form.component.html',
  styleUrls: ['./modulo-menu-form.component.scss'],
})
export class ModuloMenuFormComponent implements OnInit {
  labelRelatorio: string;
  labelInserir: string;
  labelAtualizar: string;
  labelExcluir: string;
  labelLeitura: string;
  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showFormMenu: EventEmitter<boolean>;
  @Input() registro: IModuloMenu = null;
  @Input() moduloId: number;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IModuloMenu>();

  private registroClean: IModuloMenu;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboModulo: ICombo[] = [];
  public itemSelecionadoComboModulo: any;

  wordsTranslated: any;
  public modalName: string = 'modalModuloMenuForm';

  constructor(
    private moduloCombo: ModuloCombo,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private moduloMenuService: ModuloMenuService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }
  loadWordsTranslate() {
    const keyRoot = 'paginas.seguranca.menu.form.';
    const words = {
      editar_registro: '',
      adicionar_registro: '',
      relatorio: '',
      leitura: '',
      atualizar: '',
      excluir: '',
      inserir: '',
      modulo: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadingService.show();
    const listModulos = this.moduloCombo.listComboSelect();

    forkJoin([listModulos]).subscribe((results) => {
      this.itensComboModulo = results[0];

      const keyRoot = 'paginas.seguranca.menu.form.';
      const words = {
        relatorio: '',
        leitura: '',
        atualizar: '',
        excluir: '',
        inserir: '',
      };
      let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
      this.labelRelatorio = wordsTranslate.relatorio;
      this.labelLeitura = wordsTranslate.leitura;
      this.labelAtualizar = wordsTranslate.atualizar;
      this.labelExcluir = wordsTranslate.excluir;
      this.labelInserir = wordsTranslate.inserir;

      if (this.registro != null) {
        this.loadEditData();
      } else {
        this.criarFormulario();
      }
    });

    this.loadingService.hide();
  }

  loadEditData() {
    this.moduloMenuService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      aplicacaoModuloId: [
        this.registro != null && this.registro.aplicacaoModuloId !== null
          ? this.registro.aplicacaoModuloId
          : this.moduloId,
      ],
      aplicacaoTelaId: [
        this.registro != null && this.registro.aplicacaoTelaId !== null
          ? this.registro.aplicacaoTelaId
          : null,
      ],
      menuTelaPortugues: [
        this.registro != null && this.registro.menuTelaPortugues
          ? this.registro.menuTelaPortugues
          : '',
        Validators.compose([Validators.required]),
      ],
      menuTelaIngles: [
        this.registro != null && this.registro.menuTelaIngles
          ? this.registro.menuTelaIngles
          : '',
      ],
      menuTelaEspanhol: [
        this.registro != null && this.registro.menuTelaEspanhol
          ? this.registro.menuTelaEspanhol
          : '',
      ],
      relatorio: [this.registro ? this.registro.relatorio : false],
      atualizar: [this.registro ? this.registro.atualizar : false],
      leitura: [this.registro ? this.registro.leitura : false],
      inserir: [this.registro ? this.registro.inserir : false],
      excluir: [this.registro ? this.registro.excluir : false],
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      cancelar: false,
      salvar: false,
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.moduloMenuService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.moduloMenuService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
          this.close();
        });
    }
  }

  finalitySave(retorno: IModuloMenu, tipo: string) {
    const change = {
      dados: retorno,
      tipo,
    };

    this.onChange.emit(change);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  get f() {
    return this.formulario.controls;
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  onChangeRelatorio(event: MatSlideToggleChange) {
    this.formulario.patchValue({
      relatorio: event.checked,
    });
  }
  onChangeLeitura(event: MatSlideToggleChange) {
    this.formulario.patchValue({
      leitura: event.checked,
    });
  }
  onChangeInserir(event: MatSlideToggleChange) {
    this.formulario.patchValue({
      inserir: event.checked,
    });
  }
  onChangeAtualizar(event: MatSlideToggleChange) {
    this.formulario.patchValue({
      atualizar: event.checked,
    });
  }
  onChangeExcluir(event: MatSlideToggleChange) {
    this.formulario.patchValue({
      excluir: event.checked,
    });
  }
  onChangeComboModulo($event) {
    this.itemSelecionadoComboModulo = $event;

    this.formulario.patchValue({
      aplicacaoModuloId: this.itemSelecionadoComboModulo,
    });
  }
}
