import { Injectable, EventEmitter } from '@angular/core';
import { ILegenda } from '../../components/legenda/models/ILegenda';

@Injectable()
export class LegendaService {
  showLegenda = new EventEmitter<boolean>();
  dadosLegenda = new EventEmitter<ILegenda[]>();

  public show(dados: ILegenda[]) {
    this.dadosLegenda.emit(dados);
    this.showLegenda.emit(true);
  }
  public hide() {
    this.showLegenda.emit(false);
  }
}
