import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IModuloMenu } from '../models/IModuloMenu';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 38;

@Injectable({ providedIn: 'root' })
export class ModuloMenuService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}SegurancaModuloMenu`;

  getGrid(aplicacaoModuloId: number): Observable<IModuloMenu[]> {
    return this.http.get<IModuloMenu[]>(`${this.apiUrl}/Grid/${aplicacaoModuloId}`);
  }

  getForm(dados: IModuloMenu) {
    return this.http.get<IModuloMenu>(`${this.apiUrl}/Form/${dados.aplicacaoTelaId}`);
  }


  insert(dados: IModuloMenu) {
    return this.http.post<IModuloMenu>(this.apiUrl, dados);
  }

  update(dados: IModuloMenu) {
    return this.http.put<IModuloMenu>(`${this.apiUrl}`, dados);
  }
  
  updateOrdem(dados: IModuloMenu) {
    return this.http.put<IModuloMenu>(`${this.apiUrl}/Ordem`, dados);
  }

  insertLiberado(dados: IModuloMenu) {
    return this.http.put(`${this.apiUrl}/Liberado/${dados.aplicacaoTelaId}`,null);
  }

  delete(dados: IModuloMenu) {
    return this.http.delete<IModuloMenu>(`${this.apiUrl}/${dados.aplicacaoTelaId}`);
  }
  
  deleteLiberado(dados: IModuloMenu) {
    return this.http.delete<IModuloMenu>(`${this.apiUrl}/Liberado/${dados.aplicacaoTelaId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

}