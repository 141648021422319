<div class="shortcut">
  <ul *ngIf="registro">
    <li *ngFor="let item of registro"
      [ngStyle]="{'background-image' : 'url(/assets/images/icon/atalhos/' + item.iconeSrc + ')'}"
      (click)="openShortCut(item)">
      <span>{{item.teclaAtalho}}</span>
      <div class="content">
        {{item.aplicacaoTelaTexto}}
      </div>
    </li>
  </ul>
</div>