import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { LegendaService } from 'src/app/shared/services/legenda/legenda.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { IMapaOperacionalEquipamentoMarker } from './models/IMapaOperacionalEquipamentoMarker';
import { IMapaOperacionalRodovia } from './models/IMapaOperacionalRodovia';
import { MapaOperacionalService } from './services/mapa-operacional.service';
import * as L from 'leaflet';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

enum Marker {
  'Equipment',
}
@Component({
  selector: 'app-mapa-operacional',
  templateUrl: './mapa-operacional.component.html',
  styleUrls: ['./mapa-operacional.component.scss'],
})
export class MapaOperacionalComponent implements OnInit {
  mapIcon = {
    extensao: '.png',
    eqpOnline: 'assets/images/mapa/icons/icone-online.png',
    eqpOffline: 'assets/images/mapa/icons/icone-offline.png',
    eqpVandalismo: 'assets/images/mapa/icons/icone-vandalismo.png',
    eqpComFalha: 'assets/images/mapa/icons/icone-com-falha.png',
    eqpLigacao: 'assets/images/mapa/icons/icone-ligacao.png',
  };

  @ViewChild('mapRef', { static: false }) mapElement: any;
  mapObj: any;
  mapStyle: any;
  markers = new Map<number, any>();
  roads: any = [];
  infoWindow: any;
  directionsService: any;
  directionsDisplay: any;

  private equipmentMarkers = true;

  @Input() fullScreen: boolean;
  @Input() permissoes: IGridPermissoes;

  permissoesTela: IGridPermissoes;
  readForm = true;
  equipamentoInfo: any;

  wordsTranslated: any;
  filterForm: UntypedFormGroup;
  rodovias: IMapaOperacionalRodovia[] = [];
  equipamentos: IMapaOperacionalEquipamentoMarker[] = [];
  equipamentoVandalizado = [];

  isVisible: any;
  centralize = true;
  intervalRefresh: any;
  osmServerUrl: string = '';

  public showInfo = false;
  public titleInfo = '';

  constructor(
    private wordsTranslate: WordsTranslateService,
    private loadingService: LoadingService,
    private mapaOperacionalService: MapaOperacionalService,
    private element: ElementRef,
    private notificationService: NotificationService,
    private legendaService: LegendaService,
    private configuracaoService: ConfiguracaoService,
    private env: EnvironmentService
  ) {
    this.osmServerUrl = env.osmServerUrl;
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.mapa-operacional.';
    let words = {
      saw: '',
      vandalismoTitulo: '',
      vandalismoCorpo: '',
      reconexaoTitulo: '',
      reconexaoCorpo: '',
      falhaConexaoTitulo: '',
      falhaConexaoCorpo: '',
      falhaTitulo: '',
      falhaCorpo: '',
      recuperacaoFalhaTitulo: '',
      recuperacaoFalhaCorpo: '',
      rodovias: '',
      ligacaoTitulo: '',
      ligacaoCorpo: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  @HostListener('window:resize')
  ngDoCheck() {
    this.isVisible = this.element.nativeElement.offsetParent !== null;
  }

  ngOnInit() {
    this.loadingService.show();
    this.configuracaoService.processarEventoSignal.subscribe((evento) => {
      this.processarEventoSignalAr(evento);
    });
  }

  processarEventoSignalAr(evento) {
    this.equipamentos.forEach((saw) => {
      if (saw.numeroSerie === evento.numeroSerie.numeroSerie) {
        let equipamentoMarker = this.markers.get(saw.sawId);
        if (equipamentoMarker) {
          this.mapaOperacionalService
          .getEquipamentoInfo(equipamentoMarker.referencia.sawId)
          .subscribe((result) => {
            const { prefixo } = result;
            this.setDisplay(equipamentoMarker, prefixo, evento.tipoEvento, true);
          });
        }
      }
    });
  }

  setDisplay(markerEquipamento, prefixo: string, tipo: string, startAnimation: boolean){
    let icon, bgColor;
    let notificar = true;
    switch(tipo){
      case 'vandalismo': 
        this.equipamentoVandalizado.push(markerEquipamento.referencia.sawId);
        icon = this.mapIcon.eqpVandalismo;
        bgColor = 'backGroundColorVandalismo';
      break;
      case 'ligacao': 
        let equiVandalizado = this.equipamentoVandalizado.filter(sawId => sawId == markerEquipamento.referencia.sawId);
        
        if(equiVandalizado.length == 0){
          icon = this.mapIcon.eqpLigacao;
          bgColor = 'backGroundColorLigacao'; 
        }else{
          notificar = false;
        }

      break;
      case 'online': 
        icon = this.mapIcon.eqpOnline;
        bgColor = 'backGroundColor';      
      break;
      case 'offline': 
        icon = this.mapIcon.eqpOffline;
        bgColor = 'backGroundColor';   
      break;
      case 'falha': 
        icon = this.mapIcon.eqpComFalha;
        bgColor = 'backGroundColor';   
      break;
    }

    if(notificar){
      markerEquipamento.setIcon(
        this.createMapMarkerIcon(icon, tipo)
      );
      this.setLabelEquipamento( markerEquipamento, prefixo, bgColor);
      
    }
  }

  private createMapMarkerIcon(path: string, tipo: string) {
    let scaledSize;
    let labelOrigin;
     if (tipo === 'offline' || tipo === 'online' || tipo === 'falha'){
      scaledSize = [50, 62.5];
      labelOrigin = [25, 14];
     }else{
      scaledSize = [50, 62.5];
      labelOrigin = [25, 58];
     }
     return {
      iconUrl: path,
      iconSize: scaledSize,
      iconAnchor: [(scaledSize[0]/2), scaledSize[1]],
      labelOrigin: labelOrigin,
     };
   }
 
   setLabelEquipamento(markerEquipamento, prefixo: string, backGround: string) {
    markerEquipamento.bindTooltip(prefixo, {
      maxWidth: 10,
      direction: 'top',
      permanent: true,
      className: backGround,
      offset: [0, -25],
    });
   }
 
  setNormalStateMarker(sawId: number){
    let equipamentoMarker = this.markers.get(sawId);
    let prefixo = equipamentoMarker.referencia.prefixo;
    let tipo;

    let indexVandalizado = this.equipamentoVandalizado.indexOf(e => e == sawId);
    this.equipamentoVandalizado.splice(indexVandalizado);

    if (!equipamentoMarker.referencia.statusOnline) {
      tipo = 'offline';
    }else if (equipamentoMarker.referencia.statusFalha) {
      tipo = 'falha';
    }else{
      tipo = 'online';
    }
    
    this.setDisplay(equipamentoMarker, prefixo, tipo, false);
  }

  ngAfterViewInit() {
    this.mapaOperacionalService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;
        this.fetchData();
        this.setTimeToRefresh();
      });
  }

  loadMap() {
    this.mapObj = L.map(this.mapElement.nativeElement, {
      center: { lat: -22.75836389, lng: -42.895018611 },
      zoom: 9,
      minZoom: 6,
    });
    L.tileLayer(this.osmServerUrl, {
      minZoom: 6,
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.mapObj);
  }
  

  get f() {
    return this.filterForm.controls;
  }

  fetchData(): void {
    if (this.mapObj == null) {
      this.loadMap();
    }

    const obsRodovias = this.mapaOperacionalService.getRodovias();
    const obsEquipamentos = this.mapaOperacionalService.getEquipamentos();

    forkJoin([obsRodovias, obsEquipamentos])
      .pipe(
        finalize(() => {
          setTimeout(() => {
            if (this.centralize) {
              this.setCenterMap();
            }
            this.loadingService.hide();
            this.centralize = true;
          }, 1000);
        })
      )
      .subscribe((result) => {
        this.rodovias = result[0];
        this.equipamentos = result[1];
        if (this?.rodovias?.length) this.setRoads();
        if (this?.equipamentos?.length) this.setEquipments();
        this.loadingService.hide();
      });
  }

  setTimeToRefresh() {
    setInterval(() => {
      if (this.isVisible) {
        this.centralize = false;
        this.fetchData();
      }
    }, environment.refreshpMap);
  }

  clearMaps() {
    this.rodovias = [];
    this.mapObj = null;
    this.markers = new Map<number, L.Marker>();
  }

  clearRoads() {
    this.notificationService.currentNotification = null;
    this.roads.forEach((result) => {
      this.mapObj.removeLayer(result);
    });
  }

  setCenterMap() {
    var bounds = L.latLngBounds([]);
    this.markers.forEach((marker) => {
        const markerLatLng = marker.getLatLng();
        if ((markerLatLng.lat && markerLatLng.lng) != 0) {
            bounds.extend(markerLatLng);
        }
    });
  
    this.mapObj.fitBounds(bounds, { padding: [20, 20], maxZoom: 10 });
  }

  private retrieveEquipmentIcon(equipment: IMapaOperacionalEquipamentoMarker) {
    const { eqpComFalha, eqpOffline, eqpOnline} = this.mapIcon;
    if (!equipment.statusOnline) {
      return eqpOffline;
    }
    if (equipment.statusFalha) {
      return eqpComFalha;
    }
    return eqpOnline;
  }

  setEquipments() {
    this.clearMarkes();
    this.equipamentos.forEach(
      (equipment: IMapaOperacionalEquipamentoMarker) => {
        const icon = this.retrieveEquipmentIcon(equipment);
        const { latitude, longitude } = equipment;
        const iLat = latitude.toString();
        const iLng = longitude.toString();
        const ref = { tipo: Marker.Equipment, ...equipment };
        this.setMarker(
          iLat,
          iLng,
          icon,
          ref,
          equipment.prefixo,
          equipment.numeroSerie
        );
      }
    );
  }

  setMarker(
    iLat: any,
    iLng: any,
    icone: any,
    ref: any,
    prefixo: string,
    numeroSerie: string
  ) 
  {
    let location = ([iLat, iLng] as L.LatLngTuple);
    let icon = {
      url: icone,
    };
    this.addMarker(location, icon, ref, prefixo, numeroSerie);
  }

  addMarker = (location, icone, ref, prefixo, numeroSerie) => {

    const marker = new L.Marker(location, {
      referencia: ref,
      numeroSerie: numeroSerie,
      icon: L.icon({
        iconUrl: icone.url,
        iconSize: [50, 62.5],
        iconAnchor: [25, 62.5],
        iconOrigin: [25, 14],
      }),
      draggable: false,
    });

    this.markers.set(ref.sawId, marker);
    marker.addTo(this.mapObj);

    marker.bindTooltip(prefixo, {
      maxWidth: 200,
      direction: 'center',
      permanent: true,
      className: 'backGroundColor',
      offset: [0, -47],
    });

    marker.on('click', () => {
      this.openInfo(marker);
    });
  };

  setRoads() {
    this.clearRoads();
    if (this.rodovias) {
      this.rodovias.forEach((rodovia: IMapaOperacionalRodovia) => {
        var coordinates = [];
        rodovia.coordenadas.forEach((coordenada) => {
          var polyCoordinate = {
            lat: coordenada.latitude,
            lng: coordenada.longitude,
          };
          coordinates.push(polyCoordinate);

          if (coordinates.length == 2) {
            this.createPolyline(coordinates, rodovia.corHTML);
            coordinates.shift();
          }
        });
      });
    }
  }

  createPolyline(coordinates, corHexa) {
    const polyline = L.polyline(coordinates, {
      color: corHexa,
      weight: 3,
      opacity: 1,
    });

    polyline.addTo(this.mapObj);
  }

  clearMarkes() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = new Map<number, L.Marker>();
  }

  setMapOnAll(ref, status) {
    this.markers.forEach((marker) => {
      if (marker.referencia.tipo === ref) {
        marker.setMap(status === true ? this.mapObj : null);
      }
    });
  }

  toggleMarker(ref: Marker) {
    let status = true;
    if (ref === Marker.Equipment) {
      this.equipmentMarkers = !this.equipmentMarkers;
      status = this.equipmentMarkers;
    }

    this.setMapOnAll(ref, status);
  }

  closeInfo() {
    this.showInfo = false;
    this.equipamentoInfo = null;
  }
  
  openInfo(marker, overwrite = {}) {
    this.closeInfo();

    switch (marker.options.referencia.tipo) {
      case Marker.Equipment:
        this.loadEquipamento(marker, overwrite);
        this.legendaService.hide();
        break;
    }
  }

  loadEquipamento(marker, overwrite) {
    this.mapaOperacionalService
      .getEquipamentoInfo(marker.options.referencia.sawId)
      .subscribe((result) => {
        const { statusOnline, statusFalha } = marker.options.referencia;
        const { ultimosEventos } = marker.options.referencia;
        this.equipamentoInfo = {
          ...result,
          statusOnline,
          statusFalha,
          ...overwrite,
          ultimosEventos,
        };
        this.titleInfo = `${result.prefixo}`;
        this.showInfo = true;
      });
  }

  showLegenda() {
    const legendaObj = this.rodovias.map((r) => ({
      title: r.rodoviaNome,
      badge: true,
      badgeColor: r.corHTML,
    }));
    this.legendaService.show(legendaObj);
  }
}
