<div class="changePassword">
  <div mat-dialog-title>
    <h2>{{ 'paginas.changePassword.modal.titulo' | translate }}</h2>
    <button mat-button class="close" (click)="dialogRef.close()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <mat-dialog-content>
    <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
      <div class="modal-content">
        <mat-grid-list cols="1" rowHeight="80px">
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'paginas.changePassword.formulario.senhaAtual' | translate }}
              </mat-label>
              <input id="inputFocus" matInput type="password"
                placeholder="{{ 'paginas.changePassword.formulario.senhaAtual' | translate }}"
                formControlName="oldPassword" />
              <mat-error *ngIf="formulario.controls.oldPassword.hasError('maxlength')">
                {{ 'formulario.validacao.max_length' | translate: {quantidade: formValidParam.limitPassword.max } }}
              </mat-error>
              <mat-error *ngIf="formulario.controls.oldPassword.hasError('minlength')">
                {{ 'formulario.validacao.min_length' | translate:  {quantidade: formValidParam.limitPassword.min } }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'paginas.changePassword.formulario.senhaNova' | translate }}</mat-label>
              <input matInput type="password"
                placeholder="{{ 'paginas.changePassword.formulario.senhaNova' | translate }}"
                formControlName="newPassword" />
              <mat-error *ngIf="formulario.controls.newPassword.hasError('maxlength')">
                {{ 'formulario.validacao.max_length' | translate: {quantidade: formValidParam.limitPassword.max } }}
              </mat-error>
              <mat-error *ngIf="formulario.controls.newPassword.hasError('minlength')">
                {{ 'formulario.validacao.min_length' | translate:  {quantidade: formValidParam.limitPassword.min } }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'paginas.changePassword.formulario.senhaConfirma' | translate }}
              </mat-label>
              <input matInput type="password"
                placeholder="{{ 'paginas.changePassword.formulario.senhaConfirma' | translate }}"
                formControlName="confirmPassword" />

              <mat-error *ngIf="formulario.controls.confirmPassword.hasError('maxlength')">
                {{ 'formulario.validacao.max_length' | translate: {quantidade: formValidParam.limitPassword.max } }}
              </mat-error>
              <mat-error *ngIf="formulario.controls.confirmPassword.hasError('minlength')">
                {{ 'formulario.validacao.min_length' | translate:  {quantidade: formValidParam.limitPassword.min } }}
              </mat-error>
              <mat-error *ngIf="formulario.controls.confirmPassword.hasError('mustMatch')">
                {{ 'formulario.validacao.mustMatch' | translate}}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

      <mat-dialog-actions>
        <div class="buttons">
          <button mat-button type="button" class="primary cancel" (click)="cancel()" [disabled]="!statusBotao.cancelar">
            <i class="material-icons">block</i>
            {{ 'botoes.cancelar' | translate }}
          </button>
          <button type="submit" mat-button class="save" [disabled]="formulario.invalid || !statusBotao.salvar">
            <i class="material-icons">check</i>
            {{ 'botoes.salvar' | translate }}
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>