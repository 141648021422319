<div class="modal" modal-name="modalVersaoTotemUpload">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
    style="width: 40%"
  >
    <div class="modal-container" *ngIf="showUpload">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <ws-upload
        *ngIf="!isLoading"
        [edit]="editForm"
        [customTranslate]="palavrasUpload"
        [multiple]="false"
        [listFiles]="files"
        [limit]="1"
        [accept]="acceptExtensions"
        [statusUpload]="isLoading"
        (fileToSave)="fileToSave = $event"
        (uploadEvent)="uploadFiles($event)"
      >
      </ws-upload>
    </div>
  </div>
</div>
