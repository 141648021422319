<ws-grid
    *ngIf="!isLoading"
    [permissoes]="permissoes"
    [isLoading]="isLoading"
    [acoesGrid]="acoesGrid"
    [colunasGrid]="colunasGrid"
    [dadosGrid]="dadosGrid"
    [registroPorPagina]="registroPorPagina"
    [listaOpcaoPorPagina]="listaOpcaoPorPagina"
    (refreshEvent)="refresh()"
    (editEvent)="openForm($event)"
    (readEvent)="openForm($event)"
    (addEvent)="openForm()"
    (deleteEvent)="confirmDel($event)">
</ws-grid>

<app-tipo-livro-registro-form
  *ngIf="showForm"
  [permissoes]="permissoes"
  [registro]="dadosFormulario"
  (onChange)="refreshGridData($event.dados, $event.tipo)"
  (onDelete)="confirmDel($event)"
  (closeEvent)="closeForm()">
</app-tipo-livro-registro-form>