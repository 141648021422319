import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { forkJoin } from 'rxjs';
import { SentidoCombo } from 'src/app/shared/services/combo/sentido.combo';
import { IRodovia } from '../models/IRodovia';
import { RodoviaService } from '../services/rodovia.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { CorRodoviaCombo } from 'src/app/shared/services/combo/cor-rodovia.combo';

@Component({
  selector: 'app-rodovia-form-component',
  templateUrl: './rodovia-form.component.html',
  styleUrls: ['./rodovia-form.component.scss'],
})
export class RodoviaFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;
  focusValor: boolean = false;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IRodovia = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IRodovia>();

  private registroClean: IRodovia;

  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboSentido1: ICombo[];
  public itemSelecionadoComboSentido1: any;

  public itensComboSentido2: ICombo[];
  public itemSelecionadoComboSentido2: any;

  wordsTranslated: any;
  showCadastro = true;
  showImportacao = false;

  public nameField: string = 'fieldItensComboCor';
  public nameLabelColorPicker: string = 'Cor *';
  public corValue: string = '#000000';

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private rodoviaService: RodoviaService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private sentidoCombo: SentidoCombo,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalRodoviaForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.rodovia.cadastro.form.';
    const words = {
      registro: '',
      editar_registro: '',
      adicionar_registro: '',
      nome: '',
      sigla: '',
      kmInicial: '',
      kmFinal: '',
      primeiroSentido: '',
      segundoSentido: '',
      cor: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    const listSentido = this.sentidoCombo.listComboSelect();

    forkJoin([listSentido]).subscribe((results) => {
      this.itensComboSentido1 = results[0];
      this.itensComboSentido2 = results[0];

      if (this.registro != null) {
        this.loadEditData();
      } else {
        this.criarFormulario();
      }

      this.loadingService.hide();
    });
  }

  loadEditData() {
    this.rodoviaService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.corValue = result.corHTML;
      this.criarFormulario();
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm: IRodovia = this.formulario.value;

    if (this.registro) {
      this.rodoviaService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.rodoviaService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.rodoviaService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo,
      };
      this.onChange.emit(change);
      this.registro = retorno;
      this.formLoading = false;

      this.checkAutoClose();
      this.criarFormulario();
      this.configuracaAcoesBotoes();
    });
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  add(): void {
    this.registro = this.registroClean;

    this.formulario.reset();
    this.criarFormulario();
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.wordsTranslated.registro;
    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      rodoviaId: [
        this.registro != null && this.registro.rodoviaId
          ? this.registro.rodoviaId
          : null,
      ],
      rodoviaNome: [
        this.registro != null && this.registro.rodoviaNome
          ? this.registro.rodoviaNome
          : '',
        Validators.compose([Validators.required]),
      ],
      rodoviaSigla: [
        this.registro != null && this.registro.rodoviaSigla
          ? this.registro.rodoviaSigla
          : '',
        Validators.compose([Validators.required]),
      ],
      rodoviaKmInicio: [
        this.registro != null ? this.registro.rodoviaKmInicio : null,
        Validators.compose([Validators.required]),
      ],
      rodoviaKmFim: [
        this.registro != null && this.registro.rodoviaKmFim
          ? this.registro.rodoviaKmFim
          : null,
        Validators.compose([Validators.required]),
      ],
      sentidoId1: [
        this.registro != null && this.registro.sentidoId1
          ? this.registro.sentidoId1
          : null,
        Validators.compose([Validators.required]),
      ],
      sentidoId2: [
        this.registro != null && this.registro.sentidoId2
          ? this.registro.sentidoId2
          : null,
        Validators.compose([Validators.required]),
      ],   
      corHTML: [
        this.registro != null && this.registro.corHTML !== null
        ? this.registro.corHTML
        : this.corValue,
        Validators.compose([Validators.required])
      ],
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();

    // if (!this.permissoes['update'] && this.registro) {
    //   this.formulario.disable();
    // } else {
    //   this.focusValor = true;
    // }
  }

  onChangeComboSentido1($event) {
    this.itemSelecionadoComboSentido1 = $event;

    this.formulario.patchValue({
      sentidoId1: this.itemSelecionadoComboSentido1,
    });
  }

  onChangeComboSentido2($event) {
    this.itemSelecionadoComboSentido2 = $event;

    this.formulario.patchValue({
      sentidoId2: this.itemSelecionadoComboSentido2,
    });
  }

  onTabClick($event: MatTabChangeEvent) {
    this.showCadastro = false;
    this.showImportacao = false;
    const events = {
      // Cadastro
      0: () => {
        this.showCadastro = true;
      },
      // Importacao
      1: () => {
        this.showImportacao = true;
      },
    };
    events[$event.index]();
  }

  getColor($color: any) {
    var colorHex = `#${$color.hex}`;

    this.formulario.patchValue({
      corHTML: colorHex,
    });
  }

}
