<div class="about">
  <div mat-dialog-title>
    <h2>{{ 'paginas.about.titulo' | translate }}</h2>
    <button mat-button class="close" (click)="dialogRef.close()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <mat-dialog-content>
    <ul *ngIf="registro">
      <li>
        <ul>
          <li>{{ 'paginas.about.nome_sistema' | translate }}</li>
          <li>{{ registro.nome }}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{{ 'paginas.about.versao' | translate }}</li>
          <li>{{ registro.versao }}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{{ 'paginas.about.data_versao' | translate }}</li>
          <li>{{ registro.dataVersao  | date:'dd/MM/yyyy HH:mm:ss' }}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{{ 'paginas.about.idioma' | translate }}</li>
          <li>{{ registro.idioma }}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{{'paginas.about.status' | translate}}</li>
          <li>{{ registro.statusProcessamento | date:'dd/MM/yyyy HH:mm:ss'  }}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{{'paginas.about.base_de_dados' | translate}}</li>
          <li>{{ registro.baseDados }}</li>
        </ul>
      </li>
    </ul>
  </mat-dialog-content>
</div>
