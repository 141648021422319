import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptHelper {
  private readonly _K_E_Y_ = CryptoJS.enc.Utf8.parse('H2pNgZa3pkSluNdw');

  public encrypt(password: string) {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(password)),
      this._K_E_Y_,
      {
        keySize: 128 / 8,
        iv: this._K_E_Y_,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
  }
}