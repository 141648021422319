import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { PermissaoUsuarioService } from '../services/permissaoUsuario.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { IPermissaoUsuarioModulos } from '../models/IPermissaoUsuarioModulos';
import { IPermissaoUsuario } from '../models/IPermissaoUsuario';
import { UntypedFormGroup } from '@angular/forms';
import { TextHelper } from 'src/app/shared/helper/text.helper';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';

@Component({
  selector: 'app-permissao-usuario-modulos',
  templateUrl: './permissao-usuario-modulos.component.html',
  styleUrls: ['./permissao-usuario-modulos.component.scss']
})
export class PermissaoUsuarioModulosComponent implements OnInit {
  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input('dadosUsuario') dadosUsuario: IPermissaoUsuario;
  @ViewChild('search') searchElement: ElementRef;

  public dadosModulos: IPermissaoUsuarioModulos[];
  public dadosModulosOriginal: IPermissaoUsuarioModulos[];
  public usuarioId: number;
  permissaoChange: any;
  filter: string;

  checkModuloTotal: any = [];
  telasPermitidasModulo: any = [];
  expandedModulo: any = [];
  public statusPermissao = {
    concederPermissao: 1,
    retirarPermissao: 0,
  };

  modulos: UntypedFormGroup[];

  constructor(
    private permissaoUsuarioService: PermissaoUsuarioService,
    private loadingService: LoadingService,
    private textHelper: TextHelper,
    private alertaService: AlertaService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.dadosUsuario &&
      typeof changes.dadosUsuario.currentValue != 'undefined'
    ) {
      this.expandedModulo = [];
      let dadosUsuario = changes.dadosUsuario.currentValue;
      this.listModulos(dadosUsuario.usuarioId);
    }
  }

  setPermissaoTotalModulo(modulo: IPermissaoUsuarioModulos, $event) {
    this.dadosModulos.forEach((modulo) => {
      this.expandedModulo[modulo.aplicacaoModuloId] = false;
    });

    for (let index = 1; index < this.expandedModulo.length; index++) {
      if (index == modulo.aplicacaoModuloId) {
        this.expandedModulo[modulo.aplicacaoModuloId] = true;
      }
    }

    let status = this.statusPermissao.retirarPermissao;
    if ($event.checked) {
      status = this.statusPermissao.concederPermissao;
    }

    if (status == 1) {
      var confirmAction = this.dialogService.confirmPermissionAllWindows(
        this.dadosUsuario.usuarioNome
      );
      confirmAction.afterClosed().subscribe((result) => {
        if (result) {
          this.permissaoUsuarioService
            .grantPermissionAllModule(
              this.dadosUsuario.usuarioId,
              modulo.aplicacaoModuloId,
              status
            )
            .subscribe((result) => {
              this.listModulos(this.dadosUsuario.usuarioId);
            });
        } else {
          this.listModulos(this.dadosUsuario.usuarioId);
        }
      });
    } else {
      var confirmAction = this.dialogService.removePermissionAllWindows(
        this.dadosUsuario.usuarioNome
      );
      confirmAction.afterClosed().subscribe((result) => {
        if (result) {
          this.permissaoUsuarioService
            .grantPermissionAllModule(
              this.dadosUsuario.usuarioId,
              modulo.aplicacaoModuloId,
              status
            )
            .subscribe((result) => {
              this.listModulos(this.dadosUsuario.usuarioId);
            });
        } else {
          this.listModulos(this.dadosUsuario.usuarioId);
        }
      });
    }
  }

  applyFilter(value: string) {
    if (typeof value !== 'undefined' && value != null && value !== '') {
      const filterValue = this.textHelper.removerAcentos(value.toLowerCase());
      this.dadosModulos = this.dadosModulos
        .filter((modulos) =>
          modulos.telas.some((item) =>
            this.textHelper
              .removerAcentos(item.aplicacaoTelaDesc)
              .toLowerCase()
              .includes(filterValue)
          )
        )
        .map((modulos) => {
          const n = Object.assign({}, modulos, {
            telas: modulos.telas.filter((item) =>
              this.textHelper
                .removerAcentos(item.aplicacaoTelaDesc)
                .toLowerCase()
                .includes(filterValue)
            )
          });
          return n;
        });
    } else {
      this.dadosModulos = this.dadosModulosOriginal;
      this.filter = '';
    }
  }

  clearFilter() {
    this.dadosModulos = this.dadosModulosOriginal;
    this.filter = '';
  }

  setFocusSearch() {
    this.searchElement.nativeElement.focus();
  }

  listModulos(usuarioId: number) {
    this.usuarioId = usuarioId;
    this.loadingService.show();

    this.permissaoUsuarioService
      .getModulos(this.usuarioId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          var dadosModulosFiltrado: IPermissaoUsuarioModulos[] = [];
          this.dadosModulosOriginal = response;

          response.forEach((modulo) => {
            var qtdTelas = modulo.telas.length;
            var qtdTelasAux: number = 0;

            modulo.telas.forEach((tela) => {
              if (tela.permissaoTotal) {
                qtdTelasAux++;
              }
            });

            this.telasPermitidasModulo[modulo.aplicacaoModuloId] = qtdTelasAux;

            if (qtdTelas == qtdTelasAux) {
              modulo.permissaoTotal = true;
            } else {
              modulo.permissaoTotal = false;
            }

            this.setPermissaoModulo(
              modulo.aplicacaoModuloId,
              modulo.permissaoTotal
            );
            dadosModulosFiltrado.push(modulo);
          });

          this.dadosModulos = dadosModulosFiltrado;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }
  
  onChangePermissaoTela($event: any, moduloId: number) {
    this.permissaoUsuarioService
      .changePermissaoTela(this.usuarioId, $event)
      .subscribe((response) => {
        this.permissaoChange = response;
        this.alertaService.sucesso();
        this.getModulos(moduloId);
      });
  }

  onChangePermissaoTotal($event: any, moduloId: number) {
    this.permissaoUsuarioService
      .changePermissaoTotal(this.usuarioId, $event)
      .subscribe((response) => {
        this.permissaoChange = response;
        this.alertaService.sucesso();
        this.getModulos(moduloId);
      });
  }

  getModulos(moduloId: number) {
    this.permissaoUsuarioService
      .getModulos(this.usuarioId)
      .subscribe((response) => {
        var modulo = response.find(
          (modulo) => modulo.aplicacaoModuloId == moduloId
        );
        this.checkPermissaoModulo(modulo);
      });
  }

  checkPermissaoModulo(modulo: IPermissaoUsuarioModulos) {
    var qtdTelas = modulo.telas.length;
    var qtdTelasAux: number = 0;
    modulo.telas.forEach((tela) => {
      if (tela.permissaoTotal || tela.permissaoParcial) {
        qtdTelasAux++;
      }
    });
    if (qtdTelas == qtdTelasAux) {
      this.setPermissaoModulo(modulo.aplicacaoModuloId, true);
    } else {
      this.setPermissaoModulo(modulo.aplicacaoModuloId, false);
    }

    this.telasPermitidasModulo[modulo.aplicacaoModuloId] = qtdTelasAux;
  }

  setPermissaoModulo(modulo: number, status: boolean) {
    this.checkModuloTotal[modulo] = status;
  }
}
