import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IDestinoEquipamento } from '../models/IDestinoEquipamento';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 16;

@Injectable({
  providedIn: 'root'
})
export class DestinosEquipamentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}DestinoEquipamento`;

  getDestinosGrid(sawId: number): Observable<IDestinoEquipamento[]> {
    return this.http.get<IDestinoEquipamento[]>(`${this.apiUrl}/Grid/${sawId}`);
  }

  insert(dados: any) {
    return this.http.post(`${this.apiUrl}`, dados);
  }

  update(dados: any) {
    return this.http.put(`${this.apiUrl}`, dados);
  }

  delete(dados: any) {
    var params = new HttpParams()
    .set('sawId', dados.sawId)
    .set('destinoId', dados.destinoId);

    return this.http.delete(`${this.apiUrl}`, { params });
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
