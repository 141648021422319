import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IImportacaoAdUsuarios } from '../../models/IImportacaoAdUsuarios';
import { ImportacaoAdService } from '../../services/importacao-ad.service';
import { ConfiguracaoAdCombo } from 'src/app/shared/services/combo/configuracao-ad.combo';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';

@Component({
  selector: 'app-importacao-ad-usuarios-grid',
  templateUrl: './importacao-ad-usuarios-grid.component.html',
  styleUrls: ['importacao-ad-usuarios-grid.component.scss'],
})
export class ImportacaoAdUsuariosGridComponent implements OnInit {
  formulario: UntypedFormGroup;
  formLoading = false;
  statusBotao: IDialogButton;

  usuariosSelecionados: IImportacaoAdUsuarios[];
  public focusFirstLine: boolean = false;
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public acoesGrid: IGridAcoes;
  public filterForm: UntypedFormGroup;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IImportacaoAdUsuarios[] = [];
  public dadosFormulario: IImportacaoAdUsuarios;
  public itensComboPonto: ICombo[];
  public permissoesTela: IGridPermissoes;
  public textoPersonalizado: string;
  public defaultTime: any;
  public foto: string = null;
  public logo: string = null;
  public showForm: boolean = false;
  public isLoading: boolean;
  public itemSelecionadoComboPonto: any;
  public readForm = true;
  public editForm = false;

  public wordsTranslated: any;

  public itensComboConfiguracaoAd: ICombo[] = [];
  public itemSelecionadoComboConfiguracaoAd: any;

  constructor(
    private importacaoAdService: ImportacaoAdService,
    private configuracaoAdCombo: ConfiguracaoAdCombo,
    private loadingService: LoadingService,
    private fb: UntypedFormBuilder,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService
  ) {
    const keyRoot = 'paginas.importacao-ad.usuarios.grid.';
    const words = {
      nomeCompleto: '',
      login: '',
      email: '',
      loginAD: '',
      departamento: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.statusBotao = {
      cancelar: false,
      salvar: false,
    };

    this.isLoading = true;
    this.textoPersonalizado = this.wordsTranslated.textoPersonalizado;

    this.importacaoAdService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();

        this.isLoading = true;
        const observableComboConfiguracaoAd =
          this.configuracaoAdCombo.listComboSelect();

        forkJoin([observableComboConfiguracaoAd]).subscribe((results) => {
          this.itensComboConfiguracaoAd = results[0];
          this.createFilterForm();
        });
      });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      cancelar:
        typeof this.usuariosSelecionados == 'object' &&
        this.usuariosSelecionados.length > 0
          ? true
          : false,
      salvar:
        typeof this.usuariosSelecionados == 'object' &&
        this.usuariosSelecionados.length > 0
          ? true
          : false,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'nomeCompleto',
        label: this.wordsTranslated.nomeCompleto,
        width: '250px',
        sort: true,
      },
      {
        name: 'login',
        label: this.wordsTranslated.login,
        width: '250px',
        sort: true,
      },
      {
        name: 'email',
        label: this.wordsTranslated.email,
        width: '250px',
        sort: true,
      },
      {
        name: 'loginAD',
        label: this.wordsTranslated.loginAD,
        width: '250px',
        sort: true,
      },
      {
        name: 'departamento',
        label: this.wordsTranslated.departamento,
        width: '100px',
        sort: true,
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.dadosGrid = [];

    const { configuracaoAdId } = this.filterForm.value;

    this.importacaoAdService
      .getUsuariosGrid(configuracaoAdId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      check: true,
    };
  }

  filter(): void {
    this.usuariosSelecionados = [];
    this.dadosGrid = [];
    this.editForm = false;
    const { configuracaoAdId } = this.filterForm.value;

    this.loadingService.show();

    this.importacaoAdService
      .getUsuariosGrid(configuracaoAdId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
        this.editForm = response.length > 0 ? true : false;
        this.setFocusLineTrue();
      });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      configuracaoAdId: [null, Validators.compose([Validators.required])],
    });
  }

  changeComboConfiguracaoAd(event) {
    this.filterForm.patchValue({
      configuracaoAdId: event,
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  refreshEvent() {
    if (this.filterForm.valid) this.configuraDadosGrid();
  }

  checkEvent($event) {
    this.usuariosSelecionados = null;
    if ($event) {
      this.usuariosSelecionados = JSON.parse($event);
    }
    this.configuracaAcoesBotoes();
  }

  save(): void {
    if (this.usuariosSelecionados.length > 0) {
      this.showForm = true;
    }
  }

  closeForm() {
    this.showForm = false;
  }

  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 200);
  }

  setFocusLineTrue() {
    setTimeout(() => {
      this.focusFirstLine = true;
    }, 200);
  }
}