import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IAtendimento } from '../models/IAtendimento';
import { IDestinosAtendimento } from '../models/IDestinosAtendimento';
import { IInsertedAtendimento } from '../models/IInsertedAtendimento';
import { ISAWMensagem } from '../models/ISAWMensagem';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 22;

@Injectable({
  providedIn: 'root',
})
export class AtendimentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}Atendimento`;

  loadDestino = new EventEmitter<boolean>(true);
  loadMap = new EventEmitter<any>(true);
  refreshMensagens = new EventEmitter<any>(true);
  refreshAtendimentos = new EventEmitter<any>(true);
  showFormAtendimentoLigacao = new EventEmitter<any>(true);

  getMensagens(sawId: number): Observable<ISAWMensagem[]> {
    return this.http.get<ISAWMensagem[]>(`${this.apiUrl}/Mensagens/${sawId}`);
  }

  getAtendimentos(): Observable<IAtendimento[]> {
    return this.http.get<IAtendimento[]>(`${this.apiUrl}/Atendimentos`);
  }

  getAtendimentoBy(atendimentoId: number): Observable<IInsertedAtendimento> {
    return this.http.get<IInsertedAtendimento>(`${this.apiUrl}/${atendimentoId}`);
  }

  getDestinos(sawId: number): Observable<IDestinosAtendimento[]> {
    return this.http.get<IDestinosAtendimento[]>(`${this.apiUrl}/Destinos/${sawId}`);
  }

  insertAtendimento(dados: any): Observable<IInsertedAtendimento>{
    return this.http.post<IInsertedAtendimento>(`${this.apiUrl}`, dados);
  }

  updateAtendimento(dados: any){
    return this.http.put(`${this.apiUrl}`, dados);
  }

  insertAtendimentoDestino(dados: any) {
    return this.http.post(`${this.apiUrl}/Destino`, dados);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
