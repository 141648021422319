import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IUsuarioAtendente } from '../models/IUsuarioAtendente';
import { UsuarioAtendenteService } from '../services/usuario-atendente.service';

@Component({
  selector: 'app-usuario-atendente-grid',
  templateUrl: './usuario-atendente-grid.component.html'
})
export class UsuarioAtendenteGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IUsuarioAtendente[];
  public dadosUsuarioAtendente: IUsuarioAtendente;
  public focusFirstLine: boolean = false;

  wordsTranslated: any;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private usuarioAtendenteService: UsuarioAtendenteService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.usuario-atendente.grid.';
    const words = {
      usuarioNome: '',
      ramal: '',
      grupoUsuarioDesc: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.usuarioAtendenteService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      enable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'usuarioNome',
        label: this.wordsTranslated.usuarioNome,
        width: '350px',
        sort: true
      },
      {
        name: 'ramal',
        label: this.wordsTranslated.ramal,
        width: '100px',
        sort: true
      },
      {
        name: 'grupoUsuarioDesc',
        label: this.wordsTranslated.grupoUsuarioDesc,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.usuarioAtendenteService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refreshGridData() {
    this.configuraDadosGrid();
  }

  confirmDel(dados: IUsuarioAtendente) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();
        this.usuarioAtendenteService
          .delete(dados.usuarioId)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe(() => {
            this.refreshGridData();
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }

  openForm(dados: IUsuarioAtendente = null, editForm = true) {
    this.dadosUsuarioAtendente = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  desabilitarAtendente(dados: IUsuarioAtendente) {
    const dialogConfirm = this.dialogService.confirmDisable();

    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.usuarioAtendenteService
          .delete(dados.usuarioId)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe(() => {
            this.refreshGridData();
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
      } else {
        this.refreshGridData();
      }
    });
  }

  habilitarAtendente(dados: IUsuarioAtendente) {
    const dialogConfirm = this.dialogService.confirmEnable();

    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.usuarioAtendenteService
          .insert(dados.usuarioId)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData();
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
      } else {
        this.refreshGridData();
      }
    });
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 200);
 }
}