import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'underscore';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { UsuarioService } from '../services/usuario.service';
import { IUsuario } from '../models/IUsuario';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario-grid.component.html'
})
export class UsuarioGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IUsuario[];
  public dadosFormulario: IUsuario;
  public focusFirstLine: boolean = false;
  public permissoesTela: IGridPermissoes;
  public editForm = true;

  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  constructor(
    public usuarioService: UsuarioService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.usuario.grid.';
    let words = {
      resetPassword: '',
      login: '',
      nome: '',
      email: '',
      grupo: '',
      usuarioAdLogin: '',
      possuiFoto: '',
      ativar: '',
      desativar: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.usuarioService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      read: true,
      edit: true,
      create: true,
      delete: false,
      upload: true,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      custom: this.editForm
        ? [
            {
              icon: 'done',
              action: 'enable',
              tooltip: this.wordsTranslated.ativar,
              compare: true,
              propertyToCompare: 'bloqueado',
              valueToCompare: true
            },
            {
              icon: 'block',
              action: 'disable',
              tooltip: this.wordsTranslated.desativar,
              compare: true,
              propertyToCompare: 'bloqueado',
              valueToCompare: false
            },
            {
              icon: 'settings_backup_restore',
              action: 'resetPassword',
              tooltip: this.wordsTranslated.resetPassword
            }
          ]
        : null
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'usuarioLogin',
        label: this.wordsTranslated.login,
        sort: true,
        width: '200px'
      },
      {
        name: 'usuarioNome',
        label: this.wordsTranslated.nome,
        width: '300px',
        sort: true
      },
      {
        name: 'usuarioEmail',
        label: this.wordsTranslated.email,
        width: '300px',
        sort: true
      },
      {
        name: 'grupoUsuarioDesc',
        label: this.wordsTranslated.grupo,
        width: '',
        sort: true
      },
      {
        name: 'usuarioADLogin',
        label: this.wordsTranslated.usuarioAdLogin,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.usuarioService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;

          this.dadosGrid.forEach(usuario => {
            if(usuario.bloqueado){
              usuario.ativo = false;
            } else {
              usuario.ativo = true;
            }
          });
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IUsuario = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosFormulario = $event;
  }

  confirmEnable($event) {
    const dialogConfirm = this.dialogService.confirmEnable();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();
        this.usuarioService
          .enable($event)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refresh();
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
      }
    });
  }
  confirmDisable($event) {
    const dialogConfirm = this.dialogService.confirmDisable();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();
        this.usuarioService
          .disable($event)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refresh();
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
      }
    });
  }
  confirmResetPassword($event) {
    const dialogConfirm = this.dialogService.confirmResetPassword();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();
        this.usuarioService
          .resetPassword($event)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData($event, 'edit');
            this.alertaService.sucesso();
            this.dialog.closeAll();
          });
      }
    });
  }

  customEvent($event) {
    if ($event.action === 'resetPassword') {
      this.confirmResetPassword($event.row);
    }
    if ($event.action === 'enable') {
      this.confirmEnable($event.row);
    }
    if ($event.action === 'disable') {
      this.confirmDisable($event.row);
    }
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        usuarioId: dados.usuarioId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.usuarioService.getById(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
    this.refresh();
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 200);
 }
}
