import { Component } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { RevistaService } from '../services/revista.service';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { finalize } from 'rxjs/operators';
import { IRevista } from '../models/IRevista';
import * as _ from 'underscore';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-revista-grid',
  templateUrl: './revista-grid.component.html',
  styleUrls: ['./revista-grid.component.scss']
})
export class RevistaGridComponent {

  public isLoading: boolean;
  public carregaMidia: string;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public showUpload = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IRevista[];
  public dadosFormulario: IRevista;
  public revistaId: number
  public focusFirstLine: boolean = false;

  wordsTranslated: any;

  constructor(
    private revistaService: RevistaService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private env: EnvironmentService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.revistas.grid.';
    const words = {
      imagem: '',
      revistaTitulo: '',
      revistaDataPublicacao: '',
      pdfTooltip: '',
      imagemTooltip: ""
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    this.isLoading = true;
    this.revistaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.editForm = this.permissoes.update;

        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      custom: [
        {
          icon: "image",
          action: "openImageUpload",
          tooltip: this.wordsTranslated.imagemTooltip,
        },
        {
          icon: "picture_as_pdf",
          action: "openPdfUpload",
          tooltip: this.wordsTranslated.pdfTooltip,
        }
      ]
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'imagem',
        label: this.wordsTranslated.imagem,
        width: '20px',
        sort: false,
        type: 'image',
        zoomImage: 'small'
      },
      {
        name: 'revistaDataPublicacao',
        label: this.wordsTranslated.revistaDataPublicacao,
        width: '80px',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy',
      },
      {
        name: 'revistaTitulo',
        label: this.wordsTranslated.revistaTitulo,
        width: '',
        sort: true,
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.revistaService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.loadImagensGrid();
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  loadImagensGrid() {
    this.dadosGrid.forEach((revista) => {
      revista.imagem = `${this.env.apiUrl}${environment.uploadRevistaImagem}${revista.revistaCaminhoImagem}`;
    })
  }

  confirmDel(dados: IRevista) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.revistaService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe(() => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    })
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados: IRevista, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        revistaId: dados.revistaId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      )
    } else {
      this.revistaService
        .getForm(dados)
        .subscribe((result) => {
          this.dadosGrid = this.gridService.refreshGridData(
            this.dadosGrid,
            result,
            indexPage,
            tipo
          );
        });
    }

    this.refresh();
  }

  customEvent($event) {
    $event.action == "openPdfUpload"
      ? this.carregaMidia = "pdf"
      : this.carregaMidia = "imagem"

    this.showUpload = true;
    this.dadosFormulario = $event.row;
    this.revistaId = $event.row.revistaId;
  }

  openForm(dados: IRevista = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

}
