import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaSistemaService } from '../../services/alerta.service';
import { IAlertaUsuario, IAlertaUsuariosMeioEnvio } from '../../models/IAlerta';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-alerta-usuarios-meio-envio-grid',
  templateUrl: './alerta-usuarios-meio-envio-grid.component.html',
  styleUrls: ['./alerta-usuarios-meio-envio-grid.component.scss']
})
export class AlertaUsuariosMeioEnvioGridComponent implements OnInit, OnChanges {
  @Input() permissoes: IGridPermissoes;
  @Input() alertaId: number;

  dados: IAlertaUsuariosMeioEnvio;
  usuarios: IAlertaUsuario[];

  constructor(
    private alertaSistemaService: AlertaSistemaService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes["alertaId"]) {
      this.getDados();
    }
  }

  getDados() {
    this.loadingService.show();
    this.alertaSistemaService.getUsuariosMeioEnvio(this.alertaId).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    ).subscribe((res) => {
      this.dados = res;
      this.usuarios = [ ...res.usuarios ];
    });
  }

  toggleEvent(alertaId: number, usuarioId: number, meioEnvioId: number) {
    this.loadingService.show();
    this.alertaSistemaService.updateUsuarioMeioEnvio({alertaId, usuarioId, meioEnvioId}).pipe(
      finalize(() => {
        this.loadingService.hide();
      }
    )).subscribe(() => {

    });
  }

  clickSearch(input: HTMLInputElement) {
    const value = input.value;
    if(value && value != "") {
      input.value = "";
      this.filter("");
    } else {
      input.focus();
    }
  }

  filter(value: string) {
    value = this.normalize(value);
    this.dados.usuarios = this.usuarios.filter((usuario) => {
      return this.normalize(usuario.usuarioNome).indexOf(value) != -1;
    });
  }

  private normalize(string: string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }
}
