import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IDestinoEquipamento } from '../../models/IDestinoEquipamento';
import { DestinosEquipamentoService } from '../../services/destinos-equipamento.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';

@Component({
  selector: 'app-destinos-equipamento-grid',
  templateUrl: './destinos-equipamento-grid.component.html',
  styleUrls: ['./destinos-equipamento-grid.component.scss'],
})
export class DestinosEquipamentoGridComponent implements OnInit, OnChanges {
  titleDialog: string;

  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public dadosGrid: IDestinoEquipamento[];
  public searchFilter: any = '';
  public query: any;
  public statusBotao: IDialogButton;

  @Input() selectedSaw: ISAW = null;

  wordsTranslated: any;

  constructor(
    private destinoEquipamentoService: DestinosEquipamentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.destinos-equipamento.destinos.grid.';
    const words = {
      title: '',
      destinoNome: '',
      selecionado: '',
      ordenacaoTotem: '',
      ativo: '',
      buscaDestino: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.destinoEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  ngOnChanges() {
    this.isLoading = true;
    this.destinoEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  configuraDados(): void {
    if (this.permissoesTela.read) {
      this.loadingService.show();
      this.destinoEquipamentoService
        .getDestinosGrid(this.selectedSaw.sawId)
        .pipe(
          finalize(() => {
            this.loadingService.hide();
          })
        )
        .subscribe(
          (response) => {
            this.dadosGrid = response;
          },
          (err) => {
            this.alertaService.erro();
          }
        );
    }
  }

  refresh() {
    this.configuraDados();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.query != '' && this.query != null) {
      const dialogConfirm = this.dialogService.confirmClearSearch();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.query = '';
        }
      });
    } else {
      if (this.permissoesTela.update) {
        moveItemInArray(
          this.dadosGrid,
          event.previousIndex,
          event.currentIndex
        );

        for (let index = 0; index < this.dadosGrid.length; index++) {
          if (this.dadosGrid[index].ordenacaoTotem > 0) {
            if (event.currentIndex != 0) {
              if (
                this.dadosGrid[event.previousIndex].ordenacaoTotem != 0 &&
                this.dadosGrid[event.currentIndex - 1].ordenacaoTotem != 0
              ) {
                this.dadosGrid[index].ordenacaoTotem = index + 1;
                var dado = this.dadosGrid[index];

                this.destinoEquipamentoService.update(dado).subscribe(() => {});
              }
            } else {
              if (this.dadosGrid[event.previousIndex].ordenacaoTotem != 0) {
                this.dadosGrid[index].ordenacaoTotem = index + 1;
                var dado = this.dadosGrid[index];

                this.destinoEquipamentoService.update(dado).subscribe(() => {});
              }
            }
          }
        }
      }
    }
  }

  changeToogle(dado: IDestinoEquipamento) {
    if (this.permissoesTela.update) {
      if (dado.selecionado) {
        this.destinoEquipamentoService.delete(dado).subscribe(() => {
          this.configuraDados();
        });
      }
      if (!dado.selecionado) {
        this.destinoEquipamentoService.insert(dado).subscribe(() => {
          this.configuraDados();
        });
      }
    }
  }

  changeToogleAtivo(dado: IDestinoEquipamento) {
    if (this.permissoesTela.update) {
      if (dado.ativo) {
        dado.ativo = false;
      } else {
        dado.ativo = true;
      }

      this.destinoEquipamentoService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }
}
