import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dynamicPipe' })
export class DynamicPipe implements PipeTransform {
  transform(value: string, modifier: string, format: string) {
    if (modifier === 'getNumber') {
      if (value && format) {
        const nume = Number(value);
        const form = Number(format);
        return nume.toFixed(form);
      }
    }
    if (modifier === 'getSimNao') {
      if (value) {
        return 'Sim';
      } else {
        return 'Não';
      }
    }
    if (modifier === 'getAtivo') {
      if (value) {
        return 'Ativo';
      } else {
        return 'Inativo';
      }
    }
    if (modifier === 'getLiberada') {
      if (value) {
        return 'Liberada';
      } else {
        return 'Não Liberada';
      }
    }
    if (modifier === 'getDate') {
      if(value == null || value == ''){
        return '';
      }

      let t = new Date(value);
      let valid = !isNaN(t.valueOf());
      if (value && format && valid) {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, format);
      }
    }
    return value;
  }
}
