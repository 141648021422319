import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ImportacaoAdService } from '../services/importacao-ad.service';

@Component({
  selector: 'app-importacao-ad-tabs',
  templateUrl: './importacao-ad-tab.component.html',
  styleUrls: ['./importacao-ad-tab.component.scss']
})
export class ImportacaoAdTabComponent implements OnInit {
  showUsuarios = true;
  showConfiguracao = false;

  public isLoading: boolean;

  public permissoesTela: IGridPermissoes;
  public editForm = true;

  constructor(
    private importacaoAdService: ImportacaoAdService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.importacaoAdService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
      });
  }

  onTabClick($event: MatTabChangeEvent) {
    this.showUsuarios = false;
    this.showConfiguracao = false;

    const events = {
      // Importacao
      0: () => {
        this.showUsuarios = true;
      },
      // Configuracao
      1: () => {
        this.showConfiguracao = true;
      }
    };

    events[$event.index]();
  }
}
