import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUsuarioRamal } from '../../models/IUsuarioRamal';

import { AuthService } from '../auth/auth.service';
import { ISoftPhoneEquipammento } from '../../models/ISoftPhoneEquipammento';
import { ConfiguracaoService } from '../configuracao/configuracao.service';

import { SIPml } from 'src/assets/js/SIPml-api';
import { ISAWNumeroSerie } from '../../models/ISAWNumeroSerie';
import { DeviceAudioVideo } from '../device-audio-video/device-audio-video.service';
import { EnvironmentService } from '../env/environment.service';
declare let SIPml: any;


@Injectable({
  providedIn: 'root',
})
export class SoftPhoneService implements OnInit, OnDestroy{
  numero = '';
  ramal = '';
  senha = '';
  softPhone: boolean = false;
  newCallSoftPhone = new EventEmitter<string>();

  ramalToCall = new EventEmitter<string>();
  statusSoftPhone = new EventEmitter<any>();
  showSoftPhone = new EventEmitter<any>();
  showCallingSoftPhone = new EventEmitter<any>();
  showRamalSoftPhone = new EventEmitter<string>();

  sTransferNumber;
  oRingTone;
  oRingbackTone;
  oSipStack;
  oSipSessionRegister;
  oSipSessionCall;
  oSipSessionTransferCall;
  videoRemote;
  videoLocal;
  audioRemote;
  bFullScreen = false;
  oNotifICall;
  bDisableVideo = false;
  viewVideoLocal;
  viewVideoRemote;
  viewLocalScreencast; // <video> (webrtc) or <div> (webrtc4all)
  oConfigCall;
  oReadyStateTimer;
  ringtone;
  ringbacktone;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private configuracaoService: ConfiguracaoService,
    private deviceAudioVideo: DeviceAudioVideo,
    private env: EnvironmentService
  ) {
    this.ringtone = document.getElementById('ringtone');
    this.ringbacktone = document.getElementById('ringbacktone');
  }

  apiUrl = `${this.env.apiUrl}`;
  apiUrlAtendimento = `${this.env.apiUrl}SAWAtendimento`;

  ngOnDestroy() {
    console.log('SIP: Removendo softphone');
    this.oSipStack = null;
    SIPml = null;
  }

  ngOnInit() {
    console.log('SIP: Iniciando softphone');

    let oReadyStateTimer = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(oReadyStateTimer);
        // initialize SIPML5
        SIPml.init();
        this.preInit();
      }
    }, 500);
  }

  preInit() {
    // set debug level
    SIPml.setDebugLevel(true);
    this.postInit();
  }

  postInit() {
    // check for WebRTC support
    if (!SIPml.isWebRtcSupported()) {
      // is it chrome?
      if (SIPml.getNavigatorFriendlyName() == 'chrome') {
        console.error(
          "You're using an old Chrome version or WebRTC is not enabled."
        );
        return;
      } else {
        console.error('webrtc-everywhere extension is not installed..');
      }
    }

    // checks for WebSocket support
    if (!SIPml.isWebSocketSupported()) {
      console.error("Your browser don't support WebSockets.");
      return;
    }

    // FIXME: displays must be per session
    this.viewVideoLocal = this.videoLocal;
    this.viewVideoRemote = this.videoRemote;

    if (!SIPml.isWebRtcSupported()) {
      console.error(
        "Your browser don't support WebRTC.\naudio/video calls will be disabled."
      );
    }

    this.oConfigCall = {
      audio_remote: this.audioRemote,
      video_local: this.viewVideoLocal,
      video_remote: this.viewVideoRemote,
      screencast_window_id: 0x00000000, // entire desktop
      bandwidth: { audio: undefined, video: undefined },
      video_size: {
        minWidth: undefined,
        minHeight: undefined,
        maxWidth: undefined,
        maxHeight: undefined,
      },
      events_listener: { events: '*', listener: this.onSipEventSession },
      sip_caps: [
        { name: '+g.oma.sip-im' },
        { name: 'language', value: '"en,fr"' },
      ],
    };

    this.sipRegister();
  }

  getNumero() {
    return this.numero;
  }

  showCalling(numero: string) {
    let equipamento: ISoftPhoneEquipammento = null;
    this.numero = numero;

    this.getEquipamento(this.numero).subscribe(
      (result: ISoftPhoneEquipammento) => {
        if (result) {
          equipamento = result;
        }

        let emitter = {
          numero,
          equipamento,
        };

        this.showCallingSoftPhone.emit(emitter);

        let evento = {
          tipoEvento: 'ligacao',
          numeroSerie: {
            numeroSerie: equipamento.numeroSerie,
          },
        };
        this.configuracaoService.processarEventoSignal.emit(evento);
      }
    );
  }

  getRamal(usuarioId: number): Observable<IUsuarioRamal> {
    return this.http.get<IUsuarioRamal>(
      `${this.apiUrl}UsuarioAtendente/Ramal/${usuarioId}`
    );
  }

  setChamadaEquipamento(numeroSerie: string) {
    let arg = {
      numeroSerie,
    };
    this.http.post(`${this.env.hubUrl}LigacaoEquipamento`, arg).toPromise();
  }

  setStartCall(totem: any, atendente: any) {
    let dados = {
      totem,
      atendente,
    };
    this.http.post(`${this.env.hubUrl}EndCall`, dados).toPromise();
  }

  getEquipamento(ramal: string): Observable<ISoftPhoneEquipammento> {
    return this.http.get<ISoftPhoneEquipammento>(
      `${this.apiUrl}SoftPhone/Equipamento/${ramal}`
    );
  }

  startRingTone = () => {
    try {
      this.ringtone.play();
    } catch (e) {}
  };

  stopRingTone = () => {
    try {
      this.ringtone.pause();
    } catch (e) {}
  };

  startRingbackTone = () => {
    try {
      this.ringbacktone.play();
    } catch (e) {}
  };

  stopRingbackTone = () => {
    try {
      this.ringbacktone.pause();
    } catch (e) {}
  };

  onSipEventStack = (e) => {
    console.log('==stack event = ' + e.type);
    if(navigator.onLine) {
      switch (e.type) {
        case 'started': {
          // catch exception for IE (DOM not ready)
          try {
            // LogIn (REGISTER) as soon as the stack finish starting
            this.oSipSessionRegister = this.oSipStack.newSession('register', {
              expires: 200,
              events_listener: { events: '*', listener: this.onSipEventSession },
              sip_caps: [
                { name: '+g.oma.sip-im', value: null },
                //{ name: '+sip.ice' }, // rfc5768: FIXME doesn't work with Polycom TelePresence
                { name: '+audio', value: null },
                { name: 'language', value: '"en,fr"' },
              ],
            });
            this.oSipSessionRegister.register();
          } catch (e) {
            console.log('<b>1:' + e + '</b>');
            //btnRegister.disabled = false;
          }
          break;
        }
        case 'stopping':
        case 'stopped':
        case 'failed_to_start':
        case 'failed_to_stop': {
          var bFailure =
            e.type == 'failed_to_start' || e.type == 'failed_to_stop';
          this.oSipSessionCall = null;
          this.sipUnRegister();
  
          //uiOnConnectionEvent(false, false);
  
          this.stopRingbackTone();
          this.stopRingTone();
  
          //uiVideoDisplayShowHide(false);
          //divCallOptions.style.opacity = 0;
          this.statusSoftPhone.emit(false);
          console.log(
            bFailure
              ? '<i>Disconnected: <b>' + e.description + '</b></i>'
              : '<i>Disconnected</i>'
          );
  
          this.sipStopStack();
          break;
        }
  
        case 'i_new_call': {
          if (this.oSipSessionCall) {
            // do not accept the incoming call if we're already 'in call'
            e.newSession.hangup(); // comment this line for multi-line support
          } else {
            this.oSipSessionCall = e.newSession;
            // start listening for events
            this.oSipSessionCall.setConfiguration(this.oConfigCall);
  
            // alert("Answer / Reject")
            console.log('Answer / Reject');
            //uiBtnCallSetText('Answer');
  
            this.startRingTone();
  
            var sRemoteNumber =
              this.oSipSessionCall.getRemoteFriendlyName() || 'unknown';
            console.log(
              '<i>Incoming call from [<b>' + sRemoteNumber + '</b>]</i>'
            );
            //this.showNotifICall(sRemoteNumber);
  
            // let mensagem = `Recebendo chamada de [${sRemoteNumber}]`;
            this.showCalling(sRemoteNumber);
          }
          break;
        }
  
        case 'm_permission_requested': {
          //divGlassPanel.style.visibility = 'visible';
          break;
        }
        case 'm_permission_accepted':
        case 'm_permission_refused': {
          //divGlassPanel.style.visibility = 'hidden';
          if (e.type == 'm_permission_refused') {
            console.log('SIP: Media stream permission denied');
          }
          break;
        }
  
        case 'starting':
        default:
          break;
      }
    }
  };

  onSipEventSession = (e) => {
    console.log('==session event = ' + e.type);

    if(navigator.onLine) {
      switch (e.type) {
        case 'connecting':
        case 'connected': {
          var bConnected = e.type == 'connected';
          if (e.session == this.oSipSessionRegister) {
            // this.uiOnConnectionEvent(bConnected, !bConnected);
            console.log('<i>' + e.description + '</i>');
  
            this.softPhone = true;
            localStorage.setItem(
              `${environment.prefixo}softPhone`,
              JSON.stringify(this.softPhone)
            );
  
            this.statusSoftPhone.emit(true);
          } else if (e.session == this.oSipSessionCall) {
            //if (window.btnBFCP) window.btnBFCP.disabled = false;
  
            if (bConnected) {
              this.stopRingbackTone();
              this.stopRingTone();
  
              if (this.oNotifICall) {
                this.oNotifICall.cancel();
                this.oNotifICall = null;
              }
            }
  
            console.log('<i>' + e.description + '</i>');
            //divCallOptions.style.opacity = bConnected ? 1 : 0;
  
            if (SIPml.isWebRtc4AllSupported()) {
              // IE don't provide stream callback
              //uiVideoDisplayEvent(false, true);
              //uiVideoDisplayEvent(true, true);
            }
  
            this.showSoftPhone.emit(true);
          }
          break;
        } // 'connecting' | 'connected'
        case 'terminating':
        case 'terminated': {
          if (e.session == this.oSipSessionRegister) {
            //uiOnConnectionEvent(false, false);
  
            this.oSipSessionCall = null;
            this.oSipSessionRegister = null;
  
            console.log('<i>' + e.description + '</i>');
  
            localStorage.removeItem(`${environment.prefixo}softPhone`);
  
            this.statusSoftPhone.emit(false);
          } else if (e.session == this.oSipSessionCall) {
            this.uiCallTerminated(e.description);
          }
          break;
        } // 'terminating' | 'terminated'
  
        case 'm_stream_video_local_added': {
          if (e.session == this.oSipSessionCall) {
            //uiVideoDisplayEvent(true, true);
          }
          break;
        }
        case 'm_stream_video_local_removed': {
          if (e.session == this.oSipSessionCall) {
            //uiVideoDisplayEvent(true, false);
          }
          break;
        }
        case 'm_stream_video_remote_added': {
          if (e.session == this.oSipSessionCall) {
            //uiVideoDisplayEvent(false, true);
          }
          break;
        }
        case 'm_stream_video_remote_removed': {
          if (e.session == this.oSipSessionCall) {
            //uiVideoDisplayEvent(false, false);
          }
          break;
        }
  
        case 'm_stream_audio_local_added':
        case 'm_stream_audio_local_removed':
        case 'm_stream_audio_remote_added':
        case 'm_stream_audio_remote_removed': {
          break;
        }
  
        case 'i_ect_new_call': {
          this.oSipSessionTransferCall = e.session;
          break;
        }
  
        case 'i_ao_request': {
          if (e.session == this.oSipSessionCall) {
            var iSipResponseCode = e.getSipResponseCode();
            if (iSipResponseCode == 180 || iSipResponseCode == 183) {
              this.startRingbackTone();
              console.log('<i>Remote ringing...</i>');
            }
          }
          break;
        }
  
        case 'm_early_media': {
          if (e.session == this.oSipSessionCall) {
            this.stopRingbackTone();
            this.stopRingTone();
            console.log('<i>Early media started</i>');
          }
          break;
        }
  
        case 'm_local_hold_ok': {
          if (e.session == this.oSipSessionCall) {
            if (this.oSipSessionCall.bTransfering) {
              this.oSipSessionCall.bTransfering = false;
              // this.AVSession.TransferCall(this.transferUri);
            }
            this.oSipSessionCall.bHeld = true;
          }
          break;
        }
        case 'm_local_hold_nok': {
          if (e.session == this.oSipSessionCall) {
            this.oSipSessionCall.bTransfering = false;
            console.log('<i>Failed to place remote party on hold</i>');
          }
          break;
        }
        case 'm_local_resume_ok': {
          if (e.session == this.oSipSessionCall) {
            this.oSipSessionCall.bTransfering = false;
  
            this.oSipSessionCall.bHeld = false;
  
            if (SIPml.isWebRtc4AllSupported()) {
              // IE don't provide stream callback yet
              //uiVideoDisplayEvent(false, true);
              //uiVideoDisplayEvent(true, true);
            }
          }
          break;
        }
        case 'm_local_resume_nok': {
          if (e.session == this.oSipSessionCall) {
            this.oSipSessionCall.bTransfering = false;
            //btnHoldResume.disabled = false;
            console.log('<i>Failed to unhold call</i>');
          }
          break;
        }
        case 'm_remote_hold': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Placed on hold by remote party</i>');
          }
          break;
        }
        case 'm_remote_resume': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Taken off hold by remote party</i>');
          }
          break;
        }
        case 'm_bfcp_info': {
          if (e.session == this.oSipSessionCall) {
            console.log('BFCP Info: <i>' + e.description + '</i)>');
          }
          break;
        }
  
        case 'o_ect_trying': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Call transfer in progress...</i>');
          }
          break;
        }
        case 'o_ect_accepted': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Call transfer accepted</i>');
  
            this.sipHangUp();
          }
          break;
        }
        case 'o_ect_completed':
        case 'i_ect_completed': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Call transfer completed</i>');
            //btnTransfer.disabled = false;
            if (this.oSipSessionTransferCall) {
              this.oSipSessionCall = this.oSipSessionTransferCall;
            }
            this.oSipSessionTransferCall = null;
  
            this.sipHangUp();
          }
          break;
        }
        case 'o_ect_failed':
        case 'i_ect_failed': {
          if (e.session == this.oSipSessionCall) {
            console.log('<i>Call transfer failed</i>');
            //btnTransfer.disabled = false;
          }
          break;
        }
        case 'o_ect_notify':
        case 'i_ect_notify': {
          if (e.session == this.oSipSessionCall) {
            console.log(
              '<i>Call Transfer: <b>' +
                e.getSipRespo +
                ' ' +
                e.description +
                '</b></i>'
            );
            if (e.getSipResponseCode() >= 300) {
              if (this.oSipSessionCall.bHeld) {
                this.oSipSessionCall.resume();
              }
              //btnTransfer.disabled = false;
            }
          }
          break;
        }
        case 'i_ect_requested': {
          if (e.session == this.oSipSessionCall) {
            var s_message =
              'Do you accept call transfer to [' +
              e.getTransferDestinationFriendlyName() +
              ']?'; //FIXME
            if (confirm(s_message)) {
              console.log('<i>Call transfer in progress...</i>');
              this.oSipSessionCall.acceptTransfer();
              break;
            }
            this.oSipSessionCall.rejectTransfer();
          }
          break;
        }
      }
    }
  };

  uiCallTerminated(s_description) {
    // uiBtnCallSetText("Call");
    // btnHangUp.value = 'HangUp';
    // btnHoldResume.value = 'hold';
    // btnMute.value = "Mute";
    // btnCall.disabled = false;
    // btnHangUp.disabled = true;
    // if (window.btnBFCP) window.btnBFCP.disabled = true;
    this.sipHangUp();
    this.oSipSessionCall = null;

    if (this.oNotifICall) {
      this.oNotifICall.cancel();
      this.oNotifICall = null;
    }

    // uiVideoDisplayEvent(false, false);
    // uiVideoDisplayEvent(true, false);

    // setTimeout(function () { if (!oSipSessionCall) txtCallStatus.innerHTML = ''; }, 2500);
  }

  sipRegister = () => {
    try {
      let dadosUsuario = this.authService.getLoginDados();
      let dadosSip = this.configuracaoService.getSip();

      let wssProtocolo = 'ws';
      if (dadosSip.sipWss) {
        wssProtocolo = 'wss';
      }

      this.getRamal(dadosUsuario.dados.id).subscribe((result) => {
        this.ramal = result.ramal;
        this.senha = result.senha;

        Notification.requestPermission();
        SIPml.setDebugLevel(true);

        this.oSipStack = new SIPml.Stack({
          realm: `sip:${dadosSip.sipDomain}`,
          impi: this.ramal,
          impu: `sip:${this.ramal}@${dadosSip.sipDomain}`,
          password: this.senha,
          display_name: this.ramal,
          websocket_proxy_url: `${wssProtocolo}://${dadosSip.sipDomain}:${dadosSip.sipPortWs}/ws`,
          outbound_proxy_url: `udp://${dadosSip.sipDomain}:${dadosSip.sipPortUdp}`,
          ice_servers: [],
          enable_rtcweb_breaker: true,
          events_listener: { events: '*', listener: this.onSipEventStack },
          enable_early_ims: '',
          enable_media_stream_cache: true,
          sip_headers: [
            {
              name: 'User-Agent',
              value: 'IM-client/OMA1.0 sipML5-v1.2016.03.04',
            },
            { name: 'Organization', value: '' },
          ],
        });
        if (this.oSipStack.start() != 0) {
          console.log('SIP: Falha ao iniciar o SIP');
        } else return;
      });
    } catch (e) {
      console.log('SIP: :' + e);
    }
  };

  sipUnRegister = () => {
    if(this.oSipStack && this.oSipSessionRegister)
    {
      this.oSipSessionRegister.unregister();
      window.location.reload();
    } 
  };

  sipStopStack() 
  {
    if(this.oSipStack)
    {
      this.oSipStack.stop();// shutdown all sessions
      SIPml.Stack = null;
      SIPml = null;
    }
  }

  sipTransfer = (ramal: number) => {
    if (this.oSipSessionCall) {
      // var s_destination = prompt('Enter destination number', '');
      //if (!tsk_string_is_null_or_empty(s_destination)) {
      //btnTransfer.disabled = true;
      if (this.oSipSessionCall.transfer(ramal) != 0) {
        console.log('<i>Call transfer failed</i>');
        //btnTransfer.disabled = false;
        return;
      }
      console.log('<i>Transfering the call...</i>');
      //}
    }
  };

  sipCall = (video_remoto, video_local, callTo = 0) => {
    this.deviceAudioVideo.getMediaDevices().then((r) => {
      let s_type = r.video == true ? 'call-audiovideo' : 'call-audio';
      this.oConfigCall = {
        // audio_remote: audio_remoto,
        video_local: video_local,
        video_remote: video_remoto,
        screencast_window_id: 0x00000000, // entire desktop
        bandwidth: { audio: undefined, video: undefined },
        video_size: {
          minWidth: undefined,
          minHeight: undefined,
          maxWidth: undefined,
          maxHeight: undefined,
        },
        events_listener: { events: '*', listener: this.onSipEventSession },
        sip_caps: [
          { name: '+g.oma.sip-im' },
          { name: 'language', value: '"en,fr"' },
        ],
      };
      if (this.oSipStack && !this.oSipSessionCall) {
        if (s_type == 'call-screenshare') {
          if (!SIPml.isScreenShareSupported()) {
            alert('Screen sharing not supported. Are you using chrome 26+?');
            return;
          }
          if (!location.protocol.match('https')) {
            if (
              confirm(
                'Screen sharing requires https://. Do you want to be redirected?'
              )
            ) {
              this.sipUnRegister();
              //window.location = 'https://ns313841.ovh.net/call.htm';
            }
            return;
          }
        }
        //btnCall.disabled = true;
        //btnHangUp.disabled = false;

        if (window.localStorage) {
          //oConfigCall.bandwidth = tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.bandwidth')); // already defined at stack-level but redifined to use latest values
          //oConfigCall.video_size = tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.video_size')); // already defined at stack-level but redifined to use latest values
        }
        //debugger;

        // create call session
        this.oSipSessionCall = this.oSipStack.newSession(
          s_type,
          this.oConfigCall
        );
        // make call
        if (this.oSipSessionCall.call(callTo) != 0) {
          this.oSipSessionCall = null;
          console.log('Failed to make call');
          //btnCall.disabled = false;
          //btnHangUp.disabled = true;
          return;
        }
        //saveCallOptions();
      } else if (this.oSipSessionCall) {
        console.log('<i>Connecting...</i>');
        this.oSipSessionCall.accept(this.oConfigCall);
      }
    });
  };

  sipHangUp = () => {
    if (this.oSipSessionCall) {
      this.showCallingSoftPhone.emit(false);
      this.numero = '';
      this.showSoftPhone.emit(false);
      console.log('<i>Terminating the call...</i>');
      this.oSipSessionCall.hangup({
        events_listener: { events: '*', listener: this.onSipEventSession },
      });
    }
  };

  sipToggleHoldResume() {
    if (this.oSipSessionCall) {
      var i_ret;
      console.log(
        this.oSipSessionCall.bHeld
          ? '<i>Resuming the call...</i>'
          : '<i>Holding the call...</i>'
      );
      i_ret = this.oSipSessionCall.bHeld
        ? this.oSipSessionCall.resume()
        : this.oSipSessionCall.hold();
      if (i_ret != 0) {
        console.log('<i>Hold / Resume failed</i>');
        return;
      }
    }
  }

  mute(status) {
    this.offMic(status);
    this.offCam(status);
  }

  offMic(bEnabled) {
    if (this.oSipSessionCall != null) {
      if (this.oSipSessionCall.o_session != null) {
        if (this.oSipSessionCall.o_session.o_stream_local != null) {
          if (
            this.oSipSessionCall.o_session.o_stream_local.getAudioTracks()
              .length > 0
          ) {
            for (
              var nTrack = 0;
              nTrack <
              this.oSipSessionCall.o_session.o_stream_local.getAudioTracks()
                .length;
              nTrack++
            ) {
              this.oSipSessionCall.o_session.o_stream_local.getAudioTracks()[
                nTrack
              ].enabled = bEnabled;
            }
          } else {
            console.log('SIP: [MIC] Sem audio');
          }
        } else {
          console.log('SIP: [MIC] Sem transmissao');
        }
      } else {
        console.log('SIP: [MIC] Sessao nula');
      }
    } else {
      console.log('SIP: [MIC] Sem ligacao');
    }
  }

  offCam(bEnabled) {
    console.log('SIP: [CAM] ' + bEnabled);
    if (this.oSipSessionCall != null) {
      if (this.oSipSessionCall.o_session != null) {
        if (this.oSipSessionCall.o_session.o_stream_local != null) {
          if (
            this.oSipSessionCall.o_session.o_stream_local.getVideoTracks()
              .length > 0
          ) {
            for (
              var nTrack = 0;
              nTrack <
              this.oSipSessionCall.o_session.o_stream_local.getVideoTracks()
                .length;
              nTrack++
            ) {
              this.oSipSessionCall.o_session.o_stream_local.getVideoTracks()[
                nTrack
              ].enabled = bEnabled;
            }
          } else {
            console.log('SIP: [CAM] Sem video');
          }
        } else {
          console.log('SIP: [CAM] Sem transmissao');
        }
      } else {
        console.log('SIP: [CAM] Sessao nula');
      }
    } else {
      console.log('SIP: [CAM] Sem ligacao');
    }
  }

  setHold(sawId) {
    return this.http.post(`${this.apiUrlAtendimento}/Hold/${sawId}`, {});
  }

  getEquipamentoNumeroSerie(numeroSerie: string): Observable<ISAWNumeroSerie> {
    return this.http.get<ISAWNumeroSerie>(
      `${this.apiUrlAtendimento}/Equipamento/${numeroSerie}`
    );
  }
}
