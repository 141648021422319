import { Component, OnInit } from '@angular/core';

import { LoadingService } from '../../../shared/services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public loading = false;
  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.showLoading.subscribe(state => {
      this.loading = state;
    });
  }
}
