import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'underscore';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';

import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { IPerguntasFrequentes } from '../models/IPerguntasFrequentes';
import { PerguntasFrequentesService } from '../services/perguntas-frequentes.service';

@Component({
  selector: 'app-perguntas-frequentes-grid',
  templateUrl: './perguntas-frequentes-grid.component.html'
})
export class PerguntasFrequentesGridComponent implements OnInit {

  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IPerguntasFrequentes[];
  public dadosPerguntasFrequentes: IPerguntasFrequentes;
  public focusFirstLine: boolean = false;
  public editForm = true;
  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  constructor(
    public perguntasFrequentesService: PerguntasFrequentesService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private dialogService: DialogService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.perguntasFrequentes.grid.';
    let words = {
      pergunta: '',
      resposta: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.perguntasFrequentesService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'perguntaPortugues',
        label: this.wordsTranslated.pergunta,
        width: '550px',
        sort: true
      },
      {
        name: 'respostaPortugues',
        label: this.wordsTranslated.resposta,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.perguntasFrequentesService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          var dadosFiltrados: IPerguntasFrequentes[] = [];
          response.forEach((execucao) => {
            if(execucao.perguntaPortugues.length > 79 || execucao.respostaPortugues.length > 190){
              execucao.perguntaPortugues = `${execucao.perguntaPortugues.substring(0,79)}...`
              execucao.respostaPortugues = `${execucao.respostaPortugues.substring(0,190)}...`;
            }
            dadosFiltrados.push(execucao)
          });
          this.dadosGrid = dadosFiltrados;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IPerguntasFrequentes = null, editForm = true) {
    this.dadosPerguntasFrequentes = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosPerguntasFrequentes = $event;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {

      if(dados.perguntaPortugues.length > 79 || dados.respostaPortugues.length > 190){
        dados.perguntaPortugues = `${dados.perguntaPortugues.substring(0,79)}...`;
        dados.respostaPortugues = `${dados.respostaPortugues.substring(0,190)}...`;
      }

      indexPage = _.findLastIndex(this.dadosGrid, {
        perguntaId: dados.perguntaId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.perguntasFrequentesService
      .getForm(dados)
      .subscribe((result) => {
        if(result.perguntaPortugues.length > 79 || result.respostaPortugues.length > 190){
          result.perguntaPortugues = `${result.perguntaPortugues.substring(0,79)}...`;
          result.respostaPortugues = `${result.respostaPortugues.substring(0,190)}...`;
        }
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  confirmDel(dados: IPerguntasFrequentes) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.perguntasFrequentesService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }

  setFocusLineFalse(){
    setTimeout(() => {
      this.focusFirstLine = false
    }, 500);
  }
}
