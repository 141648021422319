import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IRodoviaCoordenadas } from '../../models/IRodoviaCoordenadas';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { RodoviaCoordenadaService } from '../../services/rodovia-coordenada.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { finalize } from 'rxjs/operators';
import * as L from 'leaflet';

@Component ({
    selector: 'app-rodovia-mapa',
    templateUrl: './rodovia-mapa.component.html',
    styleUrls: ['./rodovia-mapa.component.scss']
})
export class RodoviaMapaComponent implements OnInit {
    private osmServerUrl: string;
    public wordsTranslated : any;

    @Input() rodoviaId: number | null;
    @Input() corHTML: string | null;

    private coordenadas: IRodoviaCoordenadas[] = [];
    public showMap: boolean = false;
    public hasCoordinates: boolean = false;
    public textoPersonalizado: string = "";

    constructor(
        private loadingService: LoadingService,
        private wordsTranslate: WordsTranslateService,
        private rodoviaCoordenadaService: RodoviaCoordenadaService,
        env: EnvironmentService
    ) {
      const keyRoot = 'paginas.rodovia.cadastro.form.';
      const word = {
          textoPersonalizado: '',
      };
      this.wordsTranslated = this.wordsTranslate.get(word, keyRoot)
      this.textoPersonalizado = this.wordsTranslated.textoPersonalizado;
      this.osmServerUrl = env.osmServerUrl;
    }

    ngOnInit(): void {
        this.loadCoordenadas();
    }

    private loadCoordenadas() {
        if (this.rodoviaId != null) {
            this.loadingService.show();
            this.rodoviaCoordenadaService.get(this.rodoviaId).pipe(
                finalize(() => {
                    this.loadingService.hide();
                })
            ).subscribe((res) => {
                if(res && res.length > 0) {
                    this.showMap = true;
                    this.coordenadas = res;
                    setTimeout(() => {
                        this.initMap();
                    }, 0);
                }
                this.hasCoordinates = false;
            });
        }
    }

    private initMap() {
        const map = L.map("map", {
            zoom: 10,
            center: [this.coordenadas[0].coordenadaLatitude, this.coordenadas[0].coordenadaLongitude]
        });
        L.tileLayer(this.osmServerUrl, {
            attribution: '© OpenStreetMap contributors'
          }).addTo(map);

        const latLng = this.coordenadas.map(x => [x.coordenadaLatitude, x.coordenadaLongitude] as L.LatLngTuple);
        L.polyline(latLng, {
            color: this.corHTML ?? 'red'
        }).addTo(map);
        
        map.fitBounds(latLng);
    }
}