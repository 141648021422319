import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'underscore';

import { IImportacaoAd } from '../../models/IImportacaoAd';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ImportacaoAdService } from '../../services/importacao-ad.service';
import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-importacao-ad-configuracao-grid',
  templateUrl: './importacao-ad-configuracao-grid.component.html'
})
export class ImportacaoAdConfiguracaoGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IImportacaoAd[];
  public dadosEmpresa: IImportacaoAd;

  public editForm = true;
  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  constructor(
    private importacaoAdService: ImportacaoAdService,
    private loadingService: LoadingService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.importacao-ad.configuracao.grid.';
    let words = {
      id: '',
      nomeUsuario: '',
      linkedServer: '',
      server: '',
      ou: '',
      domain: '',
      protocol: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.importacaoAdService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: false,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'usuarioADParametroId',
        label: this.wordsTranslated.id,
        width: '70px',
        sort: true
      },
      {
        name: 'usuarioADParametroNome',
        label: this.wordsTranslated.nomeUsuario,
        width: '150px',
        sort: false
      },
      {
        name: 'usuarioADParametroLinkedServer',
        label: this.wordsTranslated.linkedServer,
        width: '250px',
        sort: false
      },
      {
        name: 'usuarioADParametroServer',
        label: this.wordsTranslated.server,
        width: '250px',
        sort: false
      },
      {
        name: 'usuarioADParametroOU',
        label: this.wordsTranslated.ou,
        width: '250px',
        sort: false
      },
      {
        name: 'usuarioADParametroProtocol',
        label: this.wordsTranslated.protocol,
        width: '100px',
        sort: false
      },
      {
        name: 'usuarioADParametroDomain',
        label: this.wordsTranslated.domain,
        width: '',
        sort: false
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.importacaoAdService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: IImportacaoAd = null, editForm = true) {
    this.dadosEmpresa = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosEmpresa = $event;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        usuarioADParametroId: dados.usuarioADParametroId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.importacaoAdService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  confirmDel(dados: IImportacaoAd) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.importacaoAdService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }
}
