import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { EmpresaCombo } from 'src/app/shared/services/combo/empresa.combo';
import { LocalizacaoRodoviaCombo } from 'src/app/shared/services/combo/localizacao-rodovia.combo';
import { RodoviaCombo } from 'src/app/shared/services/combo/rodovia.combo';
import { SentidoRodoviaCombo } from 'src/app/shared/services/combo/sentido-rodovia.combo';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { ISaw } from '../models/ISaw';
import { EquipamentoService } from '../services/equipamento.service';

@Component({
  selector: 'app-equipamento-form',
  templateUrl: './equipamento-form.component.html',
  styleUrls: ['./equipamento-form.component.scss'],
})
export class EquipamentoFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;
  modalName: string = 'modalEquipamentoForm';
  statusBotao: IDialogButton;
  registroClean: ISaw;
  mapReady: boolean = false;
  wordsTranslated: any;
  latitude: number = 0;
  longitude: number = 0;
  hasCoordinates: boolean = false;

  private autoClose: boolean = false;

  public formLoading: boolean = false;
  public isLoading: boolean;
  public permissoesTela: IGridPermissoes;
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;

  public itensComboRodovia: ICombo[] = [];
  public itemSelecionadoComboRodovia: any;

  public itensComboLocalizacaoRodovia: ICombo[] = [];
  public itemSelecionadoComboLocalizacaoRodovia: any;

  public itensComboEmpresa: ICombo[] = [];
  public itemSelecionadoComboEmpresa: any;

  public itensComboSentido: ICombo[];
  public itemSelecionadoComboSentido: any;

  //TABS
  public tabArquivosAtiva = false;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: ISaw = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<ISaw>();

  @ViewChild('prefixo') prefixoRef: ElementRef;

  constructor(
    private rodoviaCombo: RodoviaCombo,
    private localizacaoRodoviaCombo: LocalizacaoRodoviaCombo,
    private empresaCombo: EmpresaCombo,
    private sentidoRodoviaCombo: SentidoRodoviaCombo,
    private equipamentoService: EquipamentoService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private closeModalService: CloseModalService
  ) {
    this.listenKeyPress();
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.equipamento.form.';
    const words = {
      prefixo: '',
      numeroSerie: '',
      rodoviaId: '',
      localizacaoRodoviaId: '',
      kmLocal: '',
      kmNominal: '',
      empresaId: '',
      ipPrimario: '',
      ipSecundario: '',
      ramal: '',
      rA: '',
      endereco: '',
      latitude: '',
      longitude: '',
      sentidoId: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  ngOnInit(): void {
    if (this.registro == null) {
      this.loadingService.show();
      const listRodovias = this.rodoviaCombo.listComboSelect();
      const listLocalizacoes = this.localizacaoRodoviaCombo.listComboSelect();
      const listEmpresas = this.empresaCombo.listComboSelect();

      forkJoin([listRodovias, listLocalizacoes, listEmpresas]).subscribe(
        (results) => {
          this.itensComboRodovia = results[0];
          this.itensComboLocalizacaoRodovia = results[1];
          this.itensComboEmpresa = results[2];
          this.criarFormulario();
        }
      );
    } else {
      const listRodovias = this.rodoviaCombo.listComboSelect();
      const listLocalizacoes = this.localizacaoRodoviaCombo.listComboSelect();
      const listEmpresas = this.empresaCombo.listComboSelect();

      forkJoin([listRodovias, listLocalizacoes, listEmpresas]).subscribe(
        (results) => {
          this.itensComboRodovia = results[0];
          this.itensComboLocalizacaoRodovia = results[1];
          this.itensComboEmpresa = results[2];

          this.loadEditData();
        }
      );
    }
  }

  loadEditData() {
    this.equipamentoService.getForm(this.registro.sawId).subscribe((result) => {
      this.registro = result;
      this.loadComboSentido(this.registro.rodoviaId);
      this.criarFormulario();
      this.checkCoordinates();
    });
  }

  checkCoordinates() {
    if (this.registro.latitude && this.registro.longitude) {
      this.latitude = this.registro.latitude;
      this.longitude = this.registro.longitude;
      this.hasCoordinates = true;
    }
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      sawId: [
        this.registro != null && this.registro.sawId !== null
          ? this.registro.sawId
          : null,
      ],
      prefixo: [
        this.registro != null && this.registro.prefixo !== null
          ? this.registro.prefixo
          : '',
        Validators.compose([Validators.required]),
      ],
      numeroSerie: [
        this.registro != null && this.registro.numeroSerie !== null
          ? this.registro.numeroSerie
          : '',
      ],
      rodoviaId: [
        this.registro != null && this.registro.rodoviaId !== null
          ? this.registro.rodoviaId
          : null,
        Validators.compose([Validators.required]),
      ],
      localizacaoRodoviaId: [
        this.registro != null && this.registro.localizacaoRodoviaId !== null
          ? this.registro.localizacaoRodoviaId
          : null,
        Validators.compose([Validators.required]),
      ],
      kmLocal: [
        this.registro != null && this.registro.kmLocal !== null
          ? this.registro.kmLocal
          : null,
        Validators.compose([Validators.required]),
      ],
      kmNominal: [
        this.registro != null && this.registro.kmNominal !== null
          ? this.registro.kmNominal
          : null,
        Validators.compose([Validators.required]),
      ],
      empresaId: [
        this.registro != null && this.registro.empresaId !== null
          ? this.registro.empresaId
          : null,
        Validators.compose([Validators.required]),
      ],
      ipPrimario: [
        this.registro != null && this.registro.ipPrimario !== null
          ? this.registro.ipPrimario
          : '',
      ],
      ipSecundario: [
        this.registro != null && this.registro.ipSecundario !== null
          ? this.registro.ipSecundario
          : '',
      ],
      ramal: [
        this.registro != null && this.registro.ramal !== null
          ? this.registro.ramal
          : '',
      ],
      ra: [
        this.registro != null && this.registro.ra !== null
          ? this.registro.ra
          : '',
      ],
      endereco: [
        this.registro != null && this.registro.endereco !== null
          ? this.registro.endereco
          : '',
        Validators.compose([Validators.required]),
      ],
      latitude: [
        this.registro != null && this.registro.latitude !== null
          ? this.registro.latitude
          : null,
      ],
      longitude: [
        this.registro != null && this.registro.longitude !== null
          ? this.registro.longitude
          : null,
      ],
      sentidoId: [
        this.registro != null && this.registro.sentidoId !== null
          ? this.registro.sentidoId
          : null,
      ],
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.equipamentoService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.equipamentoService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  get f() {
    return this.formulario.controls;
  }

  finalitySave(retorno, tipo: string) {
    this.equipamentoService.getForm(retorno.sawId).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        hasIpPrimario: this.registro.ipPrimario ? true : false,
        hasIpSecundario: this.registro.ipSecundario ? true : false,
        tipo,
      };
      this.onChange.emit(change);

      if (
        this.latitude != this.registro.latitude ||
        this.longitude != this.registro.longitude
      ) {
        this.checkCoordinates();

        if (this.hasCoordinates) {
          this.equipamentoService.editForm();
        }
      }

      this.formLoading = false;
      this.checkAutoClose();

      this.criarFormulario();
      this.configuracaAcoesBotoes();
    });
  }

  add(): void {
    this.registro = this.registroClean;
    this.resetForm();
    this.hasCoordinates = false
    this.latitude = null;
    this.longitude = null;
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  del(): void {
    this.onDelete.emit(this.registro);
  }

  close(): void {
    this.closeModalService.setStateModal(this.modalName);
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            this.dirtyFormButtonsState();
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  resetForm(){
    this.formulario.reset({
      sawId: null,
      prefixo: "",
      numeroSerie: "",
      rodoviaId: null,
      localizacaoRodoviaId: null,
      kmLocal: null,
      kmNominal: null,
      empresaId: null,
      ipPrimario: "",
      ipSecundario: "",
      ramal: "",
      endereco: "",
      latitude: null,
      longitude: null,
      sentidoId: null
    });

    Object.keys(this.formulario.controls).forEach((key) => {
      const control = this.formulario.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
      control.setErrors(null);
      control.clearValidators();
  });

    this.formulario.markAsPristine();
    this.formulario.markAsUntouched();
    this.formulario.setErrors(null);
    this.formulario.clearValidators();

    this.prefixoRef.nativeElement.focus()
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  configuraTitleDialog() {
    const keyRoot = 'paginas.equipamento.form.labels.';
    const words = {
      adicionar_registro: '',
      editar_registro: '',
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (this.registro) {
      this.titleDialog = `${wordsTranslate.editar_registro}`;
    } else {
      this.titleDialog = wordsTranslate.adicionar_registro;
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  onChangeComboRodovia($event) {
    this.itemSelecionadoComboRodovia = $event;

    this.formulario.patchValue({
      rodoviaId: this.itemSelecionadoComboRodovia,
    });

    if(typeof this.itemSelecionadoComboRodovia == 'number')
      this.loadComboSentido(this.itemSelecionadoComboRodovia);
    else {
      this.itensComboSentido = null;
      this.itemSelecionadoComboSentido = null;
      this.formulario.patchValue({
        sentidoId: null
      })
    }
  }

  onChangeComboLocalizacao($event) {
    this.itemSelecionadoComboLocalizacaoRodovia = $event;

    this.formulario.patchValue({
      localizacaoRodoviaId: this.itemSelecionadoComboLocalizacaoRodovia,
    });

    const sentidoControl = this.formulario.get('sentidoId');

    if ($event == 2) {
      sentidoControl.setValidators([Validators.required]);
      sentidoControl.updateValueAndValidity();
    } else {
      sentidoControl.setValidators([]);
      sentidoControl.setValidators(null);
      sentidoControl.updateValueAndValidity();
    }
  }

  onChangeComboEmpresa($event) {
    this.itemSelecionadoComboEmpresa = $event;

    this.formulario.patchValue({
      empresaId: this.itemSelecionadoComboEmpresa,
    });
  }

  onChangeComboSentido($event) {
    this.itemSelecionadoComboSentido = $event;

    this.formulario.patchValue({
      sentidoId: this.itemSelecionadoComboSentido,
    });
  }

  loadComboSentido(rodoviaId: number) {
    const listSentidos = this.sentidoRodoviaCombo.listComboSelect(rodoviaId);

    forkJoin([listSentidos]).subscribe((results) => {
      this.itensComboSentido = results[0];
    });
  }

  onTabClick(event: MatTabChangeEvent) {
    const events = {
      0: () => {},
      1: () => {
        this.tabArquivosAtiva = true;
      },
    };

    const fn = events[event.index];
    if (fn) {
      fn();
    }
  }

  setMapReadyToView() {
    this.mapReady = true;
  }
}
