import { Component, Input, OnInit, } from "@angular/core";
import { WordsTranslateService } from "src/app/shared/services/translate/words.translate";
import * as L from 'leaflet'
import { EnvironmentService } from "src/app/shared/services/env/environment.service";

@Component ({
    selector: 'app-equipamento-mapa',
    templateUrl: './equipamento-mapa.component.html',
    styleUrls: ['./equipamento-mapa.component.scss']
})
export class EquipamentoMapaComponent implements OnInit {

    @Input() latitude: number;
    @Input() longitude: number;

    public showMap: boolean = false;
    private osmServerUrl: string = '';
    wordsTranslated: any;
    textNoContent: string = '';

    constructor(
        private wordsTranslate: WordsTranslateService,
        private env: EnvironmentService
    ){
        this.osmServerUrl = env.osmServerUrl;
        this.loadWordsTranslate();
    }

    loadWordsTranslate() {
        let keyRoot = 'paginas.equipamento.form.labels.';
        let words = {
            textNoContent: '',
        };
        this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
        this.textNoContent = this.wordsTranslated.textNoContent;
    }

    ngOnInit() {
        if (this.latitude && this.longitude){
            this.showMap = true;
            setTimeout(() => {
                this.initMap();
            });
        } else {
            this.showMap = false;
        }
    }

    private initMap(){
        const map = L.map("map", {
            zoom: 14,
            center: [this.latitude, this.longitude]
          });
          L.tileLayer(this.osmServerUrl, {
            attribution: '© OpenStreetMap contributors'
          }).addTo(map);

        const latLng = ([this.latitude, this.longitude] as L.LatLngTuple);

        const customIcon = L.icon({
            iconUrl: 'assets/images/logo_mapa.png',
            iconSize: [45, 57],
            iconAnchor: [22, 57], 
            popupAnchor: [0, -57] 
        });

        const marker = L.marker(latLng, {
            icon: customIcon
        });

        marker.addTo(map);
    }

}