import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IRDisponibilidadeConexaoEquipamento } from '../models/IRDisponibilidadeConexaoEquipamento';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 35;

@Injectable({
  providedIn: 'root',
})
export class DisponibilidadeConexaoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}RDisponibilidadeConexao`;

  get(parametros: any): Observable<IRDisponibilidadeConexaoEquipamento[]> {
    var params = this.getParams(parametros);

    return this.http.get<IRDisponibilidadeConexaoEquipamento[]>(this.apiUrl, {
      params,
    });
  }

  export(parametros: any) {
    var params = this.getParams(parametros);

    return this.http.get(`${this.apiUrl}/export`, {
      params,
    });
  }

  getParams(parametros: any) {
    var datePipe = new DatePipe('en-US');
    parametros.dataInicio = datePipe.transform(parametros.dataInicio, 'yyyy-MM-dd');
    parametros.dataFim = datePipe.transform(parametros.dataFim, 'yyyy-MM-dd');

    let params = new HttpParams()
      .set('dataInicio', parametros.dataInicio)
      .set('dataFim', parametros.dataFim);

    parametros.sawId.forEach((x) => {
      params = params.append('sawId', x);
    });

    return params;
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}