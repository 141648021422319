import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { EnvironmentService } from '../env/environment.service';


@Injectable({ providedIn: 'root' })
export class UsuarioAtendenteCombo {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = this.env.apiUrl + 'ComboUsuarioAtendente';

  listComboSelect(): Observable<ICombo[]> {
    return this.http.get<ICombo[]>(this.apiUrl);
  }
}
