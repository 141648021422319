import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IGrupoUsuario } from '../models/IGrupoUsuario';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 6;

@Injectable({ providedIn: 'root' })
export class GrupoUsuarioService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}GrupoUsuario`;

  getGrid(): Observable<IGrupoUsuario[]> {
    return this.http.get<IGrupoUsuario[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IGrupoUsuario) {
    return this.http.get<IGrupoUsuario>(
      `${this.apiUrl}/Form/${dados.grupoUsuarioId}`
    );
  }

  insert(dados: IGrupoUsuario) {
    return this.http.post<IGrupoUsuario>(this.apiUrl, dados);
  }

  update(dados: IGrupoUsuario) {
    return this.http.put<IGrupoUsuario>(`${this.apiUrl}`, dados);
  }

  delete(dados: IGrupoUsuario) {
    return this.http.delete<IGrupoUsuario>(`${this.apiUrl}/${dados.grupoUsuarioId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
