<div class="modal" modal-name="modalRevistaForm">
    <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
      <div class="modal-container">
        <div class="modal-title" cdkDragHandle>
          <h2>{{ titleDialog }}</h2>
          <button mat-button (click)="close()" class="close">
            <i class="material-icons">close</i>
          </button>
        </div>
        <form
          class="default"
          *ngIf="formulario"
          [formGroup]="formulario"
          (ngSubmit)="save()"
          cdkTrapFocus
        >
          <div class="modal-content">
            <input type="hidden" formControlName="revistaId" />
  
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "paginas.revistas.form.revistaTitulo" | translate }}
              </mat-label>
              <input
                matInput
                placeholder="{{
                  'paginas.revistas.form.revistaTitulo' | translate
                }}"
                autocomplete="off"
                formControlName="revistaTitulo"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                  {{ "paginas.revistas.form.revistaDataPublicacao" | translate }}
                </mat-label>
                <input
                    matInput
                    [matDatepicker]="picker"
                    [max]="dataMax"
                    formControlName="revistaDataPublicacao"
                    (dateChange)="changeDate($event)"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #picker
                ></mat-datepicker>
            </mat-form-field>

          </div>
  
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="formLoading"
          ></mat-progress-bar>
  
          <div class="modal-actions">
            <div></div>
            <div class="buttons">
              <button
                mat-button
                type="button"
                class="primary add"
                *ngIf="permissoes['create']"
                (click)="add()"
                [disabled]="!statusBotao.adicionar"
              >
                <i class="material-icons">add</i>
                <span class="text">{{ "botoes.adicionar" | translate }}</span>
              </button>
  
              <button
                mat-button
                type="button"
                class="primary delete"
                *ngIf="permissoes['delete']"
                (click)="del()"
                [disabled]="!statusBotao.deletar"
              >
                <i class="material-icons">delete</i>
                <span class="text">{{ "botoes.deletar" | translate }}</span>
              </button>
  
              <button
                mat-button
                type="button"
                class="primary cancel"
                *ngIf="
                  (permissoes['create'] && !registro) ||
                  (permissoes['update'] && registro)
                "
                (click)="cancel()"
                [disabled]="!statusBotao.cancelar"
              >
                <i class="material-icons">block</i>
                <span class="text">{{ "botoes.cancelar" | translate }}</span>
              </button>
  
              <button
                type="submit"
                mat-button
                class="save"
                *ngIf="
                  (permissoes['create'] && !registro) ||
                  (permissoes['update'] && registro)
                "
                [disabled]="formulario.invalid || !statusBotao.salvar"
              >
                <i class="material-icons">check</i>
                <span class="text">{{ "botoes.salvar" | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  