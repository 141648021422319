import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ImageZoomService {
  showZoom = new EventEmitter<any>();

  public show(image) {
    let obj = {
      show: true,
      image: image
    };
    this.showZoom.emit(obj);
  }
  public hide() {
    let obj = {
      show: false
    };
    this.showZoom.emit(obj);
  }
}
