<div class="modal-container">
  <form class="default" *ngIf="formulario" (ngSubmit)="save()" [formGroup]="formulario" cdkTrapFocus>
    <div class="modal-content">

        <mat-expansion-panel class="mat-expansion-panel" [expanded]="abrirCardForm">
          <mat-expansion-panel-header class="mat-expansion-panel-header">
            <mat-panel-title>{{ dados.textoDicionario }}</mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Seletor de cor -->
          <input type="hidden" formControlName="personalizarTotemValorCor"/>
          <ws-color-picker *ngIf="possuiCor" [nameLabel]="nameLabelColorPicker" [corValue]="dados.personalizarTotemValorCor" [disabled]="false"
            [touchUi]="false" (colorPicked)="getColor($event, dados.personalizarTotemParametro)">
          </ws-color-picker>

          <input type="hidden" formControlName="personalizarTotemId">
          <div *ngIf="possuiTitulo || possuiDescricao" class="textos">

            <!-- Texto em Portugues -->
            <mat-expansion-panel class="mat-expansion-panel">
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                <mat-panel-title>{{ "paginas.mockup-totem.formulario.textoPortugues" | translate }}</mat-panel-title>
              </mat-expansion-panel-header>

              <mat-form-field *ngIf="possuiTitulo" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.tituloForm" | translate }}
                </mat-label>
                <input matInput
                  formControlName="personalizarTotemValorTituloPortugues" />
              </mat-form-field>

              <mat-form-field *ngIf="possuiDescricao" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.descricaoForm" | translate }}
                </mat-label>
                <textarea
                  matInput
                  autocomplete="off"
                  formControlName="personalizarTotemValorDescPortugues"
                  rows="4"
                ></textarea>
              </mat-form-field>
            </mat-expansion-panel>
          
            <!-- Texto em Inglês -->
            <mat-expansion-panel class="mat-expansion-panel">
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                <mat-panel-title>{{ "paginas.mockup-totem.formulario.textoIngles" | translate }}</mat-panel-title>
              </mat-expansion-panel-header>

              <mat-form-field *ngIf="possuiTitulo" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.tituloForm" | translate }}
                </mat-label>
                <input matInput
                  formControlName="personalizarTotemValorTituloIngles"/>
              </mat-form-field>

              <mat-form-field *ngIf="possuiDescricao" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.descricaoForm" | translate }}
                </mat-label>
                <textarea
                  matInput
                  autocomplete="off"
                  formControlName="personalizarTotemValorDescIngles"
                  rows="4"
                ></textarea>
              </mat-form-field>
            </mat-expansion-panel>

            <!-- Texto em Espanhol -->
            <mat-expansion-panel class="mat-expansion-panel">
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                <mat-panel-title>{{ "paginas.mockup-totem.formulario.textoEspanhol" | translate }}</mat-panel-title>
              </mat-expansion-panel-header>

              <mat-form-field *ngIf="possuiTitulo" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.tituloForm" | translate }}
                </mat-label>
                <input matInput
                  formControlName="personalizarTotemValorTituloEspanhol"/>
              </mat-form-field>

              <mat-form-field *ngIf="possuiDescricao" appearance="outline">
                <mat-label>
                  {{ "paginas.mockup-totem.formulario.descricaoForm" | translate }}
                </mat-label>
                <textarea
                  matInput
                  autocomplete="off"
                  formControlName="personalizarTotemValorDescEspanhol"
                  rows="4"
                ></textarea>
              </mat-form-field>
              
            </mat-expansion-panel>
          </div>

          <!-- Botões de salvamento -->
          <div class="modal-actions">
            <div></div>
            <div class="buttons">

              <button mat-button type="button" class="primary cancel" 
                *ngIf="(permissoes['create'] && !dados) || (permissoes['update'] && dados)" (click)="cancel()"
                [disabled]="!statusBotao.cancelar">
                <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
              </button>

              <button type="submit" mat-button class="save"
                *ngIf="(permissoes['create'] && !dados) || (permissoes['update'] && dados)"
                [disabled]="formulario.invalid || !statusBotao.salvar">
                <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
              </button>
            </div>
          </div>

          <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        </mat-expansion-panel>
    </div>
  </form>
</div>
