<div class="modal" modal-name="modalUsuarioForm">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
  >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="usuarioId" />

          <mat-form-field class="fieldLogin" appearance="outline">
            <mat-label>
              {{ "paginas.usuario.form.labels.login" | translate }}
            </mat-label>
            <input
              [focusMe]="true"
              matInput
              placeholder="{{
                'paginas.usuario.form.labels.login' | translate
              }}"
              autocomplete="off"
              formControlName="usuarioLogin"
            />
          </mat-form-field>

          <mat-form-field class="fieldNome" appearance="outline">
            <mat-label>
              {{ "paginas.usuario.form.labels.nome" | translate }}
            </mat-label>
            <input
              matInput
              placeholder="{{ 'paginas.usuario.form.labels.nome' | translate }}"
              autocomplete="off"
              formControlName="usuarioNome"
            />
          </mat-form-field>

          <mat-form-field class="fieldEmail" appearance="outline">
            <mat-label>
              {{ "paginas.usuario.form.labels.email" | translate }}
            </mat-label>
            <input
              matInput
              placeholder="{{
                'paginas.usuario.form.labels.email' | translate
              }}"
              autocomplete="off"
              formControlName="usuarioEmail"
            />
          </mat-form-field>

          <ws-combo
            class="fieldGrupo"
            [disabled]="!permissoes['update'] && registro"
            [textoLabel]="'paginas.usuario.form.labels.grupo' | translate"
            [itemCtrl]="f.grupoUsuarioId"
            [items]="itensComboGrupoUsuario"
            [itemSelecionado]="itemSelecionadoComboGrupoUsuario"
            (changeEventCombo)="onChangeComboGrupoUsuario($event)"
          >
          </ws-combo>

          <mat-form-field class="fieldLoginAd" appearance="outline">
            <mat-label>
              {{ "paginas.usuario.form.labels.usuarioAdLogin" | translate }}
            </mat-label>
            <input
              matInput
              placeholder="{{
                'paginas.usuario.form.labels.usuarioAdLogin' | translate
              }}"
              autocomplete="off"
              formControlName="usuarioADLogin"
            />
          </mat-form-field>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary add"
              *ngIf="permissoes['create']"
              (click)="add()"
              [disabled]="!statusBotao.adicionar"
            >
              <i class="material-icons">add</i>
              <span class="text">{{ "botoes.adicionar" | translate }}</span>
            </button>

            <button
              mat-button
              type="button"
              class="primary cancel"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>
            <button
              type="submit"
              mat-button
              class="save"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
