<div class="title-grid">
  <p>
    {{ wordsTranslated.title }}
  </p>
</div>
<div class="modulosSearchs">
  <button
    mat-button="" 
    class="mat-focus-indicator primary mat-button mat-button-base ng-star-inserted" 
    ng-reflect-disabled="false"
    (click)="openForm()"
  >
    <i _ngcontent-fhq-c146="" class="material-icons">add</i>
    {{ wordsTranslated.adicionar }}
  </button>
  <input
    type="text"
    class="modulosSearch"
    placeholder="{{ wordsTranslated.searchModulo }}"
    [(ngModel)]="query"
    id="listSearch"
  />
</div>
<div class="drag-drop-grid">
  <div class="bloqueio-header">
    <div class="header-drag-drop">
      <div class="acoesGridP">
        <p></p>
      </div>
      
      <div class="iconeP">
        <p>
          {{ wordsTranslated.icone }}
        </p>
      </div>
      <div class="moduloP">
        <p>
          {{ wordsTranslated.menuModulo }}
        </p>
      </div>
    </div>
  </div>
  <div cdkDropList class="list-modulo" (cdkDropListDropped)="drop($event)">
    <div class="bloqueioList">
      <div
        class="list-modulo-dados"
        *ngFor="let dado of dadosGrid | searchFilter: query"
        cdkDragLockAxis="y"
        cdkDrag
        [cdkDragDisabled]="!this.permissoesTela.update"
        (click)="sendModuloId(dado)"
      >
        <div class="drop-placeholder" *cdkDragPlaceholder></div>
        <div class="acoesGrid">
          <div class="insideAcoes">
            <button (click)="editForm(dado)"
              mat-stroked-button=""
              mattooltipposition="above"
              class="mat-focus-indicator mat-tooltip-trigger edit mat-stroked-button mat-button-base ng-star-inserted"
            >
              <span class="mat-button-wrapper">
                <i class="material-icons">edit</i>
              </span>
            </button>
          </div>
        </div>
        
        <div class="icone">
          <div [innerHTML]="dado.icone" class="espacoIcone">
          </div>
        </div>
        <div class="menuModulo">
          <p>
            {{ dado.menuModulo }}
          </p>
        </div>
      </div>
    </div>
  </div>

</div>
<app-modulo-form
*ngIf="showForm"
[permissoes]="permissoesTela"
[registro]="dadosFormulario"
(closeEvent)="closeForm()"
(onChange)="changeEvent()"
>
</app-modulo-form>