import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRevista } from '../models/IRevista';
import { RevistaService } from '../services/revista.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-revista-upload-midia-grid',
  templateUrl: './revista-upload-midia-grid.component.html',
  styleUrls: ['./revista-upload-midia-grid.component.scss']
})
export class RevistaUploadMidiaGridComponent  implements OnInit {

  titleDialogPdf: string;
  titleDialogImagem: string;
  filesPdf: File[] = [];
  filesImagem: File[] = [];
  isLoading: boolean = true;
  listErrosUpload = [];
  modalName: string = "modalRevistaUpload"
  wordsTranslated: any;
  
  @Input() revistaId: number;
  @Input() editForm: EventEmitter<boolean>;
  @Input("dados") dados: IRevista;
  @Input() carregaMidia: string;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() atualizaGrid = new EventEmitter<any>();

  constructor(
    private revistaService: RevistaService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private closeModalService: CloseModalService,
    private imagemZoomService: ImageZoomService
  ){
    this.listenKeyPress();
    this.loadWordsTranslate();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.close();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = "paginas.revistas.upload.";
    const words = {
      titlePdf: '',
      titleImagem: '',
    };
   this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadFiles();
    this.titleDialogPdf = this.wordsTranslated.titlePdf;
    this.titleDialogImagem = this.wordsTranslated.titleImagem;
  }

  loadFiles(){
    let midiaPdf = this.revistaService.getMidia(this.revistaId,"pdf");
    let midiaImagem = this.revistaService.getMidia(this.revistaId,"imagem");

    forkJoin([midiaPdf, midiaImagem])
    .subscribe(
      (result) => {
        if(result[0] && this.filesPdf != result[0])
          this.filesPdf = result[0];
        if(result[1] && this.filesImagem != result[1])
          this.filesImagem = result[1];

        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.alertaService.erro();
        this.close();
      }
    )
  }

  uploadFilesPdf(uploadDados) {
    const req = this.revistaService.uploadMidia(
      this.revistaId,
      "pdf",
      uploadDados.formData
    );
    req.subscribe(
      (results: any) => {
        if(results && results [0]) {
          const errors = [];
          errors.push(results[0]);

          errors.forEach((element) => {
            this.listErrosUpload.push(element);
          });
        }
        this.alertaService.sucesso();
        this.loadFiles();
      },
      (error: any) => {
        this.alertaService.erro();
      }
    );
  }

  uploadFilesImagem(uploadDados) {
    const req = this.revistaService.uploadMidia(
      this.revistaId,
      "imagem",
      uploadDados.formData
    );
    req.subscribe(
      (results: any) => {
        if(results && results [0]) {
          const errors = [];
          errors.push(results[0]);

          errors.forEach((element) => {
            this.listErrosUpload.push(element);
          });
        }
        this.alertaService.sucesso();
        this.loadFiles();
        this.atualizaGrid.emit();
      },
      (error: any) => {
        this.alertaService.erro();
      }
    );
  }

  close(): void {
    this.closeEvent.next();
  }

  showImage(image) {
    this.imagemZoomService.show(image)
  }

}
