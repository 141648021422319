import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../env/environment.service';

const portaAPIEquipamento = `${environment.portaAPIEquipamento}`;

@Injectable({
  providedIn: 'root'
})
export class ControleVolumeService {
  volumeEquipamento = new EventEmitter<any>();

  constructor(
    private http: HttpClient
  , private env : EnvironmentService) { }

  public setVolume(arg): void {
   this.volumeEquipamento.emit(arg);
  }

  public setVolumeEquipamento(altoFalante = '6', microfone = '3',  numeroSerie = null): void {

    let arg = {
      altoFalante: altoFalante,
      microfone: microfone,
      numeroSerie: numeroSerie,
    }

    console.log("SET DE VOLUME ");
    console.log(arg);
    if(numeroSerie){

      this.http
      .post(
        `${this.env.hubUrl}EnviarVolume`,
        arg
      )
      .subscribe(
        (result) => {
          console.log(result)
          console.log(`${numeroSerie}- VOLUME SETADO COM SUCESSO`)
        },
        (error) => {
          console.log(error)
        }
      );

    }
     // this.setVolume(arg);
    this.setVolume(arg && arg.altoFalante ? arg.altoFalante : 6);
  }
}
