<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  (refreshEvent)="refresh()"
  (deleteEvent)="confirmDel($event)"
></ws-grid>
