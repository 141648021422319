import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IMockupTotem } from '../models/IMockupTotem';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const telaId = 37;


@Injectable({
  providedIn: 'root'
})
export class MockupTotemService {

  constructor(
    private http: HttpClient, 
    private authService: AuthService, 
    private env: EnvironmentService,
    private configurationService: ConfiguracaoService
  ) { }

  apiUrl = `${this.env.apiUrl}MockupTotem`;

  changeCard = new EventEmitter<string>();
  eventoAtualizaGrid = new EventEmitter<any>();
  eventoAtualizaCor = new EventEmitter<any>();

  getGrid(): Observable<IMockupTotem[]> {
    return this.http.get<IMockupTotem[]>(`${this.apiUrl}`);
  }

  update(dados : IMockupTotem){
    return this.http.put(`${this.apiUrl}`, dados);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

  public syncConfigDefault(): Observable<boolean> {
    return this.configurationService.get();
  }
}
