import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IParametrosConfiguracaoSistema } from '../models/IParametrosConfiguracaoSistema';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ParametrosConfiguracaoSistemaService } from '../services/parametros-configuracao-sistema.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IParametrosConfiguracaoSistemaForm } from '../models/IParametrosConfiguracaoSistemaForm';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';

@Component({
  selector: 'app-parametros-configuracao-sistema-form',
  templateUrl: './parametros-configuracao-sistema-form.component.html',
  styleUrls: ['./parametros-configuracao-sistema-form.component.scss']
})
export class ParametrosConfiguracaoSistemaFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;
  focusValor: boolean = false;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IParametrosConfiguracaoSistema = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);

  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboOption: ICombo[] = [];
  public itemSelecionadoComboOption: any;

  public nameField: string = 'fieldParametroSistemaValor';
  public nameLabelColorPicker: string = 'Cor';
  public corValue: string = '#000000';

  wordsTranslated: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private parametrosConfiguracaoSistemaService: ParametrosConfiguracaoSistemaService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalConfiguracaoSistemaForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.configuracaoSistema.parametros.form.';
    const words = {
      editarRegistro: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();

    if (this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }

    this.loadingService.hide();
  }

  loadEditData() {
    this.parametrosConfiguracaoSistemaService
      .getForm(this.registro)
      .subscribe((result) => {
        this.registro = result;
        this.corValue = result.parametroSistemaValor;
        
        if (this.registro.parametroSistemaTipo === 'OPTION') {
          let splitOptions = [];
          splitOptions = this.registro.parametroSistemaOpcoes.split('|');

          splitOptions.forEach((element) => {
            let itemSplit = { text: element, value: element };
            this.itensComboOption.push(itemSplit);
          });
        }

        this.criarFormulario();
      });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm: IParametrosConfiguracaoSistemaForm = this.formulario
      .value;

    if (this.registro) {
      this.parametrosConfiguracaoSistemaService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.parametrosConfiguracaoSistemaService
      .getForm(retorno)
      .subscribe((result) => {
        this.registro = result;
        const change = {
          dados: this.registro,
          tipo
        };
        this.onChange.emit(change);

        this.formLoading = false;
        this.checkAutoClose();

        this.criarFormulario();
        this.configuracaAcoesBotoes();
      });
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.wordsTranslated.editarRegistro;
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      parametroSistemaId: [
        this.registro != null && this.registro.parametroSistemaId
          ? this.registro.parametroSistemaId
          : '',
        Validators.compose([Validators.required])
      ],
      parametroSistemaValor: [
        this.registro != null && this.registro.parametroSistemaValor
          ? this.registro.parametroSistemaValor
          : '',
        Validators.compose([Validators.required])
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    } else {
      this.focusValor = true;
    }
  }

  onChangeComboOption($event) {
    this.itemSelecionadoComboOption = $event;

    this.formulario.patchValue({
      parametroSistemaValor: this.itemSelecionadoComboOption
    });
  }

  getColor($color: any) {
    var colorHex = `#${$color.hex}`;

    this.formulario.patchValue({
      parametroSistemaValor: colorHex,
    });
  }
}
