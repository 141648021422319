import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'underscore';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';

import { environment } from 'src/environments/environment';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { ITelefonesUteis } from '../models/ITelefonesUteis';
import { TelefonesUteisService } from '../services/telefones-uteis.service';

@Component({
  selector: 'app-telefones-uteis-grid',
  templateUrl: './telefones-uteis-grid.component.html',
  styleUrls: ['./telefones-uteis-grid.component.scss']
})
export class TelefonesUteisGridComponent implements OnInit {

  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ITelefonesUteis[];
  public dadosTelefonesUteis: ITelefonesUteis;

  public editForm = true;
  public showForm = false;
  public showUpload = false;

  wordsTranslated: any;

  constructor(
    public telefonesUteisService: TelefonesUteisService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private dialogService: DialogService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    public dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.telefonesUteis.grid.';
    let words = {
      nomePortugues: '',
      telefone: ''      
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingService.show();

    this.telefonesUteisService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'nomePortugues',
        label: this.wordsTranslated.nomePortugues,
        width: '450px',
        sort: true
      },
      {
        name: 'telefone',
        label: this.wordsTranslated.telefone,
        width: '',
        sort: true
      }     
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.telefonesUteisService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  openForm(dados: ITelefonesUteis = null, editForm = true) {
    this.dadosTelefonesUteis = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  closeUpload() {
    this.showUpload = false;
  }

  openUpload($event) {
    this.showUpload = true;
    this.dadosTelefonesUteis = $event;
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        telefoneUtilId: dados.telefoneUtilId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.telefonesUteisService.getForm(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }

  confirmDel(dados: ITelefonesUteis) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.telefonesUteisService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }

}
