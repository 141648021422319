import { Color } from '@angular-material-components/color-picker';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ws-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @Input('disabled') disabled : boolean;
  @Input('corValue') corValue : string;
  @Input ('touchUi') touchUi : boolean;
  @Input('fieldClass') fieldClass : string;
  @Input('nameLabel') nameLabel : string;

  @Output() colorPicked = new EventEmitter<any>();

  public color: ThemePalette = 'primary';
  public colorCtr: AbstractControl;

  constructor(){}

  ngOnInit(){
    let tmpColor = this.hexToRgb('#000000');
    if(this.corValue){
      tmpColor = this.hexToRgb(this.corValue);
    }

    this.colorCtr = new UntypedFormControl(new Color(tmpColor.r, tmpColor.g, tmpColor.b));
    this.onValueChanges();
  }

  onDisabledChanged(value: boolean){
    if (!value) {
      this.colorCtr.enable();
    } else {
      this.colorCtr.disable();
    }
  }

  changeColor(selectedColor : any){
     this.colorPicked.emit(selectedColor);
  }

  onValueChanges(): void {
    this.colorCtr.valueChanges.subscribe((selectedColor) => {
      this.changeColor(selectedColor);
    });
  }

  hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

}
