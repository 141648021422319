import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexYAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
} from 'ng-apexcharts';
import { finalize } from 'rxjs/internal/operators/finalize';
import { DownloadHelper } from 'src/app/shared/helper/download.helper';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { IRAtendimentoIdiomaSAW } from '../models/IRAtendimentoIdiomaSaw';
import { ClickAtendimentoIdiomaService } from '../services/click-atendimento-idioma.service';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { forkJoin } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
  markers: any;
  stroke: ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  colors: string[];
  labels: string[];
  tooltip: ApexTooltip;
  subtitle: ApexTitleSubtitle;
  responsive: ApexResponsive[];
};

@Component({
  selector: 'app-click-atendimento-idioma-grid',
  templateUrl: './click-atendimento-idioma-grid.component.html',
  styleUrls: ['./click-atendimento-idioma-grid.component.scss'],
})
export class ClickAtendimentoIdiomaGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public chartOptions: Partial<ChartOptions>;
  public dadosRelatorio: IRAtendimentoIdiomaSAW[] = [];
  public wordsTranslated: any;
  public wordsNoContent: any;
  resumoSeries: any = [];
  xaxisDados: any = [];
  chartInfos: any = [];
  titlesCharts: any = [];
  itensComboSAW: ICombo[] = [];
  itemSelecionadoComboSAW: any;
  titleNoContent: string;
  textNoContent: string;
  showFilter: boolean = false;
  desabilitarExport: boolean = false;
  showChangedTab = true;
  showTabInicial= 0;

  public acoesGridDetalhe: IGridAcoes;
  public acoesGridResumo: IGridAcoes;
  public colunasGridDetalhes: IGridModelo[];
  public colunasGridResumos: IGridModelo[];
  public permissoesTela: IGridPermissoes;
  public readForm = true;
  public filterForm: UntypedFormGroup;
  public focusFirstLine: boolean = false;
  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private clickAtendimentoIdiomaService: ClickAtendimentoIdiomaService,
    private downloadHelper: DownloadHelper,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private sawEquipamentoCombo: SAWCombo,
  ) {
    this.loadWordsTranslate();
    this.loadWordsNoContent();
  }

  @HostListener('window:resize')
  onResize() {
    if (this.dadosRelatorio.length > 0 && this.filterForm.valid) {
      this.filter();
    }
  }

  @HostListener('window:fullscreenchange')
  onFullScreenChange() {
    if (this.dadosRelatorio.length > 0 && this.filterForm.valid) {
      this.filter();
    }
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.click-atendimento-idioma.grid.';
    const words = {
      dataInicio: '',
      dataFim: '',
      prefixo: '',
      rodoviaSigla: '',
      kmLocal: '',
      dataHora: '',
      idiomaDesc: '',
      qtde: '',
      resumos: '',
      detalhes: '',
      sawId: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  loadWordsNoContent() {
    const keyRoot = 'paginas.click-atendimento-idioma.no-content.';
    const words = {
      titleNoFilter: '',
      textNoFilter: '',
      titleFilter: '',
      textFilter: '',
    };
    this.wordsNoContent = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.titleNoContent = this.wordsNoContent.titleNoFilter;
    this.textNoContent = this.wordsNoContent.textNoFilter;

    this.isLoading = true;
    this.clickAtendimentoIdiomaService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;

        const listSawEquipamento = this.sawEquipamentoCombo.listComboSelect();
        forkJoin([listSawEquipamento]).subscribe((results) => {
          this.itensComboSAW = results[0];
          this.itemSelecionadoComboSAW = this.itensComboSAW;
          this.createFilterForm();
          this.setInitialDataFilters();
        });
      });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      dataInicio: [
        this.formValidParam.startDateDe,
        Validators.compose([Validators.required]),
      ],
      dataFim: [
        this.formValidParam.startDateAte,
        Validators.compose([Validators.required]),
      ],
      sawId: [[], Validators.compose([Validators.required])],
    });

    this.showFilter = true;
  }

  onChangeComboSaw($event) {
    this.itemSelecionadoComboSAW = $event;
    this.filterForm.patchValue({
      sawId: this.itemSelecionadoComboSAW,
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  changeDateDe($event) {
    if ($event.value != null) {
      let dataAtual = new Date(Date.now());
      let date: Date;
      date = new Date($event.value);

      let maxDateAte = new Date(date);
      maxDateAte.setDate(maxDateAte.getDate() + 60);
      maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

      this.formValidParam.startDateDe = date;
      this.formValidParam.startDateAte = maxDateAte;
      this.formValidParam.minDateAte = date;
      this.formValidParam.maxDateAte = maxDateAte;
      this.filterForm.patchValue({
        dataFim: maxDateAte,
      });
    } else {
      this.filterForm.patchValue({
        dataFim: null,
      });
    }
  }

  setInitialDataFilters() {
    let dataAtual = new Date(Date.now());
    let dataInicio = new Date(Date.now());
    dataInicio.setDate(dataAtual.getDate() - 60);

    this.formValidParam.startDateDe = dataInicio;
    this.formValidParam.minDateAte = dataInicio;
    this.formValidParam.startDateAte = dataAtual;

    this.filterForm.patchValue({
      dataInicio: dataInicio,
      dataFim: dataAtual,
    });
  }

  configuraAcoesGrid(): void {
    this.acoesGridDetalhe = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      name: 'grid-detalhes-atendimento-idioma'
    };

    this.acoesGridResumo = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: false,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false,   
      name: 'grid-resumo-atendimento-idioma'
    };
  }

  filter(): void {
    this.showTabInicial = 0;
    this.desabilitarExport = false;
    var valuesFilter = this.filterForm.value;

    this.loadingService.show();

    this.clickAtendimentoIdiomaService
      .get(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe((response) => {
        this.dadosRelatorio = response;

        this.configuraAcoesGrid();
        this.configuraColunasGridDetalhes();
        this.configuraColunasGridResumos();
        this.setSeriesResumos();
        this.setGrafico();

        if (response.length == 0) {
          this.titleNoContent = this.wordsNoContent.titleFilter;
          this.textNoContent = this.wordsNoContent.textFilter;
          this.desabilitarExport = true;
        }
        this.setFocusLineTrue();
      });
  }

  configuraColunasGridDetalhes(): void {
    this.colunasGridDetalhes = [
      {
        name: 'idiomaDesc',
        label: this.wordsTranslated.idiomaDesc,
        width: '',
        sort: false,
      },
      {
        name: 'dataHora',
        label: this.wordsTranslated.dataHora,
        width: '',
        sort: false,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
    ];
  }

  configuraColunasGridResumos(): void {
    this.colunasGridResumos = [
      {
        name: 'qtde',
        label: this.wordsTranslated.qtde,
        width: '150px',
        sort: false,
      },
      {
        name: 'idiomaDesc',
        label: this.wordsTranslated.idiomaDesc,
        width: '',
        sort: false,
      },
    ];
  }

  export(): void {
    var timeOut = 1500;
    var valuesFilter = this.filterForm.value;

    this.loadingService.show();

    this.clickAtendimentoIdiomaService
      .export(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        if (response) {
          this.downloadHelper.downloadMidia(response);
        } else {
          const keyRoot = 'alertas.nenhumDadoParaExportar.';
          const words = {
            mensagem: '',
            titulo: '',
          };
          let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

          this.alertaService.Warning(
            wordsTranslate.titulo,
            wordsTranslate.mensagem,
            timeOut
          );
        }
      });
  }

  setSeriesResumos() {
    this.dadosRelatorio.forEach((equipamento) => {
      this.resumoSeries[equipamento.sawId] = [];
      this.xaxisDados[equipamento.sawId] = [];
      this.chartInfos[equipamento.sawId] = [];
      this.titlesCharts[equipamento.sawId] = [];

      var title = `clicks_classificacao_atendimento_${equipamento.prefixo}_rod_${equipamento.rodoviaSigla}_km_${equipamento.kmLocal}`;
      var titleChart = `Idiomas utilizados - ${equipamento.prefixo}`;

      var dadosSeries: any[] = [];
      var categories: any[] = [];

      equipamento.resumos.forEach((resumo) => {
        dadosSeries.push(resumo.qtde);
        categories.push(resumo.idiomaDesc);
      });

      this.chartInfos[equipamento.sawId].push({
        chart: {
          animations: {
            enabled: false,
          },
          type: 'bar',
          height: 310,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: title,
              },
              svg: {
                filename: title,
              },
              png: {
                filename: title,
              },
            },
          },
        },
      });

      this.resumoSeries[equipamento.sawId].push({
        name: this.wordsTranslated['qtde'],
        data: dadosSeries,
      });

      this.titlesCharts[equipamento.sawId].push({
        title: {
          text: titleChart,
          align: 'center',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '18px',
            color: '#000000',
          },
        },
      });

      this.xaxisDados[equipamento.sawId].push({
        xaxis: {
          show: true,
          categories: categories,
        },
      });
    });
  }

  onTabClick(){
     this.showChangedTab = false;
     this.loadingService.show();
     this.setGrafico();
     this.loadingService.hide();
     this.showChangedTab = true;
  }

  refreshGridDetalhes() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  refreshGridResumos() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  setGrafico() {
    this.chartOptions = {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      legend: {
        offsetY: 10,
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
      },
      yaxis: {
        show: true,
      },
    };
  }

  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 200);
  }

  setFocusLineTrue() {
    setTimeout(() => {
      this.focusFirstLine = true;
    }, 200);
  }
}
