import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IChangePassword } from '../models/IChangePassword';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IUser } from 'src/app/shared/models/IUser';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


@Injectable({ providedIn: 'root' })
export class ChangePasswordService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}Usuario`;

  update(dados: IChangePassword) {
    const dadosLogin: IUser = this.authService.getLoginDados();

    dados.usuarioId = dadosLogin.dados.id;

    return this.http.put<IChangePassword>(
      `${this.apiUrl}/AtualizarSenha/${dados.usuarioId}`,
      dados
    );
  }
}
