<div class="modal" modal-name="modalMeusAtalhosForm">
  <div
    class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag
  >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <input type="hidden" formControlName="usuarioId" />

          <ws-combo
            class="fieldTela"
            [disabled]="permissoes['update'] && disableComboTela()"
            [focusMe]="!disableComboTela()"
            [textoLabel]="'paginas.meus-atalhos.form.tela' | translate"
            [multiple]="false"
            [selecionarTodos]="false"
            [itemCtrl]="f.aplicacaoTelaId"
            [items]="itensComboTela"
            [itemSelecionado]="itemSelecionadoComboTela"
            (changeEventCombo)="changeComboTela($event)"
          >
          </ws-combo>

          <ws-combo
            class="fieldIcone"
            [disabled]="!permissoes['update'] && registro"
            [focusMe]="disableComboTela()"
            [textoLabel]="'paginas.meus-atalhos.form.icone' | translate"
            [multiple]="false"
            [selecionarTodos]="false"
            [itemCtrl]="f.iconeId"
            [items]="itensComboIcone"
            [itemSelecionado]="itemSelecionadoComboIcone"
            (changeEventCombo)="changeComboIcone($event)"
          >
          </ws-combo>

          <mat-form-field class="fieldExibicao" appearance="outline">
            <mat-label>
              {{ "paginas.meus-atalhos.form.ordem_exibicao" | translate }}
            </mat-label>
            <input
              matInput
              placeholder="{{
                'paginas.meus-atalhos.form.ordem_exibicao' | translate
              }}"
              formControlName="ordemExibicao"
              autocomplete="off"
              type="number"
              numeric
            />
          </mat-form-field>

          <div class="fieldAbrir">
            <mat-slide-toggle
              formControlName="abrirAuto"
              (change)="onChangeVeiculoAtivo($event)"
            >
              {{ labelAbrirAutomaticamente }}
            </mat-slide-toggle>
          </div>

          <mat-form-field class="fieldTecla" appearance="outline">
            <mat-label>
              {{ "paginas.meus-atalhos.form.tecla" | translate }}
            </mat-label>
            <span matPrefix>{{ prefixoAtalho }}+</span>
            <input
              matInput
              autocomplete="off"
              formControlName="teclaAtalho"
              (keydown)="onKeydown($event)"
            />
          </mat-form-field>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary add"
              *ngIf="permissoes['create']"
              (click)="add()"
              [disabled]="!statusBotao.adicionar"
            >
              <i class="material-icons">add</i>
              <span class="text">{{ "botoes.adicionar" | translate }}</span>
            </button>

            <button
              mat-button
              type="button"
              class="primary delete"
              *ngIf="permissoes['delete']"
              (click)="del()"
              [disabled]="!statusBotao.deletar"
            >
              <i class="material-icons">delete</i>
              <span class="text">{{ "botoes.deletar" | translate }}</span>
            </button>

            <button
              mat-button
              type="button"
              class="primary cancel"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>

            <button
              type="submit"
              mat-button
              class="save"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
