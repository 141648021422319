import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';

import { AlertaService } from '../../services/alerta/alerta.service';

@Component({
  selector: 'app-tela-permissao',
  templateUrl: './tela-permissao.component.html',
  styleUrls: ['./tela-permissao.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class TelaPermissaoComponent implements OnInit {
  xpandStatus = [];
  telaPermissaoTotal = [];
  telaPermissaoParcial = [];
  itemTelaPermissao = [];
  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input('permissaoChange') permissaoChange: any;
  @Input('tela') tela: any;
  @Output('permissaoTela') permissaoTela: any = new EventEmitter();
  @Output('permissaoTotal') permissaoTotal: any = new EventEmitter();

  constructor(public alertaService: AlertaService) {}

  ngOnInit() {
    let telaPermissaoTotal = [];
    let telaPermissaoParcial = [];
    let telaPermissao = [];

    this.tela.forEach(function (telas) {
      telaPermissaoTotal[telas.aplicacaoTelaId] = telas.permissaoTotal;
      telaPermissaoParcial[telas.aplicacaoTelaId] = telas.permissaoParcial;
      telas.permissoes.forEach(function (permissao) {
        telaPermissao[`${telas.aplicacaoTelaId}_${permissao.telaPermissaoId}`] =
          permissao.possuiPermissao;
      });
    });

    this.telaPermissaoTotal = telaPermissaoTotal;
    this.telaPermissaoParcial = telaPermissaoParcial;
    this.itemTelaPermissao = telaPermissao;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.permissaoChange) {
      let retorno = changes.permissaoChange.currentValue;

      if (retorno) {
        this.telaPermissaoTotal[retorno.aplicacaoTelaId] =
          retorno.permissaoTotal;

        this.telaPermissaoParcial[retorno.aplicacaoTelaId] =
          retorno.permissaoParcial;

        retorno.permissoes.forEach((permissao) => {
          this.itemTelaPermissao[
            `${retorno.aplicacaoTelaId}_${permissao.telaPermissaoId}`
          ] = permissao.possuiPermissao;
        });
      }
    }
  }

  onChangePermissaoTela($event, permissaoId: number, telaId: number) {
    let outputPermissao = {
      telaId: telaId,
      permissaoId: permissaoId,

      permissaoStatus: $event.checked
    };

    this.permissaoTela.emit(outputPermissao);
  }

  onChangePermissaoTotal($event, telaId) {
    let outputPermissao = {
      telaId: telaId,
      permissaoStatus: $event.checked
    };

    this.permissaoTotal.emit(outputPermissao);
    this.setPermissoesTela(telaId, $event.checked);
  }

  setPermissoesTela(telaId, status) {
    this.itemTelaPermissao[`${telaId}_1`] = status;
    this.itemTelaPermissao[`${telaId}_2`] = status;
    this.itemTelaPermissao[`${telaId}_3`] = status;
    this.itemTelaPermissao[`${telaId}_4`] = status;

    this.telaPermissaoTotal[telaId] = status;
  }
}
