import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { UltimoStatusService } from '../services/ultimo-status.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { finalize } from 'rxjs/operators';
import { IUltimoStatusSaw } from '../models/IUltimoStatusSaw';
import { IUltimoStatusDados } from '../models/IUltimoStatusDados';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ultimo-status-grid',
  templateUrl: './ultimo-status-grid.component.html'
})
export class UltimoStatusGridComponent implements OnInit {
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IUltimoStatusDados[];

  @Input() permissoes: IGridPermissoes;
  @Input() sawId: number;
  @Input() dadosStatus: IUltimoStatusSaw;

  wordsTranslated: any;

  constructor(
    private wordsTranslate: WordsTranslateService,
    private ultimoStatusService: UltimoStatusService,
    private loadingService: LoadingService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.ultimo-status.gridStatusDados.';
    let words = {
      eventoId: '',
      eventoDesc: '',
      valor: '',
      dataHora: '',
      textNoContent: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes.sawId.currentValue != changes.sawId.previousValue){
      this.configuraDadosGrid();
    }

}


  ngOnInit(): void {
    this.isLoading = true;
    this.ultimoStatusService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoes = response[0];
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
      })
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      read: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      name: 'grid-ultimos-status-equipamento',
      keyboardNavigation: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'eventoId',
        label: this.wordsTranslated.eventoId,
        sort: true,
        width: '',
      },
      {
        name: 'eventoDesc',
        label: this.wordsTranslated.eventoDesc,
        width: '',
        sort: true,
      },
      {
        name: 'valor',
        label: this.wordsTranslated.valor,
        width: '',
        sort: true,
      },
      {
        name: 'dataHora',
        label: this.wordsTranslated.dataHora,
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm'
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.ultimoStatusService
      .getGridSecundario(this.sawId)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }


}
