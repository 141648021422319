import { Component, OnInit,  } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { MockupTotemService } from '../services/mockup-totem.service';
import { IMockupTotem } from '../models/IMockupTotem';
import { IConfiguracao } from 'src/app/shared/models/IConfiguracao';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mockup-totem-grid',
  templateUrl: './mockup-totem-grid.component.html',
  styleUrls: ['./mockup-totem-grid.component.scss']
})

export class MockupTotemGridComponent implements OnInit {
  public isLoading: boolean;
  public showForm = false;
  public showGrid = false;
  public showCardLigacao = false;
  public editForm = true;
  public prefixo = environment.prefixo;
  public dadosConfiguracao: IConfiguracao;
  public habilitaRevista: boolean;

  public dados: IMockupTotem[] = [];
  public dadosAplicacao: IMockupTotem;
  public dadosAtendimento: IMockupTotem;
  public dadosTitulo: IMockupTotem;
  public dadosCondicoesRodovia: IMockupTotem;
  public dadosProcurarDestino: IMockupTotem;
  public dadosPerguntasFrequentes: IMockupTotem;
  public dadosTelefonesUteis: IMockupTotem;
  public dadosTelefone: IMockupTotem;
  public dadosLivroRegistro: IMockupTotem;
  public dadosLeitorRevista: IMockupTotem;
  public dadosBarras: IMockupTotem;
  public dadosVideoRemoto: IMockupTotem;
  public dadosVideoLocal: IMockupTotem;

  constructor(
    private loadingService: LoadingService,
    private mockupTotemService: MockupTotemService,
    private alertaService: AlertaService
  ) { 
    this.listenChangesGrid();
    this.listenChangesColor();
  }

  listenChangesGrid() {
    this.mockupTotemService.eventoAtualizaGrid.subscribe(() => {
      this.configuraDadosGrid();
    });
  }

  listenChangesColor(){
    this.mockupTotemService.eventoAtualizaCor.subscribe((changeColor) => {
      this.setColorScreen(changeColor);
    });
  }
  
  ngOnInit() {
    const sync$ = this.mockupTotemService.syncConfigDefault();

    sync$.pipe(
      finalize(() => {
        this.dadosConfiguracao = JSON.parse(localStorage.getItem(this.prefixo + 'config_default'));
        this.habilitaRevista = this.dadosConfiguracao.magazineEnable;
        this.isLoading = true;
        this.configuraDadosGrid();
      })
    ).subscribe();
  }

  configuraDadosGrid(): void {
    this.mockupTotemService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dados = response;
          this.showGrid = true;
          this.showForm = true;
          this.setDadosGrid();
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  setDadosGrid() {
    this.dadosAplicacao = this.getParametro("BG_APLICACAO");
    this.dadosAtendimento = this.getParametro("BG_ATENDIMENTO");
    this.dadosBarras = this.getParametro("BG_BARRAS");
    this.dadosCondicoesRodovia = this.getParametro("BG_CONDICOES_RODOVIA");
    this.dadosLivroRegistro = this.getParametro("BG_LIVRO_REGISTRO");
    this.dadosLeitorRevista = this.getParametro("BG_LEITOR_REVISTA");
    this.dadosPerguntasFrequentes = this.getParametro("BG_PERGUNTAS_FREQUENTES");
    this.dadosProcurarDestino = this.getParametro("BG_PROCURAR_DESTINO");
    this.dadosTelefonesUteis = this.getParametro("BG_TELEFONES_UTEIS");
    this.dadosTitulo = this.getParametro("COR_TITULO");
    this.dadosVideoLocal = this.getParametro("BG_VIDEO_LOCAL");
    this.dadosVideoRemoto = this.getParametro("BG_VIDEO_REMOTO");
    this.dadosTelefone = this.getParametro("BG_TELEFONE");
  }

  getParametro(parametro: any) {
    let retorno = this.dados.find(dado => dado.personalizarTotemParametro == parametro);
    return retorno;
  }

  setColorScreen($event){
    var tipo = $event.tipo;
    var cor = $event.cor;

    switch (tipo) {
      case tipo = "BG_APLICACAO":
       this.dadosAplicacao.personalizarTotemValorCor = cor;
        break;
      case tipo = "COR_TITULO":
        this.dadosTitulo.personalizarTotemValorCor = cor;
        break;
     case tipo = "BG_CONDICOES_RODOVIA":
       this.dadosCondicoesRodovia.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_PROCURAR_DESTINO":
        this.dadosProcurarDestino.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_PERGUNTAS_FREQUENTES":
        this.dadosPerguntasFrequentes.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_TELEFONES_UTEIS":
      this.dadosTelefonesUteis.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_LIVRO_REGISTRO":
       this.dadosLivroRegistro.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_LEITOR_REVISTA":
      this.dadosLeitorRevista.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_BARRAS":
      this.dadosBarras.personalizarTotemValorCor = cor;
        break;
    case tipo = "BG_TELEFONE":
       this.dadosTelefone.personalizarTotemValorCor = cor;
        break;
  case tipo = "BG_VIDEO_REMOTO":
       this.dadosVideoRemoto.personalizarTotemValorCor = cor;
        break;
  case tipo = "BG_VIDEO_LOCAL":
       this.dadosVideoLocal.personalizarTotemValorCor = cor;
           break;
      default:
        break;
    }
  }

  showPreview(parametro: string) {
    if(parametro == 'BG_ATENDIMENTO')
    this.showCardLigacao = true;
    else if(parametro != 'BG_TELEFONE' && parametro != 'BG_VIDEO_LOCAL' && parametro != 'BG_VIDEO_REMOTO')
    this.showCardLigacao = false;

    this.mockupTotemService.changeCard.emit(parametro);
  }
}
