import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { of } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { IUserAcesso } from 'src/app/shared/models/IUserAcesso';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { IUser } from '../../models/IUser';
import { AlertaService } from '../../services/alerta/alerta.service';
import { ConfiguracaoService } from '../../services/configuracao/configuracao.service';
import { IConfiguracaoAd } from './models/IConfiguracaoAd';
import { EncryptHelper } from '../../helper/encrypt.helper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: IUserAcesso;
  public loginAd = false;
  public loginForm: UntypedFormGroup;
  public logarLoading = false;

  @Input('exibirLogin') exibirLogin = false;
  @Output() checkLogin = new EventEmitter<boolean>();
  @Output() clearTabs = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfiguracaoService,
    private authService: AuthService,
    private alertaService: AlertaService,
    private encryptHelper: EncryptHelper
  ) {}

  ngOnInit() {
    this.authService.showLogin.subscribe((state) => {
      this.exibirLogin = state;
    });
    this.criarFormulario();

    this.configService.getAD().subscribe((result: IConfiguracaoAd) => {
      this.loginAd = false;
      if (result && result.parametroSistemaValor > 0) {
        this.loginAd = true;
      }
    });
  }

  criarFormulario() {
    this.loginForm = this.fb.group({
      isActiveDirectory: [false],
      userName: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  enviarDados() {
    this.logarLoading = true;
    var password = this.loginForm.controls['password'].value;
    var encryptedPassword = this.encryptHelper.encrypt(password);

    var dados = {
      userName: this.loginForm.controls['userName'].value,
      password: `${encryptedPassword}`,
      isActiveDirectory: this.loginForm.controls['isActiveDirectory'].value,
    };

    const login = this.authService.login(dados);

    login
      .pipe(
        finalize(() => {
          this.logarLoading = false;
        }),
        catchError((_) => of(false))
      )
      .subscribe((result: IUser) => {
        if (result.dados) {
          let compare = this.authService.compareSessionUserName(
            result.dados.login
          );
          if (!compare) {
            this.clearTabs.emit(true);
          }

          this.authService.saveUserInfoOnLocalStorage(result);
          this.exibirLogin = false;
          this.checkLogin.emit(true);

          this.criarFormulario();
        } else {
          this.alertaService.erro('Dados inválidos', result['message']);
        }
      });
  }

  changeLoginAd($event: MatButtonToggle) {
    this.loginForm.patchValue({
      isActiveDirectory: $event.checked
    });
  }
}
