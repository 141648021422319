import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/operators';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IPerguntaEquipamento } from '../../models/IPerguntaEquipamento';
import { PerguntasEquipamentoService } from '../../services/perguntas-equipamento.service';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';

@Component({
  selector: 'app-perguntas-equipamento-grid',
  templateUrl: './perguntas-equipamento-grid.component.html',
  styleUrls: ['./perguntas-equipamento-grid.component.scss'],
})
export class PerguntasEquipamentoGridComponent implements OnInit, OnChanges {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public dadosGrid: IPerguntaEquipamento[];
  public searchFilter: any = '';
  public query: any;
  public statusBotao: IDialogButton;

  @Input() selectedSaw: ISAW = null;

  wordsTranslated: any;

  constructor(
    private perguntaEquipamentoService: PerguntasEquipamentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private dialogService: DialogService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.perguntas-equipamento.perguntas.grid.';
    const words = {
      title: '',
      perguntaPortugues: '',
      selecionado: '',
      ordenacaoTotem: '',
      ativo: '',
      buscaPergunta: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.perguntaEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  ngOnChanges() {
    this.isLoading = true;
    this.perguntaEquipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.configuraDados();
      });
  }

  configuraDados(): void {
    if (this.permissoesTela.read) {
      this.loadingService.show();
      this.perguntaEquipamentoService
        .getPerguntasGrid(this.selectedSaw.sawId)
        .pipe(
          finalize(() => {
            this.loadingService.hide();
          })
        )
        .subscribe(
          (response) => {
            var dados: IPerguntaEquipamento[] = [];

            response.forEach((pergunta) => {
              if (pergunta.perguntaPortugues.length > 100) {
                pergunta.perguntaPortugues = `${pergunta.perguntaPortugues.substring(
                  0,
                  100
                )}...`;
              }
              dados.push(pergunta);
            });
            this.dadosGrid = response;
          },
          (err) => {
            this.alertaService.erro();
          }
        );
    }
  }

  refresh() {
    this.configuraDados();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.query != '' && this.query != null) {
      const dialogConfirm = this.dialogService.confirmClearSearch();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.query = '';
        }
      });
    } else {
      if (this.permissoesTela.update) {
        moveItemInArray(
          this.dadosGrid,
          event.previousIndex,
          event.currentIndex
        );

        for (let index = 0; index < this.dadosGrid.length; index++) {
          if (this.dadosGrid[index].ordenacaoTotem > 0) {
            if (event.currentIndex != 0) {
              if (
                this.dadosGrid[event.previousIndex].ordenacaoTotem != 0 &&
                this.dadosGrid[event.currentIndex - 1].ordenacaoTotem != 0
              ) {
                this.dadosGrid[index].ordenacaoTotem = index + 1;
                var dado = this.dadosGrid[index];

                this.perguntaEquipamentoService
                  .update(dado)
                  .subscribe(() => {});
              }
            } else {
              if (this.dadosGrid[event.previousIndex].ordenacaoTotem != 0) {
                this.dadosGrid[index].ordenacaoTotem = index + 1;
                var dado = this.dadosGrid[index];

                this.perguntaEquipamentoService
                  .update(dado)
                  .subscribe(() => {});
              }
            }
          }
        }
      }
    }
  }

  changeToogle(dado: IPerguntaEquipamento) {
    if (this.permissoesTela.update) {
      if (dado.selecionado) {
        this.perguntaEquipamentoService.delete(dado).subscribe(() => {
          this.configuraDados();
        });
      }
      if (!dado.selecionado) {
        this.perguntaEquipamentoService.insert(dado).subscribe(() => {
          this.configuraDados();
        });
      }
    }
  }

  changeToogleAtivo(dado: IPerguntaEquipamento) {
    if (this.permissoesTela.update) {
      if (dado.ativo) {
        dado.ativo = false;
      } else {
        dado.ativo = true;
      }

      this.perguntaEquipamentoService.update(dado).subscribe(() => {
        this.configuraDados();
      });
    }
  }
}
