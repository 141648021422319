import { IMidia } from './../../../../shared/models/IMidia';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IDestino } from '../models/IDestino';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 11; 

@Injectable({
  providedIn: 'root'
})
export class DestinoService {

  constructor(private http: HttpClient, private authService: AuthService, private env : EnvironmentService) {}

  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';
  apiUrl = `${this.env.apiUrl}Destino`;

  getGrid(): Observable<IDestino[]> {
    return this.http.get<IDestino[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IDestino) {
    return this.http.get<IDestino>(`${this.apiUrl}/Form/${dados.destinoId}`);
  }

  insert(dados: IDestino) {
    return this.http.post<IDestino>(`${this.apiUrl}`, dados);
  }

  update(dados: IDestino) {
    return this.http.put<IDestino>(`${this.apiUrl}`, dados);
  }

  delete(dados: IDestino) {
    return this.http.delete<IDestino>(`${this.apiUrl}/${dados.destinoId}`);
  }

  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  getMidia(destinoId : number) {
    return this.http.get<File[]>(`${this.apiUrl}/destinoMidia/${destinoId}`);
  }

  uploadMidia(destinoId: number, uploadDados) {
    return this.http.post(`${this.apiUrl}/destinoMidia/${destinoId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
