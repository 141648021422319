<div class="modal" modal-name="modalRevistaUpload">
    <div *ngIf="!isLoading && carregaMidia == 'pdf'"
      class="box"
      cdkDragBoundary=".mat-tab-body-wrapper"
      cdkDrag
      style="width: 40%;">
        <div class="modal-container">
            <div class="modal-title" cdkDragHandle>
                <h2> {{ titleDialogPdf }} </h2>
                <button mat-button (click)="close()" class="close">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <div class="PDF">
              <ws-upload
                [edit]="editForm"
                [listFiles]="filesPdf"
                [limit]="1"
                [accept]="'.pdf'"
                [statusUpload]="isLoading"
                (eventPreview)="showImage($event)"
                (uploadEvent)="uploadFilesPdf($event)">
              </ws-upload>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading && carregaMidia == 'imagem'"
      class="box"
      cdkDragBoundary=".mat-tab-body-wrapper"
      cdkDrag
      style="width: 40%;">
        <div class="modal-container">
            <div class="modal-title" cdkDragHandle>
                <h2> {{ titleDialogImagem }} </h2>
                <button mat-button (click)="close()" class="close">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <div class="Imagem">
              <ws-upload
                [edit]="editForm"
                [listFiles]="filesImagem"
                [limit]="1"
                [accept]="'.png, .jpeg, .jpg'"
                [statusUpload]="isLoading"
                (eventPreview)="showImage($event)"
                (uploadEvent)="uploadFilesImagem($event)">
              </ws-upload>
            </div>
        </div>
    </div>
</div>