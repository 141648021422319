import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { WallpaperConfiguracaoSistemaService } from '../../../../../shared/services/wallpaper/wallpaper-configuracao-sistema.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';

@Component({
  selector: 'app-wallpaper-configuracao-sistema-upload',
  templateUrl: './wallpaper-configuracao-sistema-upload.component.html',
  styleUrls: ['./wallpaper-configuracao-sistema-upload.component.scss']
})
export class WallpaperConfiguracaoSistemaUploadComponent implements OnInit {
  @Input() editForm: EventEmitter<boolean>;

  acceptExtensions: string = '*';
  showUpload = false;
  statusUpload = false;
  listErrosUpload = [];

  files: File[] = [];

  constructor(
    private wallpaperConfiguracaoSistemaService: WallpaperConfiguracaoSistemaService,
    private alertaService: AlertaService,
    private imageZoomService: ImageZoomService
  ) {}

  ngOnInit() {
    this.loadFiles();
    this.loadExtensions();
  }

  loadFiles() {
    this.wallpaperConfiguracaoSistemaService.getMidia().subscribe(
      (response) => {
        if (response && response[0] && response[0]['content']) {
          this.files = response;
          this.showUpload = true;
        }
      },
      (err) => {
        this.alertaService.erro();
      }
    );
  }

  loadExtensions() {
    this.wallpaperConfiguracaoSistemaService
      .getExtensions()
      .subscribe((result: any) => {
        this.acceptExtensions = result.extensoes;
      });
  }

  uploadFiles(uploadDados) {
    this.listErrosUpload = [];
    this.statusUpload = true;
    const req = this.wallpaperConfiguracaoSistemaService.uploadMidia(
      uploadDados.formData
    );
    req.subscribe(
      (result: any) => {
        if (result) {
          result.forEach((element) => {
            this.listErrosUpload.push(element.detail);
          });

          this.alertaService.sucesso();
        } else {
          this.alertaService.sucesso();
        }

        this.statusUpload = false;
        this.wallpaperConfiguracaoSistemaService.changeWallpaper.emit(true);
        this.loadFiles();
      },
      (error: any) => {
        this.statusUpload = false;
        this.alertaService.erro();
      }
    );
  }

  showImage(image){
    this.imageZoomService.show(image);
  }
}
