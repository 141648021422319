<ws-grid
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [focus]="focusFirstLine"
  [registroPorPagina]="registroPorPagina"
  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
  (refreshEvent)="refresh()"
  (rowEvent)="loadPermissoes($event)"
></ws-grid>
