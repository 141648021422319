<div class="modal" modal-name="modalImportacaoAdUsuarioForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag style="width: 30%;">
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
        <div class="modal-content">
          <input type="hidden" formcontrolname="usuarios">
          <mat-grid-list cols="1" rowHeight="80px" [gutterSize]="'15px'">
            <mat-grid-tile [colspan]="1" [rowspan]="1">
              <ws-combo [focusMe]="true" [textoLabel]="'paginas.importacao-ad.usuarios.form.grupo-usuario' | translate"
                [multiple]="false" [selecionarTodos]="false" [itemCtrl]="f.grupoUsuarioId"
                [items]="itensComboGrupoUsuario" [itemSelecionado]="itemSelecionadoComboGrupoUsuario"
                (changeEventCombo)="changeComboGrupoUsuario($event)">
              </ws-combo>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button type="submit" *ngIf="editForm" mat-button class="save" [disabled]="formulario.invalid">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>