import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { ConfiguracaoService } from '../configuracao/configuracao.service';
import { IUser } from '../../../shared/models/IUser';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { EnvironmentService } from '../env/environment.service';

const prefixo = environment.prefixo;

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;

  showLogin = new EventEmitter<boolean>();
  private dadosLogin = JSON.parse(
    localStorage.getItem(`${prefixo}user_logged`)
  );

  constructor(
    private http: HttpClient,
    private configService: ConfiguracaoService,
    private env: EnvironmentService
  ) {
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  apiValidaTokenUrl = `${this.env.apiUrl}PermissaoUsuario/validate-token`;
  apiUrlPermissao = `${this.env.apiUrl}AplicacaoTelaPermissao`;
  apiUrl = `${this.env.apiUrl}account`;

  public login(dados: any) {
    return this.http.post<IUser>(`${this.apiUrl}/login`, dados);
  }

  public saveUserInfoOnLocalStorage(dados: IUser): void {
    this.dadosLogin = dados;

    localStorage.setItem(
      `${environment.prefixo}user_logged`,
      JSON.stringify(dados)
    );

    sessionStorage.setItem(
      `${environment.prefixo}user_name`,
      dados.acesso['userName']
    );

    this.configService.setIdioma(dados.dados.idioma);
  }

  public logout() {
    localStorage.removeItem(prefixo + 'softPhone');
    localStorage.removeItem(prefixo + 'user_logged');
    this.exibirLogin();
    this.currentUserSubject.next(null);
  }

  public checkLogin() {
    if (!this.dadosLogin) {
      return false;
    } else {
      return true;
    }
  }

  public setIdiomaUsuario() {
    this.configService.setIdioma(this.dadosLogin.dados.Idioma);
  }
  public getIdiomaUsuario() {
    if (this.dadosLogin.dados.idioma) {
      return this.dadosLogin.dados.idioma;
    } else if (this.configService.dadosConfiguracao.idioma) {
      return this.configService.dadosConfiguracao.idioma;
    } else {
      return environment.idioma;
    }
  }

  public getLoginDados() {
    return JSON.parse(localStorage.getItem(prefixo + 'user_logged'));
  }

  public validaToken() {
    return this.http.get<IGridPermissoes[]>(`${this.apiValidaTokenUrl}`);
  }

  public exibirLogin() {
    this.showLogin.emit(true);
  }

  compareSessionUserName(userName) {
    const sessionUserName = sessionStorage.getItem(
      `${environment.prefixo}user_name`
    );
    return userName == sessionUserName ? true : false;
  }

  public getPermissoes(telaId: number) {
    const dadosLogin: IUser = this.getLoginDados();

    const params = new HttpParams()
      .set('usuarioId', dadosLogin.dados.id.toString())
      .set('aplicacaoTelaId', telaId.toString());

    return this.http.get<IGridPermissoes[]>(`${this.apiUrlPermissao}`, {
      params
    });
  }
  
  public refreshTokenJWT(dados: any){
    return this.http.post(`${this.apiUrl}/refresh-token`, dados);
  }
}
