<div class="page-content page-content-row-2" *ngIf="readForm">
    <div class="box-filter">
      <form
        id="frm-relatorio-vandalismo"
        *ngIf="filterForm && showFilter"
        class="default"
        [formGroup]="filterForm"
        (ngSubmit)="filter()"
      >
  
      <div class="container-combo-saw">
        <ws-combo
          [focusMe]="false"
          [textoLabel]="wordsTranslated.sawId"
          [multiple]="true"
          [selecionarTodos]="true"
          [itemCtrl]="f.sawId"
          [items]="itensComboSAW"
          [itemSelecionado]="itemSelecionadoComboSAW"
          (changeEventCombo)="onChangeComboSaw($event)"
        >
        </ws-combo>
      </div>
      
        <div class="container-data-inicio">
          <mat-form-field appearance="outline">
            <mat-label>{{ wordsTranslated.dataInicio }}</mat-label>
            <input
              [focusMe]="true"
              matInput
              [matDatepicker]="pickerDataInicio"
              formControlName="dataInicio"
              (dateChange)="changeDateDe($event)"
              [max]="formValidParam.maxDateDe"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataInicio"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #pickerDataInicio
              [startAt]="formValidParam.startDateDe"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
  
        <div class="container-data-fim">
          <mat-form-field appearance="outline">
            <mat-label>{{ wordsTranslated.dataFim }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickerDataFim"
              formControlName="dataFim"
              [min]="formValidParam.minDateAte"
              [max]="formValidParam.maxDateAte"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataFim"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #pickerDataFim
              [startAt]="formValidParam.startDateAte"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
  
        <div class="container-combo-ordenacao">
          <ws-combo
            [textoLabel]="wordsTranslated.ordenacaoData"
            [multiple]="false"
            [selecionarTodos]="false"
            [itemCtrl]="f.ordenacaoData"
            [items]="itensComboOrdenacao"
            [itemSelecionado]="itemSelecionadoComboOrdenacao"
            (changeEventCombo)="onChangeComboOrdenacao($event)"
          >
          </ws-combo>
        </div>
  
        <div class="container-combo-evento">
          <ws-combo
            [textoLabel]="wordsTranslated.eventoNome"
            [multiple]="true"
            [selecionarTodos]="true"
            [itemCtrl]="f.eventoNome"
            [items]="itensComboEventos"
            [itemSelecionado]="itemSelecionadoComboEventos"
            (changeEventCombo)="onChangeComboEvento($event)"
          >
          </ws-combo>
        </div>
  
        <div class="buttons-container">
          <button
            mat-button
            type="submit"
            class="save"
            [disabled]="filterForm.invalid"
          >
            <i class="material-icons">search</i>
            {{ "botoes.visualizar" | translate }}
          </button>
        </div>
  
        <div class="buttons-container">
          <button
            mat-button
            type="button"
            (click)="export()"
            class="primary exportar"
            [disabled]="filterForm.invalid || desabilitarExport"
          >
            <i class="far fa-file-excel"></i> {{ "botoes.exportar" | translate }}
          </button>
        </div>
      </form>
    </div>
  
    <ng-container
      *ngIf="dadosRelatorio && dadosRelatorio.length > 0; else tmpEmpty"
    >
      <div class="subTabs">
        <mat-tab-group animationDuration="0ms" style="width: 100%">
          <mat-tab
            *ngFor="let equipamento of dadosRelatorio"
            label="{{ equipamento.prefixo }}"
          >
            <div class="tabela">
              <div class="body header" style="position: sticky; top: 0">
                <div class="linha">
                  <div class="dados">
                    {{ wordsTranslatedGrid.evento }}
                  </div>
                  <div
                    style="
                      border-right: 1px solid var(--bg-tertiary-color);
                      width: 150px !important;
                    "
                    class="dados"
                  >
                    {{ wordsTranslatedGrid.dataHora }}
                  </div>
                </div>
              </div>
  
              <div class="body">
                <div class="linha" *ngFor="let dado of equipamento.eventos">
                  <div class="dados">
                    {{ dado.evento }}
                  </div>
                  <div
                    style="
                      border-right: 1px solid var(--bg-tertiary-color);
                      width: 150px !important;
                    "
                    class="dados"
                  >
                    {{ dado.dataHora | date: "dd/MM/yyyy HH:mm" }}
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-container>
  
    <ng-template #tmpEmpty>
      <ws-no-content
        [title]="titleNoContent"
        [text]="textNoContent"
      ></ws-no-content>
    </ng-template>
  </div>
  