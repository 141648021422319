import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IRVandalismoSolicAtendimentoSaw } from '../models/IRVandalismoSolicAtendimentoSaw';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 39;

@Injectable({
  providedIn: 'root'
})
export class VandalismoSolicAtendimentoService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}RVandalismoSolicitacaoAtendimento`;

  get(parametros: any): Observable<IRVandalismoSolicAtendimentoSaw[]> {
    var datePipe = new DatePipe("en-US");
    parametros.dataInicio = datePipe.transform(parametros.dataInicio, "yyyy-MM-dd");
    parametros.dataFim = datePipe.transform(parametros.dataFim, "yyyy-MM-dd");

    let params = new HttpParams()
      .set('dataInicio', parametros.dataInicio)
      .set('dataFim', parametros.dataFim)
      .set('ordenacaoData', parametros.ordenacaoData);

    parametros.sawId.forEach((sawId) => {
      params = params.append('sawId', sawId.toString());
    });

    parametros.eventoNome.forEach((eventoNome) => {
      params = params.append('eventoNome', eventoNome.toString());
    });

    return this.http.get<IRVandalismoSolicAtendimentoSaw[]>(this.apiUrl, { params });
  }

  export(parametros: any) {
    var datePipe = new DatePipe("en-US");
    parametros.dataInicio = datePipe.transform(parametros.dataInicio, "yyyy-MM-dd");
    parametros.dataFim = datePipe.transform(parametros.dataFim, "yyyy-MM-dd");

    let params = new HttpParams()
      .set('dataInicio', parametros.dataInicio)
      .set('dataFim', parametros.dataFim)
      .set('ordenacaoData', parametros.ordenacaoData);

    parametros.sawId.forEach((sawId) => {
      params = params.append('sawId', sawId.toString());
    });

    parametros.eventoNome.forEach((eventoNome) => {
      params = params.append('eventoNome', eventoNome.toString());
    });

    return this.http.get(`${this.apiUrl}/export`, {
      params,
    });
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
