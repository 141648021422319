import { Injectable } from '@angular/core';

const noOp = () => {};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public currentNotification: Notification | null = null;
  private notificationPermission: string;

  constructor() {
    Notification.requestPermission().then((permission) => {
      this.notificationPermission = permission;
    });
  }

  clickWrapper(onClick: () => void) {
    return () => {
      onClick();
      this.currentNotification = null;
    };
  }

  closeWrapper(onClose: () => void) {
    return () => {
      onClose();
      this.currentNotification?.close();
      this.currentNotification = null;
    };
  }

  createNotification(iconImage: string, message: string, title: string, prefixo: string, onClick: () => void = noOp, onClose: () => void = noOp) {
    if(this.notificationPermission === 'granted') {
        this.currentNotification = new Notification(title, { body: message, icon: iconImage});
        this.currentNotification.onclick = this.clickWrapper(onClick);
        this.currentNotification.onclose = this.closeWrapper(onClose);
    }
  }
}
