<div class="grids">
  <div class="two-columns">
    <div class="grid-principal">
      <div class="title">
        <p>{{ "paginas.ultimo-status.titles.grid-saw" | translate }}</p>
      </div>
      <div class="grid">
        <ws-grid
          *ngIf="!isLoading"
          [permissoes]="permissoesTela"
          [isLoading]="isLoading"
          [acoesGrid]="acoesGrid"
          [colunasGrid]="colunasGrid"
          [focus]="focusFirstLine"
          [dadosGrid]="dadosGrid"
          (rowEvent)="rowEvent($event)"
        >
        </ws-grid>
      </div>
    </div>
    <div class="grid-secundario" *ngIf="showStatusGrid; else tmpEmptyStatus">
      <div class="title">
        <p>
          {{ "paginas.ultimo-status.titles.grid-status" | translate }} -
          {{ dadosStatus.prefixo }}
        </p>
      </div>
      <div class="grid">
        <app-ultimo-status-grid
          [permissoes]="permissoesTela"
          [dadosStatus]="dadosStatus"
          [sawId]="sawId"
        >
        </app-ultimo-status-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #tmpEmptyStatus>
  <ws-no-content
    [title]="wordsTranslated.titleNoContent"
    [text]="wordsTranslated.textNoContent"
  >
  </ws-no-content>
</ng-template>
