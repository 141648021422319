import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  HostListener
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { IShortCut } from './models/IShortCut';

import { LoadingService } from '../../services/loading/loading.service';
import { ShortCutService } from './services/shortCut.service';
import { IUser } from '../../models/IUser';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.scss']
})
export class ShorCutComponent implements OnInit {
  public dadosLogin: IUser;

  @Input() registro: IShortCut[];
  @Output() addTab = new EventEmitter();

  // MONITORA AS TECLA PRECIONADAS PARA CARREGAR OS ATALHOS
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.altKey) {
      let key = event.code.replace('Digit', '');
      key = key.replace('Key', '');
      this.getShortCut(key);
    }
  }

  constructor(
    public shortcutService: ShortCutService,
    public authService: AuthService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<ShorCutComponent>
  ) { }

  ngOnInit() { }

  openShortCut(item: IShortCut) {
    this.addTab.emit({
      label: item.aplicacaoTelaTexto,
      path: item.aplicacaoTelaPagina
    });
  }

  getShortCut(key: string) {
    const registroShortCut = this.shortcutService.getShortCut(key);
    if (registroShortCut && registroShortCut[0]) {
      this.openShortCut(registroShortCut[0]);
    }
  }
}
