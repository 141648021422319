import { EquipamentosVersaoTotemGridComponent } from './page/cadastro/versao-totem/grids/equipamentos/equipamentos-versao-totem-grid.component';
import { WsGridModule } from '@ws/grid';
import { PerguntasFrequentesGridComponent } from 'src/app/page/cadastro/perguntas-frequentes/grid/perguntas-frequentes-grid.component';
import { LivroRegistroGridComponent } from './page/cadastro/livro-registro/grid/livro-registro-grid/livro-registro-grid.component';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {} from '@angular/material/core';

import { DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { WsComboModule } from '@ws/combo';

registerLocaleData(localePt);

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
    date: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    date: 'DD/MM/YYYY',
    time: 'HH:mm',
    monthDayLabel: 'MMMM D',
    monthDayA11yLabel: 'MMMM D',
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    dateA11yLabel: 'LLLL'
  }
};

// import ngx-translate and the http loader
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// SERVICES
import { AuthService } from './shared/services/auth/auth.service';
import { DialogService } from './shared/services/dialog/dialog.service';
import { AuthInterceptor } from './shared/services/auth/interceptor.service';
import { ErrorInterceptor } from './shared/services/auth/error-interceptor.service';
import { AdService } from './shared/services/ad-service';
import { AlertaService } from './shared/services/alerta/alerta.service';
import { LoadingService } from './shared/services/loading/loading.service';
import { PaginatorTranslateService } from './shared/services/translate/paginator.translate';
import { LegendaService } from './shared/services/legenda/legenda.service';
import { ImageZoomService } from './shared/services/image-zoom/image-zoom.service';
import { WordsTranslateService } from './shared/services/translate/words.translate';

/*DIRECTIVES*/
import { NumericDirective } from './shared/directives/numeric.directive';
import { FocusDirective } from './shared/directives/focus.directive';

/*PIPES*/
import { DynamicPipe } from './shared/pipes/dynamic.pipe';

/*MODULOS*/
import { ComumModule } from './shared/modules/comum.module';
import { SharedModule } from './shared/modules/shared.module';

/*COMPONENTS*/
import { LoginComponent } from './shared/components/login/login.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { ConfirmPasswordComponent } from './shared/components/confirmPassword/confirmPassword.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ShorCutComponent } from './shared/components/shortcut/shortcut.component';
import { LegendaComponent } from './shared/components/legenda/legenda.component';
import { ErrorDetailComponent } from './shared/components/error-detail/error-detail.component';
import { TelaPermissaoComponent } from './shared/components/tela-permissao/tela-permissao.component';
import { ImageZoomComponent } from './shared/components/image-zoom/image-zoom.component';

/*PAGINAS*/
import { ParametrosLogConfiguracaoSistemaGridComponent } from './page/cadastro/configuracao-sistema/parametros-log/grid/parametros-log-configuracao-sistema-grid.component';
import { ParametrosConfiguracaoSistemaFormComponent } from './page/cadastro/configuracao-sistema/parametros/form/parametros-configuracao-sistema-form.component';
import { ParametrosConfiguracaoSistemaGridComponent } from './page/cadastro/configuracao-sistema/parametros/grid/parametros-configuracao-sistema-grid.component';

import { ImportacaoAdTabComponent } from './page/usuario/importacao-ad/tabs/importacao-ad-tab.component';
import { ImportacaoAdConfiguracaoFormComponent } from './page/usuario/importacao-ad/configuracao/form/importacao-ad-configuracao-form.component';
import { ImportacaoAdConfiguracaoGridComponent } from './page/usuario/importacao-ad/configuracao/grid/importacao-ad-configuracao-grid.component';
import { ImportacaoAdUsuariosFormComponent } from './page/usuario/importacao-ad/importar-usuarios/form/importacao-ad-usuarios-form.component';
import { ImportacaoAdUsuariosGridComponent } from './page/usuario/importacao-ad/importar-usuarios/grid/importacao-ad-usuarios-grid.component';

import { TabsConfiguracaoSistemaTabComponent } from './page/cadastro/configuracao-sistema/tabs/tabs-configuracao-sistema-tab.component';
import { WallpaperConfiguracaoSistemaUploadComponent } from './page/cadastro/configuracao-sistema/wallpaper/upload/wallpaper-configuracao-sistema-upload.component';
import { ContaEmailFormComponent } from './page/cadastro/conta-email/form/conta-email-form.component';
import { ContaEmailGridComponent } from './page/cadastro/conta-email/grid/conta-email-grid.component';
import { EmpresaFormComponent } from './page/cadastro/empresa/form/empresa-form.component';
import { EmpresaGridComponent } from './page/cadastro/empresa/grid/empresa-grid.component';
import { EmpresaUploadComponent } from './page/cadastro/empresa/upload/empresa-upload.component';
import { MeusAtalhosGridComponent } from './page/cadastro/meus-atalhos/grid/meus-atalhos-grid.component';
import { MeusAtalhosFormComponent } from './page/cadastro/meus-atalhos/form/meus-atalhos-form.component';

import { GrupoUsuarioFormComponent } from './page/usuario/grupo-usuario/form/grupo-usuario-form.component';
import { GrupoUsuarioGridComponent } from './page/usuario/grupo-usuario/grid/grupo-usuario-grid.component';
import { PermissaoGrupoGridComponent } from './page/usuario/permissao/grupo/grid/permissao-grupo-grid.component';
import { PermissaoGrupoModulosComponent } from './page/usuario/permissao/grupo/modulos/permissao-grupo-modulos.component';
import { PermissaoTabComponent } from './page/usuario/permissao/tabs/permissao-tab.component';
import { PermissaoUsuarioGridComponent } from './page/usuario/permissao/usuario/grid/permissao-usuario-grid.component';
import { PermissaoUsuarioModulosComponent } from './page/usuario/permissao/usuario/modulos/permissao-usuario-modulos.component';
import { UsuarioFormComponent } from './page/usuario/usuarios/form/usuario-form.component';
import { UsuarioGridComponent } from './page/usuario/usuarios/grid/usuario-grid.component';
import { UsuarioUploadComponent } from './page/usuario/usuarios/upload/usuario-upload.component';
import { HidePasswordDirective } from './shared/directives/hide-password.directive';
import { RodoviaGridComponent } from './page/cadastro/rodovia/grid/rodovia-grid.component';
import { RodoviaMapaComponent } from './page/cadastro/rodovia/form/mapa/rodovia-mapa.component';
import { RodoviaFormComponent } from './page/cadastro/rodovia/form/rodovia-form.component';
import { RodoviaImportarKmlGridComponent } from './page/cadastro/rodovia/tab/importar-kml/grid/rodovia-importar-kml-grid.component';
import { Globals } from './app.globals';
import { CloseModalService } from './shared/services/closeModal/close-modal.service';
import { UsuarioAtendenteGridComponent } from './page/usuario/usuario-atendente/grid/usuario-atendente-grid.component';
import { TelefonesUteisEquipamentoGridComponent } from './page/saw/telefones-uteis-equipamento/telefones/grid/telefones-uteis-grid.component';
import { SawTelefonesPrincipalGridComponent } from './page/saw/telefones-uteis-equipamento/principal/grid/saw-telefones-principal-grid.component';
import { GrupoDestinoFormComponent } from './page/cadastro/grupo-destino/form/grupo-destino-form.component';
import { GrupoDestinoGridComponent } from './page/cadastro/grupo-destino/grid/grupo-destino-grid.component';
import { ArquivoPadraoTotemGridComponent } from './page/cadastro/arquivo-padrao-totem/grid/arquivo-padrao-totem-grid.component';
import { DestinosEquipamentoGridComponent } from './page/saw/destino-equipamento/destino/grid/destinos-equipamento-grid.component';
import { SawDestinoPrincipalGridComponent } from './page/saw/destino-equipamento/principal/grid/saw-destino-principal-grid.component';
import { PerguntasEquipamentoGridComponent } from './page/saw/perguntas-equipamento/perguntas/grid/perguntas-equipamento-grid.component';
import { SawPerguntaPrincipalGridComponent } from './page/saw/perguntas-equipamento/principal/grid/saw-pergunta-principal-grid.component';
import { SawEquipamentoGridComponent } from './shared/components/saw-grid/saw-grid.component';
import { EquipamentoGridComponent } from './page/saw/equipamento/grid/equipamento-grid.component';
import { EquipamentoFormComponent } from './page/saw/equipamento/form/equipamento-form.component';
import { DestinoGridComponent } from './page/cadastro/destino/grid/destino-grid.component';
import { DestinoFormComponent } from './page/cadastro/destino/form/destino-form.component';
import { DestinoUploadMidiaGridComponent } from './page/cadastro/destino/destinoUploadMidia/destino-upload-midia-grid.component';
import { DestinoMapaComponent } from './page/cadastro/destino/form/mapa/destino-mapa.component';
import { EquipamentoMapaComponent } from './page/saw/equipamento/form/mapa/equipamento-mapa.component';
import { EquipamentoArquivoGridComponent } from './page/saw/equipamento/tabs/arquivos/grid/equipamento-arquivo-grid.component';
import { GrupoMensagemFormComponent } from './page/operacao/grupo-mensagem/form/grupo-mensagem-form.component';
import { GrupoMensagemGridComponent } from './page/operacao/grupo-mensagem/grid/grupo-mensagem-grid.component';
import { PerguntasFrequentesFormComponent } from './page/cadastro/perguntas-frequentes/form/perguntas-frequentes-form/perguntas-frequentes-form.component';
import { WsNoContentModule } from '@ws/no-content';
import { WsUploadModule } from '@ws/upload';
import { TelefonesUteisGridComponent } from './page/cadastro/telefones-uteis/grid/telefones-uteis-grid.component';
import { TelefonesUteisFormComponent } from './page/cadastro/telefones-uteis/form/telefones-uteis-form/telefones-uteis-form.component';
import { HistoricoMensagemGridComponent } from './page/operacao/historico-mensagem/grid/historico-mensagem-grid.component';
import { ClickPerguntasGridComponent } from './page/relatorio/click-perguntas/grid/click-perguntas-grid.component';
import { UltimoStatusGridSawComponent } from './page/saw/ultimo-status/grid-saw-principal/ultimo-status-grid-saw.component';
import { UltimoStatusGridComponent } from './page/saw/ultimo-status/grid-ultimo-status/ultimo-status-grid.component';
import { LivroRegistroFormComponent } from './page/cadastro/livro-registro/form/livro-registro-form/livro-registro-form.component';
import { ClickGrupoDestinoGridComponent } from './page/relatorio/click-grupo-destino/grid/click-grupo-destino-grid.component';
import { TipoAtendimentoGridComponent } from './page/operacao/tipo-atendimento/grid/tipo-atendimento-grid.component';
import { TipoAtendimentoFormComponent } from './page/operacao/tipo-atendimento/form/tipo-atendimento-form.component';
import { ClickClassificacaoAtendimentoGridComponent } from './page/relatorio/click-classificacao-atendimento/grid/click-classificacao-atendimento-grid.component';
import { ClickAtendimentoIdiomaGridComponent } from './page/relatorio/click-atendimento-idioma/grid/click-atendimento-idioma-grid.component';
import { SawAtendimentoGridComponent } from './page/operacao/atendimento/saw/grid/saw-atendimento-grid.component';
import { AtendimentoMensagensGridComponent } from './page/operacao/atendimento/mensagens/grid/atendimento-mensagens-grid.component';
import { AtendimentoAtendimentosGridComponent } from './page/operacao/atendimento/atendimentos/grid/atendimento-atendimentos-grid.component';
import { AtendimentoPrincipalComponent } from './page/operacao/atendimento/principal/grid/atendimento-principal.component';
import { AtendimentoFormComponent } from './page/operacao/atendimento/principal/form/atendimento-form.component';
import { AtendimentoDestinosGridComponent } from './page/operacao/atendimento/principal/form/destinos/grid/atendimento-destinos-grid.component';
import { AtendimentoMapaComponent } from './page/operacao/atendimento/principal/form/mapa/atendimento-mapa.component';
import { GravacoesVideosGridComponent } from './page/saw/gravacoes-videos/grid/gravacoes-videos-grid.component';
import { MensagemAvisoFormComponent } from './page/operacao/atendimento/principal/form-mensagem-aviso/mensagem-aviso-form.component';
import { DisponibilidadeConexaoGridComponent } from './page/relatorio/disponibilidade-conexao/grid/disponibilidade-conexao-grid.component';
import { HistoricoAtendimentoGridComponent } from './page/operacao/historico-atendimento/grid/historico-atendimento-grid.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { UsuarioAtendenteFormComponent } from './page/usuario/usuario-atendente/form/usuario-atendente-form.component';
import { StatusSoftPhoneComponent } from './shared/components/soft-phone/status/status-soft-phone.component';
import { WindowSoftPhoneComponent } from './shared/components/soft-phone/window/window-soft-phone.component';
import { WindowComponent } from './shared/components/soft-phone/window.component';
import { RouterModule } from '@angular/router';
import { ControleVolumeComponent } from './shared/components/controle-volume/controle-volume.component';
import { DiagnosticoGravacoesGridComponent } from './page/operacao/diagnostico-gravacoes/grid/diagnostico-gravacoes-grid.component';
import { MapaOperacionalComponent } from './page/operacao/mapa-operacional/mapa-operacional.component';
import { MapaService } from './shared/services/mapa/mapa.service';
import { MapaOperacionalInformacoesEquipamentoComponent } from './page/operacao/mapa-operacional/informacoes-equipamento/mapa-operacional-informacoes-equipamento.component';
import { NotificationService } from './shared/services/notification/notification.service';
import { ColorPickerComponent } from './shared/components/color-picker/color-picker.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { SearchFilterPipe } from './shared/pipes/search.pipe';
import { MockupTotemGridComponent } from './page/saw/mockup-totem/grid/mockup-totem-grid.component';
import { MockupTotemFormComponent } from './page/saw/mockup-totem/form/mockup-totem-form.component';
import { ModuloGridComponent } from './page/configuracoes/seguranca/modulo/grid/modulo-grid.component';
import { ModuloMenuPrincipalGridComponent } from './page/configuracoes/seguranca/principal/grid/modulo-menu-principal-grid.component';
import { ModuloMenuGridComponent } from './page/configuracoes/seguranca/menu/grid/modulo-menu-grid.component';
import { ModuloFormComponent } from './page/configuracoes/seguranca/modulo/form/modulo-form.component';
import { ModuloMenuFormComponent } from './page/configuracoes/seguranca/menu/form/modulo-menu-form.component';
import { VandalismoSolicAtendimentoGridComponent } from './page/relatorio/vandalismo-solic-atendimento/grid/vandalismo-solic-atendimento-grid.component';
import { DisponibilidadeSIPGridComponent } from './page/relatorio/disponibilidade-SIP/grid/disponibilidade-SIP-grid.component';
import { VersaoTotemFormComponent } from './page/cadastro/versao-totem/form/versao-totem-form.component';
import { VersaoTotemPrincipalComponent } from './page/cadastro/versao-totem/principal/versao-totem-principal.component';
import { VersaoTotemUploadComponent } from './page/cadastro/versao-totem/upload/form/versao-totem-upload.component';
import { VersoesTotemGridComponent } from './page/cadastro/versao-totem/grids/versoes/versoes-totem-grid.component';
import { LicencaComponent } from './page/licenca/licenca.component';
import { AlertaGridComponent } from './page/configuracoes/alerta/grid/alerta-grid.component';
import { AlertaUsuariosMeioEnvioGridComponent } from './page/configuracoes/alerta/usuarios-meio-envio/grid/alerta-usuarios-meio-envio-grid.component';
import { AlertaParametrosGridComponent } from './page/configuracoes/alerta/parametros/grid/alerta-parametros-grid.component';
import { AlertaParametrosFormComponent } from './page/configuracoes/alerta/parametros/form/alerta-parametros-form.component';
import { LivroRegistroEnviosGridComponent } from './page/cadastro/livro-registro/form/livro-registro-form-envios/grid/livro-registro-envios-grid.component';
import { CamposMockUpComponent } from './page/saw/mockup-totem/form/campos/campos-mockup-form.component';
import { EnvironmentServiceProvider } from './shared/services/env/environment.service.provider';
import { RevistaGridComponent } from './page/cadastro/revista/grid/revista-grid.component';
import { RevistaFormComponent } from './page/cadastro/revista/form/revista-form.component';
import { RevistaUploadMidiaGridComponent } from './page/cadastro/revista/revistaUploadMidia/revista-upload-midia-grid.component';
import { TipoLivroRegistroFormComponent } from './page/cadastro/tipo-livro-registro/form/tipo-livro-registro-form.component';
import { TipoLivroRegistroGridComponent } from './page/cadastro/tipo-livro-registro/grid/tipo-livro-registro-grid.component';
import { MensagensComponent } from './page/operacao/historico-atendimento/messages/mensagens.component';

export function getPages() {
  return [
    TabsConfiguracaoSistemaTabComponent,
    ParametrosConfiguracaoSistemaGridComponent,
    ParametrosConfiguracaoSistemaFormComponent,
    ParametrosLogConfiguracaoSistemaGridComponent,
    WallpaperConfiguracaoSistemaUploadComponent,
    ImportacaoAdTabComponent,
    ImportacaoAdConfiguracaoGridComponent,
    ImportacaoAdConfiguracaoFormComponent,
    ImportacaoAdUsuariosGridComponent,
    ImportacaoAdUsuariosFormComponent,
    UsuarioGridComponent,
    UsuarioFormComponent,
    UsuarioUploadComponent,
    UsuarioAtendenteGridComponent,
    UsuarioAtendenteFormComponent,
    ContaEmailGridComponent,
    ContaEmailFormComponent,
    GrupoUsuarioGridComponent,
    GrupoUsuarioFormComponent,
    PermissaoTabComponent,
    PermissaoUsuarioGridComponent,
    PermissaoUsuarioModulosComponent,
    PermissaoGrupoGridComponent,
    PermissaoGrupoModulosComponent,
    EmpresaGridComponent,
    EmpresaFormComponent,
    EmpresaUploadComponent,
    RodoviaGridComponent,
    RodoviaFormComponent,
    RodoviaMapaComponent,
    RodoviaImportarKmlGridComponent,
    MeusAtalhosGridComponent,
    MeusAtalhosFormComponent,
    TelefonesUteisGridComponent,
    SawTelefonesPrincipalGridComponent,
    GrupoDestinoFormComponent,
    GrupoDestinoGridComponent,
    SawDestinoPrincipalGridComponent,
    DestinosEquipamentoGridComponent,
    SawEquipamentoGridComponent,
    SawPerguntaPrincipalGridComponent,
    PerguntasEquipamentoGridComponent,
    ArquivoPadraoTotemGridComponent,
    EquipamentoGridComponent,
    EquipamentoFormComponent,
    EquipamentoMapaComponent,
    EquipamentoArquivoGridComponent,
    DestinoGridComponent,
    DestinoFormComponent,
    DestinoUploadMidiaGridComponent,
    DestinoMapaComponent,
    TelefonesUteisEquipamentoGridComponent,
    TelefonesUteisGridComponent,
    TelefonesUteisFormComponent,
    PerguntasFrequentesGridComponent,
    PerguntasFrequentesFormComponent,
    LivroRegistroGridComponent,
    LivroRegistroFormComponent,
    //LivroRegistroFormEnviosComponent,
    LivroRegistroEnviosGridComponent,
    CamposMockUpComponent,
    GrupoMensagemFormComponent,
    GrupoMensagemGridComponent,
    HistoricoMensagemGridComponent,
    UltimoStatusGridSawComponent,
    UltimoStatusGridComponent,
    ClickPerguntasGridComponent,
    ClickGrupoDestinoGridComponent,
    ClickClassificacaoAtendimentoGridComponent,
    ClickAtendimentoIdiomaGridComponent,
    AtendimentoPrincipalComponent,
    AtendimentoMensagensGridComponent,
    AtendimentoAtendimentosGridComponent,
    AtendimentoDestinosGridComponent,
    SawAtendimentoGridComponent,
    MensagemAvisoFormComponent,
    AtendimentoMapaComponent,
    AtendimentoFormComponent,
    TipoAtendimentoGridComponent,
    TipoAtendimentoFormComponent,
    TipoAtendimentoFormComponent,
    DisponibilidadeConexaoGridComponent,
    GravacoesVideosGridComponent,
    HistoricoAtendimentoGridComponent,
    DiagnosticoGravacoesGridComponent,
    StatusSoftPhoneComponent,
    WindowSoftPhoneComponent,
    WindowComponent,
    MapaOperacionalComponent,
    MapaOperacionalInformacoesEquipamentoComponent,
    MockupTotemGridComponent,
    MockupTotemFormComponent,
    ModuloGridComponent,
    ModuloMenuPrincipalGridComponent,
    ModuloMenuGridComponent,
    ModuloFormComponent,
    ModuloMenuFormComponent,
    VandalismoSolicAtendimentoGridComponent,
    DisponibilidadeSIPGridComponent,
    VersaoTotemFormComponent,
    VersaoTotemPrincipalComponent,
    EquipamentosVersaoTotemGridComponent,
    VersaoTotemUploadComponent,
    VersoesTotemGridComponent,
    LicencaComponent,
    AlertaGridComponent,
    AlertaUsuariosMeioEnvioGridComponent,
    AlertaParametrosGridComponent,
    AlertaParametrosFormComponent,
    RevistaGridComponent,
    RevistaFormComponent,
    RevistaUploadMidiaGridComponent,
    RodoviaMapaComponent,
    EquipamentoMapaComponent,
    TipoLivroRegistroFormComponent,
    TipoLivroRegistroGridComponent,
    MensagensComponent
  ];
}

export function getComponents() {
  return [
    AboutComponent,
    LegendaComponent,
    ChangePasswordComponent,
    LoadingComponent,
    LoginComponent,
    ConfirmComponent,
    ConfirmPasswordComponent,
    NotFoundComponent,
    ErrorDetailComponent,
    ShorCutComponent,
    ImageZoomComponent,
    TelaPermissaoComponent,
    ControleVolumeComponent,
    ColorPickerComponent
  ];
}
@NgModule({
    declarations: [
        AppComponent,
        NumericDirective,
        FocusDirective,
        HidePasswordDirective,
        DynamicPipe,
        SearchFilterPipe,
        getComponents(),
        getPages()
    ],
    imports: [
        NgxMatColorPickerModule,
        WsGridModule,
        WsComboModule,
        WsUploadModule,
        WsNoContentModule,
        GoogleMapsModule,
        WsUploadModule,
        ComumModule,
        SharedModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        RouterModule.forRoot([])
    ],
    providers: [
      EnvironmentServiceProvider,
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        Globals,
        AdService,
        DialogService,
        AlertaService,
        LoadingService,
        LegendaService,
        ImageZoomService,
        WordsTranslateService,
        CloseModalService,
        MapaService,
        DatePipe,
        AuthService,
        NotificationService,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorTranslateService(translateService).getPaginatorIntl()
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        {
            provide: MatDialogRef,
            useValue: {
                close: MatDialogRefFunction
            }
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {} // Add any data you wish to test if it is passed/used correctly
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function MatDialogRefFunction(dialogResult: any) {}
