import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  HostListener
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-detail',
  templateUrl: './error-detail.component.html',
  styleUrls: ['./error-detail.component.scss']
})
export class ErrorDetailComponent implements OnInit {
  @Output() returnEventConfirm = new EventEmitter<boolean>();

  public detail: boolean = false;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const F2_CODE = 'F2';
    if (event.code === F2_CODE) {
      this.detail = true;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ErrorDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  showDetail() {
    if (this.detail) {
      this.detail = false;
    } else {
      this.detail = true;
    }
  }

  onClick(status: boolean) {
    this.dialogRef.close(status);
  }
}
