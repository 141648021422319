<div class="modal" modal-name="modalFormAlertaParametros">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ "paginas.alerta.parametros.form.editar_registro" | translate }}</h2>
        <button (click)="close()" mat-button class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <form *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="submit()" cdkTrapFocus>
        <div class="modal-content">
          <mat-form-field appearance="outline">
            <mat-label>{{ "paginas.alerta.parametros.form.parametroDesc" | translate }}</mat-label>
            <input
              matInput
              formControlName="parametroDesc"
              type="text"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ "paginas.alerta.parametros.form.alertaParametroValor" | translate }}</mat-label>
            <input
              matInput
              formControlName="alertaParametroValor"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="modal-actions">
          <div></div>
          <div class="buttons">
              <button
                type="button"
                mat-button
                class="primary cancel"
                [disabled]="!formulario.dirty"
                (click)="cancel()"
              >
                <i class="material-icons">block</i><span class="text">{{ 'botoes.cancelar' | translate }}</span>
              </button>
              <button
                type="submit"
                mat-button
                class="save"
                *ngIf="permissoes['create']"
                [disabled]="!formulario.dirty || formulario.invalid"
              >
                <i class="material-icons">check</i><span class="text">{{ 'botoes.salvar' | translate }}</span>
              </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
