import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';
import { ITipoLivroRegistro } from '../models/ITipoLivroRegistro';
import { HttpClient } from '@angular/common/http';

const telaId = 44;

@Injectable({
  providedIn: 'root'
})
export class TipoLivroRegistroService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }
  
  apiUrl = `${this.env.apiUrl}LivroRegistroTipo`;

   getGrid(): Observable<ITipoLivroRegistro[]> {
    return this.http.get<ITipoLivroRegistro[]>(`${this.apiUrl}/Grid`);
   }

   getForm(dados: ITipoLivroRegistro) {
    return this.http.get<ITipoLivroRegistro>(`${this.apiUrl}/Form/${dados.livroRegistroTipoId}`);
   }

   insert(dados: ITipoLivroRegistro) {
    return this.http.post<ITipoLivroRegistro>(`${this.apiUrl}`, dados);
   }

   update(dados: ITipoLivroRegistro) {
    return this.http.put<ITipoLivroRegistro>(`${this.apiUrl}`, dados);
   }

   delete(dados:ITipoLivroRegistro) {
    return this.http.delete<ITipoLivroRegistro>(`${this.apiUrl}/${dados.livroRegistroTipoId}`);
   }

   getPermissoes() {
    return this.authService.getPermissoes(telaId);
   }
}
