<div class="modal" modal-name="modalAtendimentoForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="dados-atendimento">
        <div class="formulario">
          <form
            class="default"
            *ngIf="formulario"
            [formGroup]="formulario"
            (ngSubmit)="save()"
            cdkTrapFocus
          >
            <input type="hidden" formControlName="atendimentoId" />
            <input type="hidden" formControlName="sawId" />
            <div class="modal-content">
              <ws-combo
                class="fieldTipoAtendimento"
                [focusMe]="true"
                [textoLabel]="wordsTranslated.tipoAtendimentoId"
                [multiple]="false"
                [selecionarTodos]="false"
                [itemCtrl]="f.tipoAtendimentoId"
                [items]="itensComboTipoAtendimento"
                [itemSelecionado]="ItemSelecionadoComboTipoAtendimento"
                (changeEventCombo)="onChangeComboTipoAtendimento($event)"
              >
              </ws-combo>

              <mat-form-field class="fieldPessoaNome" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.atendimentoPessoaNome }}
                </mat-label>
                <input
                  [focusMe]="false"
                  matInput
                  type="text"
                  placeholder="{{ wordsTranslated.atendimentoPessoaNome }}"
                  autocomplete="off"
                  formControlName="atendimentoPessoaNome"
                />
              </mat-form-field>

              <mat-form-field class="fieldVeiculoModelo" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.atendimentoVeiculoModelo }}
                </mat-label>
                <input
                  [focusMe]="false"
                  matInput
                  type="text"
                  placeholder="{{ wordsTranslated.atendimentoVeiculoModelo }}"
                  autocomplete="off"
                  formControlName="atendimentoVeiculoModelo"
                />
              </mat-form-field>

              <mat-form-field class="fieldVeiculoPlaca" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.atendimentoVeiculoPlaca }}
                </mat-label>
                <input
                  [focusMe]="false"
                  matInput
                  type="text"
                  placeholder="{{ wordsTranslated.atendimentoVeiculoPlaca }}"
                  autocomplete="off"
                  formControlName="atendimentoVeiculoPlaca"
                />
              </mat-form-field>

              <mat-form-field class="fieldVeiculoCor" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.atendimentoVeiculoCor }}
                </mat-label>
                <input
                  [focusMe]="false"
                  matInput
                  type="text"
                  placeholder="{{ wordsTranslated.atendimentoVeiculoCor }}"
                  autocomplete="off"
                  formControlName="atendimentoVeiculoCor"
                />
              </mat-form-field>

              <mat-form-field
                class="fieldAtendimentoObs"
                [hintLabel]="hintLabelText"
                appearance="outline"
              >
                <mat-label>{{ wordsTranslated.atendimentoObs }}</mat-label>
                <textarea
                  matInput
                  cdkTextareaAutosize
                  autocomplete="off"
                  maxlength="2000"
                  formControlName="atendimentoObs"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="5"
                >
                </textarea>
              </mat-form-field>
            </div>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="formLoading"
            ></mat-progress-bar>

            <div class="modal-actions">
              <div></div>
              <div class="buttons">
                <button
                  mat-button
                  type="button"
                  class="primary cancel"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  (click)="cancel()"
                  [disabled]="!statusBotao.cancelar"
                >
                  <i class="material-icons">block</i>
                  <span class="text">{{ "botoes.cancelar" | translate }}</span>
                </button>

                <button
                  type="submit"
                  mat-button
                  class="save"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  [disabled]="formulario.invalid || !statusBotao.salvar"
                >
                  <i class="material-icons">check</i>
                  <span class="text">{{ "botoes.salvar" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="grid-destinos">
          <div class="grid">
            <app-atendimento-destino-grid
              *ngIf="equipamento.sawId"
              [sawId]="equipamento.sawId"
              [atendimentoId]="atendimentoId"
              (sendCordinates)="setLatitudeLongituteDestino($event)"
            ></app-atendimento-destino-grid>
          </div>
        </div>
        <div class="mapa" [ngClass]="!statusPhone ? 'showControle' : ''">
          <div class="fieldMapa">
          <app-atendimento-mapa
            [atendimentoId]="atendimentoId"
            [coordenadasDestino]="coordinatesDestino"
            [coordenadasEquipamento]="coordinatesEquipamento"
          ></app-atendimento-mapa>
          </div>

          <div class="controle" *ngIf="!statusPhone">
            <div>
              <app-controle-volume
                (volume)="setVolumeAudioRemoto($event)"
                [iconeOn]="'volume_up'"
                [iconeOff]="'volume_off'"
                [volumeNumber]="volumeAudioRemoto"
                [vertical]="true"
              ></app-controle-volume>
            </div>
            <div>
              <app-controle-volume
                (volume)="setVolumeMicRemoto($event)"
                [iconeOn]="'mic_none'"
                [iconeOff]="'mic_off'"
                [volumeNumber]="volumeMicRemoto"
                [vertical]="true"
              ></app-controle-volume>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
