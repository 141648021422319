<div class="page-content page-content-row-2" *ngIf="readForm">
  <div class="box-filter">
    <form
      id="frm-filter"
      class="default"
      *ngIf="filterForm"
      [formGroup]="filterForm"
      (ngSubmit)="filter()"
    >
      <div class="container-combo-configuracao-ad">
        <ws-combo
          [focusMe]="true"
          [textoLabel]="
            'paginas.importacao-ad.usuarios.filtro.configuracaoAd' | translate
          "
          [multiple]="false"
          [selecionarTodos]="false"
          [itemCtrl]="f.configuracaoAdId"
          [items]="itensComboConfiguracaoAd"
          [itemSelecionado]="itemSelecionadoComboConfiguracaoAd"
          (changeEventCombo)="changeComboConfiguracaoAd($event)"
        >
        </ws-combo>
      </div>

      <div class="buttons-container">
        <button
          mat-button
          type="submit"
          class="save"
          [disabled]="filterForm.invalid"
        >
          <i class="material-icons">search</i>
          <span class="text">{{ "botoes.visualizar" | translate }}</span>
        </button>
      </div>
    </form>
  </div>

  <div class="content">
    <ws-grid
      class="gridListaUsuarios"
      *ngIf="!isLoading && dadosGrid"
      [permissoes]="permissoesTela"
      [isLoading]="isLoading"
      [acoesGrid]="acoesGrid"
      [focus]="focusFirstLine"
      [colunasGrid]="colunasGrid"
      [dadosGrid]="dadosGrid"
      [registroPorPagina]="registroPorPagina"
      [listaOpcaoPorPagina]="listaOpcaoPorPagina"
      (checkEvent)="checkEvent($event)"
      (refreshEvent)="refreshEvent()"
    >
    </ws-grid>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="formLoading"
    ></mat-progress-bar>

    <div class="actions">
      <div></div>
      <div class="buttons">
        <button
          type="submit"
          mat-button
          class="save"
          [disabled]="!statusBotao.salvar"
          (click)="save()"
        >
          <i class="material-icons">check</i>
          <span class="text">{{ "botoes.salvar" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-importacao-ad-usuarios-form
  *ngIf="showForm"
  [editForm]="editForm"
  [permissoes]="permissoesTela"
  [registro]="usuariosSelecionados"
  (closeEvent)="closeForm()"
  (saved)="refreshEvent();"
>
</app-importacao-ad-usuarios-form>
