import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IContaEmail } from '../models/IContaEmail';
import { ComboProtocoloEmailService } from '../services/combo-protocolo-email.service';
import { ContaEmailService } from '../services/conta-email.service';

@Component({
  selector: 'app-conta-email-form',
  templateUrl: './conta-email-form.component.html',
  styleUrls: ['./conta-email-form.component.scss']
})
export class ContaEmailFormComponent implements OnInit {
  formulario: UntypedFormGroup;
  titleDialog: string;
  focusSmtp: boolean = false;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IContaEmail = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);

  private autoClose = true;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboProtocolo: ICombo[] = [];
  public itemSelecionadoProtocolo: any;

  wordsTranslated: any;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private contaEmailService: ContaEmailService,
    private comboProtocolos: ComboProtocoloEmailService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalContaEmailForm') {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.contaEmail.';
    const words = {
      editarRegistro: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();

    const listProtocolos = this.comboProtocolos.getProtocolos();

    forkJoin([listProtocolos]).subscribe((results) => {
      this.itensComboProtocolo = results[0];

      if (this.registro != null) {
        this.loadEditData();
      } else {
        this.criarFormulario();
      }
    });
    this.loadingService.hide();
  }

  loadEditData() {
    this.contaEmailService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm: IContaEmail = this.formulario.value;

    if (this.registro) {
      this.contaEmailService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.contaEmailService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.contaEmailService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo
      };
      this.onChange.emit(change);

      this.formLoading = false;
      this.checkAutoClose();
    });
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    } else {
      this.criarFormulario();
      this.configuracaAcoesBotoes();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.wordsTranslated.editarRegistro;
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      contaEmailId: [
        this.registro != null && this.registro.contaEmailId
          ? this.registro.contaEmailId
          : null
      ],
      contaEmailSMTP: [
        this.registro != null && this.registro.contaEmailSMTP
          ? this.registro.contaEmailSMTP
          : '',
        Validators.compose([Validators.required])
      ],
      contaEmailPorta: [
        this.registro != null && this.registro.contaEmailPorta
          ? this.registro.contaEmailPorta
          : '',
        Validators.compose([Validators.required])
      ],
      contaEmailUsuario: [
        this.registro != null && this.registro.contaEmailUsuario
          ? this.registro.contaEmailUsuario
          : '',
        Validators.compose([Validators.required])
      ],
      contaEmailEmail: [
        this.registro != null && this.registro.contaEmailEmail
          ? this.registro.contaEmailEmail
          : '',
        Validators.compose([Validators.required, Validators.email])
      ],
      contaEmailSenha: [
        this.registro != null && this.registro.contaEmailSenha
          ? this.registro.contaEmailSenha
          : '',
        Validators.compose([Validators.required])
      ],
      protocoloId: [
        this.registro != null && this.registro.protocoloId
          ? this.registro.protocoloId
          : '',
        Validators.compose([Validators.required])
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    } else {
      this.focusSmtp = true;
    }
  }

  onChangeComboCulture($event) {
    this.itemSelecionadoProtocolo = $event;

    this.formulario.patchValue({
      protocoloId: this.itemSelecionadoProtocolo
    });
  }
}
