<div class="contentPermissoes">
  <div class="box-notfound" *ngIf="!dadosModulos">
    <h3>{{ 'paginas.permissao.telaPermissao.selecioneGrupo' | translate }}</h3>
  </div>

  <div class="dados" *ngIf="dadosModulos">
    <div class="filter">

      <div class="box-pesquisa">
        <button mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter();">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-button *ngIf="!filter" matSuffix mat-icon-button aria-label="Search" (click)="setFocusSearch()">
          <mat-icon>search</mat-icon>
        </button>
        <input #search autocomplete="off" matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)"
          placeholder="{{ 'formulario.placeholder.pesquisar' | translate }}" />
      </div>

    </div>
    <div class="lista">
      <mat-expansion-panel *ngFor="let item of dadosModulos" [expanded]="expandedModulo[item.aplicacaoModuloId]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-checkbox class="wholeModule"
              matTooltip="{{ 'paginas.permissao.telaPermissao.todoModulo' | translate }}"
              (change)="setPermissaoTotalModulo(item, $event)" [checked]="checkModuloTotal[item.aplicacaoModuloId]">
            </mat-checkbox>
            <div class="icone" [innerHTML]="item.icone"></div>
            {{item.aplicacaoModuloDesc}}
            <small *ngIf="item.telas.length > 1">{{telasPermitidasModulo[item.aplicacaoModuloId]}} de
              {{item.telas.length}} telas permitidas.</small>
            <small *ngIf="item.telas.length == 1">{{telasPermitidasModulo[item.aplicacaoModuloId]}} de
              {{item.telas.length}} tela permitida.</small>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-tela-permissao [permissoes]="permissoes" [tela]="item.telas" [permissaoChange]="permissaoChange"
          (permissaoTela)="onChangePermissaoTela($event, item.aplicacaoModuloId)"
          (permissaoTotal)="onChangePermissaoTotal($event, item.aplicacaoModuloId)">
        </app-tela-permissao>

      </mat-expansion-panel>
    </div>
  </div>
</div>