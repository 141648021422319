import * as _ from 'underscore';
import { finalize, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGrupoMensagem } from '../models/IGrupoMensagem';
import { GrupoMensagemService } from '../services/grupo-mensagem.service';

@Component({
  selector: 'app-grupo-mensagem-grid',
  templateUrl: './grupo-mensagem-grid.component.html'
})
export class GrupoMensagemGridComponent implements OnInit {
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IGrupoMensagem[];
  public dadosFormulario: IGrupoMensagem;

  wordsTranslated: any;


  constructor(
    private grupoMensagemService: GrupoMensagemService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.grupo-mensagem.grid.';
    const words = {
      grupoMensagem: '',
      iconeSrc: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.isLoading = true;
    this.grupoMensagemService
    .getPermissoes()
    .pipe(
      finalize(() => {
        this.loadingService.hide();
        this.isLoading = false;
      })
    )
    .subscribe((response) => {
      this.permissoes = response[0];
      this.editForm = this.permissoes.update;

      this.configuraAcoesGrid();
      this.configuraColunasGrid();
      this.configuraDadosGrid();
    });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'imagem',
        label: this.wordsTranslated.iconeSrc,
        width: '20px',
        sort: false,
        type: 'image'
      },
      {
        name: 'grupoMensagem',
        label: this.wordsTranslated.grupoMensagem,
        width: '',
        sort: true
      }
    ];
  }

  configuraDadosGrid(): void {
    //this.loadingService.show();
    this.grupoMensagemService
      .getGrid()
      .pipe(
        map((x) => {
          x.forEach(
            (i) => (i.imagem = `assets/images/icon/atalhos/${i.iconeSrc}`)
          );
          return x;
        }),
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  confirmDel(dados: IGrupoMensagem) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.grupoMensagemService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
        }
    });
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        grupoMensagemId: dados.grupoMensagemId
      });
    }
    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.grupoMensagemService
      .getForm(dados)
      .subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );

        this.dadosGrid.forEach((x) => {
          x.imagem = `assets/images/icon/atalhos/${x.iconeSrc}`;
        });
      });
    }
  }

  openForm(dados: IGrupoMensagem= null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }


}
