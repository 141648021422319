import { LoadingService } from './../../../../shared/services/loading/loading.service';
import { IGrupoDestino } from './../models/IGrupoDestino';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { GrupoDestinoService } from '../services/grupo-destino.service';
import { IconeService } from 'src/app/shared/services/icone/icone.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-grupo-destino-form',
  templateUrl: './grupo-destino-form.component.html',
  styleUrls: ['./grupo-destino-form.component.scss']
})
export class GrupoDestinoFormComponent implements OnInit {

  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() permissoes: EventEmitter<IGridPermissoes>;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IGrupoDestino = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<IGrupoDestino>();

  private registroClean: IGrupoDestino;
  private autoClose = false;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboIcone: ICombo[];
  public itemSelecionadoComboIcone: any;

  wordsTranslated: any;
  public modalName: string = 'modalGrupoDestinoForm'

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private grupoDestinoService: GrupoDestinoService,
    private iconeService: IconeService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }
  loadWordsTranslate() {
    const keyRoot = 'paginas.grupo-destino.form.';
    const words = {
      editar_registro: '',
      adicionar_registro:''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadingService.show();
    const observableComboIcone = this.iconeService.listComboSelect();

    forkJoin([observableComboIcone]).subscribe(      (results) => {
        this.itensComboIcone = results[0].map((x) => {
          x.image = `assets/images/icon/atalhos/${x.image}`;
          return x;
        });

        if (this.registro != null) {
          this.loadEditaData();
        } else {
          this.criarFormulario();
        }
        this.loadingService.hide();
      },
      (error) => {
        console.error(error);
        this.loadingService.hide();
      }
    );
  }

  loadEditaData() {
    this.grupoDestinoService
      .getForm(this.registro)
      .subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }


  criarFormulario() {
    this.formulario = this.fb.group({
      grupoId:[
        this.registro != null && this.registro.grupoId !== null
          ? this.registro.grupoId
          : null,
      ],
      grupoPortugues: [
        this.registro != null && this.registro.grupoPortugues
          ? this.registro.grupoPortugues
          : '',
        Validators.compose([Validators.required])
      ],
      grupoIngles: [
        this.registro != null && this.registro.grupoIngles
          ? this.registro.grupoIngles
          : ''
      ],
      grupoEspanhol: [
        this.registro != null && this.registro.grupoEspanhol
          ? this.registro.grupoEspanhol
          : ''
      ],
      iconeId: [
        this.registro != null && this.registro.iconeId !== null
          ? this.registro.iconeId
          : null,
        Validators.compose([Validators.required])
      ]
    });

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.grupoDestinoService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((_) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
     } else {
      this.grupoDestinoService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
      }
  }

  finalitySave(retorno: IGrupoDestino, tipo: string) {
    const change = {
      dados: retorno,
      tipo
    };

    this.onChange.emit(change);
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
  }

  add(): void {
    this.registro = this.registroClean;
    this.itemSelecionadoComboIcone = null;

    const observableComboIcone = this.iconeService.listComboSelect();

    forkJoin([observableComboIcone]).subscribe(
      (results) => {
        this.itensComboIcone = results[0].map((x) => {
          x.image = `assets/images/icon/atalhos/${x.image}`;
          return x;
        });

        this.formulario.reset();
        this.criarFormulario();
      }
    );

    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  get f() {
    return this.formulario.controls;
  }

  configuraTitleDialog() {
    if (this.registro){
      this.titleDialog = this.wordsTranslated.editar_registro;
    }else{
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  disableComboTela() {
    if (this.registro) {
      return true;
    } else {
      return false;
    }
  }

  changeComboIcone($event) {
    this.itemSelecionadoComboIcone = $event;

    this.formulario.patchValue({
      iconeId: this.itemSelecionadoComboIcone
    });
  }


}
