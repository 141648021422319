<div class="opcoes">
  <ul class="informacoesGerais">
    <li class="boxRa">
      <small>{{ "paginas.mapa-operacional.ra" | translate }}</small>
      {{ dados.ra }}
    </li>
    <li class="boxRamal">
      <small>{{ "paginas.mapa-operacional.ramal" | translate }}</small>
      {{ dados.ramal }}
    </li>
    <li class="boxStatusSIP">
      <small>{{ "paginas.mapa-operacional.statusSIP" | translate }}</small>
      <mat-slide-toggle 
         class="toogleStatus" 
         disabled 
         [checked]="dados.statusSIP" 
      ></mat-slide-toggle>
    </li>
    <li class="boxStatusOnline">
      <small>{{ "paginas.mapa-operacional.statusOnline" | translate }}</small>
      <mat-slide-toggle
        class="toogleStatus"
        disabled
        [checked]="dados.statusOnline"
      ></mat-slide-toggle>
    </li>
    <li class="boxUltimoAtendimento">
      <small>{{
        "paginas.mapa-operacional.ultimoAtendimento" | translate
      }}</small>
      {{ dados.dataUltimoAtendimento | date: "dd/MM/yyyy HH:mm:ss" }}
    </li>
    <li class="boxFirmwareApp">
      <small>{{ "paginas.mapa-operacional.firmwareApp" | translate }}</small>
      {{ dados.firmwareAPP }}
    </li>
    <li class="boxTemperatura">
      <small>{{ "paginas.mapa-operacional.temperatura" | translate }}</small>
      {{ dados.temperatura }}°C
    </li>
    <!-- <li class="boxStatusTitle">
      <small>{{ "paginas.mapa-operacional.status" | translate }}</small>
    </li> -->
   
    <li class="boxIpPrimario">
      <small>{{ "paginas.mapa-operacional.ipPrimario" | translate }}</small>
      {{ dados.ipPrimario }}
    </li>
    <li class="boxIpSecundario">
      <small>{{ "paginas.mapa-operacional.ipSecundario" | translate }}</small>
      {{ dados.ipSecundario }}
    </li>
    <li class="boxStatus">
      <small>{{
        "paginas.mapa-operacional.statusEquipamento" | translate
      }}</small>
      <div class="dados">
        <div class="bolinha vand"></div>
        <p class="vandalismo">Vandalismo</p>
      </div>
      <div class="dados">
        <div class="bolinha lig"></div>
        <p class="ligacao">Ligação</p>
      </div>
      <div class="dados">
        <div class="bolinha fal"></div>
        <p class="falha">Falha</p>
      </div>
      <div class="dados">
        <div class="bolinha onl"></div>
        <p class="online">Online</p>
      </div>
      <div class="dados">
        <div class="bolinha off"></div>
        <p class="offline">Offline</p>
      </div>
    </li>
  </ul>
</div>
