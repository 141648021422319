<div mat-dialog-title>
  <h2>{{data.title}}</h2>
  <button mat-button class="close" (click)="dialogRef.close()">
    <i class="material-icons">close</i>
  </button>
</div>
<form class="default" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
<mat-dialog-content>

    <mat-form-field class="fieldSenha" hidePassword appearance="outline">
      <mat-label>
        {{'paginas.contaEmail.form.senha' | translate}}
      </mat-label>
      <input type="password" matInput placeholder="{{'paginas.contaEmail.form.senha' | translate}}"
      autocomplete="new-password" formControlName="senha" (keydown.space)="$event.preventDefault();"/>
    </mat-form-field>
  

</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button type="button" mat-button class="primary false" (click)="cancel()">{{data.textButtonFalse}}</button>
    <button type="submit" mat-button class="primary true">{{data.textButtonTrue}}</button>
  </div>
</mat-dialog-actions>
</form>
