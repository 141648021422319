import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  HostListener
} from '@angular/core';
import { EmpresaService } from '../services/empresa.service';
import { IEmpresa } from '../models/IEmpresa';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { IUploadTraducoes } from '@ws/upload/lib/interfaces/ws-upload-traducoes.interface';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';

@Component({
  selector: 'app-empresa-upload',
  templateUrl: './empresa-upload.component.html'
})
export class EmpresaUploadComponent implements OnInit {
  titleDialog: string = 'Upload de Imagem';
  acceptExtensions: string = '*';
  files: File[] = [];
  isLoading: boolean;
  showUpload: boolean;
  listErrosUpload = [];

  fileToSave: boolean = false;

  palavrasUpload: IUploadTraducoes = {
    limite_de_arquivos: ''
  };

  @Input() editForm: EventEmitter<boolean>;
  @Input() dados: IEmpresa;

  @Output() closeEvent = new EventEmitter<string>();

  constructor(
    private empresaService: EmpresaService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService,
    private imageZoomService: ImageZoomService
  ) {
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == 'modalEmpresaUpload') {
        this.close();
      }
    });
  }

  ngOnInit() {
    this.loadFiles();
    this.empresaService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });

    const keyRoot = 'paginas.empresa.upload.';
    const words = {
      title: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
    this.titleDialog = wordsTranslate.title;
  }

  loadFiles() {
    this.isLoading = true;
    this.empresaService.getMidia(this.dados).subscribe(
      (result) => {
        this.files = result;
        this.showUpload = true;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.alertaService.erro();
        this.close();
      }
    );
  }

  showImage(image){
    this.imageZoomService.show(image);
  }

  uploadFiles(uploadDados) {
    const req = this.empresaService.uploadMidia(
      this.dados,
      uploadDados.formData
    );

    req.subscribe(
      (results: any) => {
        if (results && results[0]) {
          const errors = [];
          errors.push(results[0]);
          errors.forEach((element) => {
            this.listErrosUpload.push(element);
          });
        }

        this.alertaService.sucesso();
        this.loadFiles();
      },
      (error: any) => {
        this.alertaService.erro();
      }
    );
  }

  close(): void {
    if (this.fileToSave) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.closeEvent.next();
        }
      });
    } else {
      this.closeEvent.next();
    }
  }
}
