import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DownloadHelper } from 'src/app/shared/helper/download.helper';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { EventoVandalisCombo } from 'src/app/shared/services/combo/evento-vandalismo.combo';
import { OrdenacaoDataCombo } from 'src/app/shared/services/combo/ordenacao-data.combo';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { environment } from 'src/environments/environment';
import { IRVandalismoSolicAtendimentoSaw } from '../models/IRVandalismoSolicAtendimentoSaw';
import { VandalismoSolicAtendimentoService } from '../services/vandalismo-solic-atendimento.service';

@Component({
  selector: 'app-vandalismo-solic-atendimento-grid',
  templateUrl: './vandalismo-solic-atendimento-grid.component.html',
  styleUrls: ['./vandalismo-solic-atendimento-grid.component.scss']
})
export class VandalismoSolicAtendimentoGridComponent implements OnInit {

  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public dadosRelatorio: IRVandalismoSolicAtendimentoSaw[];
  public wordsTranslated: any;
  public wordsTranslatedGrid: any;
  public wordsNoContent: any;
  titleNoContent: string;
  textNoContent: string;
  showFilter: boolean = false;
  desabilitarExport: boolean = false;

  public permissoesTela: IGridPermissoes;
  public readForm = true;
  public filterForm: UntypedFormGroup;

  public itensComboSAW: ICombo[] = [];
  public itemSelecionadoComboSAW: any;

  public itensComboOrdenacao: ICombo[] = [];
  public itemSelecionadoComboOrdenacao: any;

  public itensComboEventos: ICombo[] = [];
  public itemSelecionadoComboEventos: any;

  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private eventosVandalismoCombo: EventoVandalisCombo,
    private sawCombo: SAWCombo,
    private ordenacaoDataCombo: OrdenacaoDataCombo,
    private rVandalismoSolicAtendimentoService: VandalismoSolicAtendimentoService,
    private downloadHelper: DownloadHelper,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService
  ) {
    this.loadWordsTranslate();
    this.loadWordsTranslateFilter();
    this.loadWordsNoContent();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.vandalismo-solic-atendimento.grid.';
    const words = {
      evento: '',
      dataHora: ''
    };
    this.wordsTranslatedGrid = this.wordsTranslate.get(words, keyRoot);
  }

  loadWordsTranslateFilter(){
    const keyRoot = 'paginas.vandalismo-solic-atendimento.filtro.';
    const words = {
      dataInicio: '',
      dataFim: '',
      sawId: '',
      ordenacaoData: '',
      eventoNome: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  loadWordsNoContent() {
    const keyRoot = 'paginas.vandalismo-solic-atendimento.no-content.';
    const words = {
      titleNoFilter: '',
      textNoFilter: '',
      titleFilter: '',
      textFilter: '',
    };
    this.wordsNoContent = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.titleNoContent = this.wordsNoContent.titleNoFilter;
    this.textNoContent = this.wordsNoContent.textNoFilter;

    this.isLoading = true;
    this.rVandalismoSolicAtendimentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;

        this.isLoading = true;
        const listSaw = this.sawCombo.listComboSelect();
        const listOrdenacao = this.ordenacaoDataCombo.listComboSelect();
        const listEventos = this.eventosVandalismoCombo.listComboSelect();

        forkJoin([listSaw,listOrdenacao,listEventos]).subscribe((results) => {
          this.itensComboSAW = results[0];
          this.itemSelecionadoComboSAW = this.itensComboSAW;
          this.itensComboOrdenacao = results[1];
          this.itensComboEventos = results[2];
          this.createFilterForm();
          this.setInitialDataFilters();
        });
      });
  }

  private createFilterForm() {

    this.filterForm = this.fb.group({
      dataInicio: [
        this.formValidParam.startDateDe,
        Validators.compose([Validators.required]),
      ],
      dataFim: [
        this.formValidParam.startDateAte,
        Validators.compose([Validators.required]),
      ],
      ordenacaoData: [[], Validators.compose([Validators.required])],
      sawId: [[], Validators.compose([Validators.required])],
      eventoNome: [[], Validators.compose([Validators.required])],
    });

    this.showFilter = true;
  }

  get f() {
    return this.filterForm.controls;
  }

  onChangeComboSaw($event) {
    this.itemSelecionadoComboSAW = $event;

    this.filterForm.patchValue({
      sawId: this.itemSelecionadoComboSAW,
    });
  }

  onChangeComboEvento($event) {
    this.itemSelecionadoComboEventos = $event;

    this.filterForm.patchValue({
      eventoNome: this.itemSelecionadoComboEventos,
    });
  }

  onChangeComboOrdenacao($event) {
    this.itemSelecionadoComboOrdenacao = $event;

    this.filterForm.patchValue({
      ordenacaoData: this.itemSelecionadoComboOrdenacao,
    });
  }

  changeDateDe($event) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + 30);
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataFim: maxDateAte
    });
  }

  setInitialDataFilters() {
    let dataAtual = new Date(Date.now());
    let dataInicio = new Date(Date.now());
    dataInicio.setDate(dataAtual.getDate() - 30);

    this.formValidParam.startDateDe = dataInicio;
    this.formValidParam.minDateAte = dataInicio;
    this.formValidParam.startDateAte = dataAtual;

    this.filterForm.patchValue({
      dataInicio: dataInicio,
      dataFim: dataAtual,
    });
  }

  filter(): void {
    this.desabilitarExport = false;
    var valuesFilter = this.filterForm.value;

    this.loadingService.show();

    this.rVandalismoSolicAtendimentoService
      .get(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosRelatorio = response;

        if (response.length == 0) {
          this.titleNoContent = this.wordsNoContent.titleFilter;
          this.textNoContent = this.wordsNoContent.textFilter;
          this.desabilitarExport = true;
        }
      });
  }

  export(): void {
    var timeOut = 1500;
    var valuesFilter = this.filterForm.value;

    this.loadingService.show();

    this.rVandalismoSolicAtendimentoService
      .export(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        if (response) {
          this.downloadHelper.downloadMidia(response);
        } else {
          const keyRoot = 'alertas.nenhumDadoParaExportar.';
          const words = {
            mensagem: '',
            titulo: ''
          };
          let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

          this.alertaService.Warning(
            wordsTranslate.titulo,
            wordsTranslate.mensagem,
            timeOut
          );
        }
      });
  }

}
