import * as _ from 'underscore';
import { finalize, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGrupoDestino } from '../models/IGrupoDestino';
import { GrupoDestinoService } from '../services/grupo-destino.service';

@Component({
  selector: 'app-grupo-destino-grid',
  templateUrl: './grupo-destino-grid.component.html'
})
export class GrupoDestinoGridComponent implements OnInit {
  public isLoading: boolean;
  public permissoes: IGridPermissoes;
  public editForm = true;
  public showForm = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IGrupoDestino[];
  public dadosFormulario: IGrupoDestino;

  wordsTranslated: any;

  constructor(
    private grupoDestinoService: GrupoDestinoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    private gridService: WsGridService,
    private dialogService: DialogService,
    private dialog: MatDialog

   ) { 
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.grupo-destino.grid.';
    const words = {
      grupoId: '',
      grupoPortugues: '',
      iconeSrc: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.grupoDestinoService
    .getPermissoes()
    .pipe(
      finalize(() => {
        this.loadingService.hide();
        this.isLoading = false;
    })
  )
  .subscribe((response) => {
    this.permissoes = response[0];
    this.editForm = this.permissoes.update;

    this.configuraAcoesGrid();
    this.configuraColunasGrid();
    this.configuraDadosGrid();
      
  });
 }
  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      keyboardNavigation: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'imagem',
        label: this.wordsTranslated.iconeSrc,
        width: '20px',
        sort: false,
        type: 'image',
        zoomImage: 'small'
      },
      {
        name: 'grupoPortugues',
        label: this.wordsTranslated.grupoPortugues,
        width: '',
        sort: true
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.grupoDestinoService
      .getGrid()
      .pipe(
        map((x) => {
          x.forEach( 
            (i) => (i.imagem = `assets/images/icon/atalhos/${i.iconeSrc}`) 
          );
          return x;
        }),
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          var dadosFiltrados: IGrupoDestino[] = [];
          response.forEach((execucao) => {
            if (execucao.grupoPortugues.length > 100){
              execucao.grupoPortugues = `${execucao.grupoPortugues.substring(0,100)}...`; 
            }
            dadosFiltrados.push(execucao);
          })
          this.dadosGrid = dadosFiltrados;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  confirmDel(dados: IGrupoDestino) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.grupoDestinoService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
        }
    });
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      if (dados.grupoPortugues.length > 100){
        dados.grupoPortugues = `${dados.grupoPortugues.substring(0,100)}...`; 
      }

      indexPage = _.findLastIndex(this.dadosGrid, {
        grupoId: dados.grupoId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.grupoDestinoService
      .getForm(dados)
      .subscribe((result) => {
        if (result.grupoPortugues.length > 100){
          result.grupoPortugues = `${result.grupoPortugues.substring(0,100)}...`; 
        }

        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );

        this.dadosGrid.forEach((x) => {
          x.imagem = `assets/images/icon/atalhos/${x.iconeSrc}`;
        });
      });
    }
  }

  openForm(dados: IGrupoDestino = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

}