<div class="modal" modal-name="modalGrupoUsuarioForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag style="width: 40%;">
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
        <div class="modal-content">
          <input formControlName="grupoUsuarioId" type="hidden" />

            <mat-form-field class="fieldGrupoUsuarioDesc" appearance="outline">
              <mat-label>
                {{'paginas.grupo-usuario.form.grupoUsuario_Desc' | translate}}
              </mat-label>
              <input [focusMe]="true" matInput placeholder="{{'paginas.grupo-usuario.form.grupoUsuario_Desc' | translate}}"
                formControlName="grupoUsuarioDesc" autocomplete="off" />
              <div *ngIf="f.grupoUsuarioDesc.errors" class="invalid-feedback">
                <div *ngIf="f.grupoUsuarioDesc.errors.maxlength">
                  {{ 'formulario.validacao.max_length' | translate:{'quantidade': formValidParam.descricao.max} }}
                </div>
              </div>
            </mat-form-field>

        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button mat-button type="button" class="primary add" *ngIf="permissoes['create']" (click)="add()"
              [disabled]="!statusBotao.adicionar">
              <i class="material-icons">add</i> <span class="text">{{ 'botoes.adicionar' | translate }}</span>
            </button>
            <button mat-button type="button" class="primary delete" *ngIf="permissoes['delete']" (click)="del()"
              [disabled]="!statusBotao.deletar">
              <i class="material-icons">delete</i> <span class="text">{{ 'botoes.deletar' | translate }}</span>
            </button>
            <button mat-button type="button" class="primary cancel"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)" (click)="cancel()"
              [disabled]="!statusBotao.cancelar">
              <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
            </button>
            <button type="submit" mat-button class="save"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)"
              [disabled]="formulario.invalid || !statusBotao.salvar">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
