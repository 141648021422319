import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { finalize, map } from 'rxjs/operators';

import * as _ from 'underscore';

import { IMeusAtalhos } from '../models/IMeusAtalhos';
import { MeusAtalhosService } from '../services/meus-atalhos.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { IUserDados } from 'src/app/shared/models/IUserDados';
import { IUser } from 'src/app/shared/models/IUser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';
import { ShortCutService } from 'src/app/shared/components/shortcut/services/shortCut.service';

@Component({
  selector: 'app-meus-atalhos-grid',
  templateUrl: './meus-atalhos-grid.component.html'
})
export class MeusAtalhosGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public permissoesTela: IGridPermissoes;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IMeusAtalhos[];
  public editForm = true;
  public showForm = false;
  public dadosAtalho: IMeusAtalhos;
  public userInfo: IUserDados;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private meusAtalhosService: MeusAtalhosService,
    private loadingService: LoadingService,
    private gridService: WsGridService,
    private alertaService: AlertaService,
    private dialogService: DialogService,
    private wordsTranslate: WordsTranslateService,
    private shortcutService: ShortCutService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.loadingService.show();

    this.meusAtalhosService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.userInfo = this.getUserInfo();
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    const keyRoot = 'paginas.meus-atalhos.grid.';
    const words = {
      tela: '',
      icone: '',
      ordem_exibicao: '',
      abrir_auto: '',
      tecla: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
    this.colunasGrid = this.criaColunasGrid(wordsTranslate);
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    const { id } = this.getUserInfo();

    this.meusAtalhosService
      .get(id)
      .pipe(
        map((x) => {
          x.forEach(
            (i) => (i.imagem = `assets/images/icon/atalhos/${i.imagem}`)
          );
          return x;
        }),
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosGrid = response;
      });
  }

  refresh() {
    this.configuraDadosGrid();
    this.shortcutService.change();
  }

  confirmDel(dados) {
    const dialogConfirm = this.dialogService.confirmDelete();
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();
        this.meusAtalhosService
          .delete(dados)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeAplicacaoAtalhoForm();
          });
       }
    });
  }

  openForm(dados: IMeusAtalhos = null, editForm = true) {
    this.dadosAtalho = dados;
    this.editForm = editForm;
    this.showForm = true;
  }

  closeAplicacaoAtalhoForm() {
    this.showForm = false;
    this.refresh();
  }

  refreshGridData(dados: IMeusAtalhos, tipo: string) {
    let indexPage = -1;

    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        aplicacaoTelaId: dados.aplicacaoTelaId
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.meusAtalhosService.getById(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );

        this.dadosGrid.forEach((x) => {
          x.imagem = `assets/images/icon/atalhos/${x.iconeSrc}`;
        });
      });
    }
  }

  criaColunasGrid(words): IGridModelo[] {
    const columns: IGridModelo[] = [
      {
        name: 'imagem',
        label: words.icone,
        width: '50px',
        sort: false,
        type: 'image',
        zoomImage: 'small'
      },
      {
        name: 'aplicacaoTelaTexto',
        label: words.tela,
        sort: true,
        width: '300px'
      },
      {
        name: 'teclaAtalho',
        label: words.tecla,
        width: '200px',
        sort: false
      },
      {
        name: 'ordemExibicao',
        label: words.ordem_exibicao,
        width: '150px',
        sort: true
      },
      {
        name: 'abrirAuto',
        label: words.abrir_auto,
        width: '',
        sort: true,
        formatType: 'getSimNao'
      }
    ];

    return columns;
  }

  private getUserInfo(): IUserDados {
    const userInfo = <IUser>this.authService.getLoginDados();
    return userInfo.dados;
  }
}
