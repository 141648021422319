import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { IPerguntasFrequentes } from '../models/IPerguntasFrequentes';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 13;
const apiTela = `PerguntasFrequentes`;

@Injectable({ providedIn: 'root' })
export class PerguntasFrequentesService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<IPerguntasFrequentes[]> {
    return this.http.get<IPerguntasFrequentes[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IPerguntasFrequentes) {
    return this.http.get<IPerguntasFrequentes>(`${this.apiUrl}/Form/${dados.perguntaId}`);
  }

  getMidia(dados: IPerguntasFrequentes) {
    return this.http.get<File[]>(`${this.apiUrl}/Midia/${dados.perguntaId}`);
  }

  getExtensions() {
    return this.configuracaoService.getExtensions(telaId);
  }

  uploadMidia(dados: IPerguntasFrequentes, uploadDados) {
    return this.http.put(`${this.apiUrl}/Midia/${dados.perguntaId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  insert(dados: IPerguntasFrequentes) {
    return this.http.post<IPerguntasFrequentes>(this.apiUrl, dados);
  }

  update(dados: IPerguntasFrequentes) {
    return this.http.put<IPerguntasFrequentes>(`${this.apiUrl}`, dados);
  }

  delete(dados: IPerguntasFrequentes) {
    return this.http.delete<IPerguntasFrequentes>(`${this.apiUrl}/${dados.perguntaId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
