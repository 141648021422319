
<div class="statusBarra" [ngClass]="permissaoCameraMic ? online ? 'on' : 'off' : 'erro'"  title="{{iconeToolTip}}">
  <button class="desligar" *ngIf="showPhone" (click)="rejeitar()"><i class="fas fa-phone-alt"></i></button>
  <div class="andamento" *ngIf="showPhone">{{status}} [{{numero}}]</div>
  <div class="ramal" *ngIf="ramal">{{ramal}}</div>
  <div class="icone"><i *ngIf="!permissaoCameraMic" class="fa fa-exclamation"></i><i *ngIf="permissaoCameraMic" class="fas fa-phone-alt"></i></div>
  <div class="call" *ngIf="showChamando">
    <audio autoplay loop src="./../assets/sounds/ringtone2.mp3"> </audio>
    <div class="status">{{status}}</div>
    <div class="numero">{{numero}}</div>
    <ul>
      <li>
        <button [disabled]="bloquearAcao" class="rejeitar" (click)="rejeitar()"><i class="fas fa-times"></i></button>
      </li>
      <li>
        <button [disabled]="bloquearAcao" class="aceitar" (click)="aceitar()"><i class="fas fa-phone-alt"></i></button>
      </li>
    </ul>
  </div>
</div>
