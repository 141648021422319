import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IModulo } from '../models/IModulo';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 38;

@Injectable({ providedIn: 'root' })
export class ModuloService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}SegurancaModulo`;

  getGrid(): Observable<IModulo[]> {
    return this.http.get<IModulo[]>(`${this.apiUrl}/Grid`);
  }

  getForm(registro: IModulo) {
    return this.http.get<IModulo>(`${this.apiUrl}/Form/${registro.aplicacaoModuloId}`);
  }


  insert(dados: IModulo) {
    return this.http.post<IModulo>(`${this.apiUrl}`, dados);
  }

  update(dados: IModulo) {
    return this.http.put<IModulo>(`${this.apiUrl}`, dados);
  }

  updateOrdem(dados: IModulo) {
    return this.http.put<IModulo>(`${this.apiUrl}/Ordem`, dados);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }

}