import { IGrupoMensagem } from './../models/IGrupoMensagem';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 26;

@Injectable({
  providedIn: 'root'
})
export class GrupoMensagemService {

  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) { }

  apiUrl = `${this.env.apiUrl}GrupoMensagem`;

  getGrid(): Observable<IGrupoMensagem[]> {
    return this.http.get<IGrupoMensagem[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IGrupoMensagem) {
    return this.http.get<IGrupoMensagem>(`${this.apiUrl}/Form/${dados.grupoMensagemId}`);
  }

  insert(dados: IGrupoMensagem) {
    return this.http.post<IGrupoMensagem>(`${this.apiUrl}`, dados);
  }

  update(dados: IGrupoMensagem) {
    return this.http.put<IGrupoMensagem>(`${this.apiUrl}`, dados);
  }

  delete(dados: IGrupoMensagem) {
    return this.http.delete<IGrupoMensagem>(`${this.apiUrl}/${dados.grupoMensagemId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
