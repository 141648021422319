<div class="modal" modal-name="modalModuloForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <mat-form-field class="fieldMenuModuloPortugues" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.modulo.form.menuModuloPortugues" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              formControlName="menuModuloPortugues"
            />
          </mat-form-field>
          <mat-form-field class="fieldMenuModuloIngles" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.modulo.form.menuModuloIngles" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              formControlName="menuModuloIngles"
            />
          </mat-form-field>
          <mat-form-field class="fieldMenuModuloEspanhol" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.modulo.form.menuModuloEspanhol" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              formControlName="menuModuloEspanhol"
            />
          </mat-form-field>

          <mat-form-field class="fieldIcone" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.modulo.form.icone" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              autocomplete="off"
              formControlName="icone"
            />
          </mat-form-field>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary cancel"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>

            <button
              type="submit"
              mat-button
              class="save"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
