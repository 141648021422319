<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoes"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  (editEvent)="openForm($event)"
></ws-grid>
<app-alerta-parametros-form
  *ngIf="showForm"
  [permissoes]="permissoes"
  [registro]="registro"
  (onClose)="closeForm()"
  (onChange)="getDados()"
></app-alerta-parametros-form>
