import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexYAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
} from 'ng-apexcharts';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ILegenda } from 'src/app/shared/components/legenda/models/ILegenda';
import { DownloadHelper } from 'src/app/shared/helper/download.helper';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { SAWCombo } from 'src/app/shared/services/combo/saw.combo';
import { LegendaService } from 'src/app/shared/services/legenda/legenda.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IRDisponibilidadeConexaoEquipamento } from '../models/IRDisponibilidadeConexaoEquipamento';
import { DisponibilidadeConexaoService } from '../services/disponibilidade-conexao.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
  markers: any;
  stroke: ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  colors: string[];
  labels: string[];
  tooltip: ApexTooltip;
  subtitle: ApexTitleSubtitle;
  responsive: ApexResponsive[];
};
@Component({
  selector: 'app-disponibilidade-conexao-grid',
  templateUrl: './disponibilidade-conexao-grid.component.html',
  styleUrls: ['./disponibilidade-conexao-grid.component.scss'],
})
export class DisponibilidadeConexaoGridComponent implements OnInit {
  public chartOptions: Partial<ChartOptions>;
  public dadosRelatorio: IRDisponibilidadeConexaoEquipamento[];
  public wordsTranslated: any;
  public permissoesTela: IGridPermissoes;
  public readForm = true;
  public filterForm: UntypedFormGroup;
  public formValidParam = {
    startDateDe: new Date(Date.now()),
    maxDateDe: new Date(Date.now()),
    startDateAte: new Date(Date.now()),
    maxDateAte: new Date(Date.now()),
    minDateAte: new Date(Date.now()),
  };

  seriesFirstMonth: any = [];
  seriesSecondMonth: any = [];
  titleFirstMonth: any[] = [];
  titleSecondMonth: any[] = [];
  chartOptionsFirstMonth: any[] = [];
  chartOptionsSecondMonth: any[] = [];
  firstMesDesc: string = '';
  secondMesDesc: string = '';
  itensComboSAW: ICombo[] = [];
  itemSelecionadoComboSAW: any;
  titleNoContent: string;
  textNoContent: string;
  showChangedTab = true;
  showFilter: boolean = false;
  desabilitarExport: boolean = false;
  showTabInicial= 0;

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private disponibilidadeConexaoService: DisponibilidadeConexaoService,
    private sawEquipamentoCombo: SAWCombo,
    private legendaService: LegendaService,
    private downloadHelper: DownloadHelper,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService
  ) {
    this.loadWordsTranslate();
  }

  @HostListener('window:fullscreenchange')
  onFullScreenChange() {
    if (this.dadosRelatorio.length > 0 && this.filterForm.valid) {
      this.filter();
    }
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.disponibilidade-conexao.grid.';
    const words = {
      dataInicio: '',
      dataFim: '',
      sawId: '',
      titleNoFilter: '',
      textNoFilter: '',
      titleFilter: '',
      textFilter: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.titleNoContent = this.wordsTranslated.titleNoFilter;
    this.textNoContent = this.wordsTranslated.textNoFilter;

    this.disponibilidadeConexaoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.readForm = this.permissoesTela.read;

        const listSawEquipamento = this.sawEquipamentoCombo.listComboSelect();

        forkJoin([listSawEquipamento]).subscribe((results) => {
          this.itensComboSAW = results[0];
          this.itemSelecionadoComboSAW = this.itensComboSAW;
          this.createFilterForm();
          this.setInitialDataFilters();
        });
      });
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      dataInicio: [
        this.formValidParam.startDateDe,
        Validators.compose([Validators.required]),
      ],
      dataFim: [null, Validators.compose([Validators.required])],
      sawId: [[], Validators.compose([Validators.required])],
    });

    this.showFilter = true;
  }

  get f() {
    return this.filterForm.controls;
  }

  changeDateDe($event) {
    let dataAtual = new Date(Date.now());
    let date: Date;
    date = new Date($event.value);

    var somaDias = this.checkDataByMonth(date);

    let maxDateAte = new Date(date);
    maxDateAte.setDate(maxDateAte.getDate() + somaDias - date.getDate());
    maxDateAte = maxDateAte > dataAtual ? dataAtual : maxDateAte;

    this.formValidParam.startDateDe = date;
    this.formValidParam.startDateAte = maxDateAte;
    this.formValidParam.minDateAte = date;
    this.formValidParam.maxDateAte = maxDateAte;
    this.filterForm.patchValue({
      dataFim: maxDateAte,
    });
  }

  setInitialDataFilters() {
    let dataAtual = new Date(Date.now());
    let dataInicio = new Date(Date.now());
    dataInicio.setDate(dataAtual.getDate() - 30);

    this.formValidParam.startDateDe = dataInicio;
    this.formValidParam.minDateAte = dataInicio;
    this.formValidParam.startDateAte = dataAtual;

    this.filterForm.patchValue({
      dataInicio: dataInicio,
      dataFim: dataAtual,
    });
  }

  filter(): void {
    this.showTabInicial = 0;
    this.desabilitarExport = false;
    var valuesFilter = this.filterForm.value;
    this.seriesFirstMonth = [];
    this.seriesSecondMonth = [];
    this.dadosRelatorio = [];

    this.loadingService.show();

    this.disponibilidadeConexaoService
      .get(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        this.dadosRelatorio = response;
        if (response.length == 0) {
          this.desabilitarExport = true;
          this.titleNoContent = this.wordsTranslated.titleFilter;
          this.textNoContent = this.wordsTranslated.textFilter;
        }

        this.setSeries();
        this.setGrafico();
      });
  }

  export(): void {
    var timeOut = 3000;
    var valuesFilter = this.filterForm.value;

    this.loadingService.show();

    this.disponibilidadeConexaoService
      .export(valuesFilter)
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe((response) => {
        if (response) {
          this.downloadHelper.downloadMidia(response);
        } else {
          const keyRoot = 'alertas.nenhumDadoParaExportar.';
          const words = {
            mensagem: '',
            titulo: '',
          };
          let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

          this.alertaService.Warning(
            wordsTranslate.titulo,
            wordsTranslate.mensagem,
            timeOut
          );
        }
      });
  }

  checkDataByMonth(date: Date) {
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var isBissexto: boolean = false;
    var resto4 = year % 4;
    var resto100 = year % 100;
    if (resto4 == 0) {
      if (resto100 != 0) {
        isBissexto = true;
      }
    }

    if ((month == 1 || month == 2) && !isBissexto) {
      return 59;
    }
    if ((month == 1 || month == 2) && isBissexto) {
      return 60;
    }
    if (month != 1 && month != 2 && month != 7 && month != 12) {
      return 61;
    }
    if (month == 7 || month == 12) {
      return 62;
    }
  }

  refreshGridDetalhes() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  refreshGridResumos() {
    if (this.filterForm.valid) {
      this.filter();
    }
  }

  onChangeComboSaw($event) {
    this.itemSelecionadoComboSAW = $event;

    this.filterForm.patchValue({
      sawId: this.itemSelecionadoComboSAW,
    });
  }

  setSeries() {
    this.dadosRelatorio.forEach((equipamento) => {
      this.loadingService.show();
      this.seriesFirstMonth[equipamento.sawId] = [];
      this.seriesSecondMonth[equipamento.sawId] = [];
      this.titleFirstMonth[equipamento.sawId] = [];
      this.titleSecondMonth[equipamento.sawId] = [];
      this.chartOptionsFirstMonth[equipamento.sawId] = [];
      this.chartOptionsSecondMonth[equipamento.sawId] = [];

      if (equipamento.meses.length > 0) {
        this.firstMesDesc = equipamento.meses[0].mesDesc;

        var heightFirstChart = this.checkHeightGrafico(equipamento.meses[0].dados.length);

        var title = {
          text: `Disponibilidade - ${equipamento.prefixo} - KM: ${equipamento.kmNominal} - ${this.firstMesDesc}`,
          align: 'center',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '18px',
            color: '#000000',
          },
        };
        this.titleFirstMonth[equipamento.sawId].push({ title });

        var chart = {
          animations: {
            enabled: false,
          },
           height: heightFirstChart,
          type: 'heatmap',
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: title.text,
              },
              svg: {
                filename: title.text,
              },
              png: {
                filename: title.text,
              },
            },
          },
        };

        this.chartOptionsFirstMonth[equipamento.sawId].push({ chart });

        equipamento.meses[0].dados.forEach((dado) => {
          var data = [
            { x: '00:00', y: dado.hora0 },
            { x: '01:00', y: dado.hora1 },
            { x: '02:00', y: dado.hora2 },
            { x: '03:00', y: dado.hora3 },
            { x: '04:00', y: dado.hora4 },
            { x: '05:00', y: dado.hora5 },
            { x: '06:00', y: dado.hora6 },
            { x: '07:00', y: dado.hora7 },
            { x: '08:00', y: dado.hora8 },
            { x: '09:00', y: dado.hora9 },
            { x: '10:00', y: dado.hora10 },
            { x: '11:00', y: dado.hora11 },
            { x: '12:00', y: dado.hora12 },
            { x: '13:00', y: dado.hora13 },
            { x: '14:00', y: dado.hora14 },
            { x: '15:00', y: dado.hora15 },
            { x: '16:00', y: dado.hora16 },
            { x: '17:00', y: dado.hora17 },
            { x: '18:00', y: dado.hora18 },
            { x: '19:00', y: dado.hora19 },
            { x: '20:00', y: dado.hora20 },
            { x: '21:00', y: dado.hora21 },
            { x: '22:00', y: dado.hora22 },
            { x: '23:00', y: dado.hora23 },
            { x: 'Total Dia', y: dado.dia },
          ];

          var diaMes = new Date(dado.dataOrd);

          var diaDes;
          if (diaMes.getDate() > 9) {
            diaDes = diaMes.getDate();
          } else {
            diaDes = `0${diaMes.getDate()}`;
          }

          var mesDesc;
          if (diaMes.getMonth() + 1 > 9) {
            mesDesc = diaMes.getMonth() + 1;
          } else {
            mesDesc = `0${diaMes.getMonth() + 1}`;
          }

          var diaMesDesc = `${diaDes}/${mesDesc}`;

          var obj = {
            name: diaMesDesc,
            data: data,
          };

          this.seriesFirstMonth[equipamento.sawId].push(obj);
        });

        if (equipamento.meses.length > 1) {
          this.secondMesDesc = equipamento.meses[1].mesDesc;
          var title = {
            text: `Disponibilidade - ${equipamento.prefixo} - KM: ${equipamento.kmNominal} - ${this.secondMesDesc}`,
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: '18px',
              color: '#000000',
            },
          };

          var heightSecondChart = this.checkHeightGrafico(equipamento.meses[1].dados.length);

          this.titleSecondMonth[equipamento.sawId].push({ title });

          var chart = {
            animations: {
              enabled: false,
            },
            height: heightSecondChart,
            type: 'heatmap',
            toolbar: {
              show: true,
              export: {
                csv: {
                  filename: title.text,
                },
                svg: {
                  filename: title.text,
                },
                png: {
                  filename: title.text,
                },
              },
            },
          };

          this.chartOptionsSecondMonth[equipamento.sawId].push({ chart });

          equipamento.meses[1].dados.forEach((dado) => {
            var data = [
              { x: '00:00', y: dado.hora0 },
              { x: '01:00', y: dado.hora1 },
              { x: '02:00', y: dado.hora2 },
              { x: '03:00', y: dado.hora3 },
              { x: '04:00', y: dado.hora4 },
              { x: '05:00', y: dado.hora5 },
              { x: '06:00', y: dado.hora6 },
              { x: '07:00', y: dado.hora7 },
              { x: '08:00', y: dado.hora8 },
              { x: '09:00', y: dado.hora9 },
              { x: '10:00', y: dado.hora10 },
              { x: '11:00', y: dado.hora11 },
              { x: '12:00', y: dado.hora12 },
              { x: '13:00', y: dado.hora13 },
              { x: '14:00', y: dado.hora14 },
              { x: '15:00', y: dado.hora15 },
              { x: '16:00', y: dado.hora16 },
              { x: '17:00', y: dado.hora17 },
              { x: '18:00', y: dado.hora18 },
              { x: '19:00', y: dado.hora19 },
              { x: '20:00', y: dado.hora20 },
              { x: '21:00', y: dado.hora21 },
              { x: '22:00', y: dado.hora22 },
              { x: '23:00', y: dado.hora23 },
              { x: 'Total Dia', y: dado.dia },
            ];

            var diaMes = new Date(dado.dataOrd);

            var diaDes;
            if (diaMes.getDate() > 9) {
              diaDes = diaMes.getDate();
            } else {
              diaDes = `0${diaMes.getDate()}`;
            }

            var mesDesc;
            if (diaMes.getMonth() + 1 > 9) {
              mesDesc = diaMes.getMonth() + 1;
            } else {
              mesDesc = `0${diaMes.getMonth() + 1}`;
            }

            var diaMesDesc = `${diaDes}/${mesDesc}`;

            var obj = {
              name: diaMesDesc,
              data: data,
            };

            this.seriesSecondMonth[equipamento.sawId].push(obj);
          });
        }
      }
    });
  }

  setGrafico() {
    this.chartOptions = {
      plotOptions: {
        heatmap: {
          enableShades: false,
          colorScale: {
            ranges: [
              {
                from: 81,
                to: 100,
                name: 'Ótima',
                color: '#35DC30',
              },
              {
                from: 51,
                to: 80.99,
                name: 'Média',
                color: '#D4AC0D',
              },
              {
                from: 0,
                to: 50.99,
                name: 'Baixa',
                color: '#DC3030',
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return `${value}%`;
        },
        style: {
          colors: ['#FFFFFF'],
        },
      },
      xaxis: {
        position: 'top',
      },
      yaxis: {
        show: true,
      },
      legend: {
        show: true,
        offsetY: -15,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    };
  }

  onTabClick() {
    this.showChangedTab = false;
    this.loadingService.show();
    this.setGrafico();

    setTimeout(() => {
      this.loadingService.hide();
      this.showChangedTab = true;
    }, 1000);
  }

  showLegenda() {
    const keyRoot = 'paginas.disponibilidade-conexao.legendas.';
    const words = {
      otima: '',
      media: '',
      baixa: '',
    };
    let wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    var result: ILegenda[] = [];

    result.push(
      {
        title: wordsTranslated.otima,
        description: '>= 81%',
        badge: true,
        badgeColor: '#35DC30',
      },
      {
        title: wordsTranslated.media,
        description: '>= 51% <= 80,99%',
        badge: true,
        badgeColor: '#D4AC0D',
      },
      {
        title: wordsTranslated.baixa,
        description: '<= 50,99%',
        badge: true,
        badgeColor: '#DC3030',
      }
    );

    this.legendaService.show(result);
  }

  checkHeightGrafico(length: number) {
    if (length == 1)
    {
      return 131;
    }
    else
    {
      let altura = 119;
      altura += 28 * length;
      return altura;
    }
    
  }
}
