<div class="subTabs">
  <mat-tab-group animationDuration="0ms" style="width: 100%;" (selectedTabChange)="onTabClick($event)">
    <mat-tab label="{{ 'paginas.importacao-ad.tab.importarUsuarios' | translate }}">
      <app-importacao-ad-usuarios-grid *ngIf="showUsuarios"></app-importacao-ad-usuarios-grid>
    </mat-tab>

    <mat-tab label="{{ 'paginas.importacao-ad.tab.configuracoes' | translate }}">
      <app-importacao-ad-configuracao-grid *ngIf="showConfiguracao"></app-importacao-ad-configuracao-grid>
    </mat-tab>

  </mat-tab-group>
</div>