import { Subject, forkJoin } from 'rxjs';
import { environment } from './../../../../../environments/environment';
import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { IDestino } from '../models/IDestino';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { DestinoService } from '../services/destino.service';
import { GrupoDestinoCombo } from 'src/app/shared/services/combo/grupo-destino.combo';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { NominatimService } from 'src/app/shared/services/nomitatim/nominatim.service';

@Component({
  selector: 'app-destino-form',
  templateUrl: './destino-form.component.html',
  styleUrls: ['./destino-form.component.scss'],
})

export class DestinoFormComponent implements OnInit {
  @ViewChild('search', { static: false })
  searchElementRef!: ElementRef;
  formulario: UntypedFormGroup;
  titleDialog: string;
  modalName: string = 'modalDestinoForm';
  statusBotao: IDialogButton;
  registroClean: IDestino;
  mapReady: boolean = false;
  wordsTranslated: any;
  latitude: number;
  longitude: number;
  hasCoordenada: boolean = false;
  coordinates: any;
  iconeSrc: any = null;
  hintLabelBRA: string = '';
  hintLabelESP: string = '';
  hintLabelUSA: string = '';
  public placesSearches: any[] = [];

  public subject = new Subject<string>();
  private autoClose: boolean = false;

  public formLoading: boolean = false;
  public isLoading: boolean;
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;

  public itensComboGrupoDestino: ICombo[];
  public itemSelecionadoComboGrupoDestino: any;

  @Input() permissoes: IGridPermissoes;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IDestino = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<number>();

  constructor(
    private destinoService: DestinoService,
    private grupoDestinoCombo: GrupoDestinoCombo,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService,
    private nominatimService: NominatimService
  ) {
    this.listenKeyPress();
    this.loadWordsTranslate();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.destino.form.';
    let words = {
      registro: '',
      editar_registro: '',
      adicionar_registro: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.getUserCurrentLocation((location: any) => {
      this.coordinates = location;
    });

    this.loadingService.show();

    const listGrupoDestino = this.grupoDestinoCombo.listComboSelect();

    forkJoin([listGrupoDestino]).subscribe(
      (results) => {
        this.itensComboGrupoDestino = results[0].map((x) => {
          x.image = `assets/images/icon/atalhos/${x.image}`;
          return x;
        });

        if (this.registro != null) {
          this.loadEditData();
        } else {
          this.criarFormulario();
        }
        this.loadingService.hide();
      },
      (error) => {
        console.error(error);
        this.loadingService.hide();
      }
    );
    this.subject.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(() => {
      this.changeSearch();
    });
  }
  

  loadEditData() {
    this.destinoService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      if (this.registro.destinoLatitude && this.registro.destinoLongitude) {
        this.iconeSrc = this.registro.iconeSrc;
        this.coordinates.lat = this.registro.destinoLatitude;
        this.coordinates.lng = this.registro.destinoLongitude;
        this.hasCoordenada = true;
      }
      this.criarFormulario();
    });
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      destinoId: [
        this.registro != null && this.registro.destinoId !== null
          ? this.registro.destinoId
          : null,
      ],
      grupoId: [
        this.registro != null && this.registro.grupoId !== null
          ? this.registro.grupoId
          : null,
        Validators.compose([Validators.required]),
      ],
      destinoNome: [
        this.registro != null && this.registro.destinoNome
          ? this.registro.destinoNome
          : '',
        Validators.compose([Validators.required]),
      ],
      destinoTelefone: [
        this.registro != null && this.registro.destinoTelefone
          ? this.registro.destinoTelefone
          : '',
      ],
      destinoEndereco: [
        this.registro != null && this.registro.destinoEndereco
          ? this.registro.destinoEndereco
          : '',
        Validators.compose([Validators.required]),
      ],
      destinoFuncionamentoPortugues: [
        this.registro != null && this.registro.destinoFuncionamentoPortugues
          ? this.registro.destinoFuncionamentoPortugues
          : '',
        Validators.compose([Validators.required, Validators.maxLength(1000)]),
      ],
      destinoFuncionamentoEspanhol: [
        this.registro != null && this.registro.destinoFuncionamentoEspanhol
          ? this.registro.destinoFuncionamentoEspanhol
          : '',
        Validators.compose([Validators.maxLength(1000)]),
      ],
      destinoFuncionamentoIngles: [
        this.registro != null && this.registro.destinoFuncionamentoIngles
          ? this.registro.destinoFuncionamentoIngles
          : '',
        Validators.compose([Validators.maxLength(1000)]),
      ],
      destinoLatitude: [
        this.registro != null && this.registro.destinoLatitude !== null
          ? this.registro.destinoLatitude
          : this.coordinates.lat,
        Validators.compose([Validators.required]),
      ],
      destinoLongitude: [
        this.registro != null && this.registro.destinoLongitude !== null
          ? this.registro.destinoLongitude
          : this.coordinates.lng,
        Validators.compose([Validators.required]),
      ],
    });

    if(this.registro){
      this.setHintLabel(this.registro.destinoFuncionamentoPortugues.length, "BRA");
      this.setHintLabel(this.registro.destinoFuncionamentoEspanhol.length, "ESP");
      this.setHintLabel(this.registro.destinoFuncionamentoIngles.length, "USA");
    }
    else {
      this.setHintLabel(0, "BRA");
      this.setHintLabel(0, "ESP");
      this.setHintLabel(0, "USA");
    }

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    }
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  disableComboTela() {
    if (this.registro) {
      return true;
    } else {
      return false;
    }
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              this.getFuncionamentosToSetHintLabel();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
      this.getFuncionamentosToSetHintLabel();
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  setHintLabel(length, idioma: string) {
    switch (idioma) {
      case 'BRA':
        this.hintLabelBRA = `Máximo de caracteres permitidos: ${length}/2000`;
        break;
      case 'USA':
        this.hintLabelUSA = `Máximo de caracteres permitidos: ${length}/2000`;
        break;
      case 'ESP':
        this.hintLabelESP = `Máximo de caracteres permitidos: ${length}/2000`;
        break;
      default:
        break;
    }
  }

  getFuncionamentosToSetHintLabel(){
    var textoBRA: string = this.f.destinoFuncionamentoPortugues.value;
    var textoESP: string = this.f.destinoFuncionamentoEspanhol.value;
    var textoUSA: string = this.f.destinoFuncionamentoIngles.value;

    if (textoBRA != null) {
      this.setHintLabel(textoBRA.length, 'BRA');
    }
    if (textoESP != null) {
      this.setHintLabel(textoESP.length, 'ESP');
    }
    if (textoUSA != null) {
      this.setHintLabel(textoUSA.length, 'USA');
    }
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.destinoService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        });
    } else {
      this.destinoService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.destinoService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo,
      };
      this.onChange.emit(change);

      this.formLoading = false;
      this.checkAutoClose();
    });
  }

  add(): void {
    this.registro = this.registroClean;
    this.itemSelecionadoComboGrupoDestino = null;

    const listGrupoDestino = this.grupoDestinoCombo.listComboSelect();

    forkJoin([listGrupoDestino]).subscribe((results) => {
      this.itensComboGrupoDestino = results[0].map((x) => {
        x.image = `assets/images/icon/atalhos/${x.image}`;
        return x;
      });

      this.formulario.reset();
      this.criarFormulario();
    });
    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    } else {
      this.criarFormulario();
      this.configuracaAcoesBotoes();
    }
  }

  configuraTitleDialog() {
    this.titleDialog = this.wordsTranslated.registro;

    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  get f() {
    return this.formulario.controls;
  }

  changeComboGrupoDestino($event) {
    this.itemSelecionadoComboGrupoDestino = $event;

    this.formulario.patchValue({
      grupoId: this.itemSelecionadoComboGrupoDestino,
    });
  }

  setMapReadyToView() {
    this.mapReady = true;
  }

  onDragMap($event) {
    this.coordinates = {
      lat: $event.lat(),
      lng: $event.lng(),
    };
    this.formulario.patchValue({
      destinoLatitude: $event.lat(),
      destinoLongitude: $event.lng(),
    });
  }

  searchDestination(input: string) {
    if (input == '') {
      this.placesSearches = [];
      return;
    }
    this.nominatimService.search(input).subscribe(
      (data) => {
        this.placesSearches = data;
      }
    );
  }

  changeSearch(){
    this.searchDestination(this.searchElementRef.nativeElement.value);
  }

  confirmPlace(place: any){
    this.placesSearches = [];
    this.formulario.patchValue({
      destinoEndereco:  place.name + ' - ' + (place.neighbourhood ? place.neighbourhood + ', ' : '') + (place.city ? place.city : '') 
    });
      this.coordinates.lat = place.lat;
      this.coordinates.lng = place.lon;
      this.formulario.patchValue({
        destinoLatitude: place.lat
      })
      this.formulario.patchValue({
        destinoLongitude: place.lon
      })

  }

  locationOptionClicked(location){
     this.confirmPlace(location); 
  }

  getUserCurrentLocation(callback: Function) {
    const location: any = {
      lat: 0,
      lng: 0,
    };

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        location.lat = latitude;
        location.lng = longitude;

        callback(location);
      });
    }
    callback(location);
  }
}
