import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';

import * as _ from 'underscore';

import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { PermissaoUsuarioService } from '../services/permissaoUsuario.service';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { IPermissaoUsuario } from '../models/IPermissaoUsuario';
import { IPermissaoUsuarioModulos } from '../models/IPermissaoUsuarioModulos';
import { environment } from 'src/environments/environment';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-permissao-usuario-grid',
  templateUrl: './permissao-usuario-grid.component.html',
})
export class PermissaoUsuarioGridComponent implements OnInit {
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;
  public isLoading: boolean;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IPermissaoUsuario[];
  public focusFirstLine: boolean = false;
  @Output() dadosUsuario = new EventEmitter<any>();

  public showForm = false;
  public showUpload = false;

  public dadosModulos: IPermissaoUsuarioModulos[];

  wordsTranslated: any;

  constructor(
    public permissaoUsuarioService: PermissaoUsuarioService,
    public loadingService: LoadingService,
    public gridService: WsGridService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.permissao.usuario.grid.';
    const words = {
      nome: '',
      grupo: '',
      mensagem_exclusao: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.configuraAcoesGrid();
    this.configuraColunasGrid();
    this.configuraDadosGrid();
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: false,
      edit: false,
      create: false,
      delete: false,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      enable: false,
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'usuarioNome',
        label: this.wordsTranslated.nome,
        sort: true,
        width: '60%',
      },
      {
        name: 'grupoNome',
        label: this.wordsTranslated.grupo,
        sort: true,
        width: '',
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();

    this.permissaoUsuarioService
      .get()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  loadPermissoes(row) {
    this.dadosUsuario.next(row);
  }

  refreshGridData(dados, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        usuarioId: dados.usuarioId,
      });
    }

    if (tipo === 'del') {
      this.dadosGrid = this.gridService.refreshGridData(
        this.dadosGrid,
        dados,
        indexPage,
        tipo
      );
    } else {
      this.permissaoUsuarioService.getById(dados).subscribe((result) => {
        this.dadosGrid = this.gridService.refreshGridData(
          this.dadosGrid,
          result,
          indexPage,
          tipo
        );
      });
    }
  }
  
  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 200);
  }
}
