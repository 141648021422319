import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class WordsTranslateService {
  constructor(private translate: TranslateService) {}

  public get(words: any, keyRoot: string) {
    const wordKeys = [];

    for (const prop in words) {
      wordKeys.push(`${keyRoot}${prop}`);
    }

    this.translate.stream(wordKeys).subscribe((result) => {
      words = this.mapLanguageResource(result, keyRoot, words);
    });

    return words;
  }

  private mapLanguageResource(translateResult, keyRoot, wordsReference): any {
    const words = {};
    for (const key in translateResult) {
      const objKey = key.replace(keyRoot, '');

      if (wordsReference.hasOwnProperty(objKey)) {
        words[objKey] = translateResult[key];
      }
    }
    return words;
  }
}
