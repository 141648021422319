<ws-grid
*ngIf="!isLoading"
[permissoes]="permissoes"
[isLoading]="isLoading"
[acoesGrid]="acoesGrid"
[colunasGrid]="colunasGrid"
[dadosGrid]="dadosGrid"
(refreshEvent)="refresh()"
(editEvent)="openForm($event)"
(addEvent)="openForm()"
(deleteEvent)="confirmDel($event)"
(customEvent)="customEvent($event)">
</ws-grid>

<app-revista-upload-midia-grid
  *ngIf="showUpload"
  [revistaId]="revistaId"
  [editForm]="editForm"
  [dados]="dadosFormulario"
  [carregaMidia]="carregaMidia"
  (closeEvent)="closeUpload()"
  (atualizaGrid)="refresh()">
</app-revista-upload-midia-grid>

<app-revista-form
*ngIf="showForm"
[permissoes]="permissoes"
[registro]="dadosFormulario"
(onChange)="refreshGridData($event.dados, $event.tipo)"
(onDelete)="confirmDel($event)"
(closeEvent)="closeForm()">
</app-revista-form>

