import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IWallpaperConfiguracaoSistema } from '../../models/IWallpaperConfiguracaoSistema';
import { IConfiguracaoExtensao } from 'src/app/shared/models/IConfiguracaoExtensao';
import { EnvironmentService } from '../env/environment.service';

const telaId = 2;

@Injectable({ providedIn: 'root' })
export class WallpaperConfiguracaoSistemaService {
  changeWallpaper = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private env : EnvironmentService) {}

  apiUrlExtensao = this.env.apiUrl + 'AplicacaoTelaExtensao';
  apiUrl = `${this.env.apiUrl}ConfiguracaoSistema/Wallpaper`;

  get(): Observable<IWallpaperConfiguracaoSistema[]> {
    return this.http.get<IWallpaperConfiguracaoSistema[]>(this.apiUrl);
  }

  getMidia() {
    return this.http.get<File[]>(`${this.apiUrl}`);
  }

  getExtensions() {
    return this.http.get<IConfiguracaoExtensao[]>(
      `${this.apiUrlExtensao}/${telaId}`
    );
  }

  uploadMidia(uploadDados) {
    return this.http.put(`${this.apiUrl}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  loadImage(imagem) {
    if (!imagem[0] || imagem[0].content == '') {
      return false;
    }

    let img = imagem[0];

    const imageString = JSON.stringify(
      `data:${img.contentType};base64, ${img.content}`
    ).replace(/\\n/g, '');

    return imageString;
  }
}
