import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';


import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ITelefonesUteis } from '../models/ITelefonesUteis';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 14;
const apiTela = `TelefonesUteis`;

@Injectable({ providedIn: 'root' })
export class TelefonesUteisService {
  httpEmitter: Subscription;

  constructor(
    private http: HttpClient,
    private configuracaoService: ConfiguracaoService,
    private authService: AuthService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<ITelefonesUteis[]> {
    return this.http.get<ITelefonesUteis[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: ITelefonesUteis) {
    return this.http.get<ITelefonesUteis>(`${this.apiUrl}/Form/${dados.telefoneUtilId}`);
  }

  getMidia(dados: ITelefonesUteis) {
    return this.http.get<File[]>(`${this.apiUrl}/Midia/${dados.telefoneUtilId}`);
  }

  getExtensions() {
    return this.configuracaoService.getExtensions(telaId);
  }

  uploadMidia(dados: ITelefonesUteis, uploadDados) {
    return this.http.put(`${this.apiUrl}/Midia/${dados.telefoneUtilId}`, uploadDados, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  insert(dados: ITelefonesUteis) {
    return this.http.post<ITelefonesUteis>(this.apiUrl, dados);
  }

  update(dados: ITelefonesUteis) {
    return this.http.put<ITelefonesUteis>(`${this.apiUrl}`, dados);
  }

  delete(dados: ITelefonesUteis) {
    return this.http.delete<ITelefonesUteis>(`${this.apiUrl}/${dados.telefoneUtilId}`);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
