<div class="grids">
  <div class="grid-saw">
    <div class="title">
      <p>Equipamentos</p>
    </div>
    <div class="grid">
      <app-saw-atendimento-grid
        (showFormAtendimento)="showAtendimentoForm()"
        (showFormMensagemAviso)="showMensagemAvisoForm()"
        (clickRow)="setSawId($event)"
      ></app-saw-atendimento-grid>
    </div>
  </div>
  <div class="grids-secundarios">
    <div class="grid-atendimentos">
      <div class="title">
        <p>Atendimentos</p>
      </div>
      <div class="grid">
        <app-atendimento-atendimentos-grid></app-atendimento-atendimentos-grid>
      </div>
    </div>
    <div class="grid-mensagens" *ngIf="showGridMensagens">
      <div class="title">
        <p>Mensagens Ativas - {{ selectedSaw.prefixo }}</p>
      </div>
      <div class="grid">
        <app-atendimento-mensagens-grid
          [selectedSaw]="selectedSaw"
        ></app-atendimento-mensagens-grid>
      </div>
    </div>
    <ws-no-content
      [title]="titleNoContentMensagens"
      [text]="textNoContentMensagens"
      *ngIf="!showGridMensagens"
    ></ws-no-content>
  </div>
</div>

<app-mensagem-aviso-form
  *ngIf="showFormMensagemAviso"
  [equipamento]="selectedSaw"
  (closeEvent)="closeMensagemAvisoForm()"
>
</app-mensagem-aviso-form>

<app-atendimento-form
  *ngIf="showFormAtendimento"
  [equipamento]="selectedSaw"
  [registro]="selectedSawRegistro"
  (closeEvent)="closeAtendimentoForm()"
></app-atendimento-form>
