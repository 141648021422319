import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NominatimService {
  
  private nominatimUrl = 'https://nominatim.openstreetmap.org/search';

  constructor(private http: HttpClient) { }

  search(query: string): Observable<any[]> {
    const params = new HttpParams()
      .set('q', query)
      .set('format', 'json')
      .set('addressdetails', '1')
      .set('countrycodes','BR,UY,AR,PY,CL,BO,PE,EC,CO,VE,GY,GF,SR');

    return this.http.get<any[]>(this.nominatimUrl, { params }).pipe(
      map((results: any[]) => {
        return results.map(result => ({
          name: result.name,
          neighbourhood: result.address.neighbourhood,
          city: result.address.city,          
          state: result.address.state,
          country: result.address.country,
          lat: result.lat,
          lon: result.lon
        }));
      })
    );
  }
}