<div class="modal" modal-name="modalPerguntasFrequentesForm">
    <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag >
        <div class="modal-container">
            <div class="modal-title" cdkDragHandle>
                <h2>{{titleDialog}}</h2>
                <button mat-button (click)="close()" class="close">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <form *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()"
                cdkTrapFocus>
                <div class="modal-content">
                    <input type="hidden" formControlName="perguntaId" />

                    <mat-form-field class="fieldPerguntaPortugues" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.pergunta_portugues' | translate}}
                        </mat-label>
                        <textarea [focusMe]='focusPerguntaPortugues' matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="perguntaPortugues" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                    <mat-form-field class="fieldRespostaPortugues" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.resposta_portugues' | translate}}
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="respostaPortugues" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                    <mat-form-field class="fieldPerguntaEspanhol" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.pergunta_espanhol' | translate}}
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="perguntaEspanhol" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                    <mat-form-field class="fieldRespostaEspanhol" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.resposta_espanhol' | translate}}
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="respostaEspanhol" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                    <mat-form-field class="fieldPerguntaIngles" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.pergunta_ingles' | translate}}
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="perguntaIngles" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                    <mat-form-field class="fieldRespostaIngles" appearance="outline">
                        <mat-label>
                            {{'paginas.perguntasFrequentes.form.resposta_ingles' | translate}}
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize
                            autocomplete="off" formControlName="respostaIngles" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="2"></textarea>
                    </mat-form-field>

                </div>

                <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

                <div class="modal-actions">
                    <div></div>
                    <div class="buttons">
                        <button mat-button type="button" *ngIf="editForm" class="primary cancel" (click)="cancel()"
                            [disabled]="!statusBotao.cancelar">
                            <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate
                                }}</span>
                        </button>
                        <button type="submit" *ngIf="editForm" mat-button class="save"
                            [disabled]="formulario.invalid || !statusBotao.salvar">
                            <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate
                                }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
