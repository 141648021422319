<ws-grid
  *ngIf="!isLoading"
  [permissoes]="permissoesTela"
  [isLoading]="isLoading"
  [acoesGrid]="acoesGrid"
  [colunasGrid]="colunasGrid"
  [dadosGrid]="dadosGrid"
  [registroPorPagina]="registroPorPagina"
  [listaOpcaoPorPagina]="listaOpcaoPorPagina"
  (refreshEvent)="refresh()"
  (addEvent)="openForm()"
  (editEvent)="openForm($event)"
  (readEvent)="openForm($event, false)"
  (deleteEvent)="confirmDel($event)"
></ws-grid>

<app-meus-atalhos-form
  *ngIf="showForm"
  [permissoes]="permissoesTela"
  [registro]="dadosAtalho"
  [userInfo]="userInfo"
  (onChange)="refreshGridData($event.dados, $event.tipo)"
  (onDelete)="confirmDel($event)"
  (closeEvent)="closeAplicacaoAtalhoForm()">
</app-meus-atalhos-form>
