<div class="grids">
  <div class="grid-saw">
    <div class="title">
      <p>Equipamentos</p>
    </div>
    <div class="grid">
      <app-saw-equipamento-grid
        (clickRow)="setSawId($event)"
      ></app-saw-equipamento-grid>
    </div>
  </div>
  <div class="grid-perguntas">
    <app-perguntas-equipamento-grid
      *ngIf="showGridPerguntas"
      [selectedSaw]="selectedSaw"
    ></app-perguntas-equipamento-grid>
    <ws-no-content
      [title]="titleNoContent"
      [text]="textNoContent"
      *ngIf="!showGridPerguntas"
    ></ws-no-content>
  </div>
</div>
