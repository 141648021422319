import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ImageZoomService } from '../../services/image-zoom/image-zoom.service';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {
  public showZoom = false;
  public imageZoom = '';

  constructor(private imageZoomService: ImageZoomService) {}

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_CODE = 'Escape';
    if (event.code === ESCAPE_CODE) {
      this.closeZoom();
    }
  }

  ngOnInit() {
    this.imageZoomService.showZoom.subscribe((state) => {
      this.showZoom = state.show;
      if (this.showZoom) this.imageZoom = state.image;
    });
  }

  closeZoom() {
    this.imageZoomService.hide();
  }
}
