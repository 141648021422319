import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading/loading.service';

import { IChangePassword } from './models/IChangePassword';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { IDialogButton } from '../../models/IDialogButton';
import { ChangePasswordService } from './services/changePassword.service';
import { finalize } from 'rxjs/operators';
import { AlertaService } from '../../services/alerta/alerta.service';
import { MustMatch } from '../../helper/mustMatch.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public formValidParam = {
    limitPassword: {
      min: 4,
      max: 16
    }
  };

  formulario: UntypedFormGroup;
  titleDialog: string;

  @Input() showForm: EventEmitter<boolean>;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);

  public registro: IChangePassword;
  private autoClose = true;

  public statusBotao: IDialogButton;
  public formLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    public changePasswordService: ChangePasswordService,
    public loadingService: LoadingService,
    public alertaService: AlertaService,
    public dialogRef: MatDialogRef<IChangePassword>
  ) {}

  ngOnInit() {
    this.criarFormulario();
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      cancelar: false,
      salvar: false
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    this.changePasswordService
      .update(valuesForm)
      .pipe(
        finalize(() => {
          this.formLoading = false;
        })
      )
      .subscribe((_) => {
        this.alertaService.sucesso();
        this.finalitySave(valuesForm);
      });
  }

  finalitySave(retorno: IChangePassword) {
    this.registro = retorno;
    this.formLoading = false;

    this.checkAutoClose();
    this.cancel();
    this.configuracaAcoesBotoes();
  }

  cancel(): void {
    this.formulario.reset();
  }

  close(): void {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.close();
    }
  }

  get f() {
    return this.formulario.controls;
  }

  criarFormulario() {
    this.formulario = this.fb.group(
      {
        oldPassword: [
          this.registro != null && this.registro.oldPassword !== null
            ? this.registro.oldPassword
            : null,
          Validators.compose([
            Validators.required,
            Validators.minLength(this.formValidParam.limitPassword.min),
            Validators.maxLength(this.formValidParam.limitPassword.max)
          ])
        ],
        newPassword: [
          this.registro != null && this.registro.newPassword
            ? this.registro.newPassword
            : '',
          Validators.compose([
            Validators.required,
            Validators.minLength(this.formValidParam.limitPassword.min),
            Validators.maxLength(this.formValidParam.limitPassword.max)
          ])
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(this.formValidParam.limitPassword.min),
            Validators.maxLength(this.formValidParam.limitPassword.max)
          ])
        ]
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      }
    );

    this.onValueChanges();
    this.configuracaAcoesBotoes();
  }
}
