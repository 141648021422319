<mat-accordion>
  <mat-expansion-panel class="telas" *ngFor="let itemTelas of tela; let i = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="titulo">
          <mat-checkbox matTooltip="{{ 'paginas.permissao.telaPermissao.labelPossuiPermissao' | translate }}"
            [disabled]="!permissoes['update']"
            [(ngModel)]="telaPermissaoParcial[itemTelas.aplicacaoTelaId] || telaPermissaoTotal[itemTelas.aplicacaoTelaId]"
            (change)="onChangePermissaoTotal($event, itemTelas.aplicacaoTelaId)"></mat-checkbox>
          {{itemTelas.aplicacaoTelaDesc}}

          <small
            *ngIf="telaPermissaoParcial[itemTelas.aplicacaoTelaId] && !telaPermissaoTotal[itemTelas.aplicacaoTelaId]">
            [{{ 'paginas.permissao.telaPermissao.labelPermissaoParcial' | translate }}]</small>

          <small *ngIf="telaPermissaoTotal[itemTelas.aplicacaoTelaId]">
            [{{ 'paginas.permissao.telaPermissao.labelPermissaoTotal' | translate }}]</small>

        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul *ngIf="itemTelas.permissoes">
      <li *ngFor="let itemTelasPermissoes of itemTelas.permissoes">
        <mat-slide-toggle [disabled]="!permissoes['update']"
          [(ngModel)]="itemTelaPermissao[itemTelas.aplicacaoTelaId + '_' + itemTelasPermissoes.telaPermissaoId]"
          (change)="onChangePermissaoTela($event, itemTelasPermissoes.telaPermissaoId, itemTelas.aplicacaoTelaId)">
          {{itemTelasPermissoes.telaPermissaoDesc}}
          <small *ngIf="itemTelasPermissoes['possuiPermissaoGrupo']">(Grupo)</small>
        </mat-slide-toggle>
      </li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>