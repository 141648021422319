import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorDetailComponent } from '../../components/error-detail/error-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { WordsTranslateService } from '../translate/words.translate';

@Injectable({
  providedIn: 'root'
})
export class AlertaService {
  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private wordsTranslate: WordsTranslateService
  ) {}

  sucesso(titulo = null, mensagem = null, timeOut = 500) {
    const keyRoot = 'alertas.sucesso.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }

    this.toastr.success(mensagem, titulo, { timeOut });
  }

  atendimentoWarning(titulo, mensagem, timeOut = 7000) {
    this.toastr.warning(mensagem, titulo, { timeOut });
  }

  erro(titulo = null, mensagem = null, timeOut = 7000) {
    const keyRoot = 'alertas.erro.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.error(mensagem, titulo, { timeOut });
  }

  erro500(titulo = null, mensagem = null) {
    const keyRoot = 'alertas.erro.';
    const words = {
      ocorreuUmErro: '',
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }

    const dialogRef = this.dialog.open(ErrorDetailComponent, {
      disableClose: true,
      width: '600px',
      data: {
        title: wordsTranslate.ocorreuUmErro,
        registro: mensagem.error
      }
    });
  }

  erroDesconhecido(err) {
    const keyRoot = 'alertas.erroDesconhecido.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    let titulo = wordsTranslate.titulo;
    let mensagem = wordsTranslate.mensagem;

    let registro = {
      Title: err.statusText,
      Detail: err.message,
      StatusCode: err.status,
      Instance: err.url
    };

    const dialogRef = this.dialog.open(ErrorDetailComponent, {
      disableClose: true,
      width: '600px',
      data: {
        title: titulo,
        registro: registro
      }
    });
  }

  permissao(titulo = null, mensagem = null) {
    const keyRoot = 'alertas.permissao.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.warning(mensagem, titulo);
  }

  sessaoExpirou(titulo = null, mensagem = null) {
    const keyRoot = 'alertas.sessao_expirou.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.info(mensagem, titulo);
  }

  erroConexao(titulo = null, mensagem = null) {
    const keyRoot = 'alertas.erro_conexao.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);
    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.error(mensagem, titulo);
  }

  Warning(titulo = null, mensagem = null, timeOut = 500){
    const keyRoot = 'alertas.warning';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null){
      titulo = wordsTranslate.titulo;
    }
    if(titulo === null){
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.warning(mensagem, titulo, { timeOut });
  }

  senhaIncorreta(titulo = null, mensagem = null, timeOut = 7000) {
    const keyRoot = 'alertas.senhaIncorreta.';
    const words = {
      titulo: '',
      mensagem: ''
    };
    let wordsTranslate = this.wordsTranslate.get(words, keyRoot);

    if (titulo === null) {
      titulo = wordsTranslate.titulo;
    }
    if (mensagem === null) {
      mensagem = wordsTranslate.mensagem;
    }
    this.toastr.error(mensagem, titulo, { timeOut });
  }
}
