import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { IContaEmail } from '../models/IContaEmail';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 4; 

@Injectable({
  providedIn: 'root'
})
export class ContaEmailService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}ContaEmail`;

  getGrid(): Observable<IContaEmail[]> {
    return this.http.get<IContaEmail[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IContaEmail) {
    return this.http.get<IContaEmail>(
      `${this.apiUrl}/Form/${dados.contaEmailId}`
    );
  }

  update(dados: IContaEmail) {
    return this.http.put<IContaEmail>(
      `${this.apiUrl}`,
      dados
    );
  }

  insert(dados: IContaEmail) {
    return this.http.post<IContaEmail>(
      `${this.apiUrl}`,
      dados
    );
  }

  delete(contaEmailId: number) {
    return this.http.delete<IContaEmail>(
      `${this.apiUrl}/${contaEmailId}`,
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
