import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

const telaId = 8;

@Injectable({ providedIn: 'root' })
export class TabPermissaoService {
  constructor(private authService: AuthService) {}
  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
