<div class="modal-container">
    <div class="modal-content">

      <app-campos-mockup-form
        [dados]="dadosAplicacao"
        [possuiTitulo]="false"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardAplicacao">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosBarras"
        [possuiTitulo]="false"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardBarras">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosTitulo"
        [possuiTitulo]="true"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardTitulo">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosAtendimento"
        [possuiTitulo]="true"
        [possuiDescricao]="true"
        [possuiCor]="false"
        [abrirCardForm]="cardAtendimento">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosProcurarDestino"
        [possuiTitulo]="true"
        [possuiDescricao]="true"
        [possuiCor]="true"
        [abrirCardForm]="cardProcuraDestino">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosPerguntasFrequentes"
        [possuiTitulo]="true"
        [possuiDescricao]="true"
        [possuiCor]="true"
        [abrirCardForm]="cardPerguntasFrequentes">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        *ngIf="habilitaRevista"
        [dados]="dadosLeitorRevista"
        [possuiTitulo]="true"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardLeitorRevista">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosLivroRegistro"
        [possuiTitulo]="true"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardLivroRegistro">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosTelefonesUteis"
        [possuiTitulo]="true"
        [possuiDescricao]="true"
        [possuiCor]="true"
        [abrirCardForm]="cardTelefonesUteis">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosCondicoesRodovia"
        [possuiTitulo]="true"
        [possuiDescricao]="true"
        [possuiCor]="true"
        [abrirCardForm]="cardCondicoesRodovia">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosVideoRemoto"
        [possuiTitulo]="false"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardVideoRemoto">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosVideoLocal"
        [possuiTitulo]="false"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardVideoLocal">
      </app-campos-mockup-form>

      <app-campos-mockup-form
        [dados]="dadosTelefone"
        [possuiTitulo]="false"
        [possuiDescricao]="false"
        [possuiCor]="true"
        [abrirCardForm]="cardTelefone">
      </app-campos-mockup-form>

    </div>
</div>
