import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ILicenca } from '../models/ILicenca';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';


@Injectable({
  providedIn: 'root',
})
export class LicencaService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}Licenca`;

  update(dados: ILicenca) {
    return this.http.put<ILicenca>(`${this.apiUrl}`, dados);
  }

}
