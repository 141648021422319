import { IParametrosAtualizaTotem } from './../models/IParametrosAtualizaTotem';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IVersaoTotem } from '../models/IVersaoTotem';
import { IEquipamentosVersaoTotem } from '../models/IEquipamentosVersaoTotem';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 41;
const apiTela = `VersaoTotem`;

@Injectable({ providedIn: 'root' })
export class VersaoTotemService {
  httpEmitter: Subscription;

  @Output() saveFile = new EventEmitter<boolean>();
  @Output() saveUpdate = new EventEmitter<any>();
  @Output() onChangeFormVersao =  new EventEmitter<any>(true);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private configuracaoService: ConfiguracaoService,
    private env: EnvironmentService
  ) {}

  apiUrl = `${this.env.apiUrl}${apiTela}`;

  getGrid(): Observable<IVersaoTotem[]> {
    return this.http.get<IVersaoTotem[]>(`${this.apiUrl}/Grid`);
  }

  getEquipamentosGrid(versaoTotemId: number): Observable<IEquipamentosVersaoTotem[]> {
    return this.http.get<IEquipamentosVersaoTotem[]>(`${this.apiUrl}/EquipamentosGrid/${versaoTotemId}`);
  }

  getForm(dados: IVersaoTotem) {
    return this.http.get<IVersaoTotem>(`${this.apiUrl}/Form/${dados.id}`);
  }

  getExtensions() {
    return this.configuracaoService.getExtensions(telaId);
  }

  insertAtualizacao(dados: any) {
    return this.http.post<number[]>(`${this.apiUrl}`, dados);
  }

  getArquivoAtualizacao(versaoId: number) {
    return this.http.get<File[]>(`${this.apiUrl}/GetFile/${versaoId}`);
  }

  uploadArquivoAtualizacao(fileUpload: any , versaoTotemId: number) {
    return this.http.post(`${this.apiUrl}/PostFile/${versaoTotemId}`, fileUpload, {
      reportProgress: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  insertAtualizacaoEquipamento(dados : IParametrosAtualizaTotem) {
    return this.http.post<IParametrosAtualizaTotem>(`${this.apiUrl}/Equipamento`, dados);
  }

  update(dados: IVersaoTotem) {
    return this.http.put<IVersaoTotem>(`${this.apiUrl}`, dados);
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
