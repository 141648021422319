import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IRPerguntasSAW } from '../models/IRPerguntasSaw';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 30;

@Injectable({
  providedIn: 'root'
})
export class ClickPerguntasService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}
  
  apiUrl = `${this.env.apiUrl}RPerguntas`;

  get(parametros: any): Observable<IRPerguntasSAW[]> {
    var params = this.getParams(parametros);

    return this.http.get<IRPerguntasSAW[]>(this.apiUrl, { params });
  }

  export(parametros: any) {
    var params = this.getParams(parametros);

    return this.http.get(`${this.apiUrl}/export`, {
      params,
    });
  }

  getParams(parametros: any){
    var datePipe = new DatePipe("en-US");
    parametros.dataInicio = datePipe.transform(parametros.dataInicio, "yyyy-MM-dd");
    parametros.dataFim = datePipe.transform(parametros.dataFim, "yyyy-MM-dd");

    let params = new HttpParams()
      .set('dataInicio', parametros.dataInicio)
      .set('dataFim', parametros.dataFim);

      parametros.sawId.forEach((x) => {
        params = params.append('sawId', x);
      });  


    return params
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}