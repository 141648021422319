<div class="modal" modal-name="modalLivroRegistroFormEnvios">
  <div class="box"
       cdkDragBoundary=".mat-tab-body-wrapper"
       cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="grid">
        <ws-grid *ngIf="!isLoading"
                 [permissoes]="permissoes"
                 [isLoading]="isLoading"
                 [colunasGrid]="colunasGrid"
                 [dadosGrid]="dadosGrid">
        </ws-grid>
        </div>

      </div>
  </div>
</div>
