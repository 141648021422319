<div class="subTabs" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms" style="width: 100%;" (selectedTabChange)="onTabClick($event)">
    <mat-tab label="{{ 'paginas.configuracaoSistema.tab.titulo.parametros' | translate }}">
      <app-parametros-configuracao-sistema-grid *ngIf="showParametros" [permissoes]="permissoesTela">
      </app-parametros-configuracao-sistema-grid>
    </mat-tab>

    <mat-tab label="{{ 'paginas.configuracaoSistema.tab.titulo.parametrosLog' | translate }}">
      <app-parametros-log-configuracao-sistema-grid *ngIf="showLog"></app-parametros-log-configuracao-sistema-grid>
    </mat-tab>

    <mat-tab label="{{ 'paginas.configuracaoSistema.tab.titulo.planoDeFundo' | translate }}">
      <app-wallpaper-configuracao-sistema-upload *ngIf="showWallpaper" [editForm]="editForm">
      </app-wallpaper-configuracao-sistema-upload>
    </mat-tab>
  </mat-tab-group>
</div>