import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICombo } from '@ws/combo/lib/interfaces/ws-combo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { ISAW } from 'src/app/shared/models/ISaw';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { TipoAtendimentoCombo } from 'src/app/shared/services/combo/tipo-atendimento.combo';
import { ConfiguracaoService } from 'src/app/shared/services/configuracao/configuracao.service';
import { ControleVolumeService } from 'src/app/shared/services/controle-volume/controle-volume.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IDestinosAtendimento } from '../../models/IDestinosAtendimento';
import { IInsertedAtendimento } from '../../models/IInsertedAtendimento';
import { AtendimentoService } from '../../services/atendimento.service';

@Component({
  selector: 'app-atendimento-form',
  templateUrl: './atendimento-form.component.html',
  styleUrls: ['./atendimento-form.component.scss'],
})
export class AtendimentoFormComponent implements OnChanges {
  @Input() public registro: IInsertedAtendimento;
  @Input() public equipamento: ISAW;

  @Output() public closeEvent = new EventEmitter<string>();

  public formulario: UntypedFormGroup;
  public titleDialog: string;

  public statusBotao: IDialogButton;
  public formLoading = false;

  public itensComboTipoAtendimento: ICombo[];
  public ItemSelecionadoComboTipoAtendimento: any;
  private autoClose = false;

  permissoes: IGridPermissoes;
  hintLabelText: string = '';
  modalName: string = 'modalAtendimentoForm';
  atendimentoId: number = null;
  wordsTranslated: any;
  statusPhone: boolean;
  volumeAudioRemoto: number;
  volumeMicRemoto: number;
  volumeInicial = 0.6;
  microfoneInicial = 0.3;
  coordinatesDestino = {
    latitude: null,
    longitude: null,
  };

  coordinatesEquipamento = {
    latitude: null,
    longitude: null,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private alertaService: AlertaService,
    private atendimentoService: AtendimentoService,
    private tipoAtendimentoCombo: TipoAtendimentoCombo,
    private wordsTranslate: WordsTranslateService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService,
    private controleVolumeService: ControleVolumeService,
    private configService: ConfiguracaoService
  ) {
    this.loadWordsTranslate();
    this.listenKeyPress();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.atendimento.principal.form.';
    const words = {
      adicionar_registro: '',
      editar_registro: '',
      tipoAtendimentoId: '',
      atendimentoPessoaNome: '',
      atendimentoVeiculoPlaca: '',
      atendimentoVeiculoModelo: '',
      atendimentoVeiculoCor: '',
      atendimentoObs: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  public ngOnChanges(): void {
    this.loadingService.show();
    this.initVolume();
    this.atendimentoService.getPermissoes().subscribe((results) => {
      this.permissoes = results[0];

      const listComboTipoAtendimento = this.tipoAtendimentoCombo.listComboSelect();

      forkJoin([listComboTipoAtendimento])
      .subscribe((results) => {
        this.itensComboTipoAtendimento = results[0];
        this.coordinatesEquipamento.latitude = this.equipamento.latitude;
        this.coordinatesEquipamento.longitude = this.equipamento.longitude;
        this.atendimentoService.loadMap.emit(this.coordinatesEquipamento);
        this.criarFormulario();
      });
    });
  }

  checkStatusSip() {
    let sipConfig = this.configService.getSip();
    sipConfig.sipEnable ? this.statusPhone = true : this.statusPhone = false;

    if(!this.statusPhone) { this.setVolumeRemoto(); };
  }

  criarFormulario() {
    this.formulario = this.fb.group({
      atendimentoId: [
        this.registro && this.registro.atendimentoId
          ? this.registro.atendimentoId
          : null,
      ],
      sawId: [
        this.equipamento.sawId,
        Validators.compose([Validators.required]),
      ],
      tipoAtendimentoId: [
        this.registro && this.registro.tipoAtendimentoId
          ? this.registro.tipoAtendimentoId
          : null,
        Validators.compose([Validators.required]),
      ],
      atendimentoPessoaNome: [
        this.registro && this.registro.atendimentoPessoaNome
          ? this.registro.atendimentoPessoaNome
          : '',
        Validators.compose([Validators.maxLength(80)]),
      ],
      atendimentoVeiculoPlaca: [
        this.registro && this.registro.atendimentoVeiculoPlaca
          ? this.registro.atendimentoVeiculoPlaca
          : '',
        Validators.compose([Validators.maxLength(15)]),
      ],
      atendimentoVeiculoModelo: [
        this.registro && this.registro.atendimentoVeiculoModelo
          ? this.registro.atendimentoVeiculoModelo
          : '',
        Validators.compose([Validators.maxLength(30)]),
      ],
      atendimentoVeiculoCor: [
        this.registro && this.registro.atendimentoVeiculoCor
          ? this.registro.atendimentoVeiculoCor
          : '',
        Validators.compose([Validators.maxLength(20)]),
      ],
      atendimentoObs: [
        this.registro && this.registro.atendimentoObs
          ? this.registro.atendimentoObs
          : '',
        Validators.compose([Validators.maxLength(6000)]),
      ],
    });

    if (this.registro) {
      this.setHintLabel(this.registro.atendimentoObs.length);
    } else {
      this.setHintLabel(0);
    }

    this.onValueChanges();
    this.configuracaAcoesBotoes();
    this.configuraTitleDialog();
    this.loadingService.hide();
  }

  setHintLabel(length) {
    this.hintLabelText = `Máximo de caracteres permitidos: ${length}/2000`;
  }

  configuracaAcoesBotoes(): void {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if (prop in this.registro) {
            if (this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              var texto: string = this.f.atendimentoObs.value;
              if (texto != null) {
                this.setHintLabel(texto.length);
              }
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
      var texto: string = this.f.atendimentoObs.value;
      if (texto != null) {
        this.setHintLabel(texto.length);
      }
    });
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if (this.registro) {
      this.atendimentoService
        .updateAtendimento(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm);
        });
    } else {
      this.atendimentoService
        .insertAtendimento(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response);
        });
    }
  }

  finalitySave(retorno) {
    this.registro = retorno;
    this.atendimentoId = retorno.atendimentoId;
    this.formLoading = false;
    this.checkAutoClose();
    this.criarFormulario();
    this.configuracaAcoesBotoes();
    this.atendimentoService.refreshAtendimentos.next();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if (this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  checkAutoClose(): void {
    if (this.autoClose) {
      this.confirmClose();
    }
  }

  get f() {
    return this.formulario.controls;
  }

  configuraTitleDialog() {
    if (this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  onChangeComboTipoAtendimento($event) {
    this.ItemSelecionadoComboTipoAtendimento = $event;

    this.formulario.patchValue({
      tipoAtendimentoId: this.ItemSelecionadoComboTipoAtendimento,
    });
  }

  setLatitudeLongituteDestino(destino: IDestinosAtendimento) {
    if (
      destino.destinoLatitude &&
      destino.destinoLongitude &&
      this.equipamento.latitude &&
      this.equipamento.longitude
    ) {
      this.coordinatesDestino.latitude = destino.destinoLatitude;
      this.coordinatesDestino.longitude = destino.destinoLongitude;

      this.atendimentoService.loadDestino.emit(true);
    }
  }

  public initVolume(): void {
    this.volumeAudioRemoto = this.volumeInicial * 10;
    this.volumeMicRemoto = this.microfoneInicial * 10;

    this.checkStatusSip();
  }

  public setVolumeRemoto(): void {
    this.controleVolumeService.setVolumeEquipamento(
      this.volumeAudioRemoto.toString(),
      this.volumeMicRemoto.toString(),
      this.equipamento.numeroSerie,
    );
  }

  public setVolumeAudioRemoto($event: any): void {
    let set = parseFloat($event) * 10;
    if(set != this.volumeAudioRemoto){
      this.volumeAudioRemoto = set;
      this.setVolumeRemoto();
    }
  }

  public setVolumeMicRemoto($event: any): void {
    let set = parseFloat($event) * 10;
    if (set != this.volumeMicRemoto){
      this.volumeMicRemoto = set;
      this.setVolumeRemoto();
    }
  }
}
