import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WsGridService } from '@ws/grid';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ISaw } from '../models/ISaw';
import * as _ from 'underscore';
import { EquipamentoService } from '../services/equipamento.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-equipamento-grid',
  templateUrl: './equipamento-grid.component.html',
  styleUrls: ['./equipamento-grid.component.scss'],
})
export class EquipamentoGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: ISaw[];
  public dadosFormulario: ISaw;
  public focusFirstLine: boolean = false;

  wordsTranslated: any;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private equipamentoService: EquipamentoService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    public gridService: WsGridService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.equipamento.grid.';
    const words = {
      prefixo: '',
      numeroSerie: '',
      rodoviaSigla: '',
      localizacaoRodoviaDesc: '',
      kmLocal: '',
      sentidoDesc: '',
      ipPrimario: '',
      ipSecundario: '',
      ramal: '',
      rA: '',
      endereco: '',
      firmware: '',
      firmwareData: '',
      tooltipIpPrimario: '',
      tooltipIpSecundario: '',
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.equipamentoService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: true,
      paginacao: true,
      export: false,
      disable: false,
      custom: [
        {
          icon: 'settings',
          action: 'openUrlIpPrimario',
          tooltip: this.wordsTranslated.tooltipIpPrimario,
          compare: true,
          propertyToCompare: 'hasIpPrimario',
          valueToCompare: true
        },
        {
          icon: 'settings',
          action: 'openUrlIpSecundario',
          tooltip: this.wordsTranslated.tooltipIpSecundario,
          compare: true,
          propertyToCompare: 'hasIpSecundario',
          valueToCompare: true
        },
      ],
    };
  }

  customEventGrid($event) {
    var equipamento: ISaw;
    this.equipamentoService.getForm($event.row.sawId).subscribe((result) => {
      equipamento = result;
      if ($event.action === 'openUrlIpPrimario') {
        window.open(
          `http://${equipamento.ipPrimario}:${environment.portaConfigEquipamento}`
        );
      }
      if ($event.action === 'openUrlIpSecundario') {
        window.open(
          `http://${equipamento.ipSecundario}:${environment.portaConfigEquipamento}`
        );
      }
    });
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'prefixo',
        label: this.wordsTranslated.prefixo,
        width: '',
        sort: true,
      },
      {
        name: 'numeroSerie',
        label: this.wordsTranslated.numeroSerie,
        width: '',
        sort: true,
      },
      {
        name: 'rodoviaSigla',
        label: this.wordsTranslated.rodoviaSigla,
        width: '',
        sort: true,
      },
      {
        name: 'localizacaoRodoviaDesc',
        label: this.wordsTranslated.localizacaoRodoviaDesc,
        width: '',
        sort: true,
      },
      {
        name: 'kmLocal',
        label: this.wordsTranslated.kmLocal,
        width: '',
        sort: true,
      },
      {
        name: 'sentidoDesc',
        label: this.wordsTranslated.sentidoDesc,
        width: '',
        sort: true,
      },
      {
        name: 'ipPrimario',
        label: this.wordsTranslated.ipPrimario,
        width: '',
        sort: true,
      },
      {
        name: 'ipSecundario',
        label: this.wordsTranslated.ipSecundario,
        width: '',
        sort: true,
      },
      {
        name: 'ramal',
        label: this.wordsTranslated.ramal,
        width: '',
        sort: true,
      },
      {
        name: 'ra',
        label: this.wordsTranslated.rA,
        width: '',
        sort: true,
      },
      {
        name: 'endereco',
        label: this.wordsTranslated.endereco,
        width: '',
        sort: true,
      },
      {
        name: 'firmware',
        label: this.wordsTranslated.firmware,
        width: '',
        sort: true,
      },
      {
        name: 'firmwareData',
        label: this.wordsTranslated.firmwareData,
        width: '',
        sort: true,
        formatType: 'getDate',
        formatValue: 'dd/MM/yyyy HH:mm',
      },
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.equipamentoService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.setFocusLineFalse();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          this.dadosGrid.forEach((equipamento) => {
            if(equipamento.ipSecundario != ''){
              equipamento.hasIpSecundario = true;
            }else{
              equipamento.hasIpSecundario = false;
            }

            if(equipamento.ipPrimario != ''){
              equipamento.hasIpPrimario = true;
            }else{
              equipamento.hasIpPrimario = false;
            }
          });
          this.focusFirstLine = true;
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados: ISaw, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        sawId: dados.sawId,
        hasIpPrimario: dados.ipPrimario ? true : false,
        hasIpSecundario: dados.ipSecundario ? true : false
      });
    }

    this.dadosGrid = this.gridService.refreshGridData(
      this.dadosGrid,
      dados,
      indexPage,
      tipo
    );
  }

  deleteSaw(dados: ISaw) {
    const dialogConfirm = this.dialogService.confirmDelete();

    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.equipamentoService
          .delete(dados.sawId)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeForm();
          });
      }
    });
  }

  openForm(dados: ISaw = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  setFocusLineFalse() {
    setTimeout(() => {
      this.focusFirstLine = false;
    }, 500);
  }
}
