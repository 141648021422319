<div class="contents-upload">

  <div class="upload-area">
    <div class="title">
      <p>{{ wordsTranslated.descansoTela }}</p>
    </div>
    <ws-upload
      [edit]="editForm"
      [listFiles]="filesDescanso"
      [listErrosUpload]="listErrosUploadDescanso"
      [multiple]="false"
      [limit]="1"
      [accept]="acceptExtensions"
      [statusUpload]="statusUploadDescanso"
      (uploadEvent)="uploadFilesDescanso($event)"
      (eventPreview)="showPreview($event)"
      [isDisabled]="false"
    ></ws-upload>
  </div>

  <div class="upload-area">
    <div class="title">
      <p>{{ wordsTranslated.atendimentoIndisponivel }}</p>
    </div>
    <ws-upload
      [edit]="editForm"
      [listFiles]="filesAtendimento"
      [listErrosUpload]="listErrosUploadAtendimento"
      [multiple]="false"
      [limit]="1"
      [accept]="acceptExtensions"
      [statusUpload]="statusUploadAtendimento"
      (uploadEvent)="uploadFilesAtendimento($event)"
      (eventPreview)="showPreview($event)"
      [isDisabled]="false"
    ></ws-upload>
  </div>

  <div class="upload-area">
    <div class="title">
      <p>{{ wordsTranslated.mapaOffline }}</p>
    </div>
    <ws-upload
      [edit]="editForm"
      [listFiles]="filesLocalizacao"
      [listErrosUpload]="listErrosUploadLocalizacao"
      [multiple]="false"
      [limit]="1"
      [accept]="acceptExtensions"
      [statusUpload]="statusUploadLocalizacao"
      (uploadEvent)="uploadFilesLocalizacao($event)"
      (eventPreview)="showPreview($event)"
      [isDisabled]="false"
    ></ws-upload>
  </div>

  <div class="upload-area">
    <div class="title">
      <p>{{ wordsTranslated.audioEspera }}</p>
    </div>
    <ws-upload
      [edit]="editForm"
      [listFiles]="filesAudioEspera"
      [listErrosUpload]="listErrosUploadAudioEspera"
      [multiple]="false"
      [limit]="1"
      [accept]="acceptExtensionsAudio"
      [statusUpload]="statusUploadAudioEspera"
      (uploadEvent)="uploadFilesAudioEspera($event)"
      (eventPreview)="showPreview($event)"
      [isDisabled]="false"
    ></ws-upload>
  </div>
</div>