import { DestinoService } from './../services/destino.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { IDestino } from '../models/IDestino';
import { ImageZoomService } from 'src/app/shared/services/image-zoom/image-zoom.service';

@Component({
  selector: 'app-destino-upload-midia-grid',
  templateUrl: './destino-upload-midia-grid.component.html'
})
export class DestinoUploadMidiaGridComponent implements OnInit {
  titleDialog: string = "Upload de Imagem";
  acceptExtensions: string = "*";
  files: File[] = [];
  isLoading: boolean;
  showUpload: boolean;
  listErrosUpload = [];
  modalName: string = "modalDestinoUpload";
  wordsTranslated: any;
  statusUpload: Boolean = false;

  @Input() destinoId: number;
  @Input() editForm: EventEmitter<boolean>;
  @Input("dados") dados: IDestino;

  @Output() closeEvent = new EventEmitter<string>();

  constructor(
    private destinoService: DestinoService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private closeModalService: CloseModalService,
    private imageZoomService: ImageZoomService
  ) {
    this.listenKeyPress();
    this.loadWordsTranslate();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.close();
      }
    });
  }

  loadWordsTranslate() {
    const keyRoot = "paginas.destino.upload.";
    const words = {
      title: "",
    };
   this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit(): void {
    this.loadFiles();
    this.destinoService.getExtensions().subscribe((result: any) => {
      this.acceptExtensions = result.extensoes;
    });

    this.titleDialog = this.wordsTranslated.title;
  }

  loadFiles() {
    this.isLoading = true;
    this.destinoService.getMidia(this.destinoId).subscribe(
      (result) => {
        this.files = result;
        this.showUpload = true;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.alertaService.erro();
        this.close();
      }
    );
  }

  uploadFiles(uploadDados) {
    const req = this.destinoService.uploadMidia(
      this.destinoId,
      uploadDados.formData
    );
    req.subscribe(
      (results: any) => {
        if (results && results[0]) {
          const errors = [];
          errors.push(results[0]);

          errors.forEach((element) => {
            this.listErrosUpload.push(element);
          });
        }
        this.alertaService.sucesso();
        this.loadFiles();
      },
      (error: any) => {
        this.alertaService.erro();
      }
    );
  }

  close(): void {
    this.closeEvent.next();
  }

  showImage(image){
    this.imageZoomService.show(image);
  }

}
