<div class="modal" modal-name="modalConfiguracaoSistemaForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag >
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>

        <div class="modal-content">

          <input type="hidden" formControlName="parametroSistemaId" />
          <p class="fieldParametroSistemaId">{{registro.parametroSistemaDescricao}}</p>

          <mat-form-field class="fieldParametroSistemaValor"appearance="outline" *ngIf="registro.parametroSistemaTipo !== 'OPTION' && registro.parametroSistemaTipo !== 'HEXA'">
            <mat-label>{{'paginas.configuracaoSistema.parametros.form.valor' | translate}}</mat-label>
            <input [focusMe]='focusValor' matInput formControlName="parametroSistemaValor"
              placeholder="{{'paginas.configuracaoSistema.parametros.form.valor' | translate}}" />
          </mat-form-field>

          <ws-combo *ngIf="registro.parametroSistemaTipo === 'OPTION'"
            [textoLabel]="'paginas.configuracaoSistema.parametros.form.valor' | translate" [multiple]="false"
            [selecionarTodos]="false" [itemCtrl]="f.parametroSistemaValor" [items]="itensComboOption"
            [itemSelecionado]="itemSelecionadoComboOption" (changeEventCombo)="onChangeComboOption($event)">
          </ws-combo>

          <ws-color-picker *ngIf="registro.parametroSistemaTipo === 'HEXA'"
          [nameLabel]="nameLabelColorPicker"
          [fieldClass]="nameField"
          [corValue]="corValue"
          [disabled]="false"
          [touchUi]="false"
          (colorPicked)="getColor($event)"
           >
          </ws-color-picker>

        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button mat-button type="button" class="primary cancel"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)" (click)="cancel()"
              [disabled]="!statusBotao.cancelar">
              <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
            </button>
            <button type="submit" mat-button class="save"
              *ngIf="(permissoes['create'] && !registro) || (permissoes['update'] && registro)"
              [disabled]="formulario.invalid || !statusBotao.salvar">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
