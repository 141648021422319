import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRevista } from '../models/IRevista';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { RevistaService } from '../services/revista.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { CloseModalService } from 'src/app/shared/services/closeModal/close-modal.service';
import { IDialogButton } from 'src/app/shared/models/IDialogButton';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-revista-form',
  templateUrl: './revista-form.component.html',
  styleUrls: ['./revista-form.component.scss']
})
export class RevistaFormComponent implements OnInit {

  formulario: UntypedFormGroup;
  titleDialog: string;
  modalName: string = 'modalRevistaForm';
  wordsTranslated: any;
  statusBotao: IDialogButton;
  registroClean: IRevista;

  public dataMax = new Date(Date.now());
  public autoClose: boolean = false;
  public formLoading: boolean = false;
  public isLoading: boolean;
  public registroPorPagina = environment.registroPorPagina;
  public listaOpcaoPorPagina = environment.listaOpcaoPorPagina;

  @Input() permissoes: IGridPermissoes;
  @Input() editForm: EventEmitter<boolean>;
  @Input() showForm: EventEmitter<boolean>;
  @Input() registro: IRevista = null;

  @Output() closeEvent = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<number>();

  constructor(
    private revistaService: RevistaService,
    private alertaService: AlertaService,
    private wordsTranslate: WordsTranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private closeModalService: CloseModalService
  ) {
    this.listenKeyPress();
    this.loadWordsTranslate();
  }

  listenKeyPress() {
    this.closeModalService.closeForm.subscribe((state) => {
      if (state.close && state.name == this.modalName) {
        this.confirmClose();
      }
    });
  }

  loadWordsTranslate() {
    let keyRoot = 'paginas.revistas.form.';
    let words = {
      registro: '',
      editar_registro: '',
      adicionar_registro: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.loadingService.show();
    if (this.registro != null) {
      this.loadEditData();
    } else {
      this.criarFormulario();
    }
  }

  loadEditData(){
    this.revistaService.getForm(this.registro).subscribe((result) => {
      this.registro = result;
      this.criarFormulario();
    });
  }

  criarFormulario(){
    this.formulario = this.fb.group({
      revistaId: [
        this.registro != null && this.registro.revistaId !== null
        ? this.registro.revistaId
        : null,
      ],
      revistaTitulo: [
        this.registro != null && this.registro.revistaTitulo !== null
        ? this.registro.revistaTitulo
        : null,
        Validators.compose([Validators.required]),
      ],
      revistaDataPublicacao: [
        this.registro != null && this.registro.revistaDataPublicacao !== null
        ? this.registro.revistaDataPublicacao
        : null,
        Validators.compose([Validators.required]),
      ]
    });

    this.onValueChanges();
    this.configuraAcoesBotoes();
    this.configuraTitleDialog();

    this.loadingService.hide();

    if (!this.permissoes['update'] && this.registro) {
      this.formulario.disable();
    }
  }

  configuraAcoesBotoes() {
    this.statusBotao = {
      adicionar: this.registro ? true : false,
      deletar: this.registro ? true : false,
      cancelar: false,
      salvar: false,
    };
  }

  onValueChanges() {
    this.formulario.valueChanges.subscribe((val) => {
      if (this.registro) {
        for (const prop in val) {
          if(prop in this.registro) {
            if(this.registro[prop] !== val[prop]) {
              this.dirtyFormButtonsState();
              return;
            } else {
              this.initialFormButtonsState();
            }
          }
        }
      } else {
        this.dirtyFormButtonsState();
      }
    })
  }

  initialFormButtonsState() {
    this.statusBotao.salvar = false;
    this.statusBotao.cancelar = false;
    this.statusBotao.adicionar = true;
  }

  dirtyFormButtonsState() {
    this.statusBotao.salvar = true;
    this.statusBotao.cancelar = true;
    this.statusBotao.adicionar = false;
  }

  save(): void {
    this.formLoading = true;
    const valuesForm = this.formulario.value;

    if(this.registro) {
      this.revistaService
        .update(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe(() => {
          this.alertaService.sucesso();
          this.finalitySave(valuesForm, 'edit');
        })
    } else {
      this.revistaService
        .insert(valuesForm)
        .pipe(
          finalize(() => {
            this.formLoading = false;
          })
        )
        .subscribe((response) => {
          this.alertaService.sucesso();
          this.finalitySave(response, 'add');
        });
    }
  }

  finalitySave(retorno, tipo: string) {
    this.revistaService.getForm(retorno).subscribe((result) => {
      this.registro = result;
      const change = {
        dados: this.registro,
        tipo,
      }
      this.onChange.emit(change);

      this.formLoading = false;
      this.checkAutoClose();
    });
  }

  add(): void {
    this.registro = this.registroClean;
    this.formulario.reset();
    this.criarFormulario();
  }

  cancel(): void {
    this.formulario.reset();
    this.criarFormulario();
  }

  close(): void {
    if(this.formulario.valid && this.statusBotao.salvar) {
      const dialogConfirm = this.dialogService.confirmCloseForm();
      dialogConfirm.afterClosed().subscribe((result) => {
        if(result) {
          this.confirmClose();
        }
      });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.closeEvent.next();
    this.formulario.reset();
  }

  del(): void {
    this.onDelete.emit(this.formulario.value);
  }
  
  checkAutoClose(): void {
    if(this.autoClose) {
      this.confirmClose();
    } else {
      this.criarFormulario();
      this.configuraAcoesBotoes();
    }
  }

  configuraTitleDialog(){
    this.titleDialog = this.wordsTranslated.registro;

    if(this.registro) {
      this.titleDialog = this.wordsTranslated.editar_registro;
    } else {
      this.titleDialog = this.wordsTranslated.adicionar_registro;
    }
  }

  changeDate($event) {
    let dataEscolhida: Date;
    dataEscolhida = new Date($event.value)

    this.formulario.patchValue({
      revistaDataPublicacao: dataEscolhida,
    }); 
  }

}
