import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { AlertaService } from 'src/app/shared/services/alerta/alerta.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { ContaEmailService } from '../services/conta-email.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';

import { IContaEmail } from '../models/IContaEmail';

import * as _ from 'underscore';
import { IGridAcoes } from '@ws/grid/lib/interfaces/ws-grid-acoes.interface';
import { IGridModelo } from '@ws/grid/lib/interfaces/ws-grid-modelo.interface';
import { IGridPermissoes } from '@ws/grid/lib/interfaces/ws-grid-permissoes.interface';
import { WsGridService } from '@ws/grid';

@Component({
  selector: 'app-conta-email',
  templateUrl: './conta-email-grid.component.html'
})
export class ContaEmailGridComponent implements OnInit {
  public permissoesTela: IGridPermissoes;
  public isLoading: boolean = false;
  public editForm: boolean = false;
  public showForm: boolean = false;
  public acoesGrid: IGridAcoes;
  public colunasGrid: IGridModelo[];
  public dadosGrid: IContaEmail[];
  public dadosFormulario: IContaEmail;

  hasAnEmail: boolean = false;
  wordsTranslated: any;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private contaEmailService: ContaEmailService,
    private loadingService: LoadingService,
    private wordsTranslate: WordsTranslateService,
    private alertaService: AlertaService,
    public gridService: WsGridService
  ) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.contaEmail.grid.';
    const words = {
      smtp: '',
      porta: '',
      protocolo: '',
      usuario: '',
      email: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);
  }

  ngOnInit() {
    this.isLoading = true;
    this.contaEmailService
      .getPermissoes()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.permissoesTela = response[0];
        this.editForm = this.permissoesTela.update;
        this.configuraAcoesGrid();
        this.configuraColunasGrid();
        this.configuraDadosGrid();
      });
  }

  configuraAcoesGrid(): void {
    this.acoesGrid = {
      show: true,
      edit: true,
      create: true,
      delete: true,
      upload: false,
      search: true,
      refresh: false,
      paginacao: false,
      export: false,
      disable: false
    };
  }

  configuraColunasGrid(): void {
    this.colunasGrid = [
      {
        name: 'contaEmailSMTP',
        label: this.wordsTranslated.smtp,
        width: '',
        sort: false
      },
      {
        name: 'contaEmailPorta',
        label: this.wordsTranslated.porta,
        width: '',
        sort: false
      },
      {
        name: 'contaEmailUsuario',
        label: this.wordsTranslated.usuario,
        width: '',
        sort: false
      },
      {
        name: 'contaEmailEmail',
        label: this.wordsTranslated.email,
        width: '',
        sort: false
      },
      {
        name: 'protocoloDes',
        label: this.wordsTranslated.protocolo,
        width: '',
        sort: false
      }
    ];
  }

  configuraDadosGrid(): void {
    this.loadingService.show();
    this.contaEmailService
      .getGrid()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        (response) => {
          this.dadosGrid = response;
          if (this.dadosGrid && this.dadosGrid.length > 0) {
            this.hasAnEmail = true;
          } else {
            this.hasAnEmail = false;
          }
        },
        (err) => {
          this.alertaService.erro();
        }
      );
  }

  refresh() {
    this.configuraDadosGrid();
  }

  refreshGridData(dados: IContaEmail, tipo) {
    let indexPage = -1;
    if (tipo !== 'add') {
      indexPage = _.findLastIndex(this.dadosGrid, {
        contaEmailId: dados.contaEmailId
      });
      this.hasAnEmail = false;
    }

    this.hasAnEmail = true;
    this.dadosGrid = this.gridService.refreshGridData(
      this.dadosGrid,
      dados,
      indexPage,
      tipo
    );
  }

  deleteConta(dados: IContaEmail) {
    const dialogConfirm = this.dialogService.confirmDelete();

    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.show();

        this.contaEmailService
          .delete(dados.contaEmailId)
          .pipe(
            finalize(() => {
              this.loadingService.hide();
              this.hasAnEmail = false;
            })
          )
          .subscribe((response) => {
            this.refreshGridData(dados, 'del');
            this.alertaService.sucesso();
            this.dialog.closeAll();
            this.closeContaEmailForm();
          });
      }
    });
  }

  openForm(dados: IContaEmail = null) {
    this.dadosFormulario = dados;
    this.showForm = true;
  }

  closeContaEmailForm() {
    this.showForm = false;
  }
}
