import { Component, OnInit} from '@angular/core';
import { ConfiguracaoService } from '../../services/configuracao/configuracao.service';
import { MatDialogRef } from '@angular/material/dialog';

import { IConfiguracaoAbout } from './models/IConfiguracaoAbout';

import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public registro: IConfiguracaoAbout;

  constructor(
    public configService: ConfiguracaoService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<AboutComponent>
  ) {}

  ngOnInit() {
    this.loadInfoAbout();
  }

  loadInfoAbout() {
    const dados = this.configService.getAbout();
    dados.subscribe(res => {
      this.registro = res;
    });
  }
}
