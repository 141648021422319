<div class="modal" modal-name="modalEquipamentoForm">
  <div class="box"
    cdkDragBoundary=".mat-tab-body-wrapper"
    cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <mat-tab-group
        (selectedTabChange)="onTabClick($event)"
        animationDuration="0ms"
        style="width: 100%">
        <mat-tab
          label="{{
            'paginas.equipamento.tabs.titles-tabs.cadastro' | translate
          }}">
          <form *ngIf="formulario"
            [formGroup]="formulario"
            (ngSubmit)="save()"
            cdkTrapFocus>

            <div class="modal-content">
              <input type="hidden" formControlName="sawId" />

              <mat-form-field class="fieldPrefixo" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.prefixo }}
                </mat-label>
                <input
                  #prefixo
                  [focusMe]="true"
                  matInput
                  type="text"
                  maxLength="200"
                  placeholder="{{ wordsTranslated.prefixo }}"
                  autocomplete="off"
                  formControlName="prefixo"/>
              </mat-form-field>

              <ws-combo class="fieldComboEmpresa"
                [textoLabel]="wordsTranslated.empresaId"
                [multiple]="false"
                [selecionarTodos]="false"
                [itemCtrl]="f.empresaId"
                [items]="itensComboEmpresa"
                [itemSelecionado]="itemSelecionadoComboEmpresa"
                (changeEventCombo)="onChangeComboEmpresa($event)">
              </ws-combo>

              <mat-form-field  class="fieldNumeroSerie" appearance="outline">
                <mat-label>
                  {{ wordsTranslated.numeroSerie }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  maxLength="100"
                  placeholder="{{ wordsTranslated.numeroSerie }}"
                  autocomplete="off"
                  formControlName="numeroSerie"/>
              </mat-form-field>

                <ws-combo class="fieldComboRodoviaId"
                  [textoLabel]="wordsTranslated.rodoviaId"
                  [multiple]="false"
                  [selecionarTodos]="false"
                  [itemCtrl]="f.rodoviaId"
                  [items]="itensComboRodovia"
                  [itemSelecionado]="itemSelecionadoComboRodovia"
                  (changeEventCombo)="onChangeComboRodovia($event)">
                </ws-combo>

                <ws-combo class="fieldComboLocalizacaoRodovia"
                  [textoLabel]="wordsTranslated.localizacaoRodoviaId"
                  [multiple]="false"
                  [selecionarTodos]="false"
                  [itemCtrl]="f.localizacaoRodoviaId"
                  [items]="itensComboLocalizacaoRodovia"
                  [itemSelecionado]="
                    itemSelecionadoComboLocalizacaoRodovia
                  "
                  (changeEventCombo)="onChangeComboLocalizacao($event)">
                </ws-combo>

                <ws-combo class="fieldComboSentido"
                  [textoLabel]="wordsTranslated.sentidoId"
                  [multiple]="false"
                  [selecionarTodos]="false"
                  [itemCtrl]="f.sentidoId"
                  [items]="itensComboSentido"
                  [itemSelecionado]="itemSelecionadoComboSentido"
                  (changeEventCombo)="onChangeComboSentido($event)">
                </ws-combo>

                <mat-form-field class="fieldKmLocal" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.kmLocal }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    placeholder="{{ wordsTranslated.kmLocal }}"
                    autocomplete="off"
                    formControlName="kmLocal"/>
                </mat-form-field>

                <mat-form-field class="fieldKmNominal" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.kmNominal }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    placeholder="{{ wordsTranslated.kmNominal }}"
                    autocomplete="off"
                    formControlName="kmNominal"/>
                </mat-form-field>

                <mat-form-field class="fieldIpPrimario" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.ipPrimario }}
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ wordsTranslated.ipPrimario }}"
                    autocomplete="off"
                    formControlName="ipPrimario"/>
                </mat-form-field>

                <mat-form-field class="fieldIpSecundario" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.ipSecundario }}
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ wordsTranslated.ipSecundario }}"
                    autocomplete="off"
                    formControlName="ipSecundario"/>
                </mat-form-field>

                <mat-form-field class="fieldRamal" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.ramal }}
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    maxlength="10"
                    placeholder="{{ wordsTranslated.ramal }}"
                    autocomplete="off"
                    formControlName="ramal"/>
                </mat-form-field>
                <mat-form-field class="fieldRA" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.rA }}
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    maxlength="60"
                    placeholder="{{ wordsTranslated.rA }}"
                    autocomplete="off"
                    formControlName="ra"/>
                </mat-form-field>

                <mat-form-field class="fieldLatitude" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.latitude }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    placeholder="{{ wordsTranslated.latitude }}"
                    autocomplete="off"
                    formControlName="latitude"/>
                </mat-form-field>

                <mat-form-field class="fieldLongitude" appearance="outline">
                  <mat-label>
                    {{ wordsTranslated.longitude }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    placeholder="{{ wordsTranslated.longitude }}"
                    autocomplete="off"
                    formControlName="longitude"/>
                </mat-form-field>

                <mat-form-field class="fieldEndereco" appearance="outline">
                  <mat-label>{{ wordsTranslated.endereco }}</mat-label>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    [maxLength]="1000"
                    autocomplete="off"
                    formControlName="endereco"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="3">
                  </textarea>
                </mat-form-field>

                <app-equipamento-mapa class="fieldMapa"
                  [latitude]="latitude"
                  [longitude]="longitude"
                ></app-equipamento-mapa>

            </div>

            <mat-progress-bar
              mode="indeterminate"
              *ngIf="formLoading">
            </mat-progress-bar>

            <div class="modal-actions">
              <div></div>
              <div class="buttons">
                <button
                  mat-button
                  type="button"
                  class="primary add"
                  *ngIf="permissoes['create']"
                  (click)="add()"
                  [disabled]="!statusBotao.adicionar"
                >
                  <i class="material-icons">add</i>
                  <span class="text">{{ "botoes.adicionar" | translate }}</span>
                </button>
                <button
                  mat-button
                  type="button"
                  class="primary delete"
                  *ngIf="permissoes['delete']"
                  (click)="del()"
                  [disabled]="!statusBotao.deletar"
                >
                  <i class="material-icons">delete</i>
                  <span class="text">{{ "botoes.deletar" | translate }}</span>
                </button>
                <button
                  mat-button
                  type="button"
                  class="primary cancel"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  (click)="cancel()"
                  [disabled]="!statusBotao.cancelar"
                >
                  <i class="material-icons">block</i>
                  <span class="text">{{ "botoes.cancelar" | translate }}</span>
                </button>
                <button
                  type="submit"
                  mat-button
                  class="save"
                  *ngIf="
                    (permissoes['create'] && !registro) ||
                    (permissoes['update'] && registro)
                  "
                  [disabled]="formulario.invalid || !statusBotao.salvar"
                >
                  <i class="material-icons">check</i>
                  <span class="text">{{ "botoes.salvar" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </mat-tab>

        <mat-tab
        label="{{
          'paginas.equipamento.tabs.titles-tabs.arquivos' | translate
        }}"
            *ngIf="this.registro !== null"
          >
            <app-equipamento-arquivo-grid
              [sawId]="this.registro.sawId"
              *ngIf="tabArquivosAtiva"
            ></app-equipamento-arquivo-grid>
          </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>