<div class="modal" modal-name="modalUsuarioAtendenteEmpresaForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{titleDialog}}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form class="default" *ngIf="formulario" [formGroup]="formulario" (ngSubmit)="save()" cdkTrapFocus>
        <div class="modal-content">
          <div class="fieldUsuario">
            <ws-combo [disabled]="registro" [textoLabel]="'paginas.usuario-atendente.form.usuario' | translate"
              [itemCtrl]="f.usuarioId" [items]="itensComboUsuario" [itemSelecionado]="itemSelecionadoComboUsuario"
              (changeEventCombo)="onChangeComboUsuario($event)">
            </ws-combo>
          </div>
          <mat-form-field class="fieldRamal" appearance="outline">
            <mat-label>{{'paginas.usuario-atendente.form.ramal' | translate}}</mat-label>
            <input matInput placeholder="{{'paginas.usuario-atendente.form.ramal' | translate}}"
              formControlName="ramal" autocomplete="off" />
          </mat-form-field>
          <mat-form-field class="fieldSenha" appearance="outline">
            <mat-label>{{'paginas.usuario-atendente.form.senha' | translate}}</mat-label>
            <input matInput placeholder="{{'paginas.usuario-atendente.form.senha' | translate}}"
              formControlName="senha" autocomplete="off" />
          </mat-form-field>
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="formLoading"></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button mat-button type="button" class="primary cancel" (click)="cancel()"
              [disabled]="!statusBotao.cancelar">
              <i class="material-icons">block</i> <span class="text">{{ 'botoes.cancelar' | translate }}</span>
            </button>
            <button type="submit" mat-button class="save" [disabled]="formulario.invalid || !statusBotao.salvar">
              <i class="material-icons">check</i> <span class="text">{{ 'botoes.salvar' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>