import { Component, OnInit } from '@angular/core';
import { ISAW } from 'src/app/shared/models/ISaw';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { PerguntasEquipamentoService } from '../../services/perguntas-equipamento.service';

@Component({
  selector: 'app-saw-pergunta-principal-grid',
  templateUrl: './saw-pergunta-principal-grid.component.html',
  styleUrls: ['./saw-pergunta-principal-grid.component.scss'],
})
export class SawPerguntaPrincipalGridComponent implements OnInit {
  selectedSaw: ISAW = null;
  showGridPerguntas: boolean = false;
  wordsTranslated: any;
  titleNoContent: string = "";
  textNoContent: string = "";

  constructor(
    private wordsTranslate: WordsTranslateService,
    private perguntasEquipamentoService: PerguntasEquipamentoService) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.perguntas-equipamento.principal.';
    const words = {
      tituloNoContent: '',
      textNoContent: '',
      tituloHasNoPerguntas: '',
      textHasNoPerguntas: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    this.textNoContent = this.wordsTranslated.textNoContent;
    this.titleNoContent = this.wordsTranslated.tituloNoContent;
  }
  ngOnInit(): void {}

  setSawId(selectedSaw: ISAW) {
    this.perguntasEquipamentoService
    .getPerguntasGrid(selectedSaw.sawId)
    .subscribe((response) => {
      this.showGridPerguntas = response.length > 0 ? true : false;
      this.textNoContent = this.wordsTranslated.textHasNoPerguntas;
      this.titleNoContent = this.wordsTranslated.tituloHasNoPerguntas;
    });
    
    this.selectedSaw = selectedSaw;
  }
}
