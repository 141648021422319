import { Component, OnInit } from '@angular/core';
import { ISAW } from 'src/app/shared/models/ISaw';
import { WordsTranslateService } from 'src/app/shared/services/translate/words.translate';
import { TelefonesUteisEquipamentoService } from '../../services/telefones-uteis-equipamento.service';

@Component({
  selector: 'app-saw-telefones-principal-grid',
  templateUrl: './saw-telefones-principal-grid.component.html',
  styleUrls: ['./saw-telefones-principal-grid.component.scss'],
})
export class SawTelefonesPrincipalGridComponent implements OnInit {
  selectedSaw: ISAW = null;
  showGridTelefones: boolean = false;
  wordsTranslated: any;
  titleNoContent: string = "";
  textNoContent: string = "";

  constructor(
    private wordsTranslate: WordsTranslateService,
    private telefonesEquipamentoService: TelefonesUteisEquipamentoService) {
    this.loadWordsTranslate();
  }

  loadWordsTranslate() {
    const keyRoot = 'paginas.telefones-equipamento.principal.';
    const words = {
      tituloNoContent: '',
      textNoContent: '',
      tituloHasNoTelefones: '',
      textHasNoTelefones: ''
    };
    this.wordsTranslated = this.wordsTranslate.get(words, keyRoot);

    this.textNoContent = this.wordsTranslated.textNoContent;
    this.titleNoContent = this.wordsTranslated.tituloNoContent;
  }
  ngOnInit(): void {}

  setSawId(selectedSaw: ISAW) {
    this.telefonesEquipamentoService
    .getTelefonesGrid(selectedSaw.sawId)
    .subscribe((response) => {
      this.showGridTelefones = response.length > 0 ? true : false;
      this.textNoContent = this.wordsTranslated.textHasNoTelefones;
      this.titleNoContent = this.wordsTranslated.tituloHasNoTelefones;
    });
    this.selectedSaw = selectedSaw;
  }
}