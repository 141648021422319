import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IParametrosConfiguracaoSistema } from '../models/IParametrosConfiguracaoSistema';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { IParametrosConfiguracaoSistemaForm } from '../models/IParametrosConfiguracaoSistemaForm';
import { EnvironmentService } from 'src/app/shared/services/env/environment.service';

const telaId = 2;

@Injectable({ providedIn: 'root' })
export class ParametrosConfiguracaoSistemaService {
  constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

  apiUrl = `${this.env.apiUrl}ConfiguracaoSistema`;

  getGrid(): Observable<IParametrosConfiguracaoSistema[]> {
    return this.http.get<IParametrosConfiguracaoSistema[]>(`${this.apiUrl}/Grid`);
  }

  getForm(dados: IParametrosConfiguracaoSistema) {
    return this.http.get<IParametrosConfiguracaoSistema>(
      `${this.apiUrl}/Form/${dados.parametroSistemaId}`
    );
  }

  update(dados: IParametrosConfiguracaoSistemaForm) {
    return this.http.put<IParametrosConfiguracaoSistemaForm>(
      `${this.apiUrl}`,
      dados
    );
  }

  getPermissoes() {
    return this.authService.getPermissoes(telaId);
  }
}
