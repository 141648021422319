<div class="geral" [ngClass]="showSoftPhone ? 'content-soft-phone' : ''">
  <mat-sidenav-container fullscreen class=" sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport>
      <div class="logo">
        <img *ngIf="!logoEmpresa" src="/assets/images/logo-menu-saw.svg" alt="" />
        <div *ngIf="logoEmpresa" class="empresa"
          [ngStyle]="logoEmpresa ? {'background-image':'url(' + logoEmpresa.toString() + ')'} : ''">
        </div>
      </div>

      <mat-accordion *ngIf="menuAplicacao">
        <mat-expansion-panel *ngFor="let menu of menuAplicacao">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="iconeMenu" [innerHTML]="menu['icon']"></div>
              {{ menu['title'] }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list *ngIf="menu['itens']">
            <a mat-list-item *ngFor="let item of menu['itens']"
              (click)="drawer.toggle(); addTab(item['title'], item['page'])">
              {{ item['title'] }}
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-sidenav>

    <mat-toolbar>
      <div class="bloco-1">
        <button (click)="drawer.toggle()" class="secondary">
          <i class="material-icons">menu</i>
        </button>

        <button (click)="removeTab(0, true)" class="secondary fechar-abas">
          <i class="material-icons close">close</i>
          <i class="material-icons">tab_unselected</i>
        </button>

        <button (click)="loadItensMenu()" class="secondary">
          <i class="material-icons">refresh</i>
        </button>

        <form class="formMenuAutoComplete" *ngIf="filteredOptions">
          <mat-form-field>
            <input type="text" matInput [formControl]="formAutoCompleteControl" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <div class="categoria" *ngFor="let menu of filteredOptions | async" value="menu['title']">
                <small class="secondary">{{ menu['title'] }}</small>
                <mat-option *ngFor="let item of menu['itens']"
                  (onSelectionChange)="addTab(item['title'], item['page'])">
                  {{ item['title'] }}
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
      <div class="bloco-2">
        <img src="/assets/images/logo-topo-saw.svg" alt="" />
      </div>
      <div class="bloco-3" *ngIf="dadosUsuario">

        <app-status-soft-phone *ngIf="statusSoftPhone && !exibirLogin" [acesso]="acessoSoftPhone"></app-status-soft-phone>

        <button class="secondary">
          <i class="material-icons">notifications</i>
        </button>
        <button class="secondary" [matMenuTriggerFor]="menuUser">
          <div *ngIf="imageUser" class="imageUser" [ngStyle]="{ 'background-image': imageUser}"></div>
          <i *ngIf=" !imageUser" class="material-icons">account_circle</i>
        </button>

        <mat-menu class="menu-usuario" #menuUser="matMenu">
          <h2>{{ dadosUsuario.dados.nome }}</h2>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="showChangePassword()">
            <i class="material-icons">lock</i>
            <span>Alterar Senha</span>
          </button>
          <button mat-menu-item (click)="showAbout()">
            <i class="material-icons">info</i>
            <span>Sobre</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()">
            <i class="material-icons">exit_to_app</i>
            <span>Sair</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <mat-tab-group *ngIf="tabs.length > 0" [@.disabled]="true" [selectedIndex]="selected.value"
      (selectedIndexChange)="onSelectedIndexChange($event)">
      <mat-tab *ngFor="let tab of tabs; let index = index">
        <ng-template mat-tab-label>
          <i class="material-icons" (click)="removeTab(index)">close</i>
          {{ tab.label }}
        </ng-template>
        <ng-container class="content-tab" *ngComponentOutlet="tab.component"></ng-container>
      </mat-tab>
    </mat-tab-group>

    <div class="dashboard"
      [ngStyle]="wallPaperImage != false ? {'background-image':'url(' + wallPaperImage.toString() + ')'} : ''">
      <app-shortcut *ngIf="registroShortCut" (addTab)="openShortCut($event)" [registro]="registroShortCut">
      </app-shortcut>
    </div>
  </mat-sidenav-container>

  <app-window-soft-phone class="soft-phone" *ngIf="showSoftPhone"></app-window-soft-phone>

</div>

<app-login *ngIf="!showSegurancaModal" (checkLogin)="checkLogin()" (clearTabs)="removeTab(null, true)" [exibirLogin]="exibirLogin"></app-login>
<app-loading *ngIf="!showSegurancaModal"></app-loading>
<app-image-zoom *ngIf="!false"></app-image-zoom>
<app-legenda></app-legenda>

<app-licenca *ngIf="showLicencaModal"></app-licenca>
