<div class="modal" modal-name="modalModuloMenuForm">
  <div class="box" cdkDragBoundary=".mat-tab-body-wrapper" cdkDrag>
    <div class="modal-container">
      <div class="modal-title" cdkDragHandle>
        <h2>{{ titleDialog }}</h2>
        <button mat-button (click)="close()" class="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <form
        class="default"
        *ngIf="formulario"
        [formGroup]="formulario"
        (ngSubmit)="save()"
        cdkTrapFocus
      >
        <div class="modal-content">
          <mat-form-field class="fieldMenuTelaPortugues" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.menu.form.menuTelaPortugues" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              formControlName="menuTelaPortugues"
            />
          </mat-form-field>
          <mat-form-field class="fieldMenuTelaIngles" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.menu.form.menuTelaIngles" | translate
            }}</mat-label>
            <input matInput maxLength="1000" formControlName="menuTelaIngles" />
          </mat-form-field>
          <mat-form-field class="fieldMenuTelaEspanhol" appearance="outline">
            <mat-label>{{
              "paginas.seguranca.menu.form.menuTelaEspanhol" | translate
            }}</mat-label>
            <input
              matInput
              maxLength="1000"
              formControlName="menuTelaEspanhol"
            />
          </mat-form-field>
          <ws-combo
            class="fieldModulo"
            [textoLabel]="wordsTranslated.modulo"
            [multiple]="false"
            [selecionarTodos]="false"
            [itemCtrl]="f.aplicacaoModuloId"
            [items]="itensComboModulo"
            [itemSelecionado]="itemSelecionadoComboModulo"
            (changeEventCombo)="onChangeComboModulo($event)"
          >
          </ws-combo>
          <div class="fieldRelatorio">
            <mat-slide-toggle
              [disabled]="formulario.value.aplicacaoTelaId == 38"
              class="fieldRelatorio"
              formControlName="relatorio"
              (change)="onChangeRelatorio($event)"
            >
              {{ labelRelatorio }}
            </mat-slide-toggle>
          </div>
          <div class="fieldLeitura">
            <mat-slide-toggle
              [disabled]="formulario.value.aplicacaoTelaId == 38"
              class="fieldLeitura"
              formControlName="leitura"
              (change)="onChangeLeitura($event)"
            >
              {{ labelLeitura }}
            </mat-slide-toggle>
          </div>
          <div class="fieldInserir">
            <mat-slide-toggle
              [disabled]="formulario.value.aplicacaoTelaId == 38"
              class="fieldInserir"
              formControlName="inserir"
              (change)="onChangeInserir($event)"
            >
              {{ labelInserir }}
            </mat-slide-toggle>
          </div>
          <div class="fieldAtualizar">
            <mat-slide-toggle
              [disabled]="formulario.value.aplicacaoTelaId == 38"
              class="fieldAtualizar"
              formControlName="atualizar"
              (change)="onChangeAtualizar($event)"
            >
              {{ labelAtualizar }}
            </mat-slide-toggle>
          </div>
          <div class="fieldExcluir">
            <mat-slide-toggle
              [disabled]="formulario.value.aplicacaoTelaId == 38"
              class="fieldExcluir"
              formControlName="excluir"
              (change)="onChangeExcluir($event)"
            >
              {{ labelExcluir }}
            </mat-slide-toggle>
          </div>
        </div>

        <mat-progress-bar
          mode="indeterminate"
          *ngIf="formLoading"
        ></mat-progress-bar>

        <div class="modal-actions">
          <div></div>
          <div class="buttons">
            <button
              mat-button
              type="button"
              class="primary cancel"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              (click)="cancel()"
              [disabled]="!statusBotao.cancelar"
            >
              <i class="material-icons">block</i>
              <span class="text">{{ "botoes.cancelar" | translate }}</span>
            </button>

            <button
              type="submit"
              mat-button
              class="save"
              *ngIf="
                (permissoes['create'] && !registro) ||
                (permissoes['update'] && registro)
              "
              [disabled]="formulario.invalid || !statusBotao.salvar"
            >
              <i class="material-icons">check</i>
              <span class="text">{{ "botoes.salvar" | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
